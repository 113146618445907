export class DocumentModel {
    constructor(
      public documentType: string,
      public documentName: string,
      public documentExtension: string,
      public contentGuid: string,
      public isRequired: boolean,
      public uploadedDate?: Date,
      public publishedDate?: Date,
      public documentTypeId?: number,
      public documentLocation?: string,
      public documentSize?: string,
      public uploadedBy?: string,
      public version?: string) {}
  }
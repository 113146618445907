import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContraventionStatusTypes, RepresentationTypes } from '@apis/shared/enums/app.enum';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { PaymentHistory } from '@apis/shared/models/payment-history.model';
import { TransactionLineItem } from '@apis/shared/models/transaction-line-item.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContraventionPaymentRequest } from 'apps/driver/src/app/shared/models/contravention-payment-request.model';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverBaseComponent } from '../../../driver-base.component';
import { ContraventionPageComponent } from '../../contravention-page/contravention-page.component';
import * as fileSaver from "file-saver";

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent extends DriverBaseComponent implements OnInit {
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;
  
  RepresentationTypes = RepresentationTypes;
  ContraventionStatusTypes = ContraventionStatusTypes;

  contraventionNumber: string;
  paymentHistory: PaymentHistory;
  contraventionOverview: ContraventionOverview;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private contraventionService: ContraventionService,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService) {
    super(router);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');

      this.contraventionOverview = this.localStorageService.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      setTimeout(() => {
        this.loadPaymentHistory();
      });
    });
  }

  loadPaymentHistory() {
    this.masterPage.showSpinner();

    this.contraventionService.getPaymentHistroy(this.contraventionNumber)
      .subscribe((paymentHistory: PaymentHistory) => { 
        this.paymentHistory = paymentHistory;

        this.masterPage.hideSpinner();
      });
  }

  canMakePayment() {
    return this.contraventionOverview.contraventions
      .some(c => c.contraventionStatusTypeId != ContraventionStatusTypes.Paid
        && c.contraventionStatusTypeId != ContraventionStatusTypes.Cancelled
        && c.contraventionStatusTypeId != ContraventionStatusTypes.Uncollectable
        && c.contraventionStatusTypeId != ContraventionStatusTypes.Stay);
  }

  makePayment() {
    const paymentRequest = new ContraventionPaymentRequest();
    
    paymentRequest.isSelfSubmit = true;
    paymentRequest.paymentAmount = 0;
    paymentRequest.recordNumber = this.contraventionNumber;

    this.localStorageService.setContraventionPaymentRequest(paymentRequest);
    
    this.router.navigate(['../'], {relativeTo: this.activatedRoute});
  }

  onViewReceiptClick(transactionId) {
    this.spinner.show();
    this.contraventionService.getPaymentReceipt(this.contraventionNumber, Number.parseInt(transactionId))
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Contravention - ${this.contraventionNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
   }
}

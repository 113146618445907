import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, find, catchError } from "rxjs/operators";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { APP_CONFIG } from '@apis/app-config';
import { ContraventionSearch } from '@apis/shared/models/contravention-search.model';
import { VehicleSeizureSearch } from '@apis/shared/models/vehicle-seizure-search.model';
import { LiteSearchResult } from '@apis/shared/models/lite-search-result.model';

@Injectable()
export class DriverService {
  private readonly apiUrl = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;

  constructor(private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private appConfig: any) { }


    lookupContraventionAsync(contraventionNumber: string, recaptchaToken: string): Observable<LiteSearchResult> {
        return this.httpClient.get(`${this.apiUrl}/contraventions/lookup/${contraventionNumber}`,        
        {
            headers: {
                "X-RECAPTCHA-TOKEN": recaptchaToken
            }
        }).pipe(
            map(response => new LiteSearchResult(response))
          );
    }

    searchContraventionAsync(contraventionSearch: ContraventionSearch, recaptchaToken: string): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}/contraventions/search`,
        contraventionSearch,
        {
            headers: {
                "X-RECAPTCHA-TOKEN": recaptchaToken
            }
        });
    }

    searchVehicleSeizureAsync(vehicleSeizureSearch: VehicleSeizureSearch, recaptchaToken: string): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}/vehicle-seizures/search`,
        vehicleSeizureSearch,
        {
            headers: {
                "X-RECAPTCHA-TOKEN": recaptchaToken
            }
        });
    }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SeizureLateReviewBaseComponent } from '../../../../vehicle-seizure/late-review/seizure-late-review-base.component';
import { Constants } from '@apis/shared/helpers/constants';
declare var $: any

@Component({
  selector: 'app-seizure-late-review-terms-and-conditions',
  templateUrl: './seizure-late-review-terms-and-conditions.component.html',
  styleUrls: ['./seizure-late-review-terms-and-conditions.component.scss']
})
export class SeizureLateReviewTermsAndConditionsComponent extends SeizureLateReviewBaseComponent implements OnInit {
  Constants=Constants;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService
  ) { 
    super(router, activatedRoute, localStorageService);
  }

  ngOnInit(): void {
  }

  onProceedClick(isValid: boolean)
  {
    if(isValid)
      $('#confirmModal').modal();
  }

  onContinueClick()
  {
    this.router.navigate(['../details'], { relativeTo: this.activatedRoute, });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DailyBalanceRequest } from '@apis/shared/models/daily-balance-request.model';
import { DailyBalanceResponse } from '@apis/shared/models/daily-balance-response.model';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { RegistryService } from 'apps/driver/src/app/shared/services/registry.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-daily-balance-report',
  templateUrl: './daily-balance-report.component.html',
  styleUrls: ['./daily-balance-report.component.scss']
})
export class DailyBalanceReportComponent implements OnInit {
  dailyBalanceRequest: DailyBalanceRequest = new DailyBalanceRequest();
  dailyBalanceResponseList: DailyBalanceResponse[];
  datePickerConfig: Partial<BsDatepickerConfig>;
  rsgAgentProfile: RsgAgentProfile;
  totalAmount: number = 0;

  constructor(private registryService: RegistryService,
    private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router) {
    this.datePickerConfig = Object.assign({}, 
      {
        containerClass: 'theme-dark-blue', 
        showWeekNumbers: false,
        dateInputFormat: 'MM/DD/YYYY',
        isAnimated: true
      })
   }

  ngOnInit(): void {
    this.rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
      if (!this.rsgAgentProfile)
        this.router.navigate(['/unauthorized']);

    this.dailyBalanceRequest.registryAgentOrganizationId = this.rsgAgentProfile.organization;
    this.dailyBalanceRequest.registryAgentId = this.rsgAgentProfile.userName;
  }

  onResetFilter()
  {
    this.dailyBalanceRequest = new DailyBalanceRequest();
    this.dailyBalanceRequest.registryAgentOrganizationId = this.rsgAgentProfile.organization;
    this.dailyBalanceRequest.registryAgentId = this.rsgAgentProfile.userName;
    this.dailyBalanceResponseList = null;
    this.totalAmount = 0;
  }

  onSearchClick(isValid: boolean){
    if (isValid)
    {
      this.spinner.show();
      this.registryService.getDailyBalanceReport(this.dailyBalanceRequest)
      .pipe(
        finalize(() => {
          this.spinner.hide(); 
        })
      )
      .subscribe((result) => {
        this.dailyBalanceResponseList = result;
        const totalAmount = this.dailyBalanceResponseList.reduce((totalAmount, current) => totalAmount + current.transactionAmount, 0);
        this.totalAmount = totalAmount;
      }, ()=> {
        this.dailyBalanceResponseList = null;
        this.totalAmount = 0;
      },
      () => { this.spinner.hide(); });
    }
    else
    {
      this.dailyBalanceResponseList = null;
      this.totalAmount = 0;
    }
  }

  printReport(){
    if (this.dailyBalanceResponseList?.length>0)
      window.print();
  }
}

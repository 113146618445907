import { Router } from '@angular/router';

export abstract class DriverBaseComponent {
  isRSGPortal: boolean = false;

  constructor(
    public router: Router,
  ) {
    this.isRSGPortal = this.router.url.indexOf('/rsg/') >= 0;
  }

  printSummary(){
    window.print();
  }
}

<app-contravention-page [suppressConfirmation]="true" #masterPage>
    <ng-container content>
        <form *ngIf="paymentHistory">
        <div class="d-flex flex-column">
            <div class="title-container border-bottom pb-0 mb-3">
                <div>
                    <h1>Payment History</h1>
                    <h3>{{contraventionNumbers}}</h3>
                </div>
                <div class="info-btn-container">
                    <button class="btn-block goa-button button-pay mt-0"
                        *ngIf="!isRSGPortal && canMakePayment()" (click)="makePayment()">Pay Now</button>
                </div>
            </div>

            <div class="summary-item-container pb-4">
                <div class="info-item">
                    <div class="d-flex flex-row">
                        <div>
                            <div class="p-2 icon-circle penalty-amount">
                            </div>
                        </div>
                        <div class="d-flex flex-column ml-3 pt-1">
                            <div class="summary-item-label">Original Penalty Amount</div>
                            <div class="summary-item-value">{{ paymentHistory.originalPenaltyAmount | currency }}</div>
                        </div>
                    </div>
                </div>    
                <div class="info-item">
                    <div class="d-flex flex-row">
                        <div>
                            <div class="p-2 icon-circle exclamation">
                            </div>
                        </div>
                        <div class="d-flex flex-column ml-3 pt-1">
                            <div class="summary-item-label">Balance Owing</div>
                            <div class="summary-item-value">{{ paymentHistory.totalDueAmount | currency }}</div>
                        </div>
                    </div>
                </div>    
                <div class="info-item">
                    <div class="d-flex flex-row">
                        <div>
                            <div class="p-2 icon-circle pay-penalty">
                            </div>
                        </div>
                        <div class="d-flex flex-column ml-3 pt-1">
                            <div class="summary-item-label">Full Payment Due</div>
                            <div class="summary-item-value">{{ paymentHistory.fullPaymentDueDate | date:'mediumDate' }}</div>
                        </div>
                    </div>
                </div>             
            </div>

            <div class="card mb-4">
                <div class="card-header">
                    Payment Extensions
                </div>
                <div class="card-body mb-2">
                    <div class="time-line mt-3">
                        <div class="time-line-item flex-fill">
                            <div class="point"></div>
                            <div class="time-line-text">
                                <div>
                                    Original Due Date
                                </div>
                                <div>
                                    {{ paymentHistory.originalDueDate | date:'mediumDate' }}
                                </div>
                            </div>
                        </div>  
                        <div class="time-line-item flex-fill" *ngIf="paymentHistory.extendedDueDate">
                            <div class="point"></div>
                            <div class="time-line-text">
                                <div>
                                Extended Due Date
                            </div>
                            <div>
                                {{ paymentHistory.extendedDueDate | date:'mediumDate' }}
                            </div>
                            </div>  
                        </div>
                        <div class="time-line-item flex-fill" *ngIf="paymentHistory.additionallyExtendedDueDate">
                            <div class="point"></div>
                            <div class="time-line-text">
                                <div>
                                Aditionally Extended Due Date
                            </div>
                            <div>
                                {{ paymentHistory.additionallyExtendedDueDate | date:'mediumDate' }}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-header">
                    Payment Overview
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Sum of Previous Payments</div>
                        <div><h5>{{ paymentHistory.totalPaidAmount | currency}}</h5></div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Remaining Balance</h5></div>
                        <div><h5>{{ paymentHistory.totalDueAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Payment History
                </div>
                <div class="card-body">
                    <div class="">
                        <div class="goa-table history-table mt-0">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Payment Date</th>
                                        <th class="border-0">Transaction ID</th>
                                        <th class="border-0 amount-heading">Amount</th>
                                        <th *ngIf="!isRSGPortal" class="border-0">Receipt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of paymentHistory.payments">
                                        <td class="date-cell">{{ p.transactionDate | date:'mediumDate' }}</td>
                                        <td>{{ p.isPaidAtRegistry ? p.transactionId : (p.financialTransactionId | transactionid) }}</td>
                                        <td class="amount-cell">{{ p.amount | currency }}</td>
                                        <td *ngIf="!isRSGPortal" class="receipt-cell">
                                            <a class="receipt-link" *ngIf="!p.isPaidAtRegistry"
                                            (click)="onViewReceiptClick(p.financialTransactionId)">Download</a>
                                        </td>
                                    </tr>                            
                                    <tr *ngIf="paymentHistory.payments.length == 0">
                                        <td colspan="8">No records found.</td>
                                    </tr>
                                </tbody>                        
                            </table>
                        </div>
                    </div>            
                </div>
            </div>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../../']">Back to Contravention Overview</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-contravention-page>
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Review } from '@apis/shared/models/review.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { ReviewMethodTypes, ReviewTypes, MeetingTypes } from '@apis/shared/enums/app.enum';
import { DriverBaseComponent } from '../../../driver-base.component';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { Observable, of } from 'rxjs';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { DatePipe } from '@angular/common';

export abstract class SeizureReviewBaseComponent extends DriverBaseComponent {
  review: Review;
  vehicleSeizure: VehicleSeizure;
  tempFolderName: string;
  ReviewTypes = ReviewTypes;
  reviewContext = "Review";

  datePipe: DatePipe = new DatePipe("en-US");

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorage: LocalStorageService,
    public schedulerService: SchedulerService
  ) {
    super(router);
    this.localStorage.setCreatedReview(null); // If we have created a review, clear it before making a new one
    if (!this.review) {
      this.review = this.localStorage.getReviewRequest();

      // Navigate to seizure details page if review request is null
      if (this.review == null) {
        this.router.navigate(['../../../'], {
          relativeTo: this.activatedRoute,
        });
      }

      this.vehicleSeizure = this.localStorage.getItem<VehicleSeizure>(
        this.review.seizureNumber
      );
      this.tempFolderName = this.localStorage.getTempFolderName();
    }

    if (this.review.reviewTypeId == this.ReviewTypes.ThirdPartySeizureReview) {
      this.reviewContext = "Relief";
    }
  }

  public saveContext() {
    this.localStorage.setReviewRequest(this.review);
  }

  public get countdownKey() {
    return `${this.review.seizureNumber}-C-${this.review.scheduledEventId}`;
  }

  public get lapseMessage() {
    return `Reserved review time expired`;
  }

  public resetReviewSchedule() {
    this.review.scheduledEventId = null;
    this.review.scheduledEvent = null;
    
    this.review.reviewTime = null;
    this.review.reviewDate = null;
  }

  cancelRequest() {
    this.localStorage.setReviewRequest(null);
    this.releaseEvent().subscribe();
  }

  getAttendee() {
    if (this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview) {
      return `${this.review.reviewApplicant.lastName} ${this.review.reviewApplicant.firstName} ${this.review.reviewApplicant.otherName??''}`;
    } else {
      return `${this.vehicleSeizure.recipient.lastName} ${this.vehicleSeizure.recipient.firstName} ${this.vehicleSeizure.recipient.otherName??''}`;
    }
  }

  getEventTitle() {
    let recipientLastName = this.vehicleSeizure.recipient.lastName;
    if (this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview) {
      recipientLastName = this.review.reviewApplicant.lastName;
    }

    return `${this.review.seizureNumber} ${recipientLastName}`;
  }  

  reserveEvent() : Observable<ScheduledEvent> {
    var meetingTypeId = MeetingTypes.SeizureReview;

    var event = new ScheduledEvent({
      eventDate: this.datePipe.transform(this.review.reviewDate, 'M/d/yyyy', 'UTC'),
      eventTime: this.review.reviewTime,
      duration: 30,
      meetingTypeId: meetingTypeId,
      title: this.getEventTitle(),
      attendees: this.getAttendee(),
      recordNumber: this.review.seizureNumber
    });

    return this.schedulerService.reserveEvent(event);    
  }
  
  releaseEvent() {
    if (this.review.scheduledEventId) {
      return this.schedulerService.releaseEvent(this.review.scheduledEventId)
    }

    return of(true);
  }
}

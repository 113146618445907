<div class="card">
    <div class="card-header">
        Late Review Request No. {{ lateReviewRequest.lateReviewRequestNumber }}
    </div>
    <div class="card-body">
        <app-section-details [sectionTitle]="''" [sectionDetails]="requestDetailsInformation"></app-section-details>  
        
        <div *ngIf="this.lateReviewRequest.requestDecisionTypeId == 1">
            <div class="row">
                <div class="col-12 col-sm-4">
                    <div class="label">Request Deadline</div>
                    <div class="value">
                        {{ lateReviewRequest.reviewRequestDeadline | date:'mediumDate' }}
                    </div>                    
                </div>
                <div class="col-12 col-sm-4" *ngIf="lateReviewRequest.canRequestReview">
                    <button class="btn-block goa-button mt-0" (click)="onRequestReviewClick()">Request Review</button>    
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col">
                <div class="label">Decision Letter</div>
                <div class="value" *ngIf="!hasDecisionDocument(); else docLink">
                    -                                        
                </div>

                <ng-template #docLink>
                    <a [routerLink]="[]" class="d-block" (click)="DownloadDocument(getDecisionDocument())">
                        {{ getDecisionDocument().documentName}}
                    </a>
                </ng-template>
            </div>
        </div>

        <app-section-details [sectionDetails]="representationGroundsInformation"></app-section-details>

        <div class="row mt-2">
            <div class="col">
                <div class="label">Supporting Documents</div>
            </div>
        </div>
        <div class="row" *ngFor="let document of lateReviewRequest.documents">
            <div class="col" *ngIf="document.documentTypeId != 30">
                <button class="btn-link value" (click)="DownloadDocument(document)">
                    {{document.documentName}}
                </button>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contravention-details-pending',
  templateUrl: './contravention-details-pending.component.html',
  styleUrls: ['./contravention-details-pending.component.scss']
})
export class ContraventionDetailsPendingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { RegistryService } from '../../../shared/services/registry.service';
import { JWTTokenService } from '../../../shared/services/jwttoken.service';

@Component({
  selector: 'app-rsg-home-page',
  templateUrl: './rsg-home-page.component.html',
  styleUrls: ['./rsg-home-page.component.scss']
})
export class RsgHomePageComponent implements OnInit {
  rsgToken: string;
  rsgAgent: RsgAgentProfile;
  isServerErr: boolean;
  errorMessage: string;

  constructor(private activatedRoute: ActivatedRoute,
              private registryService: RegistryService,
              private jwtTokenService: JWTTokenService,
              private readonly localStorageService: LocalStorageService,
              private router: Router,
              private readonly spinner: NgxSpinnerService) { 
              }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.rsgToken = params['access_token'];
      if (this.rsgToken)
      {
        this.spinner.show();
        this.registryService.validateRSGTokenAsync(this.rsgToken)
        .subscribe(
          (result: any) => {
            this.jwtTokenService.setToken(result.accessToken)
            this.rsgAgent = new RsgAgentProfile();
            this.rsgAgent.userName = result.userName;
            this.rsgAgent.organization = result.organization;
            this.rsgAgent.accessToken = result.accessToken;
            this.rsgAgent.transactionId = result.transactionId;
            this.localStorageService.setRegistryAgentProfile(this.rsgAgent);
            this.spinner.hide();
          },
          (error: any) => {
            if (error.status == 403 || error.status == 401) {
              this.localStorageService.logout();
              this.router.navigate(['/session-expired']);
            }
            else {
              this.isServerErr = true;
              this.errorMessage = error;
            }
            this.spinner.hide();
          });
      }
      else if (!this.localStorageService.getRegistryAgentProfile())
      {
        this.router.navigate(['/unauthorized']);
      }
    },
      (error) => {
        this.isServerErr = true;
        this.errorMessage = error;
        this.spinner.hide();
    });  
  }
  
  onFindContraventionClick()
  {
    this.router.navigateByUrl('/rsg/contraventions/search');
  }

  onFindSeizureClick()
  {
    this.router.navigateByUrl('/rsg/vehicle-seizures/search');
  }
}

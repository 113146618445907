<div class="d-flex flex-column w-100">    
    <div class="rsg-home-body">
        <div class="selection-box d-flex flex-column justify-content-between">
            <div>
                <div class="selection-box-header">Notice of Administrative Penalty</div>
                <div class="selection-box-text d-flex align-items-start">
                    <span>The client must present the original copy of the Notice of Administrative Penalty (Form PS2731 Rev. 2020-12).</span>
                </div>
                <div class="selection-box-message d-flex align-items-start">
                    <span>If client is presenting a yellow violation ticket, photo radar ticket, a notice of conviction or time to pay notice, you need to process this payment through the Current Fines application.</span>
                </div>
            </div>
            <div class="button-container">
                <button class="btn-block goa-button button-primary" (click)="onFindContraventionClick()">Find a Contravention</button>
            </div>
        </div>
        <div class="selection-box d-flex flex-column justify-content-between">
            <div>
                <div class="selection-box-header">Seizure Notice</div>
                <div class="selection-box-text d-flex align-items-start">
                    The client must present the original copy of the Seizure Notice (Form TRANS3134 Rev 2020-12).
                </div>
            </div>
            <div class="button-container">
                <button class="btn-block goa-button button-primary" (click)="onFindSeizureClick()">Find a Seizure</button>
            </div>
        </div>        
    </div> 
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>         
</div>


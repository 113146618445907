
export class DailyBalanceRequest {
    public fromDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    public toDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    public registryAgentId: string;
    public registryAgentOrganizationId: string;
    public recordNumber: string;
    public transactionId: string;
    public IsReviewTransactionRequired: boolean = true;
    public IsFineTransactionRequired: boolean = true;

    constructor(init?: Partial<DailyBalanceRequest>) {
        Object.assign(this, init);
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { LateReviewBaseComponent } from '../late-review-base.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { ContraventionPageComponent } from '../../contravention-page/contravention-page.component';
import { Document } from '@apis/shared/models/document.model';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from 'file-saver';
import { RegistryService } from 'apps/driver/src/app/shared/services/registry.service';
import { ContraventionInformation } from '@apis/shared/models/contravention-information.model';
import { RepresentationTypes } from '@apis/shared/enums/app.enum';

@Component({
  selector: 'app-late-review-summary',
  templateUrl: './late-review-summary.component.html',
  styleUrls: ['./late-review-summary.component.scss']
})
export class LateReviewSummaryComponent extends LateReviewBaseComponent
implements OnInit {
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;

 reviewTypeInformation: SectionDetailsModel[] = [];
 representationInformation: SectionDetailsModel[] = [];
 requestDetailsInformation: SectionDetailsModel[] = [];
 requestInformation: SectionDetailsModel[] = [];
 recipientInformation: SectionDetailsModel[] = [];
 recipientIdentificationInformation: SectionDetailsModel[] = [];
 reasonForRequestInformation: SectionDetailsModel[] = [];
 preferredContactInformation: SectionDetailsModel[] = [];
 termsConditionsInformation: SectionDetailsModel[] = [];
 preferredReviewTimesSection: SectionDetailsModel[] = [];
 requesterInformation: SectionDetailsModel[] = [];
 supportingDocuments: DocumentModel[] = [];
 contraventionsInRequest: ContraventionInformation[] = [];

 recipient: Recipient;

 isRequestFailed: boolean;
 isRequestAppliedAfterDeadline: boolean;
 isPaymentFailed: boolean;
 isSubmitClicked: boolean = false;
 
 constructor(
   public router: Router,
   private datePipe: DatePipe,
   private currencyPipe: CurrencyPipe,
   public activatedRoute: ActivatedRoute,
   public localStorageService: LocalStorageService,
   private contraventionService: ContraventionService,
    private documentService: DocumentService,
    private readonly spinner: NgxSpinnerService,
    private registryService: RegistryService
    ) { 
   super(router, activatedRoute, localStorageService);
 }

 ngAfterViewInit(): void {
  if (!this.isRSGPortal)
  {
    const cardLogoDiv = document.getElementById("cardLogoDiv") as HTMLImageElement;
    cardLogoDiv.style.backgroundImage = this.lateReviewRequest.paymentRequest['cardLogo'];
  }
 }

 ngOnInit(): void {
   // Request Information
   this.reviewTypeInformation.push(new SectionDetailsModel('Request Type', 
     this.lateReviewRequest.isSelfSubmit ? "I am submitting this application on behalf of myself" : 
                                "I am submitting this application on behalf of another",
     true
   ));

   if (this.lateReviewRequest.representation) {
     // Representation Informaton
     const repTypeId = this.lateReviewRequest.representation.representationTypeId;

     const repTypeName = this.localStorageService.getRepresentationTypes()
                             .find(x=>x.id == repTypeId)?.name;

     this.representationInformation.push(new SectionDetailsModel('Type of Acting Representation', repTypeName, true));

     this.representationInformation.push(new SectionDetailsModel(`Representative First Name`, this.lateReviewRequest.representation.firstName));
     this.representationInformation.push(new SectionDetailsModel(`Representative Last Name`, this.lateReviewRequest.representation.lastName));

     if (+repTypeId === RepresentationTypes.Agent || +repTypeId === RepresentationTypes.Lawyer){
       this.representationInformation.push(new SectionDetailsModel(`Practice Name`, this.lateReviewRequest.representation.practiceName, true));
     }

     if (+repTypeId === RepresentationTypes.Other){
       this.representationInformation.push(new SectionDetailsModel('Other Representation Type', this.lateReviewRequest.representation.otherRepresentationType, true));
     }
   }

    //Requester Information
    if (this.isRSGPortal) {
      const registryAgentInformation = this.lateReviewRequest.registryAgentInformation[0];
      if (registryAgentInformation.isPaidByRecipient) {
        this.requesterInformation.push(new SectionDetailsModel('Person initiating request', "Recipient (Driver)", true));
      }
      else {
        this.requesterInformation.push(new SectionDetailsModel('Person initiating request', "Someone else", true));
        this.requesterInformation.push(new SectionDetailsModel('Surname', registryAgentInformation.payerLastName));
        this.requesterInformation.push(new SectionDetailsModel('First Name', registryAgentInformation.payerFirstName));
        this.requesterInformation.push(new SectionDetailsModel('MVID', registryAgentInformation.payerMVID));
      }
    }

   // Contravention Information
   for (let index = 0; index < this.lateReviewRequest.lateReviewRequestItems.length; index++) {
    const r = this.lateReviewRequest.lateReviewRequestItems[index];
    const contravention = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == r.recordNumber);
    this.contraventionsInRequest.push(contravention);
  }    

  // Review Details Information
  this.requestDetailsInformation.push(new SectionDetailsModel('Grounds for Request', 
    this.lateReviewRequest.requestReason, true));
       
   // Preferred Contact Information
   this.preferredContactInformation.push(new SectionDetailsModel('Preferred Contact Method', 
      CommonUtil.getContactMethodTypeName(this.lateReviewRequest.contactMethodTypeId)));
   if (this.lateReviewRequest.phoneNumber) {
     this.preferredContactInformation.push(new SectionDetailsModel('Phone Number', this.lateReviewRequest.phoneNumber));
   }
   else {
     this.preferredContactInformation.push(new SectionDetailsModel('Email Address', this.lateReviewRequest.email));
   }

   // Supporting Documents Section
  this.lateReviewRequest.documents?.forEach(document => {
    var documentType : DocumentType = this.localStorageService.getDocumentTypes().find(x=> x.id == document.documentTypeId);
    this.supportingDocuments.push(new DocumentModel(documentType?.name, document.documentName, document.documentExtension, document.contentGuid, false));
  });

   // Terms & Conditions
   this.termsConditionsInformation.push(new SectionDetailsModel(this.isRSGPortal? 'The Registry Agent provided the Terms & Conditions to the client': 'I acknowledge the SafeRoads Alberta Terms and Conditions.', "Yes", true));
 }

 submitRequest() {
  this.masterPage.showSpinner();

  // Set temp folder
  this.lateReviewRequest.tempFolderName = this.tempFolderName;

  // Set document uploaded_date and uploaded_by
  var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  this.lateReviewRequest.documents?.filter(x=>(x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
    document.uploadedDate = today;
    document.uploadedBy = "Recipient";
  });

  this.contraventionService
    .requestLateReview(this.contraventionNumber, this.lateReviewRequest)
    .subscribe(
      (result: any) => {
        this.masterPage.hideSpinner();

        // Update late review request number
        this.lateReviewRequest.lateReviewRequestNumber = result.recordNumber;

        this.saveContext();

        this.router.navigate(['../submit-complete'], {
          relativeTo: this.activatedRoute,
        });
      },
      (error) => {
        if (error.error && error.error.errorCode == "6001") {
          this.isPaymentFailed = true;
        }
        else if (error.error && error.error.errorCode == '3005') {
          this.isRequestAppliedAfterDeadline = true;
        }
        else {
          this.isRequestFailed = true;
        }
        this.masterPage.hideSpinner();

        window.scroll(0,0);
      }
    );
 }

 submitRegistryRequest(isValid: boolean) {
  this.isSubmitClicked=true; 
  if (isValid)
   {
    this.masterPage.showSpinner();

    // Set temp folder
    this.lateReviewRequest.tempFolderName = this.tempFolderName;

    // Set document uploaded_date and uploaded_by
    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.lateReviewRequest.documents?.filter(x=>(x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
      document.uploadedDate = today;
      document.uploadedBy = "Registry";
    });

    this.registryService
      .requestLateReview(this.contraventionNumber, this.lateReviewRequest)
      .subscribe(
        (result: any) => {
          this.masterPage.hideSpinner();

          // Update late review request number
          this.lateReviewRequest.lateReviewRequestNumber = result.recordNumber;

          this.saveContext();

          // Clear TransactionId to avoid any further transaction with same fulfillment ID
          var rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
          if (rsgAgentProfile)
          {
            rsgAgentProfile.transactionId = null;
            this.localStorageService.setRegistryAgentProfile(rsgAgentProfile);
          }

          this.router.navigate(['../submit-complete'], {
            relativeTo: this.activatedRoute,
          });
        },
        (error) => {
          if (error.error && error.error.errorCode == '3005') {
            this.isRequestAppliedAfterDeadline = true;
          }
          else {
            this.isRequestFailed = true;
          }
          this.masterPage.hideSpinner();
          window.scroll(0,0);
        }
      );
    }
 }


 DownloadDocument(document: Document) {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;
      this.documentService.downloadDocument(this.tempFolderName, "", "", storageFileName , document.documentName)
      .subscribe((result: any) => {
        if (result)
        { 
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      });
  }

  getChargeCodes(c: ContraventionInformation) {
    var contraventionType = this.localStorageService.getContraventionTypes().find(ct => ct.id == c.contraventionTypeId);

    var chargeCodes = contraventionType.formattedChargeCode;

    if (c.secondaryContraventionTypeId) {
      contraventionType = this.localStorageService.getContraventionTypes().find(ct => ct.id == c.secondaryContraventionTypeId);

      chargeCodes = chargeCodes + ', ' + contraventionType.formattedChargeCode;
    }

    if (c.tertiaryContraventionTypeId) {
      contraventionType = this.localStorageService.getContraventionTypes().find(ct => ct.id == c.tertiaryContraventionTypeId);

      chargeCodes = chargeCodes + ', ' + contraventionType.formattedChargeCode;
    }

    return chargeCodes;
  }
}

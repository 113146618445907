<div>
    <div class="row" *ngIf="isIrs">
        <label class="col bold-text">There may be additional conditions for reinstatement of the recipient's operator's licence.</label>
    </div>
    <div class="row">
        <div class="col contravention-number">
            Contravention Number: {{contravention.contraventionNumber}}
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-4 d-flex flex-column">
            <span class="heading">Traffic Safety Act Section</span>
            <div class="charge-section d-flex flex-column">
                <span class="charge-code">{{getChargeCode(contravention.contraventionTypeId)}}</span>
                <span>{{getChargeDescription(contravention.contraventionTypeId)}}</span>
            </div>
            <div class="charge-section d-flex flex-column" *ngIf="contravention.secondaryContraventionTypeId">
                <span class="charge-code">{{getChargeCode(contravention.secondaryContraventionTypeId)}}</span>
                <span>{{getChargeDescription(contravention.secondaryContraventionTypeId)}}</span>
            </div>
            <div class="charge-section d-flex flex-column" *ngIf="contravention.tertiaryContraventionTypeId">
                <span class="charge-code">{{getChargeCode(contravention.tertiaryContraventionTypeId)}}</span>
                <span>{{getChargeDescription(contravention.tertiaryContraventionTypeId)}}</span>
            </div>
        </div>
        <div class="col-md-4 d-flex flex-column info-setion" *ngIf="showContraventionDetails">
            <span class="heading">Contravention Details</span>
            <div class="info-field">
                <span class="info-field-label">{{getContraventionDetails(contravention.contraventionDetails)}}</span>
            </div>
        </div>
        <div class="col-md-4 d-flex flex-column info-setion" *ngIf="isIrs">
            <span class="heading">Immediate Licence Suspension</span>
            <div class="info-field">
                <span class="info-field-label">{{contravention.suspensionDuration}} {{contravention.suspensionDurationType}} {{contravention.additionalSuspension? "+ " + contravention.additionalSuspension :"" }}</span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="d-flex flex-row">
                <div class="d-flex flex-column demerits-section" *ngIf="!isIrs">
                    <span class="heading">Demerits</span>
                    <span *ngIf="contravention.demerits; else nullContent" >{{contravention.demerits}}</span>
                    <ng-template #nullContent>-</ng-template>
                </div>
                <div class="d-flex flex-column flex-grow-1 info-setion" *ngIf="!showContraventionDetails">
                    <span class="heading">Fine Amount</span>
                    <div class="info-field">
                        <span class="info-field-label">{{contravention.fine.fineAmount | currency}} + {{contravention.fine.victimFineSurchargeAmount | currency}} VFS</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="showContraventionDetails || contravention.remedialEducation || additionalFieldsActive">
        <div class="col-md-4 d-flex flex-column info-setion" *ngIf="showContraventionDetails">
            <span class="heading">Fine Amount</span>
            <div class="info-field">
                <span class="info-field-label">{{contravention.fine.fineAmount | currency}} + {{contravention.fine.victimFineSurchargeAmount | currency}} VFS</span>
            </div>
        </div>
        <div class="col-md-4 d-flex flex-column info-setion" *ngIf="contravention.remedialEducation">
            <span class="heading">Remedial Education</span>
            <div class="info-field">
                <span class="info-field-label">{{contravention.remedialEducation}}</span>
            </div>
        </div>
        <div class="col-md-4 d-flex flex-column info-setion" *ngIf="additionalFieldsActive">
            <span class="heading">Licence Seized</span>
            <div class="info-field">
                <span class="info-field-label">{{contravention.isLicenceSeized ? 'Yes': 'No'}}</span>
            </div>
        </div>
        <div class="col-md-4 d-flex flex-column info-setion" *ngIf="additionalFieldsActive && !(showContraventionDetails && contravention.remedialEducation)">
            <span class="heading">Licence Destroyed</span>
            <div class="info-field">
                <span class="info-field-label">{{contravention.isLicenceDestroyed ? 'Yes': 'No'}}</span>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="additionalFieldsActive && showContraventionDetails && contravention.remedialEducation">
        <div class="col-md-4 d-flex flex-column info-setion">
            <span class="heading">Licence Destroyed</span>
            <div class="info-field">
                <span class="info-field-label">{{contravention.isLicenceDestroyed ? 'Yes': 'No'}}</span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="isIrs">
        <div class="col d-flex flex-column">
            <p>
                Please visit <a href="https://www.alberta.ca/impaired-driving-penalties" target="_blank">Impaired Driving Penalties</a> 
                for information on the administrative penalties applicable to this contravention.
            </p>
        </div>
    </div>
</div>
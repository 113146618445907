import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeizureTypes, SeizureUserType } from '@apis/shared/enums/app.enum';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { VehicleSeizureService } from '../../../shared/services/vehicle-seizure.service';
import { DriverBaseComponent } from '../../driver-base.component';

@Component({
  selector: 'app-vehicle-seizure-user-type',
  templateUrl: './vehicle-seizure-user-type.component.html',
  styleUrls: ['./vehicle-seizure-user-type.component.scss']
})
export class VehicleSeizureUserTypeComponent extends DriverBaseComponent implements OnInit {
  seizureNumber: string;
  SeizureUserType = SeizureUserType;
  driverBirthDate: Date;
  userType: string;
  isDriver: boolean;
  isRegisteredOwner: boolean;
  invalidMatch: boolean;
  datePickerConfig: Partial<BsDatepickerConfig>;
  seizureType: number;

  SeizureTpes = SeizureTypes;

  constructor(
              router: Router,
              private datePipe: DatePipe,
              private activatedRoute: ActivatedRoute,
              private seizureService: VehicleSeizureService,
              private localStorageService: LocalStorageService
  ) { 
    super(router);
    this.datePickerConfig = Object.assign({}, 
      {
        containerClass: 'theme-dark-blue', 
        showWeekNumbers: false,
        dateInputFormat: 'YYYY-MM-DD',
        isAnimated: true
      });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.seizureNumber = paramMap.get('seizureNumber');
      this.activatedRoute.queryParams.subscribe((params) => {
        this.seizureType = params['seizuretype'];
      });
    });
  }

  continueRequest(isValid: boolean) {
    this.userType = this.isDriver ? SeizureUserType.Driver.toString() : (this.isRegisteredOwner ? SeizureUserType.RegisteredOwner.toString() : null);

    if(isValid && this.userType) {
      if(+this.userType == SeizureUserType.Driver && !this.isRSGPortal) {
        // validate recipient birth date
        this.seizureService.checkBirthDate(this.seizureNumber, this.datePipe.transform(this.driverBirthDate, "mediumDate"), this.userType)
        .subscribe((result: any) => {
          this.localStorageService.set(`${this.seizureNumber}_user_type`, this.userType);
          this.router.navigate(['../'], {relativeTo: this.activatedRoute});
        },
        (error: any) => {
          this.invalidMatch = true;
        });
      } else {
        this.localStorageService.set(`${this.seizureNumber}_user_type`, this.userType);
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
      }
    }
  }
}

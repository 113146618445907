<!-- Driver Portal View -->
<app-contravention-page #masterPage *ngIf="!isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <div class="d-flex flex-column">
            <h1>Application Summary</h1>
            <div class="d-flex w-100 border-bottom mb-2">
                <h3>Notice(s). {{this.getContraventionsInReview()}}</h3>
                <div class="countdown">
                    <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                </div>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isPaymentFailed">
                <h6 class="mt-0">Payment Failed</h6>
                <p>
                    Your payment was unsuccessful. Please re-enter your <a [routerLink]="['../payment-method']">Payment Method Details</a> and try again.
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewAppliedAfterDeadline">
                <h6 class="mt-0">Review Request Failed</h6>
                <p>
                    Request could not be submitted. Submission deadline has passed. <a [routerLink]="['../../../']">Return back to Contravention Overview.</a>
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewRequestNotAvailable">
                <h6 class="mt-0">Review Request Failed</h6>
                <p>
                    Request could not be submitted. <a [routerLink]="['../../../']">Return back to Contravention Overview.</a>
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                <h6 class="mt-0">Review Request Failed</h6>
                <p>
                    Your request can't be completed right now. Please try again later. 
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewTimeExpired">
                <b>
                    <p class="mt-0">
                        Reserved review time expired. Please <a [routerLink]="['../schedule-review']">select a new timeslot</a>.
                    </p>
                </b>
            </div>

            <div class="card border-0">
                <p>Review the details of your application below before submitting.</p>

                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Payments are non-refundable</h6>
                    <p>
                        Be advised that all payments to SafeRoads Alberta are final. No refunds will be issued.
                    </p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Application Summary
                </div>
                <div class="card-body">  
                    <app-section-details sectionTitle="Review Type" [sectionDetails]="reviewTypeInformation"></app-section-details>            
                    <app-section-details *ngIf="!review.isSelfSubmit"
                    sectionTitle="Representation" [sectionDetails]="representationInformation"></app-section-details>
                    
                    <div class="border-bottom pb-1">Contravention Information</div>
                    <div class="row mt-3">
                        <div class="col-4 label">
                            Contravention Number
                        </div>
                        <div class="col-4 label">
                            Traffic Safety Act Section
                        </div>
                        <div class="col-4 label">
                            Occurrence Date
                        </div>
                    </div>
                    <div *ngFor="let c of this.contraventionsInReview; let i = index;" class="row">
                        <div class="col-4 value">
                            {{c.contraventionNumber}}
                        </div>
                        <div class="col-4 value">
                            {{getChargeCodes(c)}}
                        </div>
                        <div class="col-4 value" *ngIf="i == 0">
                            {{c.occurrenceDate | date:'mediumDate' }}
                        </div>
                    </div>

                    <app-section-details sectionTitle="Review Details" [sectionDetails]="reviewsDetailsInformation"></app-section-details>            
                    <app-section-details sectionTitle="Preferred Contact Information" [sectionDetails]="preferredContactInformation"></app-section-details>
                    <app-section-details sectionTitle="Terms & Conditions" [sectionDetails]="termsConditionsInformation"></app-section-details>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Review Date
                </div>
                <div *ngIf="review.reviewMethodTypeId==1" class="card-body">
                    <div>You have scheduled a <b>{{ reviewMethod }} Review</b> for 
                        <b>{{ review.reviewDate | date:'fullDate':'UTC' }}.</b></div>
                    <div>A confirmation will be sent via the preferred contact method listed above.</div>
                </div>
                <div *ngIf="review.reviewMethodTypeId==2" class="card-body">
                    <div>You have scheduled a <b>{{ reviewMethod }} Review</b> for 
                        <b>{{ review.reviewDate | date:'fullDate':'UTC' }}</b> at <b>{{ reviewTime }}.</b></div>
                    <div>A confirmation will be sent via the preferred contact method listed above.</div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Details
                </div>
                <div class="card-body">  
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Application for Review</div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Amount Total</h5></div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Method
                </div>
                <div class="card-body">  
                    <div id="cardLogoDiv"></div>
                    <div>
                        ending in {{review.paymentRequest.cardLastFour}}
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../payment-method']">Return to Payment Method</button>
                    </div>
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button btn-block" [disabled]="isPaymentFailed == true || isSubmitClicked == true" data-toggle="modal" data-target="#confirmReviewModal">Pay & Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page #masterPage *ngIf="isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <div class="d-flex flex-column">
            <div class="row">
                <div class="col-12 col-sm-10">        
                    <h1>Application Summary</h1>
                </div>
                <div class="col-12 col-sm-2 d-flex align-self-end justify-content-end">
                    <div class="float-right">
                        <div class="print-icon not-for-print" (click)="printSummary()"></div>
                    </div>
                </div>
            </div>

            <div class="d-flex w-100 border-bottom mb-2">
                <h3>Notice(s). {{this.getSelectedContraventionsInReview()}}</h3>
                <div class="countdown not-for-print">
                    <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                </div>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewAppliedAfterDeadline">
                <h6 class="mt-0">Review Request Failed</h6>
                <p>
                    Request could not be submitted. Submission deadline has passed. <a [routerLink]="['../../../']">Return back to Contravention Overview.</a>
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewRequestNotAvailable">
                <h6 class="mt-0">Review Request Failed</h6>
                <p>
                    Request could not be submitted. <a [routerLink]="['../../../']">Return back to Contravention Overview.</a>
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                <h6 class="mt-0">Review Request Failed</h6>
                <p>
                    Your request can't be completed right now. Please try again later. 
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewTimeExpired">
                <b>
                    <p class="mt-0">
                        Reserved review time expired. Please <a [routerLink]="['../schedule-review']">select a new timeslot</a>.
                    </p>
                </b>
            </div>

            <div class="card border-0 not-for-print">
                <div class="goa-callout goa--information mt-2">
                    <h6 class="mt-0">Print Application for Review Summary</h6>
                    <p>Print this page and have the client confirm the application details before submitting.</p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Application Summary
                </div>
                <div class="card-body">  
                    <app-section-details sectionTitle="Requester" [sectionDetails]="requesterInformation"></app-section-details>
                    <app-section-details sectionTitle="Recipient Information" [sectionDetails]="recipientInformation"></app-section-details>
                    <app-section-details *ngIf="!review.isSelfSubmit" sectionTitle="Representation" [sectionDetails]="representationInformation"></app-section-details>

                    <div class="border-bottom pb-1">Contravention Information</div>
                    <div class="row mt-3">
                        <div class="col-4 label">
                            Contravention Number
                        </div>
                        <div class="col-4 label">
                            Traffic Safety Act Section
                        </div>
                        <div class="col-4 label">
                            Occurrence Date
                        </div>
                    </div>
                    <div *ngFor="let c of this.contraventionsInReview; let i = index;" class="row">
                        <div class="col-4 value">
                            {{c.contraventionNumber}}
                        </div>
                        <div class="col-4 value">
                            {{getChargeCodes(c)}}
                        </div>
                        <div class="col-4 value" *ngIf="i == 0">
                            {{c.occurrenceDate | date:'mediumDate' }}
                        </div>
                    </div>
                    <app-section-details sectionTitle="Review Details" [sectionDetails]="reviewsDetailsInformation"></app-section-details>            
                    <app-section-details sectionTitle="Preferred Contact Information" [sectionDetails]="preferredContactInformation"></app-section-details>
                    <app-section-details sectionTitle="Terms & Conditions" [sectionDetails]="termsConditionsInformation"></app-section-details>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Review Date
                </div>
                <div *ngIf="review.reviewMethodTypeId==1" class="card-body">
                    <div>You have scheduled a <b>{{ reviewMethod }} Review</b> for 
                        <b>{{ review.reviewDate | date:'fullDate':'UTC' }}.</b></div>
                    <div>A confirmation will be sent via the preferred contact method listed above.</div>
                </div>
                <div *ngIf="review.reviewMethodTypeId==2" class="card-body">
                    <div>You have scheduled a <b>{{ reviewMethod }} Review</b> for 
                        <b>{{ review.reviewDate | date:'fullDate':'UTC' }}</b> at <b>{{ reviewTime }}.</b></div>
                    <div>Please go online to <a href="https://www.saferoads.com" target="_blank">www.saferoads.com</a> to access your review details, including the videoconference link to attend the oral review.</div>
                    <div>A confirmation will be sent via the preferred contact method listed above.</div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Supporting Documents
                </div>
                <div class="card-body">  
                    <div *ngFor="let document of supportingDocuments">
                        <div class="row">
                            <div class="col-12 col-sm-12 mb-0 pb-0">
                                <label>{{document.documentType}} <span *ngIf="document.isRequired" class="label-hint">(Required)</span></label>
                            </div>
                            <div class="col-12 col-sm-12 mb-4">
                                <button class="btn-link" (click)="DownloadDocument(document)">
                                    {{document.documentName}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <p>Any supporting documents you wish to have considered as part of the review must be uploaded to the SafeRoads portal at least 2 full calendar days before the scheduled review date. You can add or modify documents online at <a href="https://www.saferoads.com" target="_blank">www.saferoads.com</a> or return to an Alberta Registry Agent for assistance.</p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Application for Review Payment Details
                </div>
                <div class="card-body">  
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Transaction ID {{review.paymentRequest.transactionId}}</div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Amount Total</h5></div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="checkbox mt-4 not-for-print">
                <input required type="checkbox" id="summaryPrinted" name="summaryPrinted" class="mr-2"
                    #summaryPrinted="ngModel" [(ngModel)]="isSummaryPrinted" [ngClass]="{'is-invalid': isSubmitClicked && summaryPrinted.invalid }">
                <label class="required" for="summaryPrinted">
                    <b>A copy of the review request summary was printed for the driver to review</b>
                </label>        
                <div class="invalid-feedback">
                    <div>Please indicate that a copy of the review request summary was printed for the driver to review</div>
                </div>
            </div>

            <div class="form-group not-for-print">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../payment-method']">Return to Payment</button>
                    </div>
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button btn-block" (click)="submitRegistryRequest(!summaryPrinted.invalid)">Submit</button>
                    </div>
                </div>
            </div>

            <div class="card border-0">
                <div class="form-group">
                    <div class="row">
                        <div class="col-12">
                            <p>Due to the potential for delayed or missed messages sent via email and SMS, it is your responsibility to check the status of your request. You can do this online in the SafeRoads portal or at an Alberta Registry Agent. Please contact SafeRoads Alberta at <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> if you have any questions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-contravention-page>

<div class="modal" id="confirmReviewModal" tabindex="-1" role="dialog" aria-labelledby="confirmReviewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <div class="card goa-callout goa--emergency mt-0">
                    <h6 class="mt-0 font-weight-bold">A review won't reduce fines or penalties</h6>
                    <p>
                        An adjudicator <strong>cannot lower the fine amount</strong> of a contravention, 
                        and they <strong>cannot reduce any associated penalities</strong> (e.g. demerits, licence suspension).
                    </p>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0">
                <p class="font-weight-bold">Do you wish to continue?</p>
            </div>
            <div class="modal-footer border-0 d-flex justify-content-between">
                <button type="button" class="goa-button goa--secondary mt-2 btn-confirm" 
                    data-dismiss="modal">No</button>
                <button type="button" class="goa-button mt-2 btn-confirm" 
                    data-dismiss="modal" (click)="submitRequest()">Yes</button>
            </div>
        </div>
    </div>
</div>
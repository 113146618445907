import { DocumentTypes, HighwayCharacteristicsTypes, RefusalOrFailedClassification, SampleClassification, TestTypes } from '@apis/shared/enums/app.enum';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecipientTypes, IdentificationTypes, ContraventionTypes, ObservedByTypes, OfficerTransportationTypes, OfficerUniformTypes, SpeedingDeviceTypes, RoadConditionTypes, WeatherConditionTypes, VisibilityConditionTypes, RoadSurfaceTypes, DriverDeclinedToTestTypes } from '@apis/shared/enums/app.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Recipient } from '@apis/shared/models/recipient.model';
import { ContraventionTest } from '@apis/shared/models/contravention-test.model';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Document } from '@apis/shared/models/document.model';
import * as moment from 'moment';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from 'file-saver';
import { ContraventionService } from '../../../shared/services/contravention.service';
import { DriverBaseComponent } from '../../driver-base.component';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { RecipientContact } from '@apis/shared/models/recipient-contact.model';
import { PoliceServiceInformation } from '@apis/shared/models/police-service-information.model';
import { RecipientIdentification } from '@apis/shared/models/recipient-identification.model';
import { Constants } from '@apis/shared/helpers/constants';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { ContraventionPageComponent } from '../contravention-page/contravention-page.component';
import { ContraventionUtilityService } from '@apis/shared/services/contravention-utility.service';
import { MovesVehicle } from '@apis/shared/models/moves-vehicle.model';
import { TimePipe } from '@apis/shared/pipes/time.pipe';

declare var $: any

@Component({
  selector: 'app-contravention-details',
  templateUrl: './contravention-details.component.html',
  styleUrls: ['./contravention-details.component.scss']
})
export class ContraventionDetailsComponent extends DriverBaseComponent implements OnInit {
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;

  //isDriverSectionVisible = false;
  isOccurrenceSectionVisible = false;
  isRecipientSectionVisible = true
  isContraventionSectionVisible = true;
  isVehicleSectionVisible = true;
  isVehicleSeizureSectionVisible = true;
  isSupportingDocumentsSectionVisible = true;
  isPoliceServiceSectionVisible = true;
  isSupportingDocumentDeclarationsSectionVisible = true;
  isCollapsed = false;
  contraventionTypes: ContraventionType[];
  recipientInformation: SectionDetailsModel[] = [];
  recipientIdentificationInformation: SectionDetailsModel[] = [];
  recipientContactInformation: SectionDetailsModel[] = [];
  movesRecipientContactInformation: SectionDetailsModel[] = [];
  contraventionInformation: SectionDetailsModel[][] = [];
  occurrenceInformation: SectionDetailsModel[] = [];
  locationConditions: SectionDetailsModel[] = [];
  stopInformationDetails: SectionDetailsModel[] = [];
  witnessInformation: SectionDetailsModel[] = [];
  officerInformation: SectionDetailsModel[] = [];
  contraventionGrounds: SectionDetailsModel[] = [];
  impairmentScreeningInformation: SectionDetailsModel[] = [];
  impairmentScreeningDetails: SectionDetailsModel[] = [];
  additionalScreeningInformation: SectionDetailsModel[][] = [];
  roadsideAppealInformation: SectionDetailsModel[] = [];
  roadsideAppealDetails: SectionDetailsModel[] = [];
  reasonableGrounds: SectionDetailsModel[] = [];
  seizureInformation: SectionDetailsModel[] = [];
  vehicleInformation: SectionDetailsModel[] = [];
  movesVehicleInformation: SectionDetailsModel[] = [];
  trailerInformation: SectionDetailsModel[] = [];
  registeredOwnerInformation: SectionDetailsModel[] = [];
  vehicleDriverInformation: SectionDetailsModel[] = [];
  towLotInformation: SectionDetailsModel[] = [];
  supportingDocuments: Document[] = [];
  policeServiceInformation: SectionDetailsModel[] = [];
  officerDeclarations: SectionDetailsModel[] = [];
  supportingDocumentDeclarations: SectionDetailsModel[] = [];
  reServiceInformation: SectionDetailsModel[] = [];
  serviceInformation: SectionDetailsModel[] = [];
  policeNarrativeDetails: SectionDetailsModel[] = [];
  vehicleSeizurePoliceNarrativeDetails: SectionDetailsModel[] = [];
  speedingInformation: SectionDetailsModel[] = [];
  speedingDeviceInformation: SectionDetailsModel[] = [];

  stopInformation: StopInformation;
  irsContravention: Contravention;
  speedingContravention: Contravention;
  vehicleSeizure: VehicleSeizure;
  recipient: Recipient;
  recipientIdentification: RecipientIdentification;
  recipientContact: RecipientContact;
  vehicle: Vehicle;
  movesVehicle: MovesVehicle;
  policeServiceInfo: PoliceServiceInformation
  policeFileNumber: string;

  pageHeading: string
  isNoVehicleSeizureMade: boolean = false;
  showSupportingDocumentDeclarations: boolean;
  policeOfficerFullName: string;
  submissionDate: Date;
  submissionTime: string;
  isTrueInformation: boolean;
  isSubmitClicked: boolean = false;
  showPublishedDate: boolean = false;
  additionalFieldsActive: boolean = false;
  isBodycamVideoActive: boolean = false;
  isChangeDisplayedInformationActive: boolean = false;
  showDocumentSubmitter: boolean = false;
  recipientType: string;
  selectedContraventionType: ContraventionType;
  relativeLocationDirection: string = "Unknown direction";
  RecipientTypes = RecipientTypes;
  isPhase2Submission: boolean = false;
  Adjudication:any=Constants.Adjudication;

  contraventionNumber: string;
  contravention: Contravention;
  DocumentTypes = DocumentTypes;
  
  documentRefTypeName = "Contraventions";
  contraventionNumbers:string="";
  timePipe: TimePipe;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private contraventionService: ContraventionService,
    private documentService: DocumentService,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private contraventionUtilityService: ContraventionUtilityService) {
    super(router);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');

      setTimeout(() => {
        this.loadContravention();
      });
    });
    this.timePipe = new TimePipe();
  }

  loadContravention() {
    this.masterPage.showSpinner();

    //Get Types
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    var irsTypeIds = this.contraventionTypes.filter(x => x.isIRS).map(s => s.id);
    var speedingTypeIds = this.contraventionTypes.filter(x => x.isSpeedingOffence).map(s => s.id);

    this.contraventionService.getStopInformation(this.contraventionNumber)
      .subscribe((stopInformation: StopInformation) => {
        if (stopInformation) {
          this.stopInformation = stopInformation;
          this.contravention = this.stopInformation.contraventions.find(c => c.contraventionNumber == this.contraventionNumber);

          this.showPublishedDate = new Date(this.contravention.submissionDate) >= new Date(this.Adjudication.DOCUMENT_PUBLISHED_DATE_EFFECTIVE_DATE);
          this.showDocumentSubmitter = new Date(this.contravention.submissionDate) >= new Date(this.Adjudication.DOCUMENT_SUBMITTER_EFFECTIVE_DATE);

          // Display Licence Seized, Licence Destroyed, and Date of Submission for all submissions on/after May 17th
          this.additionalFieldsActive = new Date(this.contravention.submissionDate) >= new Date(+Constants.Driver.PUBLISH_ADDITIONAL_FIELDS_EFFECTIVE_DATE.substring(0, 4), +Constants.Driver.PUBLISH_ADDITIONAL_FIELDS_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Driver.PUBLISH_ADDITIONAL_FIELDS_EFFECTIVE_DATE.substring(8, 10));
          this.isChangeDisplayedInformationActive = new Date(this.contravention.submissionDate) >= new Date(+Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(0, 4), +Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(8, 10));

          this.isPhase2Submission = this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE2;

          //Recipient Information
          this.recipient = this.contravention.recipient;
          this.recipientIdentification = this.contravention.recipient.recipientIdentification;
          this.recipientContact = this.contravention.recipient.recipientContact;
          this.isRecipientSectionVisible = this.contravention.recipient.recipientTypeId != RecipientTypes.RegisteredOwner;

          this.isContraventionSectionVisible = this.stopInformation.contraventions.length > 0;
          this.stopInformation.contraventions.forEach(c => {
            if (this.contraventionNumbers) {
              this.contraventionNumbers += `, ${c.contraventionNumber}`;
            } else {
              this.contraventionNumbers = c.contraventionNumber;
            }
          });

          //Vehicle Information
          this.vehicle = this.stopInformation.contraventions.length > 0 ? this.stopInformation.contraventions[0].vehicle : (this.stopInformation.vehicleSeizures.length > 0 ? this.stopInformation.vehicleSeizures[0].vehicle : null);
          this.isVehicleSeizureSectionVisible = this.stopInformation.vehicleSeizures.length > 0 || this.stopInformation.contraventions.filter(x => x.vehicleSeizure).length > 0;
          this.isVehicleSectionVisible = this.contravention.recipient.recipientTypeId != RecipientTypes.NotInVehicle && !this.isVehicleSeizureSectionVisible;

          //Supporting Documents
          if (this.stopInformation.documents.length > 0)
            this.supportingDocuments = this.stopInformation.documents;
          else if (this.stopInformation.contraventions.length > 0)
            this.supportingDocuments = this.stopInformation.contraventions[0].documents;
          else if (this.stopInformation.vehicleSeizures.length > 0)
            this.supportingDocuments = this.stopInformation.vehicleSeizures[0].documents;

          switch (+this.recipient.recipientTypeId) {
            case RecipientTypes.Driver:
              this.recipientType = "Driver";
              break;
            case RecipientTypes.RegisteredOwner:
              this.recipientType = "Registered Owner";
              break;
            case RecipientTypes.Passenger:
              this.recipientType = "Passenger";
              break;
            case RecipientTypes.NotInVehicle:
              this.recipientType = "Not In Vehicle";
              break;
          }

          // Contravention Section
          if (this.isContraventionSectionVisible) {
            this.stopInformation.contraventions.forEach(contravention => {
              var contraventionSectionDtails: SectionDetailsModel[] = [];

              contraventionSectionDtails.push(new SectionDetailsModel('Contravention Number', contravention.contraventionNumber));
              contraventionSectionDtails.push(new SectionDetailsModel('Fine Amount', `${this.currencyPipe.transform(contravention.fine.fineAmount)}`));
              contraventionSectionDtails.push(new SectionDetailsModel('Demerits', contravention?.demerits?.toString()));

              if (irsTypeIds.includes(+contravention.contraventionTypeId)) //IRS Contravention
              {
                this.irsContravention = contravention;
                if (contravention.contraventionTypeId == ContraventionTypes.IRS24 || contravention.contraventionTypeId == ContraventionTypes.IRSFail1st || contravention.contraventionTypeId == ContraventionTypes.IRSFail2nd || contravention.contraventionTypeId == ContraventionTypes.IRSFail3rd) {
                  contraventionSectionDtails.push(new SectionDetailsModel('Traffic Safety Act Section', this.contraventionTypes.find(x => x.id == contravention.contraventionTypeId)?.formattedName));
                }
                else
                  contraventionSectionDtails.push(new SectionDetailsModel('Traffic Safety Act Section', this.contraventionTypes.find(x => x.id == contravention.contraventionTypeId)?.formattedName, true));

                contraventionSectionDtails.push(new SectionDetailsModel('Contravention Description', this.contraventionUtilityService.getContraventionDescriptionText(this.contravention)));
              }
              else {
                contraventionSectionDtails.push(new SectionDetailsModel('Traffic Safety Act Section', this.contraventionTypes.find(x => x.id == contravention.contraventionTypeId)?.formattedName, true));

                if (contravention.secondaryContraventionTypeId)
                  contraventionSectionDtails.push(new SectionDetailsModel('Secondary Traffic Safety Act Section', this.contraventionTypes.find(x => x.id == contravention.secondaryContraventionTypeId)?.formattedName, true));

                if (contravention.tertiaryContraventionTypeId)
                  contraventionSectionDtails.push(new SectionDetailsModel('Secondary Traffic Safety Act Section', this.contraventionTypes.find(x => x.id == contravention.tertiaryContraventionTypeId)?.formattedName, true));
              }

              const occurrenceYear = new Date(this.contravention.occurrenceDate).getFullYear();
              const victimFineSurchargePercentage = this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId)?.victimFineSurchargePercentage;
              const victimFineSurchargeAmount = occurrenceYear > 2020
                                                ? (this.contravention.fine.fineAmount * victimFineSurchargePercentage) / 100
                                                : 0;
        
              if (victimFineSurchargeAmount > 0) {
                contraventionSectionDtails.push(new SectionDetailsModel('Contravention Fine Amount', `${this.currencyPipe.transform(this.contravention.fine.fineAmount)} + ${this.currencyPipe.transform(victimFineSurchargeAmount)} VFS`));
              } else {
                contraventionSectionDtails.push(new SectionDetailsModel('Contravention Fine Amount', `${this.currencyPipe.transform(this.contravention.fine.fineAmount)}`));
              }

              this.contraventionInformation.push(contraventionSectionDtails);
            });
          }

          // Occurrence Information
          this.occurrenceInformation.push(new SectionDetailsModel('Occurrence Date', this.datePipe.transform(this.stopInformation.contraventions[0].occurrenceDate, 'dd MMM, yyyy')));
          this.occurrenceInformation.push(new SectionDetailsModel('Occurrence Time', this.timePipe.transform(this.stopInformation.contraventions[0].occurrenceTime, 'shortTime24h')));
          this.occurrenceInformation.push(new SectionDetailsModel('Issuance Date', this.datePipe.transform(this.stopInformation.contraventions[0].issueDate, 'dd MMM, yyyy')));
          this.occurrenceInformation.push(new SectionDetailsModel('Municipality At or Near', this.localStorageService.getLocationTypes().find(x => x.id == this.stopInformation?.occurrenceLocation?.locationTypeId)?.name));
          if (this.isPhase2Submission) {
            this.occurrenceInformation.push(new SectionDetailsModel('Address or Highway Number', this.stopInformation?.occurrenceLocation?.addressOrHighwayNumber));
            this.occurrenceInformation.push(new SectionDetailsModel('GPS Coordinates', `${this.stopInformation?.occurrenceLocation?.latitude ?? ""} ${this.stopInformation?.occurrenceLocation?.longitude ?? ""}`));
            this.occurrenceInformation.push(new SectionDetailsModel('At Intersection of', this.stopInformation?.occurrenceLocation?.atStreet));
            this.occurrenceInformation.push(new SectionDetailsModel('Relative location', `${this.stopInformation?.occurrenceLocation?.directionTypeId ? this.localStorageService.getDirectionTypes().find(x => x.id == this.stopInformation?.occurrenceLocation?.directionTypeId)?.name + " of " : ""} ${this.stopInformation?.occurrenceLocation?.relativeLocation ?? ""}`));
          }
          this.occurrenceInformation.push(new SectionDetailsModel('Additional Location Information', this.stopInformation?.occurrenceLocation?.additionalLocationDetails, true));

          //Location Conditions
          this.locationConditions.push(new SectionDetailsModel('Traffic Conditions', this.stopInformation?.trafficCondition));
          this.locationConditions.push(new SectionDetailsModel('Road Conditions', Array.prototype.map.call(this.localStorageService.getRoadConditionTypes().filter(x => this.stopInformation?.roadConditionTypes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (this.stopInformation?.roadConditionTypes?.split(',').includes(RoadConditionTypes.Other.toString())? "-" + this.stopInformation?.roadConditionOther:"")));
          this.locationConditions.push(new SectionDetailsModel('Weather Conditions', Array.prototype.map.call(this.localStorageService.getWeatherConditionTypes().filter(x => this.stopInformation?.weatherConditionTypes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (this.stopInformation?.weatherConditionTypes?.split(',').includes(WeatherConditionTypes.Other.toString())? "-" + this.stopInformation?.weatherConditionOther:"")));
          this.locationConditions.push(new SectionDetailsModel('Visibility', Array.prototype.map.call(this.localStorageService.getVisibilityConditionTypes().filter(x => this.stopInformation?.visibilityConditionTypes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (this.stopInformation?.visibilityConditionTypes?.split(',').includes(VisibilityConditionTypes.Other.toString())? "-" + this.stopInformation?.visibilityConditionOther:"")));
          this.locationConditions.push(new SectionDetailsModel('Road Surface', Array.prototype.map.call(this.localStorageService.getRoadSurfaceTypes().filter(x => this.stopInformation?.roadSurfaceTypes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (this.stopInformation?.roadSurfaceTypes?.split(',').includes(RoadSurfaceTypes.Other.toString())? "-" + this.stopInformation?.roadSurfaceOther:"")));
          this.locationConditions.push(new SectionDetailsModel('Highway Characteristics', Array.prototype.map.call(this.localStorageService.getHighwayCharacteristicsTypes().filter(x => this.stopInformation?.highwayCharacteristicsTypes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (this.stopInformation?.highwayCharacteristicsTypes?.split(',').includes(HighwayCharacteristicsTypes.Other.toString())? "-" + this.stopInformation?.highwayCharacteristicsOther:"")));

          // Stop Information
          this.stopInformationDetails.push(new SectionDetailsModel('Observed by', this.localStorageService.getObservedByTypes().find(x => x.id == this.stopInformation?.observedByTypeId)?.name));
          this.stopInformationDetails.push(new SectionDetailsModel('Time 1st Observed', this.timePipe.transform(this.stopInformation?.firstObservedTime, 'shortTime24h')));
          this.stopInformationDetails.push(new SectionDetailsModel('1st Observed Location', this.stopInformation?.firstObservedLocation));

          if (this.stopInformation.isNoTrafficStop != true) {
            this.stopInformationDetails.push(new SectionDetailsModel('Circumstances for Stop', this.stopInformation?.circumstanceTypeId == 4 ? this.stopInformation?.circumstanceOther : this.localStorageService.getCircumstanceTypes().find(x => x.id == this.stopInformation?.circumstanceTypeId)?.name));
            this.stopInformationDetails.push(new SectionDetailsModel('Time of Stop', this.timePipe.transform(this.stopInformation?.stopTime, 'shortTime24h')));
          }

          //Witness Information
          this.stopInformation?.witnesses?.forEach(witness => {
            this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' First Name', witness.firstName));
            this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' Last Name', witness.lastName));
            this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' DOB', witness.dateOfBirth == null ? "-" : this.datePipe.transform(witness.dateOfBirth, 'dd MMM, yyyy')));
          });

          if (this.stopInformation.observedByTypeId == ObservedByTypes.OtherOfficerObservedSubject)
            this.stopInformationDetails.push(new SectionDetailsModel('Other Officer Name', `${this.stopInformation?.otherOfficerFirstName ?? ""} ${this.stopInformation?.otherOfficerLastName ?? ""}`));
          else if (this.stopInformation.observedByTypeId == ObservedByTypes.MultipleOfficerOperation) {
            this.stopInformationDetails.push(new SectionDetailsModel('Issuer', this.stopInformation?.multipleOfficerOperationIssuer));
            this.stopInformationDetails.push(new SectionDetailsModel('Observer(s)', this.stopInformation?.multipleOfficerOperationObserver));
            this.stopInformationDetails.push(new SectionDetailsModel('Interceptor(s)', this.stopInformation?.multipleOfficerOperationInterceptor));
            this.stopInformationDetails.push(new SectionDetailsModel('Other', this.stopInformation?.multipleOfficerOperationInvestigator));
          }

          //Bodycam video question is only active when contravention was issued between Aug 1 and Aug 4 (inclusive), or details were added while the feature was active (meaning hasBodycamVideo is not null)
          const issuedAfterBodycamVideoEffective = new Date(this.contravention.issueDate) > new Date(+Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(8, 10));
          const issuedBeforeBodycamVideoRemoved = new Date(this.contravention.issueDate) < new Date(+Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(0, 4), +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(5, 7)-1, +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(8, 10));
          if ((issuedAfterBodycamVideoEffective && issuedBeforeBodycamVideoRemoved) || this.stopInformation.hasBodycamVideo != null) {
            this.isBodycamVideoActive = true;
          }

          // Officer Information
          if (this.stopInformation?.officerTransportationTypeId) {
            this.officerInformation.push(new SectionDetailsModel('Peace Officer Transportation', this.localStorageService.getOfficerTransportationTypes().find(x => x.id == this.stopInformation?.officerTransportationTypeId)?.name));
            if (this.stopInformation?.officerTransportationTypeId == OfficerTransportationTypes.InMarkedPatrolVehicle) {
              this.officerInformation.push(new SectionDetailsModel('Type of Vehicle', this.localStorageService.getOfficerVehicleTypes().find(x => x.id == this.stopInformation?.officerVehicleTypeId)?.name));
              this.officerInformation.push(new SectionDetailsModel('Vehicle Number', this.stopInformation?.officerVehicleNumber));
            }
            else if (this.stopInformation?.officerTransportationTypeId == OfficerTransportationTypes.Other || this.stopInformation?.officerTransportationTypeId == OfficerTransportationTypes.InUnMarkedPatrolVehicle)
              this.officerInformation.push(new SectionDetailsModel('Other Transportation', this.stopInformation?.officerTransportationOther));

            this.officerInformation.push(new SectionDetailsModel('Officer Uniform', this.localStorageService.getOfficerUniformTypes().find(x => x.id == this.stopInformation?.officerUniformTypeId)?.name));
            if (this.stopInformation?.officerUniformTypeId == OfficerUniformTypes.Other)
              this.officerInformation.push(new SectionDetailsModel('Other Uniform', this.stopInformation?.officerUniformOther));
          }

          //Police Narrative
          if (this.stopInformation.contraventions[0].additionalNotes)
            this.policeNarrativeDetails.push(new SectionDetailsModel('', this.stopInformation.contraventions[0].additionalNotes, true));

          if (this.irsContravention) {
            //Contravention Grounds
            this.contraventionGrounds.push(new SectionDetailsModel('Suspected Impairment Type', this.localStorageService.getSuspectedImpairmentTypes().find(x => x.id == this.irsContravention.impairmentScreeningInformation?.suspectedImpairmentTypeId)?.name));
            this.contraventionGrounds.push(new SectionDetailsModel('Was a Screening Method Used?', this.localStorageService.getContraventionGroundsTypes().find(x => x.id == this.irsContravention.contraventionGroundsTypeId)?.name, true));

            if (this.irsContravention.contraventionGroundsTypeId == 1) //Impairment Screening Information
            {
              this.impairmentScreeningInformation.push(new SectionDetailsModel('Reasonable Suspicion or Mas?', this.localStorageService.getImpairmentScreeningTypes().find(x => x.id == this.irsContravention.impairmentScreeningInformation?.impairmentScreeningTypeId)?.name));

              if (this.irsContravention.impairmentScreeningInformation?.impairmentScreeningTypeId == 1) //Reasonable Suspicion
              {
                this.impairmentScreeningInformation.push(new SectionDetailsModel('Reasonable Suspicion Based On', Array.prototype.map.call(this.localStorageService.getSuspicionTypes().filter(x => this.irsContravention.impairmentScreeningInformation?.suspicionTypes.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.irsContravention.impairmentScreeningInformation?.suspicionTypes.split(',').includes("5") ? "-" + this.irsContravention.impairmentScreeningInformation?.suspicionOther : "")));
              }

              this.impairmentScreeningInformation.push(new SectionDetailsModel('Impairment Screening Method', this.localStorageService.getTestTypes().find(x => x.id == this.irsContravention.impairmentScreeningInformation?.impairmentTests[0]?.testTypeId)?.name));

              this.DisplayTestResults(this.irsContravention.impairmentScreeningInformation?.impairmentTests[0], this.impairmentScreeningDetails, false);
            }
            else if (this.irsContravention.contraventionGroundsTypeId == 2) // Reasonable Grounds
            {
              this.reasonableGrounds.push(new SectionDetailsModel('Describe in Detail What Led to Your Grounds', this.irsContravention.reasonableGroundsDetails, true));
              this.reasonableGrounds.push(new SectionDetailsModel('Evidence of Consumption', this.irsContravention.consumptionEvidence, true));
            }

            if (this.contravention.isReasonableGroundsToBelieve) {
              this.reasonableGrounds.push(new SectionDetailsModel('By checking this box, the undersigned officer has reasonable grounds to believe that the recipient named in this Notice of Administrative Penalty did contravene the section of the Traffic Safety Act specified herein', "Yes", true));
            }

            //Additional Screening Test
            this.irsContravention.impairmentScreeningInformation?.impairmentTests.forEach((test, index) => {
              if (test.testSequence != 0) //Avoid first test as that is impairment screening test 
              {
                var sectionDtails: SectionDetailsModel[] = [];
                this.DisplayTestResults(test, sectionDtails, true);
                this.additionalScreeningInformation.push(sectionDtails);
              }
            });

            //Roadside Appeal
            if (this.irsContravention?.roadsideAppeal) {
              this.roadsideAppealInformation.push(new SectionDetailsModel('Was Recipient Presented with Roadside Appeal Information?', this.irsContravention.roadsideAppeal?.isRecipientPresentedWithInformation ? "Yes" : "No"));
              if (!this.irsContravention.roadsideAppeal?.isRecipientPresentedWithInformation)
                this.roadsideAppealInformation.push(new SectionDetailsModel('If No, Explain', this.irsContravention.roadsideAppeal?.appealOptionsNotPresentedReason, true));
              else {
                this.roadsideAppealInformation.push(new SectionDetailsModel('Did Driver Request a Roadside Appeal?', this.irsContravention.roadsideAppeal?.hasDriverRequestedRoadsideAppeal ? "Yes" : "No"));

                if (this.irsContravention.roadsideAppeal?.hasDriverRequestedRoadsideAppeal) {
                  this.roadsideAppealInformation.push(new SectionDetailsModel('Roadside Appeal Method', this.localStorageService.getTestTypes().find(x => x.id == this.irsContravention.roadsideAppeal?.roadsideTests[0].testTypeId)?.name));

                  if (this.irsContravention.roadsideAppeal?.roadsideTests[0].testTypeId == 8) //No Appeal Provided
                    this.roadsideAppealInformation.push(new SectionDetailsModel('If Appeal Was Requested But Not Provided, Explain Why Not', this.irsContravention.roadsideAppeal?.roadsideTests[0].appealNotProvidedReason, true));

                  this.DisplayTestResults(this.irsContravention.roadsideAppeal?.roadsideTests[0], this.roadsideAppealDetails, false, true);
                }
              }
            }
          }

          if (this.speedingContravention) {
            this.speedingInformation.push(new SectionDetailsModel('How was vehicle speed determined?', this.localStorageService.getSpeedingDeviceTypes().find(x => x.id == this.speedingContravention?.speedingTicket?.speedingDeviceTypeId)?.name));
            this.speedingInformation.push(new SectionDetailsModel('Issuing officer was the device operator?', this.speedingContravention?.speedingTicket?.isNotSpeedingDeviceOperator == true ? "No" : "Yes"));

            if (this.speedingContravention?.speedingTicket?.isNotSpeedingDeviceOperator != true) {
              if (this.speedingContravention?.speedingTicket?.speedingDeviceTypeId == SpeedingDeviceTypes.Lidar)
                this.speedingInformation.push(new SectionDetailsModel('LIDAR Distance to Vehicle (m)', this.speedingContravention?.speedingTicket?.speedingDeviceDistanceToVehicle));

              this.speedingInformation.push(new SectionDetailsModel('Recipient vehicle direction', this.speedingContravention?.speedingTicket?.recipientVehicleDirection));
              this.speedingInformation.push(new SectionDetailsModel('Police vehicle speed', this.speedingContravention?.speedingTicket?.policeVehicleSpeed?.toString()));
              this.speedingInformation.push(new SectionDetailsModel('Police vehicle direction', this.speedingContravention?.speedingTicket?.policeVehicleDirection));

              if (this.speedingContravention?.speedingTicket?.isVisualEstimate)
                this.speedingInformation.push(new SectionDetailsModel('Additional Details', this.speedingContravention?.speedingTicket?.additionalDetails));

              this.speedingInformation.push(new SectionDetailsModel('Operator Report (tracking sheet) uploaded instead?', this.speedingContravention?.speedingTicket?.isUploadOperatorReport == true ? "Yes" : "No"));

              if (this.speedingContravention?.speedingTicket?.isUploadOperatorReport != true && this.speedingContravention?.speedingTicket?.speedingDeviceTypeId != null) {
                //Speeding Device Information
                this.speedingDeviceInformation.push(new SectionDetailsModel('Make', this.localStorageService.getSpeedingDeviceMakeTypes().find(x => x.id == this.speedingContravention?.speedingTicket?.speedingDeviceMakeTypeId)?.name));
                this.speedingDeviceInformation.push(new SectionDetailsModel('Model', this.localStorageService.getSpeedingDeviceModelTypes().find(x => x.id == this.speedingContravention?.speedingTicket?.speedingDeviceModelTypeId)?.name ?? "Other"));
                if (this.speedingContravention?.speedingTicket?.speedingDeviceModelTypeId == 99)
                  this.speedingDeviceInformation.push(new SectionDetailsModel('Other Model', this.speedingContravention?.speedingTicket?.speedingDeviceModelOther));

                this.speedingDeviceInformation.push(new SectionDetailsModel('Are you trained in the use and operation of the equipment?', this.speedingContravention?.speedingTicket?.isOperatorCertified ? "Yes" : "No"));
                this.speedingDeviceInformation.push(new SectionDetailsModel('Did you complete the manufacturer-approved tests?', this.speedingContravention?.speedingTicket?.isManufacturerTestCompleted ? "Yes" : "No"));
                this.speedingDeviceInformation.push(new SectionDetailsModel('Did the device pass the manufacturer-approved tests?', this.speedingContravention?.speedingTicket?.isManufacturerTestPassed ? "Yes" : "No"));

                this.speedingDeviceInformation.push(new SectionDetailsModel('Date of testing', this.datePipe.transform(this.speedingContravention?.speedingTicket?.dateOfTest, 'dd MMM, yyyy')));
                this.speedingDeviceInformation.push(new SectionDetailsModel('Time of testing', this.timePipe.transform(this.speedingContravention?.speedingTicket?.timeOfTest, 'shortTime24h')));
              }
            }
          }

          // Recipient Section
          if (this.isRecipientSectionVisible) {
            this.recipientInformation.push(new SectionDetailsModel('Surname', this.recipient.lastName));
            this.recipientInformation.push(new SectionDetailsModel('First Name', this.recipient.firstName));
            this.recipientInformation.push(new SectionDetailsModel('Middle Name', this.recipient.otherName));

            if (this.isPhase2Submission) {
              this.recipientInformation.push(new SectionDetailsModel("Recipient Type", this.recipientType));

              if (this.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence)
                this.recipientInformation.push(new SectionDetailsModel("Identity verified by", "Driver's Licence"));
              else {
                this.recipientInformation.push(new SectionDetailsModel("Identity verified by", this.localStorageService.getIdentificationTypes().find(x => x.id == this.recipientIdentification.identificationTypeId)?.name));
                if (this.recipientIdentification.identificationTypeId == IdentificationTypes.Other)
                  this.recipientInformation.push(new SectionDetailsModel("Identification Type", this.recipientIdentification?.identificationOther));
              }
            }

            if (this.recipientIdentification.identificationTypeId != IdentificationTypes.Verbal) {
              this.recipientIdentificationInformation.push(new SectionDetailsModel('ID Number', this.recipient.recipientIdentification?.identificationNumber));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Issuing Country', this.recipient.recipientIdentification?.issuingCountryId == null ? "" : this.localStorageService.getCountryTypes().find(x => x.id == this.recipient.recipientIdentification?.issuingCountryId)?.name));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Issuing State/Province', (this.recipient.recipientIdentification?.issuingCountryId == 40 || this.recipient.recipientIdentification?.issuingCountryId == 236) ? (this.recipient.recipientIdentification?.issuingProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient.recipientIdentification?.issuingProvinceId)?.name) : this.recipient.recipientIdentification?.issuingProvinceOther));
            }

            if (this.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence) {
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Class', this.recipient.recipientIdentification?.driversLicenceClassTypeId == null ? this.recipient.recipientIdentification?.driversLicenceClass : this.localStorageService.getDriversLicenceClassTypes().find(x => x.id == this.recipient.recipientIdentification?.driversLicenceClassTypeId)?.name));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Status', this.localStorageService.getDriversLicenceStatusTypes().find(x => x.id == this.recipient.recipientIdentification?.driversLicenceStatusTypeId)?.name));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Expiry', this.datePipe.transform(this.recipient.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('MVID', this.recipient.recipientIdentification?.motorVehicleIdentificationNumber));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Is GDL/Novice Driver', this.recipient.recipientIdentification?.isGDLDriver == null ? "" : (this.recipient.recipientIdentification?.isGDLDriver ? "Yes" : "No")));
            }

            this.recipientIdentificationInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.recipient.recipientIdentification?.genderId)?.name));
            this.recipientIdentificationInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.recipient.recipientIdentification?.dateOfBirth, 'dd MMM, yyyy')));
            if (this.isPhase2Submission) {
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Condition Codes',Array.prototype.map.call(this.localStorageService.getConditionCodeTypes().filter(x => this.recipient?.recipientIdentification?.conditionCodes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join("")));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Eye Color', this.recipient.eyeColor));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Hair Color', this.recipient.hairColor));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Weight (kg)', this.recipient.weight));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Height (cm)', this.recipient.height));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Restrictions', this.recipient.recipientIdentification?.restrictions, true));
            }


            this.recipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient.recipientContact?.addressLine1));
            this.recipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient.recipientContact?.addressLine2));
            this.recipientContactInformation.push(new SectionDetailsModel('City', this.recipient.recipientContact?.city));
            this.recipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient.recipientContact?.countryId == 40 || this.recipient.recipientContact?.countryId == 236) ? (this.recipient.recipientContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient.recipientContact?.provinceId)?.name) : this.recipient.recipientContact?.province));
            this.recipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.recipient.recipientContact?.countryId)?.name));
            this.recipientContactInformation.push(new SectionDetailsModel('Postal Code', this.recipient.recipientContact?.postalCode));
            this.recipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient.recipientContact?.phoneNumber1));
            this.recipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.recipient.recipientContact?.phoneNumber3));
            this.recipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient.recipientContact?.phoneNumber2));
            this.recipientContactInformation.push(new SectionDetailsModel('Email Address', this.recipient.recipientContact?.emailAddress));
            this.recipientContactInformation.push(new SectionDetailsModel('Address Different from Moves', this.recipient.recipientContact?.isAddressDifferentFromMOVES ? "Yes" : "No"));
            this.recipientContactInformation.push(new SectionDetailsModel('No Fixed Address', this.recipient.recipientContact?.isNoFixedAddress? "Yes" : "No"));

            this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient.movesRecipientContact?.addressLine1));
            this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient.movesRecipientContact?.addressLine2));
            this.movesRecipientContactInformation.push(new SectionDetailsModel('City',this.recipient.movesRecipientContact?.city));
            this.movesRecipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient.movesRecipientContact?.countryId == 40 || this.recipient.movesRecipientContact?.countryId == 236)? (this.recipient.movesRecipientContact?.provinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.recipient.movesRecipientContact?.provinceId)?.name) : this.recipient.movesRecipientContact?.province));
            this.movesRecipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x=> x.id == this.recipient.movesRecipientContact?.countryId)?.name));
            this.movesRecipientContactInformation.push(new SectionDetailsModel('Postal Code',this.recipient.movesRecipientContact?.postalCode));
            this.movesRecipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient.movesRecipientContact?.phoneNumber1));
            this.movesRecipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.recipient.movesRecipientContact?.phoneNumber3));
            this.movesRecipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient.movesRecipientContact?.phoneNumber2));
          }

          //Vehicle Information
          if (this.isVehicleSectionVisible) {
            this.movesVehicle = this.vehicle?.movesVehicle;

            this.vehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.vehicle?.licencePlateNumber));
            this.vehicleInformation.push(new SectionDetailsModel("Plate Doesn't Match/Stolen Plate", this.vehicle?.isStolenPlate ? "Yes" : "No"));
            this.vehicleInformation.push(new SectionDetailsModel("No Plate", this.vehicle?.isNoPlate ? "Yes" : "No"));
            this.vehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.vehicle?.vehicleIdentificationNumber));
            this.vehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.vehicle?.vehicleRegistrationStatusTypeId)?.name));
            this.vehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.vehicle?.clientTypeId)?.name));
            this.vehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.vehicle?.licensedCountryId)?.name));
            this.vehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.vehicle?.licensedCountryId == 40 || this.vehicle?.licensedCountryId == 236) ? (this.vehicle?.licensedProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.vehicle?.licensedProvinceId)?.name) : this.vehicle?.licensedProvince));
            this.vehicleInformation.push(new SectionDetailsModel('Make', this.vehicle?.make));
            this.vehicleInformation.push(new SectionDetailsModel('Model', this.vehicle?.model));
            this.vehicleInformation.push(new SectionDetailsModel('Year', this.vehicle?.year?.toString()));
            this.vehicleInformation.push(new SectionDetailsModel('Colour', this.vehicle?.colour));
            this.vehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.vehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));
            if (!(this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate))
            {
              this.vehicleInformation.push(new SectionDetailsModel('Class of Plate', this.vehicle?.licencePlateClass));
              this.vehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.vehicle?.vehicleStyle));
              this.vehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.vehicle?.vehicleWeight));
              this.vehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.vehicle?.vehicleSeatingCapacity));
              this.vehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.vehicle?.vehicleStatusTypeId)?.name));
              this.vehicleInformation.push(new SectionDetailsModel('Last Service Type', this.vehicle?.lastServiceType));
              this.vehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.vehicle?.lastServiceDate, 'dd MMM, yyyy')));
            }

            //Moves Vehicle Information
            this.movesVehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.movesVehicle?.licencePlateNumber));
            this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.movesVehicle?.vehicleIdentificationNumber));
            this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.movesVehicle?.vehicleRegistrationStatusTypeId)?.name));
            this.movesVehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.movesVehicle?.clientTypeId)?.name));
            this.movesVehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.movesVehicle?.licensedCountryId)?.name));
            this.movesVehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.movesVehicle?.licensedCountryId == 40 || this.movesVehicle?.licensedCountryId == 236)? (this.movesVehicle?.licensedProvinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.movesVehicle?.licensedProvinceId)?.name) : this.movesVehicle?.licensedProvince));
            this.movesVehicleInformation.push(new SectionDetailsModel('Make', this.movesVehicle?.make));
            this.movesVehicleInformation.push(new SectionDetailsModel('Model', this.movesVehicle?.model));
            this.movesVehicleInformation.push(new SectionDetailsModel('Year', this.movesVehicle?.year?.toString()));
            this.movesVehicleInformation.push(new SectionDetailsModel('Colour', this.movesVehicle?.colour));
            this.movesVehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.movesVehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));
            this.movesVehicleInformation.push(new SectionDetailsModel('Class of Plate', this.movesVehicle?.licencePlateClass));
            this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.movesVehicle?.vehicleStyle));
            this.movesVehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.movesVehicle?.vehicleWeight));
            this.movesVehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.movesVehicle?.vehicleSeatingCapacity));
            this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.movesVehicle?.vehicleStatusTypeId)?.name));
            this.movesVehicleInformation.push(new SectionDetailsModel('Last Service Type', this.movesVehicle?.lastServiceType));
            this.movesVehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.movesVehicle?.lastServiceDate, 'dd MMM, yyyy')));

            // Trailer Information
            if (this.isPhase2Submission) {
              this.trailerInformation.push(new SectionDetailsModel("Trailer Involved", this.vehicle?.isTrailerInvolved ? "Yes" : "No"));
            }
            if (this.vehicle?.isTrailerInvolved) {
              this.trailerInformation.push(new SectionDetailsModel('Trailer Licence Plate Number', this.vehicle?.trailerLicencePlateNumber));
              this.trailerInformation.push(new SectionDetailsModel('Additional Trailer Information', this.vehicle?.additionalTrailerInformation));
              if (this.vehicle?.isSecondTrailerInvolved)
              {
                this.trailerInformation.push(new SectionDetailsModel('Second Trailer Licence Plate Number', this.vehicle?.secondTrailerLicencePlateNumber));
                this.trailerInformation.push(new SectionDetailsModel('Additional Second Trailer Information', this.vehicle?.additionalSecondTrailerInformation));
              }
            }

            if (this.vehicle?.isNoRegisteredOwner)
              this.registeredOwnerInformation.push(new SectionDetailsModel('Registered Owner', "Unable to determine"));
            else
            {
              this.registeredOwnerInformation.push(new SectionDetailsModel('Driver Licence Number', this.vehicle?.registeredOwner?.driverLicenceNumber));
              this.registeredOwnerInformation.push(new SectionDetailsModel('M.V.I.D', this.vehicle?.registeredOwner?.motorVehicleIdentificationNumber));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Surname', this.vehicle?.registeredOwner?.lastName));
              this.registeredOwnerInformation.push(new SectionDetailsModel('First Name', this.vehicle?.registeredOwner?.firstName));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Middle Name', this.vehicle?.registeredOwner?.otherName));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.vehicle?.registeredOwner?.genderId)?.name));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.vehicle?.registeredOwner?.dateOfBirth, 'dd MMM, yyyy')));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Telephone Number', this.vehicle?.registeredOwner?.registeredOwnerContact?.phoneNumber1));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Address 1', this.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine1));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Address 2', this.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine2));
              this.registeredOwnerInformation.push(new SectionDetailsModel('City', this.vehicle?.registeredOwner?.registeredOwnerContact?.city));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Province/State', (this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 40 || this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 236) ? (this.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId)?.name) : this.vehicle?.registeredOwner?.registeredOwnerContact?.province));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId)?.name));
              this.registeredOwnerInformation.push(new SectionDetailsModel('Postal Code', this.vehicle?.registeredOwner?.registeredOwnerContact?.postalCode));
            }
            
            this.vehicleDriverInformation.push(new SectionDetailsModel('Driver Licence Number', this.vehicle?.vehicleDriver?.driversLicenceNumber));
            this.vehicleDriverInformation.push(new SectionDetailsModel('M.V.I.D', this.vehicle?.vehicleDriver?.motorVehicleIdentificationNumber));
            this.vehicleDriverInformation.push(new SectionDetailsModel('Surname', this.vehicle?.vehicleDriver?.lastName));
            this.vehicleDriverInformation.push(new SectionDetailsModel('First Name', this.vehicle?.vehicleDriver?.firstName));
            this.vehicleDriverInformation.push(new SectionDetailsModel('Middle Name', this.vehicle?.vehicleDriver?.otherName));
          }

          // Supporting Document Declarations

          var declarationTypes = this.localStorageService.getDeclarationTypes();

          this.contravention.policeServiceInformation.forEach(p => {
            if (p.declarationInformation != null) {
              var declaration = declarationTypes.find(d => d.id == p.declarationInformation.declarationTypeId);
              this.supportingDocumentDeclarations.push(new SectionDetailsModel('Name of Submitter', p.declarationInformation.policeOfficerName));
              this.supportingDocumentDeclarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(p.declarationInformation.declarationDate, 'dd MMM, yyyy')));
              this.supportingDocumentDeclarations.push(new SectionDetailsModel(declaration.description, 'Yes', true));       
              this.showSupportingDocumentDeclarations = true;  
            }
          });
          
          // Police Service Section
          this.policeServiceInfo = this.contravention.policeServiceInformation[0];
          this.policeFileNumber = this.contravention.policeFileNumber;
          var detachment = this.localStorageService.getDetachments().find(x => x.detachmentId == this.policeServiceInfo.detachmentId);

          var officerSubmissionDeclaration = declarationTypes?.find(d => d.id == this.contravention.officerSubmissionDeclarationTypeId).description; 

          this.policeServiceInformation.push(new SectionDetailsModel('Name of Police Officer', this.policeServiceInfo.policeOfficerName));
          this.policeServiceInformation.push(new SectionDetailsModel('Police File Number', this.policeFileNumber));
          this.policeServiceInformation.push(new SectionDetailsModel('Regimental Number', this.policeServiceInfo.regimentalNumber));
          this.policeServiceInformation.push(new SectionDetailsModel('Police Service', detachment?.agency?.agencyName));
          this.policeServiceInformation.push(new SectionDetailsModel('Detachment', detachment.detachmentName));
          if (this.additionalFieldsActive) {
            this.officerDeclarations.push(new SectionDetailsModel('Name of Police Officer', this.policeServiceInfo.policeOfficerName));
            this.officerDeclarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(this.contravention.submissionDate, 'dd MMM, yyyy')));
          }
          this.officerDeclarations.push(new SectionDetailsModel(officerSubmissionDeclaration, this.contravention.isTrueInformation? "Yes": "No", true));

          if (this.contravention.officerAdditionalDetailsDeclarationTypeId) {
            const officerAdditionalDetailsDeclaration = declarationTypes?.find(d => d.id == this.contravention.officerAdditionalDetailsDeclarationTypeId).description;
            this.officerDeclarations.push(new SectionDetailsModel('Name of Police Officer', this.policeServiceInfo.policeOfficerName));
            this.officerDeclarations.push(new SectionDetailsModel('Date of Additional Details', this.datePipe.transform(this.contravention.additionalDetailsDate, 'dd MMM, yyyy')));
            this.officerDeclarations.push(new SectionDetailsModel(officerAdditionalDetailsDeclaration, "Yes", true));
          }

          // Add Service Type & declartion to police service information *To Do

          //Set Submission Date and Time
          var today = new Date();
          this.submissionDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
          this.submissionTime = (moment(today)).format('HH:mm');
        } else {
          this.router.navigate(['../../contraventions/search']);
        }
        this.masterPage.hideSpinner();
      });
  }

  DisplayTestResults(test: ContraventionTest, section: SectionDetailsModel[], isTypeRequired: boolean, isRoadsideAppeal: boolean = false) {
    if (test == null) return;

    var newFormatDate = this.localStorageService.getDriverDeclinedToTestTypes().find(x => x.id == DriverDeclinedToTestTypes.FailedToComply)?.effectiveDate;
    var isNewFormat = new Date(this.contravention.submissionDate) >= new Date(newFormatDate);
    var isMas = this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId == 2 && !isRoadsideAppeal;
    var isNewFormatV2 = new Date(this.contravention.submissionDate) > new Date(+Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE.substring(0, 4), +Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE.substring(5, 7)-1, +Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE.substring(8, 10));
    var isAIThirdTestVisible = new Date(this.contravention.submissionDate) >= new Date(+Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(8, 10));
    var isNewDREProcess = new Date(this.contravention.submissionDate) >= new Date(+Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(8, 10));

    if (isTypeRequired)
      section.push(new SectionDetailsModel('Screening Method', this.localStorageService.getTestTypes().find(x => x.id == test.testTypeId)?.name));

    switch (test.testTypeId.toString())
    {
      case "1": //SFST
        if (isNewFormatV2)
        {
          section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal?"Roadside Appeal Request":"SFST Demand"}`, this.timePipe.transform(test.standardizedFieldSobrietyTest?.testDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal?"Roadside Appeal":"SFST"} Test Started`, this.timePipe.transform(test.standardizedFieldSobrietyTest?.testStartedTime, 'shortTime24h')));
        }
        else
        {
          section.push(new SectionDetailsModel(`Time of SFST ${(isRoadsideAppeal && isNewFormat)?"Request":"Demand"}`, this.timePipe.transform(test.standardizedFieldSobrietyTest?.testDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel('Time SFST Test Started', this.timePipe.transform(test.standardizedFieldSobrietyTest?.testStartedTime, 'shortTime24h')));
        }
        section.push(new SectionDetailsModel('SFST Results', this.localStorageService.getTestResultTypes().find(x => x.id == test.standardizedFieldSobrietyTest?.testResultTypeId)?.name));
        break;
      case "2": //ASD
        section.push(new SectionDetailsModel('ASD Model', test.approvedScreeningDeviceTest?.testModelTypeId == 2? test.approvedScreeningDeviceTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.approvedScreeningDeviceTest?.testModelTypeId)?.name));
        if (isNewFormatV2)
        {
          if (test.approvedScreeningDeviceTest?.testDemandTime) {
            section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal?"Roadside Appeal Request": ((isMas && isNewFormat)?"MAS":"ASD") + " Demand"}`, this.timePipe.transform(test.approvedScreeningDeviceTest?.testDemandTime, 'shortTime24h')));
          }
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal?"Roadside Appeal": ((isMas && isNewFormat)?"MAS":"ASD")} Test Started`, this.timePipe.transform(test.approvedScreeningDeviceTest?.testStartedTime, 'shortTime24h')));
        }
        else
        {
          if (!isRoadsideAppeal || test.approvedScreeningDeviceTest?.testDemandTime) {
            section.push(new SectionDetailsModel(`Time of ${(isMas && isNewFormat)?"MAS":"ASD"} Demand`, this.timePipe.transform(test.approvedScreeningDeviceTest?.testDemandTime, 'shortTime24h')));
          }
          section.push(new SectionDetailsModel(`Time ${(isMas && isNewFormat)?"MAS":"ASD"} Test Started`, this.timePipe.transform(test.approvedScreeningDeviceTest?.testStartedTime, 'shortTime24h')));
        }
        section.push(new SectionDetailsModel(`${(isMas && isNewFormat)?"MAS":"ASD"} Results`, this.localStorageService.getTestResultTypes().find(x => x.id == test.approvedScreeningDeviceTest?.testResultTypeId)?.name));
        break;
      case "3": //ADSE
        section.push(new SectionDetailsModel('ADSE Model', test.drugScreeningEquipmentTest?.testModelTypeId == 5? test.drugScreeningEquipmentTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.drugScreeningEquipmentTest?.testModelTypeId)?.name));
        if (isNewFormatV2)
        {
          section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal?"Roadside Appeal Request":"ADSE Demand"}`, this.timePipe.transform(test.drugScreeningEquipmentTest?.testDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal?"Roadside Appeal":"ADSE"} Test Started`, this.timePipe.transform(test.drugScreeningEquipmentTest?.testStartedTime, 'shortTime24h')));
        }
        else
        {
          section.push(new SectionDetailsModel(`Time of ADSE ${(isRoadsideAppeal && isNewFormat)?"Request":"Demand"}`, this.timePipe.transform(test.drugScreeningEquipmentTest?.testDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel('Time ADSE Test Started', this.timePipe.transform(test.drugScreeningEquipmentTest?.testStartedTime, 'shortTime24h')));
        }
        section.push(new SectionDetailsModel('ADSE Results', Array.prototype.map.call(this.localStorageService.getTestResultTypes().filter(x => test.drugScreeningEquipmentTest?.testResultTypeId?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (test.drugScreeningEquipmentTest?.testResultTypeId?.split(',').includes("10")? "-" + test.drugScreeningEquipmentTest?.testResultOther:"")));
        break;
        case "4": //DRE
        if (isNewDREProcess)
        {
          section.push(new SectionDetailsModel(`Time of DRE ${isRoadsideAppeal?"Request":"Demand"}`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.testDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(isRoadsideAppeal?"Decline?":"Refusal", this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));

          if (test.driverDeclinedToTestTypeId != DriverDeclinedToTestTypes.No)
          {
            var fieldLabel = test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal? (isRoadsideAppeal?"Declined?":"Refused"):"Failed to Comply With";
            switch (+test.drugRecognitionEvaluationTest?.refusalOrFailedClassificationId) 
            {
              case RefusalOrFailedClassification.BreathSample:
                section.push(new SectionDetailsModel(fieldLabel, "Breath Sample"));
                break;
              case RefusalOrFailedClassification.DREProcess:
                section.push(new SectionDetailsModel(fieldLabel, "DRE Process"));
                break;
              case RefusalOrFailedClassification.ToxicologicalSample:
                section.push(new SectionDetailsModel(fieldLabel, "Toxicological Sample"));
                break;
            }
          }

          section.push(new SectionDetailsModel(`Breath ${isRoadsideAppeal?"Request":"Demand"} (Step 1) Made?`, test.drugRecognitionEvaluationTest?.isBreathDemandMade?"Yes":"No"));

          if (test.drugRecognitionEvaluationTest?.isBreathDemandMade)
          {
            section.push(new SectionDetailsModel(`Time of Breath ${isRoadsideAppeal?"Request":"Demand"}`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.breathDemandTime, 'shortTime24h')));
            section.push(new SectionDetailsModel('Breath Test Results', test.drugRecognitionEvaluationTest?.breathTestResult));
          }

          if (test.drugRecognitionEvaluationTest?.testStartedTime)
            section.push(new SectionDetailsModel('Time DRE Test Started', this.timePipe.transform(test.drugRecognitionEvaluationTest?.testStartedTime, 'shortTime24h')));

          if (test.drugRecognitionEvaluationTest.sampleClassificationId)
          {
            var sampleType = test.drugRecognitionEvaluationTest?.sampleClassificationId == SampleClassification.Blood? "Blood": "Urine";
            section.push(new SectionDetailsModel(`Type of Sample ${isRoadsideAppeal?"Requested":"Demanded"}`, sampleType));
            section.push(new SectionDetailsModel(`Sample ${isRoadsideAppeal?"Request":"Demand"} Time`, this.timePipe.transform(test.drugRecognitionEvaluationTest.sampleDemandTime, 'shortTime24h')));

            if (test.drugRecognitionEvaluationTest.sampleCollectionTime)
            {
              section.push(new SectionDetailsModel('Sample Time', this.timePipe.transform(test.drugRecognitionEvaluationTest.sampleCollectionTime, 'shortTime24h')));
              section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.drugRecognitionEvaluationTest?.labTypeId)?.name));
            }
          }  

          if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
            section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal?"Decline":"Refusal"}`, this.timePipe.transform(test.refusalTime, 'shortTime24h')));
        }
        else
        {
          if (isNewFormatV2)
          {
            section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal?"Roadside Appeal Request":"DRE Demand"}`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.testDemandTime, 'shortTime24h')));
            section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal?"Roadside Appeal":"DRE"} Test Started`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.testStartedTime, 'shortTime24h')));
          }
          else
          {
            section.push(new SectionDetailsModel(`Time of DRE ${(isRoadsideAppeal && isNewFormat)?"Request":"Demand"}`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.testDemandTime, 'shortTime24h')));
            section.push(new SectionDetailsModel('Time DRE Test Started', this.timePipe.transform(test.drugRecognitionEvaluationTest?.testStartedTime, 'shortTime24h')));
          }
        }
        break;
      case "5": //AI
        section.push(new SectionDetailsModel('AI Model', test.approvedInstrumentTest?.testModelTypeId == 7? test.approvedInstrumentTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.approvedInstrumentTest?.testModelTypeId)?.name));
        
        if (isAIThirdTestVisible)
        {
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal?"Roadside Appeal":"AI"} Test Started`, this.timePipe.transform(test.approvedInstrumentTest?.testStartedTime, 'shortTime24h')));
        }

        if (isNewFormatV2)
        {
          section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal?"Roadside Appeal Request":"1st Breath AI Demand"}`, this.timePipe.transform(test.approvedInstrumentTest?.firstBreathDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(`${isRoadsideAppeal?"":"1st "}Evidentiary Test Result`, test.approvedInstrumentTest?.firstEvidentiaryTestResult));
        }
        else
        {
          section.push(new SectionDetailsModel(`Time of 1st Breath AI ${(isRoadsideAppeal && isNewFormat)?"Request":"Demand"}`, this.timePipe.transform(test.approvedInstrumentTest?.firstBreathDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel('1st Evidentiary Test Result', test.approvedInstrumentTest?.firstEvidentiaryTestResult));
        }
     
        if (!isRoadsideAppeal)
        {
          section.push(new SectionDetailsModel(`Time of 2nd Breath AI ${isAIThirdTestVisible?"Request":"Demand"}`, this.timePipe.transform(test.approvedInstrumentTest?.secondBreathDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(`2nd Evidentiary Test Result`, test.approvedInstrumentTest?.secondEvidentiaryTestResult));

          if (isAIThirdTestVisible)
          {
            section.push(new SectionDetailsModel(`Time of 3rd Breath AI Request`, this.timePipe.transform(test.approvedInstrumentTest?.thirdBreathDemandTime, 'shortTime24h')));
            section.push(new SectionDetailsModel(`3rd Evidentiary Test Result`, test.approvedInstrumentTest?.thirdEvidentiaryTestResult));
          }
        }
     
        break;
      case "6": //Blood Sample
        if (isNewDREProcess && !isRoadsideAppeal)
        {
          if (test.bloodSampleTest.isBloodDemand)
          {
            section.push(new SectionDetailsModel('Type of Sample', 'Blood Demand (320.28)'));
            section.push(new SectionDetailsModel("Refusal?", this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));
            section.push(new SectionDetailsModel('Time of Demand', this.timePipe.transform(test.bloodSampleTest?.bloodSampleDemandTime, 'shortTime24h')));
            if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No)
            {
              section.push(new SectionDetailsModel('Blood Sample Time', this.timePipe.transform(test.bloodSampleTest?.bloodSampleTime, 'shortTime24h')));
              section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.labTypeId)?.name));
            }
            
            if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
              section.push(new SectionDetailsModel('Time of Refusal', this.timePipe.transform(test.refusalTime, 'shortTime24h')));
          }

          if (test.bloodSampleTest.isBloodWarrant)
          {
            section.push(new SectionDetailsModel('Type of Sample', 'Blood Warrant (320.29)', true));
            section.push(new SectionDetailsModel('Blood Sample Time', this.timePipe.transform(test.bloodSampleTest?.bloodWarrantSampleTime, 'shortTime24h')));
            section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.bloodWarrantLabTypeId)?.name));
          }

          if (test.bloodSampleTest.isSearchWarrant)
            section.push(new SectionDetailsModel('Type of Sample', 'Search Warrant (487)', true));
          
          if (test.bloodSampleTest.isHospitalRecord)
            section.push(new SectionDetailsModel('Type of Sample', 'Hospital Records (Production Order or Consent)', true));  
        }
        else
        {
          if (isNewDREProcess)
            section.push(new SectionDetailsModel(isRoadsideAppeal?"Time of Roadside Appeal Request":"Blood Sample Demand Time", this.timePipe.transform(test.bloodSampleTest?.bloodSampleDemandTime, 'shortTime24h')));  
            
          section.push(new SectionDetailsModel('Blood Sample Time', this.timePipe.transform(test.bloodSampleTest?.bloodSampleTime, 'shortTime24h')));
          section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.labTypeId)?.name));
          section.push(new SectionDetailsModel('Blood Sample Result', test.bloodSampleTest?.bloodSampleResult));
        }
        break;
      case "9": //Urine Sample
        if (isNewDREProcess)
        section.push(new SectionDetailsModel(isRoadsideAppeal?"Time of Roadside Appeal Request":"Urine Sample Demand Time", this.timePipe.transform(test.urineSampleTest?.urineSampleDemandTime, 'shortTime24h')));  

        section.push(new SectionDetailsModel('Urine Sample Time', this.timePipe.transform(test.urineSampleTest?.urineSampleTime, 'shortTime24h')));
        section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.urineSampleTest?.labTypeId)?.name));
        section.push(new SectionDetailsModel('Urine Sample Result', test.urineSampleTest?.urineSampleResult));
        break;
    }

    if (test.testTypeId != TestTypes.NoScreening && test.testTypeId != TestTypes.NoAppeal && (test.testTypeId != TestTypes.DRE || !isNewDREProcess) && (test.testTypeId != TestTypes.Blood || !isNewDREProcess || isRoadsideAppeal)) //No Screening Method and No Appeal
    {
      section.push(new SectionDetailsModel((isRoadsideAppeal && isNewFormat)?"Decline?":"Refusal?", ((isRoadsideAppeal || !isNewFormat) && test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)?"Yes":this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));
      
      if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
        section.push(new SectionDetailsModel(`Time of ${(isRoadsideAppeal && isNewFormat)?"Decline":"Refusal"}`, this.timePipe.transform(test.refusalTime, 'shortTime24h')));
    }
  }
  
  getDocumentTypeName(document: Document) : string {
    return `${this.localStorageService.getTypeItemDescriptionById(document.documentTypeId, TypeTable.DocumentType)} ${document.documentDescription != null ? " - " + document.documentDescription : ""}`
  }

  downloadDocument(supportingDocument: Document) {
    var documentRefTypeName: string;
    var documentRefTypeNumber: string;

    documentRefTypeName = this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1 ? this.getDocumentRefName() : "Stop";
    documentRefTypeNumber = this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1 ? this.getDocumentRefNumber() : this.stopInformation.stopInformationId.toString();

    var storageFileName = `${supportingDocument.contentGuid}.${supportingDocument.documentExtension}`;

    if (supportingDocument.version == Constants.DocumentVersion.VERSION3)
    {
     this.documentService.getDocumentSasUri("", documentRefTypeName, documentRefTypeNumber, storageFileName , supportingDocument.documentName)
     .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
     });
      
      return;
    }

    this.documentService.downloadDocument("", documentRefTypeName, documentRefTypeNumber, storageFileName , supportingDocument.documentName, supportingDocument.documentLocation)
      .subscribe((result: any) => {
        if (result) {
          fileSaver.saveAs(new Blob([result]), supportingDocument.documentName);
        }
      });
  }

  getDocumentRefNumber(): string {
    if (this.stopInformation.contraventions.length > 0)
      return this.stopInformation.contraventions[0].contraventionNumber;

    if (this.stopInformation.vehicleSeizures.length > 0)
      return this.stopInformation.vehicleSeizures[0].seizureNumber;

    return "";
  }

  getDocumentRefName(): string {
    if (this.stopInformation.contraventions.length > 0)
      return "Contraventions";

    if (this.stopInformation.vehicleSeizures.length > 0)
      return "VehicleSeizures";

    return "";
  }
}

<!-- Driver Portal View -->
<app-vehicle-seizure-page *ngIf="!isRSGPortal">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm">
        <div class="d-flex flex-column">
            <h1>Preferred Review Times</h1>    
            <h3 class="border-bottom pb-4 mb-2">No. {{review.seizureNumber}}</h3>

            <div class="card border-0">
                <p>
                    Please select at least three timeslots below when you wish your seizure to be reviewed.
                </p>
            </div>

            <div class="card goa-callout goa--information mt-1">
                <h6 class="mt-0">Scheduling Requests</h6>
                <p>
                    SafeRoads Alberta will accommodate requested review times whenever possible, 
                    however your preferred time or day of the week cannot be guaranteed. 
                    Your review date and time will be scheduled once your application is received. 
                    SafeRoads Alberta will confirm your review date and time via your preferred contact method.
                </p>
            </div>

            <div class="card border-0">
                <app-scheduler                     
                    [periodStartDate]="reviewPeriodStartDate" 
                    [periodEndDate]="reviewPeriodEndDate" 
                    [(preferredTimes)]="review.reviewScheduleRequest.availabilityTimeSlots">
                </app-scheduler>
            </div>

            <div *ngIf="requestForm.submitted && !isRequiredTimeslotsSelected()" class="text-danger">
                Please select timeslots from at least three different dates
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block"
                            [routerLink]="['../details']">Return to Seizure Review Details</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Supporting Documents</button>
                    </div>
                </div>
            </div>	
        </div>

        <!-- Modal -->
        <div class="modal" id="confirmReviewDateModal" tabindex="-1" aria-labelledby="confirmReviewDateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content p-4">
                    <div class="modal-header border-0 pt-2">
                        <span class="modal-title h4" id="confirmReviewDateModalLabel">
                            Confirm?
                        </span>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="far fa-times-circle fa-lg"></i>
                        </button>
                    </div>
                    <div class="modal-body py-0">
                        <p>
                            You have selected a preferred date within three days from today, 
                            which limits your window to upload supporting documents. 
                            As per section 13(2) of the <i>Provincial Administrative Penalties Act</i>, 
                            any supporting documents you wish to have considered as part of your 
                            review must be uploaded to the SafeRoads portal <b>at least two (2) calendar 
                            days before</b> your scheduled review date.
                        </p>
                        <p>
                            <b>Do you still want to proceed with this review date?</b>
                        </p>
                    </div>
                    <div class="modal-footer border-0 d-flex justify-content-between">
                        <button type="button" class="goa-button goa--secondary mt-2" data-dismiss="modal">Cancel</button>
                        <button type="button" class="goa-button mt-2" data-dismiss="modal" (click)="navigate()">Continue</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page *ngIf="isRSGPortal">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm">
        <div class="d-flex flex-column">
            <h1>Review Times</h1>    
            <h3 class="border-bottom pb-4 mb-2">No. {{review.seizureNumber}}</h3>

            <div class="card goa-callout goa--information mt-1">
                <h6 class="mt-0">Schedule the Review</h6>
                <p>
                    Please ask the client to select at least 3 timeslots when they wish their seizure to be reviewed. 
                    SafeRoads Alberta will accomodate requested review times whenever possible, however the client's preferred 
                    time or day of the week cannot be guaranteed. The review date and time will be scheduled once this 
                    application is received. SafeRoads Alberta will confirm the review date and time via the client's preferred contact method.
                </p>
            </div>

            <div class="card border-0">
                <app-scheduler                     
                    [periodStartDate]="reviewPeriodStartDate" 
                    [periodEndDate]="reviewPeriodEndDate" 
                    [(preferredTimes)]="review.reviewScheduleRequest.availabilityTimeSlots">
                </app-scheduler>
            </div>

            <div *ngIf="requestForm.submitted && !isRequiredTimeslotsSelected()" class="text-danger">
                Please select timeslots from at least three different dates
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block"
                            [routerLink]="['../details']">Return to Seizure {{reviewContext}} Details</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Supporting Documents</button>
                    </div>
                </div>
            </div>	
        </div>

        <!-- Modal -->
        <div class="modal" id="confirmReviewDateModal" tabindex="-1" aria-labelledby="confirmReviewDateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content p-4">
                    <div class="modal-header border-0 pt-2">
                        <span class="modal-title h4" id="confirmReviewDateModalLabel">
                            Confirm?
                        </span>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="far fa-times-circle fa-lg"></i>
                        </button>
                    </div>
                    <div class="modal-body py-0">
                        <p>
                            You have selected a preferred date within three days from today, 
                            which limits your window to upload supporting documents. 
                            As per section 13(2) of the <i>Provincial Administrative Penalties Act</i>, 
                            any supporting documents you wish to have considered as part of your 
                            review must be uploaded to the SafeRoads portal <b>at least two (2) calendar 
                            days before</b> your scheduled review date.
                        </p>
                        <p>
                            <b>Do you still want to proceed with this review date?</b>
                        </p>
                    </div>
                    <div class="modal-footer border-0 d-flex justify-content-between">
                        <button type="button" class="goa-button goa--secondary mt-2" data-dismiss="modal">Cancel</button>
                        <button type="button" class="goa-button mt-2" data-dismiss="modal" (click)="navigate()">Continue</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>

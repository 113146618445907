import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Document } from '@apis/shared/models/document.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { FileUploadModel } from '@apis/shared/models/file-upload.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { AdditionalDocumentCategory } from '@apis/shared/enums/additional-document-category.enum';
import { DocumentTypes, RepresentationTypes, ReviewTypes, SeizureUserType } from '@apis/shared/enums/app.enum';
import { Constants } from '@apis/shared/helpers/constants';
import { Review } from '@apis/shared/models/review.model';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentUploadRequest } from '@apis/shared/models/document-upload-request.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { finalize } from 'rxjs/operators';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { DriverBaseComponent } from '../../../../driver-base.component';
import { ReviewService } from 'apps/driver/src/app/shared/services/review.service';

@Component({
  selector: 'app-seizure-review-supporting-documents',
  templateUrl: './seizure-review-supporting-documents.component.html',
  styleUrls: ['./seizure-review-supporting-documents.component.scss']
})
export class SeizureReviewSupportingDocumentsComponent extends DriverBaseComponent
  implements OnInit {

  review: Review;
  ReviewTypes = ReviewTypes;
  documents: Document[] = [];
  documentTypes: DocumentType[];
  fileUploadModel: FileUploadModel;
  errorMessage: string = "";
  vehicleSeizure: VehicleSeizure;
  seizureNumber: string;
  seizureUserType: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private documentService: DocumentService,
    private reviewService: ReviewService,
    private readonly spinner: NgxSpinnerService
  ) { 
    super(router);
  }

  ngOnInit(): void {
    //scroll to top with tab change
    window.scroll(0,0);

    const createdReview = this.localStorageService.getCreatedReview();
    
    if (createdReview) {
      this.seizureNumber = createdReview.seizureNumber;
      this.vehicleSeizure = this.localStorageService.getItem<VehicleSeizure>(createdReview.seizureNumber);
      this.seizureUserType = this.localStorageService.get(`${this.seizureNumber}_user_type`);

      if (+this.seizureUserType == SeizureUserType.Driver) {
        this.review = this.vehicleSeizure.review;
      } else {
        this.review = this.vehicleSeizure.relief;
      }

      this.documentTypes = this.localStorageService.getDocumentTypes();
      this.initializeDocuments();
    }
    else {
      this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
    }
  }

  initializeDocuments() {
    //Initialize file upload model and a temporary file storage location
    this.fileUploadModel = new FileUploadModel();
    this.fileUploadModel.tempFileFolder = Guid.create().toString();
    this.fileUploadModel.additionalDocumentsCategory = AdditionalDocumentCategory.VehicleSeizureReview;
    this.fileUploadModel.documentRefTypeName = "Reviews";
    this.fileUploadModel.documentRefTypeNumber = this.review.reviewNumber;

    if (this.review.representation)
    {
      this.addPlaceHolder(DocumentTypes.RecipientsConsentOfRepresentation, true);
    }

    if (this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview) {
      if (this.review.isCommercialVehicle) {
        this.addPlaceHolder(DocumentTypes.DriverAbstract, true);
      }

      if (this.review.isVehicleTakenWithoutPermission) {
        this.addPlaceHolder(DocumentTypes.SwornAffidavit, true);
      }

      if (this.review.isVehicleStolen) {
        this.addPlaceHolder(DocumentTypes.PoliceReport, true);
      }

      if (this.review.hasRentalAgreement) {
        this.addPlaceHolder(DocumentTypes.RentalAgreement, true);
      }
    }

    //Check if any additional document is added
    if(this.review.documents != null && this.review.documents.filter(x => Constants.Driver.SeizureReviewDocuments.includes(+x.documentTypeId)))
    {
      var additionaldocuments = this.review.documents.filter(x => Constants.Driver.SeizureReviewDocuments.includes(+x.documentTypeId));
      
      additionaldocuments.forEach(d => {
        d.isOptional = true;
        d.documentNumber = Guid.create().toString();
      });

      this.documents = this.documents.concat(additionaldocuments);
    }
  }

  addPlaceHolder(documentType: DocumentTypes, isOptional: boolean = false) {
    let document = null;

    if (this.review.documents) {
      document = this.review.documents.find(x => x.documentTypeId == +documentType);

      if (document == null) {
        document = new Document({
          documentTypeId: +documentType,
          reviewId: this.review.reviewId,
          isOptional: true,
          isSubmitLater: false,
          isPublished: true,
          forcePrimaryDocument: true,
          uploadedBy: this.isRSGPortal? "Registry":"Recipient"
        });
        this.documents.push(document);
      }
    }
  }

  trackDocument(index: number, document: Document): any {
    return document.documentNumber;
  }

  AddOptionalDocument() {
    var document = new Document({
      reviewId: this.review.reviewId,
      documentTypeId: null,
      isOptional: true,
      isSubmitLater: false,
      isPublished: true,
      uploadedBy: this.isRSGPortal? "Registry":"Recipient"
    });

    this.documents.push(document);
  }

  onCancelDocument(document: Document)
  {
    this.documents.splice(this.documents.findIndex(x => x==document), 1);
  }

  onRemoveDocument(document: Document)
  {
    // Remove document from Review
    if (document.documentId > 0){
      const documentIndex = this.review.documents.findIndex(d => d && d.contentGuid==document.contentGuid);
      if (documentIndex != -1) {
        this.review.documents.splice(documentIndex, 1);
      }
      this.saveContext();
    }

    let placeholderDocumentTypes = [];
    if (this.review.representation)
    {
      placeholderDocumentTypes.push(DocumentTypes.RecipientsConsentOfRepresentation);
    }
    if (this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview) {
      placeholderDocumentTypes.push(DocumentTypes.DriverAbstract, DocumentTypes.SwornAffidavit, DocumentTypes.PoliceReport, DocumentTypes.RentalAgreement);
    }

    if (placeholderDocumentTypes.includes(+document.documentTypeId) // If the document's type is a placeholder document type
      && this.review.documents.filter(x => +x.documentTypeId === +document.documentTypeId).length === 0 // If no document exists, excluding placeholders
      && this.documents.filter(x => +x.documentTypeId === +document.documentTypeId && x.forcePrimaryDocument && +x.documentId === 0).length === 0 // If no placeholder exists (excluding non-primary document placeholders)
      ) {
      // Turn the document into a placeholder instead of removing the collection entry
      document.forcePrimaryDocument = true;
      document.documentId = 0;
      document.documentName = null;
      document.documentExtension = null;
      document.contentGuid = null;
    } else {
      // Remove document from collection
      const documentIndex = this.documents.findIndex(d => d && d.contentGuid===document.contentGuid);
      if (documentIndex != -1) {
        this.documents.splice(documentIndex, 1);
      }
    }
  }

  onDocumentBeforeUpload = (document: Document) => {
    const error = this.validateDocument(document);
    if (error) {
      const subject = new Subject<any>();
      subject.error(error);
      return subject.asObservable();
    }

    return this.reviewService.canSubmitReviewDocuments(this.review);
  }

  onDocumentUploaded = (document: Document) => {
    return this.finalizeDocument(document);
  }

  onDocumentBeforeRemove = () => {
    return this.reviewService.canRemoveReviewDocuments(this.review);
  }

  finalizeDocument(document: Document): Observable<any>
  {
    var subject = new Subject<any>();

    this.spinner.show();

    var documentUploadRequest = new DocumentUploadRequest();
    documentUploadRequest.documents = [document];
    documentUploadRequest.tempFolderName = this.fileUploadModel.tempFileFolder;
    documentUploadRequest.documentRefTypeName = this.fileUploadModel.documentRefTypeName;
    documentUploadRequest.documentRefTypeNumber = this.fileUploadModel.documentRefTypeNumber;

    this.documentService.updateAndFinalizeDocumentsAsync(documentUploadRequest)
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        )
        .subscribe((result: Document[]) => {
          var updatedDocument = result[0];
          updatedDocument.documentNumber = document.documentNumber; // Document received from the API does not have a documentNumber. Restore the old value here.

          document.documentId = updatedDocument.documentId;

          this.review.documents.push(updatedDocument);

          // Update seizure context
          this.localStorageService.setItem<VehicleSeizure>(this.seizureNumber, this.vehicleSeizure);

          subject.next(null);
        },
        (error) => {
          subject.error(this.getErrorMessage(error));

          window.scroll(0,0);
        });

    return subject.asObservable();
  }

  validateDocument(document: Document): string {
    //Check if uploaded document is under the maximum document count limit
    if (document && document.documentTypeId) {
      var documentType = this.documentTypes.find(x => x.id == document.documentTypeId);
      var uploadedDocumentsCount = this.documents.filter(x => x && x.documentTypeId == document.documentTypeId).length;
      if (uploadedDocumentsCount > documentType.maximumFileCount)
      {
        return `You can upload maximum of ${documentType.maximumFileCount} document(s) of type ${documentType.name.trim()}.`;
      }
    }

    return null;
  }

  getErrorMessage(error: any): string {
    if (error?.error && Array.isArray(error?.error))
      return error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      return error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      return error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      return error.error.errors;  
    else    
      return "Something went wrong. Please try again later.";
  }

  saveContext() {
    if (+this.seizureUserType == SeizureUserType.Driver) {
      this.vehicleSeizure.review = this.review;
    } else {
      this.vehicleSeizure.relief = this.review;
    }

    // Update seizure context
    this.localStorageService.setItem<VehicleSeizure>(
      this.seizureNumber,
      this.vehicleSeizure
    );
  }
}




export class TransactionLineItem {
    public transactionLineItemId: number = 0;
    public financialTransactionId: number = 0;
    public recordNumber: string;
    public amount: number;
    public isChecked: boolean;

    constructor(init?: Partial<TransactionLineItem>) {
        
        Object.assign(this, init);
    }
}

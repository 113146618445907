import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecisionTypes, DocumentTypes, RepresentationTypes, ReviewMethodTypes, ReviewStatusTypes, ReviewTypes, SeizureUserType, VehicleInterestTypes } from '@apis/shared/enums/app.enum';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { Review } from '@apis/shared/models/review.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentService } from '@apis/shared/services/document.service';
import { ReviewService } from 'apps/driver/src/app/shared/services/review.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fileSaver from 'file-saver';
import { finalize } from 'rxjs/operators';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { Constants } from '@apis/shared/helpers/constants';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { TermCondition } from '@apis/shared/models/term-condition.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DatePipe } from '@angular/common';
import { ViewContainerRef } from '@angular/core';
import { UploadReviewConsentModalComponent } from 'apps/driver/src/app/shared/components/modals/upload-review-consent-modal/upload-review-consent-modal.component';
import { SeizureReviewDetailsBaseComponent } from '../seizure-review-details-base.component';
import * as moment from 'moment';

declare var $: any

@Component({
  selector: 'app-seizure-review-details-view',
  templateUrl: './seizure-review-details-view.component.html',
  styleUrls: ['./seizure-review-details-view.component.scss'],
})
export class SeizureReviewDetailsViewComponent extends SeizureReviewDetailsBaseComponent
implements OnInit
{
  reviewMethodName: string;
  reviewDecision: string;
  isConsentAccepted = false;
  isDecisionAvailable = false;
  isCancelFailed = false;
  isThirdPartySeizure: boolean;
  applicantFirstName: string;
  applicantLastName: string;
  vehicleInterestTypeName: string;
  showDocumentMetadataActive: boolean;

  ReviewStatusTypes = ReviewStatusTypes;
  ReviewMethodTypes = ReviewMethodTypes;

  registryAgentInformation: SectionDetailsModel[] = [];
  representationInformation: SectionDetailsModel[] = [];
  requesterInformation: SectionDetailsModel[] = [];
  supportingDocuments: DocumentModel[] = [];
  consentToRepresentationDocuments: DocumentModel[] = [];
  reviewUploads: DocumentModel[] = [];
  decisionLetterDocument: DocumentModel;
  termCondition: TermCondition;

  reviewDate: string = '-';
  reviewTime: string = '-';
  reviewStartTime: string;
  reviewEndTime: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private documentService: DocumentService,
    public reviewService: ReviewService,
    private readonly spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    readonly viewContainerRef: ViewContainerRef,) {
      super(router, activatedRoute, localStorageService, reviewService);
     }

  ngOnInit(): void {
    this.spinner.show();
    super.onInit();
  }

  onInitComplete(): void {
    this.spinner.hide();

    if (this.review.reviewStatusTypeId == ReviewStatusTypes.New) {
      this.router.navigate(['../reschedule'], { relativeTo: this.activatedRoute });  
      return;
    } 

    this.populateData();
  }

  populateData() {
    this.requesterInformation = [];
    this.representationInformation = [];
    this.supportingDocuments = [];  
    this.consentToRepresentationDocuments = [];
    this.reviewUploads = [];
    
    this.termCondition = this.localStorageService.getTermsAndConditionsList().find(t => t.version == this.review.termsConditionsVersion);

    this.reviewMethodName = this.localStorageService
      .getReviewMethodTypes()
      .find((x) => x.id == this.review.reviewMethodTypeId)?.name;

    this.isThirdPartySeizure = +this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview;

    if (this.isThirdPartySeizure) { // JTI-2120: Display applicant name and interest in vehicle for 3rd party seizures
      this.applicantFirstName = this.review.reviewApplicant.firstName;
      this.applicantLastName = this.review.reviewApplicant.lastName;

      const vehicleInterestType = this.localStorageService.getVehicleInterestTypes().find(v => v.id == this.review.reviewApplicant.vehicleInterestTypeId);
      this.vehicleInterestTypeName = vehicleInterestType.name;
      if (+this.review.reviewApplicant.vehicleInterestTypeId == VehicleInterestTypes.Other) {
        this.vehicleInterestTypeName = `${this.vehicleInterestTypeName}, ${this.review.reviewApplicant.otherInterestType}`;
      }
      this.requesterInformation.push(new SectionDetailsModel('Applicant First Name', this.applicantFirstName));
      this.requesterInformation.push(new SectionDetailsModel('Applicant Last Name', this.applicantLastName));
      this.requesterInformation.push(new SectionDetailsModel('Interest in Vehicle', this.vehicleInterestTypeName));
    }

    if (this.isRSGPortal && this.review.isSubmittedByRegistry) {
      let registryAgentInfo = this.review.registryAgentInformation[0];

      if (!this.isThirdPartySeizure) { // Applicant display for non-third party seizures
        this.registryAgentInformation.push(new SectionDetailsModel('Requester', registryAgentInfo.isPaidByRecipient ?
          "Recipient (Driver)" : "Someone else", true));
      }

      if (!registryAgentInfo.isPaidByRecipient) {
        this.registryAgentInformation.push(new SectionDetailsModel('First Name', registryAgentInfo.payerFirstName));
        this.registryAgentInformation.push(new SectionDetailsModel('Last Name', registryAgentInfo.payerLastName));
      }
    }

    if (this.review.representation) {
      // Representation Informaton
      const repTypeId = this.review.representation.representationTypeId;

      const repTypeName = this.localStorageService.getRepresentationTypes().find(x=>x.id == repTypeId)?.name;

      this.representationInformation.push(new SectionDetailsModel(`Representation Type`, repTypeName, true));
      this.representationInformation.push(new SectionDetailsModel(`Representative First Name`, this.review.representation.firstName));
      this.representationInformation.push(new SectionDetailsModel(`Representative Last Name`, this.review.representation.lastName));

      if (+repTypeId === RepresentationTypes.Agent || +repTypeId === RepresentationTypes.Lawyer){
        this.representationInformation.push(new SectionDetailsModel(`Practice Name`, this.review.representation.practiceName, true));
      }

      if (+repTypeId === RepresentationTypes.Other){
        this.representationInformation.push(new SectionDetailsModel('Other Representation Type', this.review.representation.otherRepresentationType, true));
      }
    }
    
      // Supporting Documents Section
      this.showDocumentMetadataActive = new Date(this.review.requestDate) >= new Date(+Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(0, 4), +Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(5, 7) - 1, +Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(8, 10));

      this.review.documents.forEach((document) => {
        var documentType: DocumentType = this.localStorageService
          .getDocumentTypes()
          .find((x) => x.id == document.documentTypeId);

        let documentModel = new DocumentModel(documentType?.name, document.documentName, document.documentExtension, document.contentGuid, !documentType?.isSubmittableAtLaterDate, document.uploadedDate);
        documentModel.documentSize = document.documentSize;

        if (+document.documentTypeId == DocumentTypes.ReviewDecisionLetter) {
          // Review decision letter
          this.decisionLetterDocument = documentModel;
        } else if (+document.documentTypeId == DocumentTypes.ReviewUploads) {
          if (document.isPublished) {
            this.reviewUploads.push(documentModel);
          }                    
        } else {
          // Show documents that are related to Driver
          if (Constants.Driver.SeizureReviewDocuments.includes(+document.documentTypeId)) {
            if (+document.documentTypeId == DocumentTypes.RecipientsConsentOfRepresentation) {
              this.consentToRepresentationDocuments.push(documentModel);
            } else {
              this.supportingDocuments.push(documentModel);
            }
          }
        }
      });

      if (this.review.reviewStatusTypeId == ReviewStatusTypes.Complete) {
        this.isDecisionAvailable = true;
        if (this.isThirdPartySeizure) {
          this.reviewDecision = this.review.reviewItems[0].decisionTypeId == DecisionTypes.Confirm ? 'Seizure Relief Denied' : 'Seizure Relief Granted';
        } else {
          this.reviewDecision = this.review.reviewItems[0].decisionTypeId == DecisionTypes.Confirm ? 'Seizure Confirmed'
            : this.review.reviewItems[0].decisionTypeId == DecisionTypes.VaryAndConfirm ? "Seizure Confirmed at Lower Occurrence Level" 
            : 'Seizure Cancelled';
        }
      } else if (this.review.reviewStatusTypeId == ReviewStatusTypes.Cancelled) {
        this.isDecisionAvailable = false;
      }

      if (this.review.reviewDate) {
        this.reviewDate = this.datePipe.transform(this.review.reviewDate, 'fullDate');
  
        this.reviewStartTime = this.review.reviewTime.substr(0, 5);
        let d = new Date('01 01 1980 ' + this.review.reviewTime);
        d.setMinutes(d.getMinutes() + 30);
        this.reviewEndTime = d.toTimeString().substr(0, 5);
  
        this.reviewTime = `${this.reviewStartTime} - ${this.reviewEndTime}`;
      }
  }

  DownloadDocument(document: DocumentModel) {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;
    this.documentService
      .downloadDocument(
        '',
        'Reviews',
        this.review.reviewNumber,
        storageFileName,
        document.documentName
      )
      .subscribe((result: any) => {
        if (result) {
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      });
  }

  onNewerReviewsclick() {
    if (this.reviewSequence + 1 <= this.maxReviewSequence) {
      this.router.navigate(['../../', this.reviewSequence + 1, 'details'], { relativeTo: this.activatedRoute });
    }
  }

  onPastReviewsclick() {
    if (this.reviewSequence - 1 > 0) {
      this.router.navigate(['../../', this.reviewSequence - 1, 'details'], { relativeTo: this.activatedRoute });
    }
  }

  onAddConsentFormClick() {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(UploadReviewConsentModalComponent);

    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.close.subscribe((result: Review) => {
      if (result != null) {
        this.review = result;

        this.populateData();
      }
                  
      componentRef.destroy();
    });    
  }

  onCancelReviewClick() {
    this.isConsentAccepted = false;
    this.isCancelFailed = false;
    $('#cancelReviewModal').modal();
  }

  onConfirmClick() {
    this.spinner.show();

    this.reviewService
      .cancelReview(this.review)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (result) => {
          $('#cancelReviewModal').modal('hide');
          this.router.navigate(['../../../'], {
            relativeTo: this.activatedRoute,
          });
        },
        (error) => {
          if (error.error && error.error.errorCode == "2004") {
            this.isCancelFailed = true;
          }

          window.scroll(0, 0);
        }
      );
  }

  isReviewAvailable(): boolean {
    if (this.review.reviewMethodTypeId == ReviewMethodTypes.Oral 
        && this.review.reviewStatusTypeId != ReviewStatusTypes.Cancelled
        && !this.isDecisionAvailable
        && this.review.reviewDateTimeUtc) {
      var reviewDateTimeUtc = new Date(this.review.reviewDateTimeUtc);
      var reviewEndDateTimeUtc = reviewDateTimeUtc.setMinutes(reviewDateTimeUtc.getMinutes() + 31);
      return +moment.utc() < +reviewEndDateTimeUtc;
    }

    return false;
  }

  joinMeeting() {
    window.open(this.review.videoConferenceUrl, '_blank');
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { APP_CONFIG } from '@apis/app-config';
import { PaymentRequest } from '@apis/shared/models/payment-request.model';

@Injectable()
export class PaymentService {
  private readonly apiUrl = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {}

  processPayment(paymentRequest: PaymentRequest): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/payment/submit`,
      paymentRequest,
      httpOptions
    );
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ReviewApplicant } from '@apis/shared/models/review-applicant.model';
import { VehicleInterestType } from '@apis/shared/models/types/vehicle-interest-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-applicant-information',
  templateUrl: './applicant-information.component.html',
  styleUrls: ['./applicant-information.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ApplicantInformationComponent implements OnInit {
  @Input() model: ReviewApplicant;
  @Input() isSubmitClicked: boolean;
  @Input() disabled: boolean;
  @Output() vehicleInterestTypeChange = new EventEmitter();

  vehicleInterestTypes: VehicleInterestType[];

  constructor(
    private localStorageService: LocalStorageService
  ) {    
   }

  ngOnInit(): void {
    this.vehicleInterestTypes = this.localStorageService.getVehicleInterestTypes();
    console.log(this.vehicleInterestTypes);
  }

  onVehicleInterestTypeChange() {
    this.model.otherInterestType = '';
    this.vehicleInterestTypeChange.emit();
  }
}

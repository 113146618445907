//import { ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountdownTimerComponent } from '@apis/shared/components/countdown-timer/countdown-timer.component';
import { ContactMethodTypes, ReviewMethodTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { ReviewItem } from '@apis/shared/models/review-item.model';
import { Representation } from '@apis/shared/models/representation.model';
import { Review } from '@apis/shared/models/review.model';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { ContactMethodType } from '@apis/shared/models/types/contact-method-type.model';
import { ContraventionDetailType } from '@apis/shared/models/types/contravention-detail-type.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { ReviewMethodType } from '@apis/shared/models/types/review-method-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { ReviewBaseComponent } from '../review-base.component';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { Constants } from '@apis/shared/helpers/constants';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';

declare var $: any

@Component({
  selector: 'app-review-details',
  templateUrl: './review-details.component.html',
  styleUrls: ['./review-details.component.scss']
})
export class ReviewDetailsComponent extends ReviewBaseComponent
  implements OnInit {

  @ViewChild('cd') private countdown: CountdownTimerComponent;

  genderTypes: GenderType[];
  contraventionTypes: ContraventionType[];
  contraventionDetailTypes: ContraventionDetailType[];
  contactMethodTypes: ContactMethodType[];
  reviewMethodTypes: ReviewMethodType[];

  rsgAgentProfile: RsgAgentProfile;
  registryAgentInformation: RegistryAgentInformation;

  originalReviewMethod: number;
  hasMultipleContraventions: boolean;
  errorMessage: string;
  originalComplexity: number;
  failedAttendanceDeclarationActive: boolean = false;

  ReviewTypes = ReviewTypes;
  ReviewMethodTypes = ReviewMethodTypes;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public schedulerService: SchedulerService,
    public contraventionService: ContraventionService
    ) { 
    super(router, activatedRoute, localStorageService, schedulerService);
  }

  ngOnInit(): void {
    if (this.isRSGPortal)
    {
      this.rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
      if (!this.rsgAgentProfile)
        this.router.navigate(['/unauthorized']);

      // Create a new RegistryAgentInformation object, or load the old one if it exists
      if (this.review.registryAgentInformation.length === 0) {
        this.registryAgentInformation = new RegistryAgentInformation();
        this.registryAgentInformation.userName = this.rsgAgentProfile.userName;
        this.registryAgentInformation.organizationCode = this.rsgAgentProfile.organization;
      }
      else {
        this.registryAgentInformation = this.review.registryAgentInformation[0];
      }

      this.review.contactMethodTypeId = ContactMethodTypes.Email;
    }

    this.genderTypes = this.localStorageService.getGenderTypes();
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    this.contraventionDetailTypes = this.localStorageService.getContraventionDetailTypes();
    this.contactMethodTypes = this.localStorageService.getContactMethodTypes();
    this.reviewMethodTypes = this.localStorageService.getReviewMethodTypes();

    this.originalReviewMethod = this.review.reviewMethodTypeId;
    this.originalComplexity = this.review.complexity;

    this.hasMultipleContraventions = this.review.reviewItems.length > 1;

    this.updateReviewFee();

    this.failedAttendanceDeclarationActive = new Date(this.review.requestDate) >= new Date(+Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(0, 4), +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(5, 7)-1, +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(8, 10));
  }

  onReviewTypeChange() {
      this.review.representation = this.review.isSelfSubmit ? null : new Representation({
        representationTypeId: null
      });
  }

  onRequesterTypeChange() {
    this.review.representation = this.registryAgentInformation.isPaidByRecipient ? null : new Representation({
      representationTypeId: null
    });
    this.review.isSelfSubmit = this.registryAgentInformation.isPaidByRecipient;
  }

  onReviewMethodChange() {
    this.review.reviewFeeAmount = null;
    if(this.review.reviewMethodTypeId) {
      this.review.reviewFeeAmount = this.reviewMethodTypes.find(rmt => rmt.id === this.review.reviewMethodTypeId).feeAmount;
    }
    this.review.failedAttendanceDeclarationTypeId = null;
  }

  updateReviewFee() {
    if (this.review.reviewTypeId == ReviewTypes.TrafficReview || this.review.reviewTypeId == ReviewTypes.AteReview) {
      let totalFineAmount = 0;
      this.review.reviewItems.filter(r => r.isSelected)
        .forEach(r => {
          var c = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == r.recordNumber);
          totalFineAmount += c.fineAmount;
        });  

      // Rules for review fee:
      //  Total (base) fine amount of all TSA contraventions selected for review totals $299 or less, review fee is $50
      //  Total (base) fine amount of all TSA contraventions selected for review totals $300 or more, review fee is $150 

      if (totalFineAmount <= 0) {
        this.review.reviewFeeAmount = 0;
      } else if (totalFineAmount < 299) {
        this.review.reviewFeeAmount = 50;
      } else if (totalFineAmount > 299) {
        this.review.reviewFeeAmount = 150;
      }
    }
  }

  continueRequest(isValid: boolean) {
    this.errorMessage = '';
    
    const atleastOneContraventionSelected = this.review.reviewItems.some(r => r.isSelected);
    if (!atleastOneContraventionSelected) {
      this.errorMessage = 'Please select at least one contravention to proceed with the review.';
      return;
    }

    if (this.failedAttendanceDeclarationActive && this.review.reviewMethodTypeId == ReviewMethodTypes.Oral && this.review.failedAttendanceDeclarationTypeId == null) {
      return;
    }

    if(isValid) {
      if (this.review.reviewTypeId == ReviewTypes.TrafficReview) {
        var contraventionNumbersInReview = this.review.reviewItems.map(i => i.recordNumber);
        this.contraventionService.getReviewComplexity(this.review.contraventionNumber, contraventionNumbersInReview)
          .subscribe(c => {
            this.review.complexity = c;
            this.proceedRequest();
          });
      } else {
        this.proceedRequest();
      }
    }
  }

  proceedRequest() {
    var hasAllContraventionsSelected = this.review.reviewItems.every(r => r.isSelected);

    if (hasAllContraventionsSelected){
      this.proceed();
    } else {
      $('#confirmReviewModal').modal();
    }
  }

  proceed() {
    this.updateModel();
    this.saveContext();

    if (this.isRSGPortal){
      this.router.navigate(['../tech-material-library'], { relativeTo: this.activatedRoute, });
    }else {
      this.router.navigate(['../schedule-review'], { relativeTo: this.activatedRoute, });
    }
  }

  updateModel() {
    var isReviewMethodChanged = this.originalReviewMethod != this.review.reviewMethodTypeId;

    var isComplexityChanged = this.originalComplexity != this.review.complexity;

    if ((isComplexityChanged || isReviewMethodChanged) && this.review.scheduledEventId) {      
      this.countdown.stopCountdown();

      this.releaseEvent().subscribe();
      this.resetReviewSchedule();
    }

    this.review.paymentRequest.paymentAmount = this.review.reviewFeeAmount;

    if (this.isRSGPortal) {
      // Update registryAgentInformation
      if (this.review.registryAgentInformation.length === 0) {
        this.review.registryAgentInformation.push(this.registryAgentInformation);
      }
      else {
        this.review.registryAgentInformation[0] = this.registryAgentInformation;
      }

      // Set applicant name
      if (this.review.isSelfSubmit) {
        this.review.reviewApplicant.firstName = this.contraventionOverview.recipientFirstName;
        this.review.reviewApplicant.lastName = this.contraventionOverview.recipientLastName;
        this.review.reviewApplicant.otherName = this.contraventionOverview.recipientMiddleName;
      }
      else {
        this.review.reviewApplicant.firstName = this.registryAgentInformation.payerFirstName;
        this.review.reviewApplicant.lastName = this.registryAgentInformation.payerLastName;
        this.review.reviewApplicant.otherName = '';
      }

      // Set representative name
      if (this.review.representation?.sameAsRequester) {
        this.review.representation.firstName = this.registryAgentInformation.payerFirstName;
        this.review.representation.lastName = this.registryAgentInformation.payerLastName;
      }
    }
    else {
      // Set applicant name
      if (this.review.isSelfSubmit) {
        this.review.reviewApplicant.firstName = this.contraventionOverview.recipientFirstName;
        this.review.reviewApplicant.lastName = this.contraventionOverview.recipientLastName;
        this.review.reviewApplicant.otherName = this.contraventionOverview.recipientMiddleName;
      }
      else {
        this.review.reviewApplicant.firstName = this.review.representation.firstName;
        this.review.reviewApplicant.lastName = this.review.representation.lastName;
        this.review.reviewApplicant.otherName = '';
      }
    }
  }

  getPrimaryCharge(contraventionNumber: string) {
    var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == contraventionNumber);

    return this.getChargeDescription(contravention.contraventionTypeId);
  }

  getSecondaryCharge(contraventionNumber: string) {
    var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == contraventionNumber);

    return this.getChargeDescription(contravention.secondaryContraventionTypeId);
  }

  getTertiaryCharge(contraventionNumber: string) {
    var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == contraventionNumber);

    return this.getChargeDescription(contravention.tertiaryContraventionTypeId);
  }

  getChargeDescription(contraventionTypeId: number) {
    if (contraventionTypeId) {
      return this.contraventionTypes.find(ct => ct.id == contraventionTypeId).formattedName;
    } else {
      return '';
    }
  }
}
<!-- Driver Portal View -->
<app-contravention-page [suppressConfirmation]="true" *ngIf="!isRSGPortal" #masterPage>
    <ng-container content>
        <div class="d-flex flex-column" *ngIf="review">
            <div class="mb-4 border-bottom d-flex justify-content-between">
                <h1 class="mb-3 mt-2">Review Details</h1>
                <div *ngIf="maxReviewSequence > 1">
                    <button class="goa-button mt-3" (click)="onNewerReviewsclick()"
                        [disabled]="reviewSequence == maxReviewSequence">
                        <i class="fa fa-chevron-left"></i> Newer Reviews
                    </button>
                    <button class="goa-button mt-3" (click)="onPastReviewsclick()"
                        [disabled]="reviewSequence == 1">
                        Past Reviews <i class="fa fa-chevron-right"></i>
                    </button>
                </div>
            </div>

            <div class="tabs">
                <div class="row">
                    <div class="col-6 tab-item" 
                        [ngClass]="{'tab-item-selected': +this.reviewTypeId == ReviewTypes.IrsReview}"
                        (click)="onTabItemClick(ReviewTypes.IrsReview)"
                        *ngIf="reviewTypeExistsInSequence(ReviewTypes.IrsReview)">
                        IRS Review
                    </div>
                    <div class="col-6 tab-item" 
                        [ngClass]="{'tab-item-selected': +this.reviewTypeId == ReviewTypes.TrafficReview}"
                        (click)="onTabItemClick(ReviewTypes.TrafficReview)"
                        *ngIf="reviewTypeExistsInSequence(ReviewTypes.TrafficReview)">
                        Traffic Review
                    </div>
                    <div class="col-6 tab-item"
                        [ngClass]="{'tab-item-selected': +this.reviewTypeId == ReviewTypes.AteReview}"
                        (click)="onTabItemClick(ReviewTypes.AteReview)"
                        *ngIf="reviewTypeExistsInSequence(ReviewTypes.AteReview)">
                        Traffic Review
                    </div>
                </div>
            </div>

            <div class="ml-2">
                <h3 class="mt-1 mb-1">No. {{review.reviewNumber}}</h3>

                <div *ngIf="!isDecisionAvailable">
                    <p class="mt-0 mb-3">
                        If you require assistance or encounter any issues, please contact
                        <a [routerLink]="['../../../../email-saferoads']">SafeRoads Alberta</a>.
                    </p>
                </div>
            </div>

            <div class="card card-full mt-2 mb-4" *ngIf="isDecisionAvailable">
                <div class="card-header">
                    {{ +this.reviewTypeId == ReviewTypes.IrsReview ? 'IRS' : 'Traffic' }} Review Decision
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-sm-3">
                            <div class="label">Review Decision</div>
                        </div>
                    </div>

                    <div class="desktop">
                        <div class="row mb-2">
                            <div class="col-2 label">NUMBER</div>
                            <div class="col-3 label">TSA SECTION</div>
                            <div class="col-5 label">DESCRIPTION</div>
                            <div class="col-2 label">DECISION</div>
                        </div>
                        <div class="row mb-2" *ngFor="let reviewItem of review.reviewItems">
                            <div class="col-2 review-item-value font-weight-bold">{{getContraventionNumber(reviewItem.contraventionId)}}</div>
                            <div class="col-3 review-item-value font-weight-bold">{{getChargeSection(reviewItem.contraventionId)}}</div>
                            <div class="col-5 review-item-value font-weight-bold">{{getChargeDescription(reviewItem.contraventionId)}}</div>
                            <div class="col-2 review-item-value">{{reviewDecision}}</div>
                        </div>  
                    </div>

                    <div class="mobile">
                        <div class="row mb-2" *ngFor="let reviewItem of review.reviewItems">
                            <div class="col-12 label">NUMBER</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getContraventionNumber(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">TSA SECTION</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getChargeSection(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">DESCRIPTION</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getChargeDescription(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">DECISION</div>
                            <div class="col-12 review-item-value">{{reviewItem.decisionTypeId == 1 ? 'Confirmed' : 'Cancelled'}}</div>
                        </div>  
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="label">Date Decision Issued</div>
                            <div class="value">{{ review.decisionDate | date:'fullDate' }}</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="label">Decision Letter</div>
                            <div class="value" *ngIf="decisionLetterDocument == null; else docLink">
                                -                                        
                            </div>

                            <ng-template #docLink>
                                <a class="d-block value" [routerLink]="[]" (click)="DownloadDocument(decisionLetterDocument)">
                                    {{decisionLetterDocument.documentName}}
                                </a>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card card-full mt-2 mb-4" *ngIf="!isDecisionAvailable">
                <div class="card-header">
                    {{ +this.reviewTypeId == ReviewTypes.IrsReview ? 'IRS' : 'Traffic' }} Review Details
                    <button class="float-right goa-button btn-block btn-small" 
                    *ngIf="review.isRescheduleAllowed" [routerLink]="['../reschedule']">                             
                        <i class="fa fa-edit"></i>
                        Edit / Reschedule
                    </button>
                </div>
                <div class="card-body">
                    <div *ngIf="!isReviewCancelled" class="desktop">
                        <div class="row">
                            <div class="col-3">
                                <div class="label">Review Type</div>
                                <div class="value">{{ reviewMethodName }} Review</div>
                            </div>
                            <div class="col-3">
                                <div class="label">Date</div>
                                <div class="value">{{ reviewDate }}</div>
                            </div>
                            <div class="col-6" *ngIf="review.videoConferenceUrl && isReviewAvailable()">
                                <button class="goa-button btn-block mt-0"
                                    (click)="joinMeeting()">
                                    Join the Meeting Room
                                </button>
                            </div>                        
                        </div>
                        <div class="row" *ngIf="review.reviewMethodTypeId==2">
                            <div class="col-3"></div>
                            <div class="col-3">
                                <div class="label">Time (MT)</div>
                                <div class="value">{{ reviewTime }}</div>
                            </div>
                            <div class="col-4" *ngIf="isReviewAvailable()">
                                <div class="label">Or join by phone:</div>
                                <div class="value mb-0">1-780-392-9152  (Local)</div>
                                <div class="value py-0">1-855-699-3239  (Toll free Canada/US)</div>
                            </div>
                            <div class="col-2" *ngIf="isReviewAvailable()">
                                <div class="label">Phone access code</div>
                                <div class="value">{{review.scheduledEvent.meetingKey}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isReviewCancelled" class="mobile">
                        <div class="row">
                            <div class="col-12">
                                <div class="label">Review Type</div>
                                <div class="value">{{ reviewMethodName }} Review</div>
                            </div>
                            <div class="col-12">
                                <div class="label">Date</div>
                                <div class="value">{{ reviewDate }}</div>
                            </div>
                            <div class="col-12">
                                <div class="label">Time (MT)</div>
                                <div class="value">{{ reviewTime }}</div>
                            </div>
                        </div>
                        <div class="row" *ngIf="review.reviewMethodTypeId==2">
                            <div class="col-12" *ngIf="review.videoConferenceUrl && isReviewAvailable()">
                                <button class="goa-button btn-block mt-0"
                                    (click)="joinMeeting()">
                                    Join the Meeting Room
                                </button>
                            </div>                        
                            <div class="col-12">
                                <div class="label">Or join by phone:</div>
                                <div class="value mb-0">1-780-392-9152  (Local)</div>
                                <div class="value py-0">1-855-699-3239  (Toll free Canada/US)</div>
                            </div>
                            <div class="col-12">
                                <div class="label">Phone access code</div>
                                <div class="value">{{review.scheduledEvent.meetingKey}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isReviewCancelled">
                        <div class="col-12">
                            <div class="label">Review Status</div>
                            <b>
                                <div class="value">
                                    Review Cancelled
                                    <span *ngIf="isReviewAbandoned"> - {{getReviewOutcome(review.outcome.reviewOutcomeTypeId)}}</span>
                                </div>
                            </b>
                        </div>
                    </div>

                    <hr>

                    <div class="desktop">
                        <div class="label">
                            Contraventions in this review:
                        </div>
                        <div class="row mb-2">
                            <div class="col-2 label">NUMBER</div>
                            <div class="col-3 label">TSA SECTION</div>
                            <div class="col label">DESCRIPTION</div>
                        </div>
                        <div class="row mb-2" *ngFor="let reviewItem of review.reviewItems">
                            <div class="col-2 review-item-value font-weight-bold">{{getContraventionNumber(reviewItem.contraventionId)}}</div>
                            <div class="col-3 review-item-value font-weight-bold">{{getChargeSection(reviewItem.contraventionId)}}</div>
                            <div class="col review-item-value font-weight-bold">{{getChargeDescription(reviewItem.contraventionId)}}</div>
                        </div>  
                    </div>

                    <div class="mobile">
                        <div class="label">
                            Contraventions in this review:
                        </div>
                        <div class="row mb-2" *ngFor="let reviewItem of review.reviewItems">
                            <div class="col-12 label">NUMBER</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getContraventionNumber(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">TSA SECTION</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getChargeSection(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">DESCRIPTION</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getChargeDescription(reviewItem.contraventionId)}}</div>
                        </div>  
                    </div>

                    <div class="d-flex mt-4" *ngIf="this.review.termsConditionsVersion">
                        <div>
                            <a class="d-block mb-2" 
                            href="assets/{{termCondition.documentName}}" target="_blank">Terms & Conditions ({{termCondition.documentDate | date:'mediumDate'}})</a>
                            <div *ngIf="review.isCancelAllowed">
                                <a class="cancel-link" (click)="onCancelReviewClick()">
                                    Abandon (Cancel) Review
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="heading-alert mb-4">
                <div class="goa-callout goa--information mt-1">
                    <p class="mt-0">
                        <b>Additional documents may be uploaded by law enforcement up to four (4) days prior to your scheduled review.</b> 
                        It is your responsibility to check back to ensure you have all of the information and update your grounds or arguments for the review accordingly.
                    </p>
                </div>
            </div>

            <div class="content">
                <div class="review-section">
                    <div class="card mb-4" *ngIf="isDecisionAvailable">
                        <div class="card-header">
                            {{ +this.reviewTypeId == ReviewTypes.IrsReview ? 'IRS' : 'Traffic' }} Review Details
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <div class="label">Review Type</div>
                                    <div class="value">{{ reviewMethodName }} Review</div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div class="label">Date</div>
                                    <div class="value">{{ reviewDate }}</div>
                                </div>
                                <div class="col-12 col-sm-4" *ngIf="review.reviewMethodTypeId==2">
                                    <div class="label">Time (MT)</div>
                                    <div class="value">{{ reviewTime }}</div>
                                </div>                        
                            </div>
                            <div class="row" *ngIf="this.review.termsConditionsVersion">
                                <div class="col-12">
                                    <a class="d-block mb-2" 
                                    href="assets/{{termCondition.documentName}}" target="_blank">Terms & Conditions ({{termCondition.documentDate | date:'mediumDate'}})</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            Your Request Details
                            <button class="float-right goa-button btn-block btn-small" 
                            *ngIf="review.isEditAllowed"
                            [routerLink]="['../edit']">
                                <i class="fa fa-edit"></i>
                                Edit Details
                            </button>
                        </div>
                        <div class="card-body">

                            <app-section-details 
                            sectionTitle="" [sectionDetails]="representationInformation"></app-section-details>

                            <app-section-details
                            sectionTitle="" [sectionDetails]="failedAttendanceDeclarationInformation"></app-section-details>

                            <div class="row" *ngFor="let reviewItem of review.reviewItems">
                                <div class="col-12">
                                    <div class="label">
                                        Grounds for Review
                                    </div>
                                    <div class="value pb-0">
                                        Contravention Number: {{getContraventionNumber(reviewItem.contraventionId)}}
                                    </div>
                                    <div class="value pt-0">
                                        {{ reviewItem.reviewGrounds }}
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="review.additionalRequestNotes">
                                <div class="col-12">
                                    <div class="label">
                                        Additional notes
                                    </div>
                                    <div class="value">
                                        {{ review.additionalRequestNotes }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div>
                <div class="">
                    <div class="card card-action">
                        <div class="card-header">
                            Supporting Documents
                        </div>
                        <div class="card-body">
                            <div class="row mt-2">
                                <div class="col-12">
                                    <a class="d-block extern" href="technical-material" target="_blank">Technical Materials Library</a>
                                </div>
                            </div>

                            <div class="row" *ngFor="let document of supportingDocuments">
                                <div class="col-12">
                                    <p>{{document.documentType}}</p>
                                    <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                        <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                        ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                    </div>
                                    <ng-template #doNotShowMetadata>
                                        <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row" *ngIf="reviewUploads?.length > 0">
                                <div class="col-12">
                                    <p>Review Uploads</p>
                                    <div class="row" *ngFor="let document of reviewUploads">
                                        <div class="col-12 mb-2">
                                            <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                                <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                                ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                            </div>
                                            <ng-template #doNotShowMetadata>
                                                <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                            </ng-template>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-12">
                                    <button class="btn-block goa-button" [disabled]="!review.isEditSupportingDocumentsAllowed"
                                        [routerLink]="['../supporting-documents']">Add or Modify Documents</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="card card-action">
                        <div class="card-header">
                            Consent to Representation
                        </div>
                        <div class="card-body">
                            <div class="row mt-2">
                                <div class="col-12">
                                    <a class="d-block" download="Consent to Representation"
                                     href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" target="_blank">Consent to Representation</a>
                                </div>
                            </div>

                            <div class="row my-2" *ngFor="let document of consentToRepresentationDocuments">
                                <div class="col-12">
                                    <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                        <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                        ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                    </div>
                                    <ng-template #doNotShowMetadata>
                                        <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                    </ng-template>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-12">
                                    <button class="btn-block goa-button" [disabled]="!review.isEditAllowed"
                                        (click)="onAddConsentFormClick()">Add Consent Form</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="cancelReviewModal" tabindex="-1" role="dialog" aria-labelledby="cancelReviewModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content p-4">
                        <div class="modal-header border-0 pt-2">
                            <span class="modal-title h4" id="cancelReviewModalLabel">
                                Are you sure you wish to abandon (cancel) your review?
                            </span>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="far fa-times-circle fa-lg"></i>
                            </button>
                        </div>
                        <div class="modal-body py-0">
                            <p class="pb-3">
                                Abandoning (cancelling) your review will confirm your Notice of Administrative Penalty. 
                                The fee to apply for a review is non-refundable.
                            </p>
                            
                            <div class="goa-form p-0">
                                <div class="goa-field">
                                    <div class="goa-option mt-0 mb-4">
                                        <input type="checkbox" id="consent" name="consent" [(ngModel)]="isConsentAccepted">
                                        <label class="consent-label" for="consent">
                                            I understand that by abandoning (cancelling) my review, I am confirming my Notice of Administrative Penalty.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer border-0 d-flex justify-content-between mt-4">
                            <button type="button" class="goa-button goa--secondary mt-2" 
                                data-dismiss="modal">Cancel</button>
                            <button type="button" class="goa-button mt-2" [disabled]="isConsentAccepted == false" 
                                (click)="onConfirmClick()">Confirm Cancellation</button>
                        </div>

                        <div class="text-danger" *ngIf="isCancelFailed">
                            Review cannot be cancelled after commencement (or) decision was rendered.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page [suppressConfirmation]="true" *ngIf="isRSGPortal" #masterPage>
    <ng-container content>
        <div class="d-flex flex-column" *ngIf="review">
            <div class="mb-4 border-bottom d-flex justify-content-between">
                <h1 class="mb-3 mt-2">Review Details</h1>
                <div>
                    <div *ngIf="maxReviewSequence > 1">
                        <button class="goa-button mt-3" (click)="onNewerReviewsclick()"
                            [disabled]="reviewSequence == maxReviewSequence">
                            <i class="fa fa-chevron-left"></i> Newer Reviews
                        </button>
                        <button class="goa-button mt-3" (click)="onPastReviewsclick()"
                            [disabled]="reviewSequence == 1">
                            Past Reviews <i class="fa fa-chevron-right"></i>
                        </button>
                    </div>
                    <div class="print-icon not-for-print" (click)="printSummary()"></div>
                </div>
            </div>

            <div class="tabs">
                <div class="row">
                    <div class="col-6 tab-item" 
                        [ngClass]="{'tab-item-selected': +this.reviewTypeId == ReviewTypes.IrsReview}"
                        (click)="onTabItemClick(ReviewTypes.IrsReview)"
                        *ngIf="reviewTypeExistsInSequence(ReviewTypes.IrsReview)">
                        IRS Review
                    </div>
                    <div class="col-6 tab-item" 
                        [ngClass]="{'tab-item-selected': +this.reviewTypeId == ReviewTypes.TrafficReview}"
                        (click)="onTabItemClick(ReviewTypes.TrafficReview)"
                        *ngIf="reviewTypeExistsInSequence(ReviewTypes.TrafficReview)">
                        Traffic Review
                    </div>
                    <div class="col-6 tab-item"
                        [ngClass]="{'tab-item-selected': +this.reviewTypeId == ReviewTypes.AteReview}"
                        (click)="onTabItemClick(ReviewTypes.AteReview)"
                        *ngIf="reviewTypeExistsInSequence(ReviewTypes.AteReview)">
                        Traffic Review
                    </div>
                </div>
            </div>

            <div class="ml-2">
                <h3 class="mt-1 mb-1">No. {{review.reviewNumber}}</h3>

                <div class="card-full">
                    <p>
                        <b>Please inform the client that additional documents may be uploaded by law enforcement up to four (4) days prior to the scheduled review.</b>
                        It is the driver's responsibility to check back to ensure they have all of the information and update their grounds or arguments for the review accordingly.
                    </p>
                </div>
            </div>

            <div class="card card-full mt-2 mb-4" *ngIf="isDecisionAvailable">
                <div class="card-header">
                    {{ +this.reviewTypeId == ReviewTypes.IrsReview ? 'IRS' : 'Traffic' }} Review Decision
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-sm-3">
                            <div class="label">Review Decision</div>
                        </div>
                    </div>

                    <div class="desktop">
                        <div class="row mb-2">
                            <div class="col-2 label">NUMBER</div>
                            <div class="col-3 label">TSA SECTION</div>
                            <div class="col-5 label">DESCRIPTION</div>
                            <div class="col-2 label">DECISION</div>
                        </div>
                        <div class="row mb-2" *ngFor="let reviewItem of review.reviewItems">
                            <div class="col-2 review-item-value font-weight-bold">{{getContraventionNumber(reviewItem.contraventionId)}}</div>
                            <div class="col-3 review-item-value font-weight-bold">{{getChargeSection(reviewItem.contraventionId)}}</div>
                            <div class="col-5 review-item-value font-weight-bold">{{getChargeDescription(reviewItem.contraventionId)}}</div>
                            <div class="col-2 review-item-value">{{reviewItem.decisionTypeId == 1 ? 'Confirmed' : 'Cancelled'}}</div>
                        </div>  
                    </div>

                    <div class="mobile">
                        <div class="row mb-2" *ngFor="let reviewItem of review.reviewItems">
                            <div class="col-12 label">NUMBER</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getContraventionNumber(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">TSA SECTION</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getChargeSection(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">DESCRIPTION</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getChargeDescription(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">DECISION</div>
                            <div class="col-12 review-item-value">{{reviewItem.decisionTypeId == 1 ? 'Confirmed' : 'Cancelled'}}</div>
                        </div>  
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="label">Date Decision Issued</div>
                            <div class="value">{{ review.decisionDate | date:'fullDate' }}</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="label">Decision Letter</div>
                            <div class="value" *ngIf="decisionLetterDocument == null; else docLink">
                                -                                        
                            </div>

                            <ng-template #docLink>
                                <a class="d-block value" [routerLink]="[]" (click)="DownloadDocument(decisionLetterDocument)">
                                    {{decisionLetterDocument.documentName}}
                                </a>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card card-full mt-2 mb-4" *ngIf="!isDecisionAvailable">
                <div class="card-header">
                    {{ +this.reviewTypeId == ReviewTypes.IrsReview ? 'IRS' : 'Traffic' }} Review Details
                    <button class="float-right goa-button btn-block btn-small not-for-print" 
                    *ngIf="review.isRescheduleAllowed" [routerLink]="['../reschedule']">                             
                        <i class="fa fa-edit"></i>
                        Edit / Reschedule
                    </button>
                </div>
                <div class="card-body">
                    <div *ngIf="!isReviewCancelled" class="desktop">
                        <div class="row">
                            <div class="col-3">
                                <div class="label">Review Type</div>
                                <div class="value">{{ reviewMethodName }} Review</div>
                            </div>
                            <div class="col-3">
                                <div class="label">Date</div>
                                <div class="value">{{ reviewDate }}</div>
                            </div>
                            <div class="col-6" *ngIf="review.videoConferenceUrl && isReviewAvailable()">
                                <button class="goa-button btn-block mt-0"
                                    (click)="joinMeeting()">
                                    Join the Meeting Room
                                </button>
                            </div>                        
                        </div>
                        <div class="row" *ngIf="review.reviewMethodTypeId==2">
                            <div class="col-3"></div>
                            <div class="col-3">
                                <div class="label">Time (MT)</div>
                                <div class="value">{{ reviewTime }}</div>
                            </div>
                            <div class="col-4" *ngIf="isReviewAvailable()">
                                <div class="label">Or join by phone:</div>
                                <div class="value mb-0">1-780-392-9152  (Local)</div>
                                <div class="value py-0">1-855-699-3239  (Toll free Canada/US)</div>
                            </div>
                            <div class="col-2" *ngIf="isReviewAvailable()">
                                <div class="label">Phone access code</div>
                                <div class="value">{{review.scheduledEvent.meetingKey}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isReviewCancelled" class="mobile">
                        <div class="row">
                            <div class="col-12">
                                <div class="label">Review Type</div>
                                <div class="value">{{ reviewMethodName }} Review</div>
                            </div>
                            <div class="col-12">
                                <div class="label">Date</div>
                                <div class="value">{{ reviewDate }}</div>
                            </div>
                            <div class="col-12">
                                <div class="label">Time (MT)</div>
                                <div class="value">{{ reviewTime }}</div>
                            </div>
                        </div>
                        <div class="row" *ngIf="review.reviewMethodTypeId==2">
                            <div class="col-12" *ngIf="review.videoConferenceUrl && isReviewAvailable()">
                                <button class="goa-button btn-block mt-0"
                                    (click)="joinMeeting()">
                                    Join the Meeting Room
                                </button>
                            </div>                        
                            <div class="col-12">
                                <div class="label">Or join by phone:</div>
                                <div class="value mb-0">1-780-392-9152  (Local)</div>
                                <div class="value py-0">1-855-699-3239  (Toll free Canada/US)</div>
                            </div>
                            <div class="col-12">
                                <div class="label">Phone access code</div>
                                <div class="value">{{review.scheduledEvent.meetingKey}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isReviewCancelled">
                        <div class="col-12 col-sm-3">
                            <div class="label">Review Status</div>
                            <b><div class="value">Review Cancelled</div></b>
                        </div>
                    </div>

                    <hr>

                    <div class="desktop">
                        <div class="label">
                            Contraventions in this review:
                        </div>
                        <div class="row mb-2">
                            <div class="col-2 label">NUMBER</div>
                            <div class="col-3 label">TSA SECTION</div>
                            <div class="col label">DESCRIPTION</div>
                        </div>
                        <div class="row mb-2" *ngFor="let reviewItem of review.reviewItems">
                            <div class="col-2 review-item-value font-weight-bold">{{getContraventionNumber(reviewItem.contraventionId)}}</div>
                            <div class="col-3 review-item-value font-weight-bold">{{getChargeSection(reviewItem.contraventionId)}}</div>
                            <div class="col review-item-value font-weight-bold">{{getChargeDescription(reviewItem.contraventionId)}}</div>
                        </div>  
                    </div>

                    <div class="mobile">
                        <div class="label">
                            Contraventions in this review:
                        </div>
                        <div class="row mb-2" *ngFor="let reviewItem of review.reviewItems">
                            <div class="col-12 label">NUMBER</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getContraventionNumber(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">TSA SECTION</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getChargeSection(reviewItem.contraventionId)}}</div>
                            <div class="col-12 label">DESCRIPTION</div>
                            <div class="col-12 review-item-value font-weight-bold">{{getChargeDescription(reviewItem.contraventionId)}}</div>
                        </div>  
                    </div>

                    <div class="d-flex mt-4">
                        <div>
                            <a class="d-block mb-2" 
                            href="assets/{{termCondition.documentName}}" target="_blank">Terms & Conditions ({{termCondition.documentDate | date:'mediumDate'}})</a>
                            <div *ngIf="review.isCancelAllowed">
                                <a class="cancel-link" (click)="onCancelReviewClick()">
                                    Abandon (Cancel) Review
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="review-section">
                    <div class="card mb-4" *ngIf="isDecisionAvailable">
                        <div class="card-header">
                            {{ +this.reviewTypeId == ReviewTypes.IrsReview ? 'IRS' : 'Traffic' }} Review Details
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <div class="label">Review Type</div>
                                    <div class="value">{{ reviewMethodName }} Review</div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div class="label">Date</div>
                                    <div class="value">{{ reviewDate }}</div>
                                </div>
                                <div class="col-12 col-sm-4" *ngIf="review.reviewMethodTypeId==2">
                                    <div class="label">Time (MT)</div>
                                    <div class="value">{{ reviewTime }}</div>
                                </div>                        
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <a class="d-block mb-2" 
                                    href="assets/{{termCondition.documentName}}" target="_blank">Terms & Conditions ({{termCondition.documentDate | date:'mediumDate'}})</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            Your Request Details
                            <button class="float-right goa-button btn-block btn-small not-for-print" 
                            *ngIf="review.isEditAllowed"
                            [routerLink]="['../edit']">
                                <i class="fa fa-edit"></i>
                                Edit Details
                            </button>
                        </div>
                        <div class="card-body">

                            <app-section-details
                            sectionTitle="" [sectionDetails]="registryAgentInformation"></app-section-details>

                            <app-section-details 
                            sectionTitle="" [sectionDetails]="representationInformation"></app-section-details>

                            <app-section-details
                            sectionTitle="" [sectionDetails]="failedAttendanceDeclarationInformation"></app-section-details>

                            <div class="row" *ngFor="let reviewItem of review.reviewItems">
                                <div class="col-12">
                                    <div class="label">
                                        Grounds for Review
                                    </div>
                                    <div class="value pb-0">
                                        Contravention Number: {{getContraventionNumber(reviewItem.contraventionId)}}
                                    </div>
                                    <div class="value pt-0">
                                        {{ reviewItem.reviewGrounds }}
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="review.additionalRequestNotes">
                                <div class="col-12">
                                    <div class="label">
                                        Additional notes
                                    </div>
                                    <div class="value">
                                        {{ review.additionalRequestNotes }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div>
                <div class="">
                    <div class="card card-action">
                        <div class="card-header">
                            Supporting Documents
                        </div>
                        <div class="card-body">
                            <div class="row mt-2">
                                <div class="col-12">
                                    <a class="d-block extern" href="technical-material" target="_blank">Technical Materials Library</a>
                                </div>
                            </div>

                            <div class="row" *ngFor="let document of supportingDocuments">
                                <div class="col-12">
                                    <p>{{document.documentType}}</p>                                    
                                    <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                        <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                        ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                    </div>
                                    <ng-template #doNotShowMetadata>
                                        <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row" *ngIf="reviewUploads?.length > 0">
                                <div class="col-12">
                                    <p>Review Uploads</p>
                                    <div class="row" *ngFor="let document of reviewUploads">
                                        <div class="col-12 mb-2">
                                            <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                                <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                                ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                            </div>
                                            <ng-template #doNotShowMetadata>
                                                <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                            </ng-template>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            
                            <div class="row not-for-print">
                                <div class="col-12">
                                    <button class="btn-block goa-button" [disabled]="!review.isEditSupportingDocumentsAllowed"
                                        [routerLink]="['../supporting-documents']">Add or Modify Documents</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="card card-action">
                        <div class="card-header">
                            Consent to Representation
                        </div>
                        <div class="card-body">
                            <div class="row mt-2">
                                <div class="col-12">
                                    <a class="d-block" download="Consent to Representation"
                                     href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" target="_blank">Consent to Representation</a>
                                </div>
                            </div>

                            <div class="row my-2" *ngFor="let document of consentToRepresentationDocuments">
                                <div class="col-12">
                                    <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                        <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                        ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                    </div>
                                    <ng-template #doNotShowMetadata>
                                        <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                    </ng-template>
                                </div>
                            </div>
                            
                            <div class="row not-for-print">
                                <div class="col-12">
                                    <button class="btn-block goa-button" [disabled]="!review.isEditAllowed"
                                        (click)="onAddConsentFormClick()">Add Consent Form</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="cancelReviewModal" tabindex="-1" role="dialog" aria-labelledby="cancelReviewModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content p-4">
                        <div class="modal-header border-0 pt-2">
                            <span class="modal-title h4" id="cancelReviewModalLabel">
                                Are you sure you wish to abandon (cancel) your review?
                            </span>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="far fa-times-circle fa-lg"></i>
                            </button>
                        </div>
                        <div class="modal-body py-0">
                            <p class="pb-3">
                                Please advise the client that abandoning (cancelling) the review will confirm the Notice of Administrative Penalty. 
                                The fee to apply for a review is non-refundable.
                            </p>
                            
                            <div class="goa-form p-0">
                                <div class="goa-field">
                                    <div class="goa-option mt-0 mb-4">
                                        <input type="checkbox" id="consent" name="consent" [(ngModel)]="isConsentAccepted">
                                        <label class="consent-label" for="consent">
                                            <b>The client confirms that they wish to abandon (cancel) the review.</b> The client understands the Notice of Administrative Penalty will be confirmed.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer border-0 d-flex justify-content-between mt-4">
                            <button type="button" class="goa-button goa--secondary mt-2" 
                                data-dismiss="modal">Cancel</button>
                            <button type="button" class="goa-button mt-2" [disabled]="isConsentAccepted == false" 
                                (click)="onConfirmClick()">Confirm Cancellation</button>
                        </div>

                        <div class="text-danger" *ngIf="isCancelFailed">
                            Review cannot be cancelled after commencement (or) decision was rendered.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-contravention-page>



import { RegistryAgentInformation } from './registry-agent-information.model';
import { Representation } from './representation.model';

export class PaymentAdditionalTimeRequest2 {
    public contraventionNumber: string;
    public contraventionList: string[] = [];
    public driverLastName: string;
    public driverFirstName: string;
    public paymentAdditionalTimeRequest2Id: number = 0;
    public contraventionId: number = 0;
    public isSelfSubmit: boolean = false;
    public representationId: number;
    public requestDate: Date = new Date();
    public representation: Representation;
    public termsConditionsVersion: string;
    public registryAgentInformation: RegistryAgentInformation[] = [];
    public isSubmittedByRegistry: boolean;

    constructor(init?: Partial<PaymentAdditionalTimeRequest2>) {
        
        Object.assign(this, init);
    }
}

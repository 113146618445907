<!-- Driver Portal View -->
<app-vehicle-seizure-page [suppressConfirmation]="true" *ngIf="lateReviewRequest && !isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column">
            <h1>Late Review Request Decision</h1>    
            <h3 class="border-bottom pb-4 mb-2">No. {{ lateReviewRequest.lateReviewRequestNumber }}</h3>
        </div>

        <div class="card border-0 mt-2">
            <div class="goa-callout goa--information mt-1">
                <h6 class="mt-0">The decision of an adjudicator is final.</h6>
                <p>
                    If you are not satisfied with the decision of the adjudicator, 
                    you may seek judicial review of decisions rendered by SafeRoads Alberta 
                    by filing an application with the <a href="https://albertacourts.ca/qb/home" target="_blank">Court of King’s Bench of Alberta</a>, 
                    in accordance with section 24(2) of the <i>Provincial Administrative Penalties Act</i>. 
                    An application for judicial review must be made within 30 days of receipt of the 
                    adjudicator’s decision, and you must serve notice of your application to SafeRoads Alberta.
                </p>
            </div>
        </div>
        
        <div class="row mt-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        Late Review Request Decision
                    </div>
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-12">
                                <label>Reason for Request</label>
                                <div class="value">
                                    {{ lateReviewRequest.requestReason }}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-4">
                                <label>Review Decision</label>
                                <div class="value">{{ localStorageService.getTypeItemDescriptionById(
                                                        lateReviewRequest.requestDecisionTypeId, 
                                                        TypeTable.RequestDecisionType) }}
                                </div>
                            </div>
                            <div class="col-4">
                                <label>Date Decision Issued</label>
                                <div class="value">{{ lateReviewRequest.decisionDate | date:'fullDate' }}</div>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col">
                                <label>Decision Letter</label>
                                <div *ngIf="decisionLetterDocument == null; else docLink">
                                    -                                        
                                </div>

                                <ng-template #docLink>
                                    <a [routerLink]="[]" class="d-block" (click)="DownloadDocument(decisionLetterDocument)">
                                        {{decisionLetterDocument.documentName}}
                                    </a>
                                </ng-template>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page [suppressConfirmation]="true" *ngIf="lateReviewRequest && isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column">
            <h1>Late Review Request Decision</h1>    
            <h3 class="border-bottom pb-4 mb-2">No. {{ lateReviewRequest.lateReviewRequestNumber }}</h3>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        Late Review Request Decision
                    </div>
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-4">
                                <label>Review Decision</label>
                                <div class="value">{{ localStorageService.getTypeItemDescriptionById(
                                                        lateReviewRequest.requestDecisionTypeId, 
                                                        TypeTable.RequestDecisionType) }}
                                </div>
                            </div>
                            <div class="col-4">
                                <label>Date Decision Issued</label>
                                <div class="value">{{ lateReviewRequest.decisionDate | date:'fullDate' }}</div>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col">
                                <label>Decision Letter</label>
                                <div *ngIf="decisionLetterDocument == null; else docLink">
                                    -                                        
                                </div>

                                <ng-template #docLink>
                                    <a [routerLink]="[]" class="d-block" (click)="DownloadDocument(decisionLetterDocument)">
                                        {{decisionLetterDocument.documentName}}
                                    </a>
                                </ng-template>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>
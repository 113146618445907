import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Representation } from '@apis/shared/models/representation.model';
import { RepresentationType } from '@apis/shared/models/types/representation-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { RepresentationTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';

@Component({
  selector: 'app-representation',
  templateUrl: './representation.component.html',
  styleUrls: ['./representation.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class RepresentationComponent implements OnInit {
  @Input() model: Representation;
  @Input() isSubmitClicked: boolean;
  @Input() consentRequired: boolean;
  @Input() hideRepresenationMessage: boolean;
  @Input() isYouth: boolean;
  @Input() isThirdParty: boolean;

  // RSG Portal inputs - not used by driver portal
  @Input() isRSGPortal: boolean;
  @Input() isLateReviewRequest: boolean;
  @Input() reviewTypeId: number;
  @Input() registryAgentInformation: RegistryAgentInformation;
  @Input() showSameAsRequester: boolean;

  representationTypesList: RepresentationType[];
  representationTypes = RepresentationTypes;
  reviewTypes = ReviewTypes;
  repTitle: string;

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.representationTypesList = this.localStorageService
                                    .getRepresentationTypes()
                                    .filter(r => this.filterRepresentationType(r));
    this.repTitle = CommonUtil.getRepresentationTitle(this.model.representationTypeId);
  }

  filterRepresentationType(r) {
    if (r.id == RepresentationTypes.ParentGuardian) { // JTI-1816 Parent/Guardian
      if (this.isThirdParty || !this.isYouth) { // Remove if this is a third party seizure review, or if the recipient is not youth
        return false;
      }
    }
    return true;
  }

  onSameAsRequesterChange() {
    this.model.firstName = '';
    this.model.lastName = '';
    this.model.practiceName = '';
    this.model.otherRepresentationType = '';

    this.model.representationTypeId = RepresentationTypes.Other;
    this.repTitle = CommonUtil.getRepresentationTitle(this.model.representationTypeId);
  }

  onRepresentationTypeChange() {
    this.model.firstName = '';
    this.model.lastName = '';
    this.model.practiceName = '';
    this.model.otherRepresentationType = '';

    this.repTitle = CommonUtil.getRepresentationTitle(this.model.representationTypeId);
  }
}

<div class="d-flex flex-column h-100">
    <app-driver-header></app-driver-header>
    <div class="flex-fill">       
        <div class="d-flex flex-column w-100">
            <div class="not-for-print">
                <div class="goa-band-header">
                    <h1>SafeRoads</h1>
                </div>
                <ng-container *ngIf="showStepper">
                    <div class="driver-sub-header">
                        <stepper2 [activatedRouteSnapshot]="routeSnapshot"></stepper2>
                    </div>
                </ng-container>
            </div>
            <div class="d-flex justify-content-center p-4">
                <router-outlet></router-outlet>
            </div>    
        </div>
    </div>
    <app-driver-footer></app-driver-footer>
    <ngx-spinner name="downloadDocumentSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white"> Loading... </p></ngx-spinner>
</div>
  
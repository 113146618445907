<div class="d-flex flex-column">
    <h1 class="border-bottom md-0">Email Sent</h1>    
    
    <div class="card border-0 mt-4">
        <p>
            Your email has been sent.
        </p>

        <p>
            SafeRoads will respond to your inquiry as soon as possible.
        </p>

        <div class="form-group mt-4">
            <div class="row pt-3">
                <div class="col-12 col-sm-12">                
                    <a class="btn button-primary float-right" [routerLink]="['/driver/contravention-details']">Return to Contravention Details</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-column" *ngIf="paymentTransaction">
    <div class="card goa-callout goa--emergency mb-4 mt-0">
        <h2 class="my-0">Close this SafeRoads (APIS) browser tab to return to MOVES</h2>
    </div>

    <h1>Payment Successful</h1>
    <h2 class="mb-0">Transaction ID {{ paymentTransaction.financialTransaction.transactionId }}</h2>
    <div class="pb-2 border-bottom"></div>

    <div class="card mt-4">
        <div class="card-header">
            Payment Details
        </div>
        <div>  
            <ng-template ngFor let-li [ngForOf]="paymentTransaction.financialTransaction.transactionLineItems">
                <div class="d-flex justify-content-between align-items-center border-bottom card-row line-item-row">
                    <div>Contravention {{li.recordNumber}}</div>
                    <div><h5>{{li.amount | currency}}</h5></div>
                </div>
            </ng-template>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between align-items-center">
                <div><h4>Amount Total</h4></div>
                <div><h4>{{ paymentTransaction.financialTransaction.amount | currency }}</h4></div>
            </div>
        </div>
    </div>

    <div *ngIf="registryAgentInformation.isPaidByRecipient==false" class="card mt-4">
        <div class="card-header">
            Paid By
        </div>
        <div class="card-body py-0"> 
            <app-section-details sectionTitle="" [sectionDetails]="payerInformation"></app-section-details> 
        </div>        
    </div>
</div>


import { ActivatedRoute, Router } from '@angular/router';
import { ContraventionInformation } from '@apis/shared/models/contravention-information.model';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { TransactionLineItem } from '@apis/shared/models/transaction-line-item.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContraventionPaymentRequest } from '../../../shared/models/contravention-payment-request.model';

export abstract class PaymentBaseComponent {
  paymentRequest: ContraventionPaymentRequest;
  contraventionOverview: ContraventionOverview;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public localStorage: LocalStorageService
  ) {
    if (!this.paymentRequest) {
      this.paymentRequest = this.localStorage.getContraventionPaymentRequest();

      this.contraventionOverview = this.localStorage.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      // Navigate to contravention details page if Payment request is null
      if (this.paymentRequest == null || this.contraventionOverview == null) {
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      }

      this.initializePaymentRequest();
    }
  }

  private initializePaymentRequest(){
    if (this.paymentRequest.lineItems == null || this.paymentRequest.lineItems.length == 0) {
      this.paymentRequest.lineItems = [];

      // Create line items for each contravention
      this.contraventionOverview.contraventions.forEach(c => {
        if (+c.dueAmount > 0) {
          this.paymentRequest.lineItems.push(new TransactionLineItem({
            isChecked: true,
            recordNumber: c.contraventionNumber,
          }));
        }
      });
    }
  }

  public SaveState() {
    this.localStorage.setContraventionPaymentRequest(this.paymentRequest);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ContraventionStatusTypes, ContraventionTypes } from '@apis/shared/enums/app.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-contravention-detail-information',
  templateUrl: './contravention-detail-information.component.html',
  styleUrls: ['./contravention-detail-information.component.scss']
})
export class ContraventionDetailInformationComponent implements OnInit {
  @Input() contravention: Contravention;
  @Input() additionalFieldsActive: boolean = false;

  contraventionTypeName: string;
  contraventionStatusTypeName: string;
  isIrs: boolean = false;
  showContraventionDetails: boolean = false;

  ContraventionTypes = ContraventionTypes;
  ContraventionStatusTypes = ContraventionStatusTypes;

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    // Get contravention status type name to show it in the badge
    this.contraventionStatusTypeName = this.localStorageService.getContraventionStatusTypes().find(x => x.id == this.contravention.contraventionStatusTypeId)?.name;
  
    var contraventionType = this.localStorageService.getContraventionTypes().find( x => x.id == this.contravention.contraventionTypeId);
    this.isIrs = contraventionType.isIRS;
    this.showContraventionDetails = this.contravention.contraventionTypeId == ContraventionTypes.IRS24
                                    || this.contravention.contraventionTypeId == ContraventionTypes.IRSFail1st
                                    || this.contravention.contraventionTypeId == ContraventionTypes.IRSFail2nd
                                    || this.contravention.contraventionTypeId == ContraventionTypes.IRSFail3rd;
  }

  getContraventionDetails(contraventionDetails: string) {
    let contraventionDetailTypes = this.localStorageService.getContraventionDetailTypes();
    let filteredDetails = contraventionDetailTypes.filter(detailType => contraventionDetails?.split(',').includes(detailType.id.toString()));
    let detailNames = Array.prototype.map.call(filteredDetails, function(detail) { return detail.name; });
    return detailNames.join(", ");
  }

  getChargeCode(contraventionTypeId: number) {
    return this.localStorageService.getContraventionTypes().find( x => x.id == contraventionTypeId).formattedChargeCode;
  }

  getChargeDescription(contraventionTypeId: number) {
    var contraventionType = this.localStorageService.getContraventionTypes().find( x => x.id == contraventionTypeId);
    return contraventionType.isIRS ? contraventionType.name : contraventionType.description;
  }
}

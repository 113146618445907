import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { ContraventionPayment } from '@apis/shared/models/contravention-payment.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  contraventionNumber: string;
  transactionId: string;
  paymentTransaction: ContraventionPayment;
  

  constructor(
    private activatedRoute: ActivatedRoute,
    private contraventionService: ContraventionService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
      this.activatedRoute.queryParams.subscribe((params) => {
        this.transactionId = params['transId'];
        this.loadPaymentTransaction();
      });
    });
  }

  loadPaymentTransaction() {
    this.contraventionService
      .getPaymentTransaction(this.contraventionNumber,Number.parseInt(this.transactionId))
      .subscribe((result) => {
        this.paymentTransaction = result;
      });
  }

  onPrintReceiptClick() {
    this.spinner.show();
    this.contraventionService.getPaymentReceipt(this.contraventionNumber, Number.parseInt(this.transactionId))
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Contravention - ${this.contraventionNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }
}

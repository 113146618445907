import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-materials-library',
  templateUrl: './technical-materials-library.component.html',
  styleUrls: ['./technical-materials-library.component.scss']
})
export class TechnicalMaterialsLibraryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

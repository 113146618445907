<div *ngIf="currentTermCondition">
    <div class="card goa-callout goa--information mb-4 mt-2" *ngIf="canShowUpdateAlert()">
        <h6 class="mt-0">We've Updated Our Terms & Conditions</h6>
        <p>
            Please be advised that we have updated our Terms and Conditions, effective {{ currentTermCondition.documentDate | date:'mediumDate' }}. 
        </p>
    </div>

    <div class="card">
        <div class="card-header">
            Terms & Conditions
        </div>
        <div class="card-body">
            <div class="form-group row">
                <div class="col-lg-12">
                    <p>1. Please download and print the <a href="assets/{{currentTermCondition.documentName}}" target="_blank">
                        Terms & Conditions</a> and provide the hard copy to the client.</p>
                    <p>2. Please download and print the <a href="{{infoSheetLink}}" target="_blank">
                        {{infoSheetName}}</a> and provide the hard copy to the client.</p>
                    <p *ngIf="review && review.reviewTypeId==ReviewTypes.IrsReview">3. Please download and print the <a href="{{lateEvidenceInfoSheetLink}}" target="_blank">
                        {{lateEvidenceInfoSheetName}}</a> and provide the hard copy to the client.</p>    
                </div>
            </div>
            <div class="form-group row">
                <div class="col-lg-12">
                    <div class="checkbox">
                        <input required type="checkbox" id="terms" name="terms" class="mr-2" 
                            #terms="ngModel" [(ngModel)]="isTermsAccepted" [ngClass]="{'is-invalid': isSubmitClicked && terms.invalid }">
                        <div *ngIf="(review && review.reviewTypeId==ReviewTypes.IrsReview); else notIRSReview">
                            <label class="required" for="terms">
                                <b>I have provided all three documents listed above to the client</b>
                            </label>
                            <div class="invalid-feedback">
                                <div>Please indicate that you printed and provided all three documents listed above to the client</div>
                            </div>
                        </div>
                        <ng-template #notIRSReview>
                            <label class="required" for="terms">
                                <b>I have provided the Terms & Conditions and the {{infoSheetName}} to the client</b>
                            </label>
                            <div class="invalid-feedback">
                                <div>Please indicate that you printed and provided the Terms & Conditions and the {{infoSheetName}} to the client</div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</div>
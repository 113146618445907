import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Constants } from '@apis/shared/helpers/constants';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { TermCondition } from '@apis/shared/models/term-condition.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TermsConditionsComponent implements OnInit, AfterViewInit {
  @Input() hideUpdateAlert: boolean;
  @Input() version: string;
  @Input() isSubmitClicked: boolean;
  
  isTermsAccepted: boolean;
  currentTermCondition: TermCondition = null;

  constructor(
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setCurrentTermCondition();
    });
  }

  setCurrentTermCondition() {
    if (this.version) {
      this.currentTermCondition = this.localStorageService
                                    .getTermsAndConditionsList()
                                    .find(t => t.version == this.version);
    } else {
      this.currentTermCondition = this.localStorageService
                                    .getTermsAndConditionsList()
                                    .find(t => t.expiryDate == null)
    }
  }

  canShowUpdateAlert(): boolean {
    return !this.hideUpdateAlert &&
           DateUtil.today() < DateUtil.addDays(new Date(this.currentTermCondition.documentDate),
                                                Constants.Driver.TermConditionNotificationPeriod);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { RegisteredOwner } from '@apis/shared/models/registered-owner.model';

@Component({
  selector: 'app-registered-owner-information',
  templateUrl: './registered-owner-information.component.html',
  styleUrls: ['./registered-owner-information.component.scss']
})
export class RegisteredOwnerInformationComponent implements OnInit {
  @Input() registeredOwner: RegisteredOwner;
  
  constructor() { }

  ngOnInit(): void {
  }

}

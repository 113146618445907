import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactMethodTypes } from '@apis/shared/enums/app.enum';
import { ContactAddress } from '@apis/shared/models/contact-address.model';
import { CountryProvinceModel } from '@apis/shared/models/country-province.model';
import { ContactMethodType } from '@apis/shared/models/types/contact-method-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DriverBaseComponent } from '../../../components/driver-base.component';
@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ContactInformationComponent extends DriverBaseComponent implements OnInit {
  @Input() context: string;
  @Input() model: any;
  @Input() set isSubmitClicked(value: boolean){
    this._isSubmitClicked = value;
    if (this.contactCountryProvince) {
      this.contactCountryProvince.isSubmitClicked = value;
    }
  }
  get isSubmitClicked(): boolean {
    return this._isSubmitClicked;
  };

  private _isSubmitClicked: boolean;

  confirmPhoneNumber: string;
  confirmEmail: string;

  ContactMethodTypes = ContactMethodTypes;

  contactCountryProvince: CountryProvinceModel;
  contactMethodTypes: ContactMethodType[];
  
  constructor(
    private localStorageService: LocalStorageService,
    public router: Router,
  ) {
    super(router);
  }

  ngOnInit(): void {
    if(!this.context) this.context = "review";
    this.contactMethodTypes = this.localStorageService.getContactMethodTypes();

    // Default Preferred contact method to E-mail - JTI-425
    if (this.model != null && this.model.contactMethodTypeId === null) {
      this.model.contactMethodTypeId = 1;
    }

    //Initialize registered owner country province control
    this.contactCountryProvince = new CountryProvinceModel();
    this.contactCountryProvince.countryId = this.model.contactAddress?.countryId;
    this.contactCountryProvince.provinceId = this.model.contactAddress?.provinceId;
    this.contactCountryProvince.province = this.model.contactAddress?.province;
    this.contactCountryProvince.isCountryRequired = true;
    this.contactCountryProvince.isProvinceRequired = true;
    this.contactCountryProvince.countryLabel = "Country";
    this.contactCountryProvince.provinceLabel = "Province/State";
    this.contactCountryProvince.controlSuffixText = "";
  }

  public refreshContactCountryProvince(e) {
    this.model.contactAddress.countryId = e.countryId;
    this.model.contactAddress.provinceId = e.provinceId;
    this.model.contactAddress.province = e.province;
  }

  onContactMethodChange() {
    this.model.tempPhoneNumber = '';
    this.model.phoneNumber = '';
    this.confirmPhoneNumber = null;

    this.model.email = '';
    this.confirmEmail = '';

    if (this.model.contactMethodTypeId == ContactMethodTypes.Phone) {
      this.model.contactAddress = new ContactAddress();
    } else {
      this.model.contactAddress = null;
    }
  }
}

<!-- Driver Portal View -->
<div class="d-flex flex-column" *ngIf="!isRSGPortal">
  <h1>Late Review Request Submitted</h1>
  <div class="header-container border-bottom pb-4 mb-2">
    <div>
      <h3 class="mb-0">No. {{lateReviewRequest.lateReviewRequestNumber}}</h3>
    </div>
    <div>
        <button class="btn-block goa-button btn-print" (click)="onPrintReceiptClick()">Download Receipt</button>   
    </div>                 
  </div>

  <div class="card border-0">
    <p>
      Your request for a late review was submitted successfully. 
      Once your late review is processed, you will be contacted via 
      your specified preferred contact method with the decision of your 
      application request and, if applicable, details to apply for a review.
    </p>
    <p>
      Due to the potential for delayed or missed messages sent via email and SMS, 
      it is your responsibility to check the status of your request in the SafeRoads portal. 
      Please contact SafeRoads Alberta at <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> if you have any questions.
    </p>
  </div>

  <div class="form-group">
    <div class="row">
      <div class="col-12 col-sm-12">
        <button
          class="goa-button float-right mr-0"
          [routerLink]="['../../../']"
        >
          Return to Contravention Overview
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Registry Portal View -->
<div class="d-flex flex-column" *ngIf="isRSGPortal">
  <div class="card goa-callout goa--emergency mb-4 mt-0">
    <h2 class="my-0">Close this SafeRoads (APIS) browser tab to return to MOVES</h2>
  </div>
  <h1>Late Review Request Submitted</h1>
  <h3 class="mb-0">No. {{lateReviewRequest.lateReviewRequestNumber}}</h3>

  <div class="card border-0">
    <p>
      The request for late review was submitted successfully. Once the request is processed, 
      the client will be contacted via their specified preferred contact method with the decision of their late review request.
    </p>
  </div>
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Contravention } from '@apis/shared/models/contravention.model';
import { ContraventionService } from '../../../shared/services/contravention.service';
import { PaymentTimeRequest } from '@apis/shared/models/payment-time-request.model';
import { ContraventionPageComponent } from '../contravention-page/contravention-page.component';
import { Representation } from '@apis/shared/models/representation.model';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { ContactMethodTypes } from '@apis/shared/enums/app.enum';
import { DriverBaseComponent } from '../../driver-base.component';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { ContraventionInformation } from '@apis/shared/models/contravention-information.model';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';

@Component({
  selector: 'app-request-time',
  templateUrl: './request-time.component.html',
  styleUrls: ['./request-time.component.scss'],
})
export class RequestTimeComponent extends DriverBaseComponent implements OnInit {
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;
  
  contraventionOverview: ContraventionOverview;
  contraventions: ContraventionInformation[] = [];
  paymentTimeRequest = new PaymentTimeRequest({
    contactMethodTypeId: null,
    isSelfSubmit: true,
    contraventionList: []
  });
  isRequestFailed: boolean;
  rsgAgentProfile: RsgAgentProfile;
  registryAgentInformation: RegistryAgentInformation;
  contraventionNumber: string;
  
  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private contraventionService: ContraventionService,
    private localStorageService: LocalStorageService
  ) {
    super(router);
  }

  ngOnInit(): void {
    // Registry Portal - If logged in user is registry agent then set the RA profile information in representation object
    if (this.isRSGPortal)
    {
      this.rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
      if (!this.rsgAgentProfile)
        this.router.navigate(['/unauthorized']);

      // Create a new RegistryAgentInformation object, or load the old one if it exists
      if (this.paymentTimeRequest.registryAgentInformation.length === 0) {
        this.registryAgentInformation = new RegistryAgentInformation();
        this.registryAgentInformation.userName = this.rsgAgentProfile.userName;
        this.registryAgentInformation.organizationCode = this.rsgAgentProfile.organization;
      }
      else {
        this.registryAgentInformation = this.paymentTimeRequest.registryAgentInformation[0];
      }

      this.paymentTimeRequest.isSelfSubmit = true; // We do not create representatives for registry portal payment time requests
    }

    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');

      this.contraventionOverview = this.localStorageService.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      if(this.contraventionOverview === null) {
        this.router.navigate(['../../'], {
          relativeTo: this.activatedRoute,
        });
        return;
      }

      this.paymentTimeRequest.contraventionNumber = this.contraventionNumber;

      this.paymentTimeRequest.contraventionList = this.contraventionOverview.timeToPayEligibleContraventions;

      this.paymentTimeRequest.contraventionList.forEach(c => {
        this.contraventions.push(this.contraventionOverview.contraventions.find(ci => ci.contraventionNumber == c));
      });
    });
  }

  onRepresentationTypeChange() {
    this.paymentTimeRequest.representation = this.paymentTimeRequest.isSelfSubmit ? null : new Representation({
      representationTypeId: null
    });
  }

  submitRequest(isValid: boolean) {
    if(isValid) {
      if (this.isRSGPortal) {
        if (this.paymentTimeRequest.registryAgentInformation.length === 0) {
          this.paymentTimeRequest.registryAgentInformation.push(this.registryAgentInformation);
        }
        else {
          this.paymentTimeRequest.registryAgentInformation[0] = this.registryAgentInformation;
        }
      }
      this.masterPage.showSpinner();

      this.paymentTimeRequest.contraventionNumber = this.paymentTimeRequest.contraventionList[0];

      this.contraventionService
        .requestTimeToPay(this.paymentTimeRequest)
        .subscribe((result) => {
          this.localStorageService.setItem<PaymentTimeRequest>("PaymentTimeRequest", this.paymentTimeRequest);

          this.router.navigate(['../'], {
            relativeTo: this.activatedRoute,
          });
        },
        error => {
          this.isRequestFailed = true;
          this.masterPage.hideSpinner();

          window.scroll(0,0);
        });
    }
  }

  getLatestTermsAndConditionsVersion(): string {
    return this.localStorageService
               .getTermsAndConditionsList()
               .find(t => t.expiryDate == null)
               .version;
  }

  getChargeName(contraventionTypeId: number) {
    return this.localStorageService.getContraventionTypes().find( x => x.id == contraventionTypeId).formattedName;
  }
}

<!-- Driver Portal View -->
<app-vehicle-seizure-page *ngIf="lateReviewRequest && !isRSGPortal">
    <ng-container content>
        <form #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
            <div class="d-flex flex-column">
                <h1>Request a Late Review</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{seizureNumber}}</h3>

                <div class="card goa-callout goa--info">
                    <p class="mt-1">
                        The recipient of the Notice of Seizure may make an application for late review of the 
                        Notice of Seizure in certain instances by establishing a described circumstance. 
                        To view information about these requirements, <a href="https://www.alberta.ca/saferoads-find-your-notice.aspx" target="_blank">click here</a>.
                    </p>
                </div>

                <div class="card mt-3 mt-lg-4">
                    <div class="card-header">
                        Representation Type
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <label for="reviewType">Are you the Recipient of the Contravention Notice?</label>
                                <select class="form-control" id="reviewType" name="reviewType" 
                                    [(ngModel)]="lateReviewRequest.isSelfSubmit" (change)="onReviewTypeChange()">
                                    <option [ngValue]="true">Yes, I am submitting this application on behalf of myself</option>
                                    <option [ngValue]="false">No, I am submitting this application on behalf of another</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <app-representation *ngIf="!lateReviewRequest.isSelfSubmit"
                    [model]="lateReviewRequest.representation"
                    [isSubmitClicked]="requestForm.submitted"
                    [hideRepresenationMessage]="true"
                    [isYouth]="vehicleSeizure.isRecipientYouth"
                    [isThirdParty]="lateReviewRequest.reviewTypeId == ReviewTypes.ThirdPartySeizureReview"></app-representation>

                <app-driver-information [recipient]="vehicleSeizure.recipient">
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="driverEmail" class="required">Email Address</label>
                            <input type="text" class="form-control" id="driverEmail" name="driverEmail"
                                #driverEmail="ngModel" [(ngModel)]="lateReviewRequest.driverEmail" required email
                                [ngClass]="{ 'is-invalid': requestForm.submitted && driverEmail.invalid }">
                            <div class="invalid-feedback">
                                <div *ngIf="driverEmail.errors?.required">Email Address is required</div>
                                <div *ngIf="driverEmail.errors?.email">Please provide a valid email address</div>
                            </div>
                        </div>
                    </div>
                </app-driver-information>

                <app-vehicle-seizure-information [vehicleSeizure]="vehicleSeizure"></app-vehicle-seizure-information>

                <app-vehicle-information [vehicle]="vehicleSeizure.vehicle"></app-vehicle-information>

                <div class="card mt-4">
                    <div class="card-header">
                        Request Details
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="requestReason" class="required">Describe the Reason for Your Request</label>
                                <textarea class="form-control" id="requestReason" name="requestReason"
                                    rows="6" maxlength="5000"
                                    required noWhiteSpace [ngClass]="{ 'is-invalid': requestForm.submitted && requestReason.invalid }"
                                    [(ngModel)]="lateReviewRequest.requestReason" #requestReason="ngModel"
                                    placeholder="Please Provide Your Reason For Requesting a Late Review (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Reason for Request is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <app-contact-information [model]="lateReviewRequest" [isSubmitClicked]="requestForm.submitted"></app-contact-information>

                <div class="card border-0">
                    <app-foip></app-foip>
                </div>

                <div class="mt-2">
                    <app-terms-conditions [isSubmitClicked]="requestForm.submitted"></app-terms-conditions>
                </div>                

                <div class="form-group">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../']">Return to Seizure Details</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">                
                            <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Supporting Documents</button>
                        </div>
                    </div>
                </div>	
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page *ngIf="lateReviewRequest && isRSGPortal">
    <ng-container content>
        <form #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
            <div class="d-flex flex-column">
                <h1>Late Review Request Details</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{seizureNumber}}</h3>

                <div class="card goa-callout goa--info">
                    <h6 class="mt-0">Collect Review Details</h6>
                    <p class="mt-1">
                        The driver may request a late review between 7 and 365 days from the date of issuance. An application 
                        for late review is limited to the grounds listed in Section 10 of Provincial Administrative Penalties 
                        Regulation. Depending on the ground under which the client qualifies, some or all of the following grounds may apply:
                    </p>
                    <ul class="list ml-4">
                        <li>Where the recipient was unaware of the Notice of Administrative Penalty or Seizure Notice, if on becoming aware of it the recipient took prompt action to notify the Director</li>
                        <li>Where the recipient experienced physical or mental incapacity rendering the recipient unable to respond to the Notice of Administrative Penalty or Seizure Notice within 7 days of the issuance of the Notice, if on regaining capacity the recipient took prompt action to notify the Director</li>
                        <li>Where the recipient experienced unforeseen and unavoidable events rendering the recipient unable to respond to the Notice of Administrative Penalty or Seizure Notice within 7 days of the issuance of the Notice, if the recipient took prompt action to notify the Director</li>
                    </ul>
                    <p>
                        Collect the following information from the client to submit a request for a late review.
                    </p>
                </div>

                <!-- This component is unused, but it should use registryAgentInformation instead of representation.
                    <app-registry-agent-information [representation]="lateReviewRequest.representation"></app-registry-agent-information>

                    <app-registry-payer-information
                    [representation]="lateReviewRequest.representation"
                    [isFormSubmitted]="requestForm.submitted"
                    sectionHeader="Requester"
                    sectionLabel="Who is requesting this review?">
                    <div class="goa-callout goa--important mt-1">
                        <p>
                            Please advise the client that the driver who was issued the seizure notice must complete and sign a Consent to Representation form and this form must be uploaded with this application.
                        </p>
                        <p>
                            <a href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" download="Consent to Representation" target="_blank">Download the Consent to Representation Form</a>
                            print it, and provide the hard copy to the client
                        </p>
                    </div>
                    </app-registry-payer-information>
                -->

                <app-driver-information [recipient]="vehicleSeizure.recipient"></app-driver-information>

                <app-vehicle-seizure-information [vehicleSeizure]="vehicleSeizure"></app-vehicle-seizure-information>

                <app-vehicle-information [vehicle]="vehicleSeizure.vehicle"></app-vehicle-information>

                <div class="card mt-4">
                    <div class="card-header">
                        Request Details
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="requestReason" class="required">Describe the Reason for Your Request</label>
                                <textarea class="form-control" id="requestReason" name="requestReason"
                                    rows="6" maxlength="5000"
                                    required noWhiteSpace [ngClass]="{ 'is-invalid': requestForm.submitted && requestReason.invalid }"
                                    [(ngModel)]="lateReviewRequest.requestReason" #requestReason="ngModel"
                                    placeholder="Please Provide Your Reason For Requesting a Late Review (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Reason for Request is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <app-contact-information [model]="lateReviewRequest" [isSubmitClicked]="requestForm.submitted"></app-contact-information>

                <div class="form-group">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../terms']">Return to Terms & Conditions</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">                
                            <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Supporting Documents</button>
                        </div>
                    </div>
                </div>	
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>
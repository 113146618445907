import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SeizureStatusTypes, SeizureUserType } from '@apis/shared/enums/app.enum';
import { Constants } from '@apis/shared/helpers/constants';
import { SeizureInformation } from '@apis/shared/models/seizure-information.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-seizure-information',
  templateUrl: './seizure-information.component.html',
  styleUrls: ['./seizure-information.component.scss']
})
export class SeizureInformationComponent implements OnInit {
  @Input() seizureInformation: SeizureInformation;
  @Input() contraventionSubmissionDate: Date;

  seizureTypeName: string;
  seizureStatusTypeName: string;
  SeizureStatusTypes = SeizureStatusTypes;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    // Get contravention status type name to show it in the badge
    this.seizureStatusTypeName = this.localStorageService.getSeizureStatusTypes().find(x => x.id == this.seizureInformation.seizureStatusTypeId)?.name;
  }

  viewVehicleSeizure() {
    this.localStorageService.set(`${this.seizureInformation.seizureNumber}_user_type`, SeizureUserType.Driver.toString());
    this.router.navigate(['../../vehicle-seizures', this.seizureInformation.seizureNumber], {relativeTo: this.activatedRoute});
  }

  getChargeCode(seizureTypeId: number) {
    return this.localStorageService.getContraventionTypes().find( x => x.id == seizureTypeId).formattedChargeCode;
  }

  getChargeDescription(seizureTypeId: number) {
    var contraventionType = this.localStorageService.getContraventionTypes().find( x => x.id == seizureTypeId);
    return contraventionType.isIRS ? contraventionType.name : contraventionType.description;
  }

  getIRSCharge(contraventionTypeId: number) {
    const showIRSSeizureType = new Date(this.contraventionSubmissionDate) >= new Date(+Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(0, 4), +Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(5, 7) - 1, +Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(8, 10));
    if (showIRSSeizureType) {
      const contraventionType = this.localStorageService.getContraventionTypes().find(x => x.id == contraventionTypeId);
      return contraventionType.formattedChargeCode + ' ' + contraventionType.name;
    }
    else {
      return 'IRS Vehicle Seizure';
    }
  }
}

<!-- Driver Portal View -->
<app-vehicle-seizure-page *ngIf="!isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
        <div class="d-flex flex-column">
            <h1>Seizure {{reviewContext}} Details</h1>
            <div class="d-flex w-100 border-bottom mb-2">
                <h3>No. {{review.seizureNumber}}</h3>
                <div class="countdown">
                    <countdown-timer [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                </div>
            </div>

            <div class="card border-0 mt-2" *ngIf="showReleaseDateWarning">
                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Vehicle Release Date is Approaching</h6>
                    <p>
                        Be advised that your {{reviewContext.toLowerCase()}} decision may not be completed by the vehicle release date.
                    </p>
                </div>
            </div>

            <div class="card border-0 mt-2" *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview && vehicleSeizure.secondarySeizureNumber">
                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Your vehicle was seized as a result of two seizure notices</h6>
                    <p>
                        This application for relief will address both seizure notices
                    </p>
                </div>
            </div>

            <div class="card mt-3 mt-lg-4">
                <div class="card-header">
                    Representation Type
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-lg-12" *ngIf="review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview">
                            <label for="reviewType">Are you the Recipient of the Contravention Notice?</label>
                            <select class="form-control" id="reviewType" name="reviewType" 
                                [(ngModel)]="review.isSelfSubmit" (change)="onReviewTypeChange()">
                                <option [ngValue]="true">Yes, I am submitting this on behalf of myself</option>
                                <option [ngValue]="false">No, I am submitting this on behalf of the recipient</option>
                            </select>
                        </div>
                        <div class="col-lg-12" *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview">
                            <label for="reviewType">Are you the Registered Owner?</label>
                            <select class="form-control" id="reviewType" name="reviewType" 
                                [(ngModel)]="review.isSelfSubmit" (change)="onReviewTypeChange()">
                                <option [ngValue]="true">Yes, I am submitting this on behalf of myself</option>
                                <option [ngValue]="false">No, I am submitting this on behalf of the registered owner</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <app-representation *ngIf="!review.isSelfSubmit" [model]="review.representation"
                    [isSubmitClicked]="requestForm.submitted"
                    [hideRepresenationMessage]="true"
                    [isYouth]="vehicleSeizure.isRecipientYouth"
                    [isThirdParty]="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview"></app-representation>

            <ng-container *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview">
                
                <app-applicant-information [model]="review.reviewApplicant"
                    [isSubmitClicked]="requestForm.submitted" (vehicleInterestTypeChange)="onVehicleInterestTypeChange()">
                </app-applicant-information>

                <app-registered-owner-information [registeredOwner]="vehicleSeizure.vehicle.registeredOwner">
                </app-registered-owner-information>

            </ng-container>
            
            <ng-container *ngIf="review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview">
                <app-driver-information [recipient]="vehicleSeizure.recipient">
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="driverEmail" class="required">Email Address</label>
                            <input type="text" class="form-control" id="driverEmail" name="driverEmail"
                                #driverEmail="ngModel" [(ngModel)]="review.driverEmail" required email
                                [ngClass]="{ 'is-invalid': requestForm.submitted && driverEmail.invalid }">
                            <div class="invalid-feedback">
                                <div *ngIf="driverEmail.errors?.required">Email Address is required</div>
                                <div *ngIf="driverEmail.errors?.email">Please provide a valid email address</div>
                            </div>
                        </div>
                    </div>
                </app-driver-information>
            </ng-container>

            <app-vehicle-seizure-information [vehicleSeizure]="vehicleSeizure"></app-vehicle-seizure-information>
            
            <app-vehicle-information [vehicle]="vehicleSeizure.vehicle"></app-vehicle-information>
            
            <div class="card mt-4">
                <div class="card-header">
                    Review Details
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="hearingFeeAmount">Review Fee Amount</label>
                            <input readonly type="text" readonly class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="reviewGrounds" class="required">Grounds for Review</label>
                            <ng-container *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview; else notThirdPartyReviewGrounds">
                                <p class="mt-0">
                                    Financial hardship and inconvenience are not grounds for cancellation of your Seizure Notice. 
                                    For more information on the grounds on which a review may be requested, 
                                    please refer to Section 11.1 of the <a href="https://kings-printer.alberta.ca/documents/Regs/2006_251.pdf" target="_blank">Vehicle Seizure and Removal Regulation</a>.
                                </p>
                            </ng-container>
                            <ng-template #notThirdPartyReviewGrounds>
                                <p class="mt-0">
                                    Financial hardship and inconvenience are not grounds for cancellation of your Seizure Notice. 
                                    For more information on the grounds on which a review may be requested, 
                                    please refer to Section 11 of the <a href="https://kings-printer.alberta.ca/documents/Regs/2006_251.pdf" target="_blank">Vehicle Seizure and Removal Regulation</a>.
                                </p>
                            </ng-template>
                            <textarea class="form-control" id="reviewGrounds" name="reviewGrounds" 
                                rows="6" maxlength="5000"
                                required noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="reviewItem.reviewGrounds"
                                [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                placeholder="Please Provide Your Reason For Requesting a Review (Max 5000 Characters)"></textarea>
                            <div class="invalid-feedback">
                                <div>Grounds for Review is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="additionalNotes">Additional Request Notes</label>
                            <textarea class="form-control" id="additionalNotes" name="additionalNotes" 
                                rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                placeholder="Please Provide Any Additional Notes for Your Request (Max 5000 Characters)">
                            </textarea>
                        </div>
                    </div>
                    <ng-container *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview">
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="recipientRelationship" class="required">Describe Your Relationship with the Recipient</label>
                                <textarea class="form-control" id="recipientRelationship" name="recipientRelationship" 
                                    rows="6" maxlength="5000"
                                    required noWhiteSpace #recipientRelationship="ngModel" [(ngModel)]="review.recipientRelationship"
                                    [ngClass]="{ 'is-invalid': requestForm.submitted && recipientRelationship.invalid }"
                                    placeholder="Describe Your Relationship with the Recipient (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Relationship with the Recipient is required</div>
                                </div>
                            </div>
                        </div>              
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="isCommercialVehicle" class="required">Is this vehicle registered under a company?</label>
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="commercialVehicleYes" name="isCommercialVehicle"
                                            required #isCommercialVehicle="ngModel" [value]="true" [(ngModel)]="review.isCommercialVehicle">
                                        <label class="custom-control-label" for="commercialVehicleYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="commercialVehicleNo" name="isCommercialVehicle"
                                            required [value]="false" [(ngModel)]="review.isCommercialVehicle">
                                        <label class="custom-control-label" for="commercialVehicleNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && isCommercialVehicle.errors?.required">
                                            Please indicate whether the vehicle was registered under a company
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="isVehicleTakenWithoutPermission" class="required">Was the Vehicle Taken without Your Permission?</label>
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="accompainedYes" name="isVehicleTakenWithoutPermission"
                                            required #isVehicleTakenWithoutPermission="ngModel" [value]="true" [(ngModel)]="review.isVehicleTakenWithoutPermission">
                                        <label class="custom-control-label" for="accompainedYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="accompainedNo" name="isVehicleTakenWithoutPermission"
                                            required [value]="false" [(ngModel)]="review.isVehicleTakenWithoutPermission">
                                        <label class="custom-control-label" for="accompainedNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && isVehicleTakenWithoutPermission.errors?.required">
                                            Please indicate whether the vehicle was taken without your permission
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="isStolenVehicle" class="required">Was the Vehicle Stolen?</label>
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="stolenYes" name="isStolenVehicle"
                                            required #isStolenVehicle="ngModel" [value]="true" [(ngModel)]="review.isVehicleStolen">
                                        <label class="custom-control-label" for="stolenYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="stolenNo" name="isStolenVehicle"
                                            required [value]="false" [(ngModel)]="review.isVehicleStolen">
                                        <label class="custom-control-label" for="stolenNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && isStolenVehicle.errors?.required">Please indicate whether the vehicle was stolen</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="review.reviewApplicant?.vehicleInterestTypeId == VehicleInterestTypes.Lessor">
                            <div class="col-12">
                                <label for="hasRentalAgreement" class="required">Did You Have a Rental Agreement with the Recipient/Driver?</label>
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="rentalAgreementYes" name="hasRentalAgreement"
                                            required #hasRentalAgreement="ngModel" [value]="true" [(ngModel)]="review.hasRentalAgreement">
                                        <label class="custom-control-label" for="rentalAgreementYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="rentalAgreementNo" name="hasRentalAgreement"
                                            required [value]="false" [(ngModel)]="review.hasRentalAgreement">
                                        <label class="custom-control-label" for="rentalAgreementNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && hasRentalAgreement.errors?.required">
                                            Please indicate whether you have a rental agreement with the Recipient/Driver
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            
            <app-contact-information [context]="reviewContext.toLowerCase()" [model]="review" [isSubmitClicked]="requestForm.submitted"></app-contact-information>

            <div class="card border-0">
                <app-foip></app-foip>
            </div>

            <div class="mt-2">
                <app-terms-conditions [isSubmitClicked]="requestForm.submitted"></app-terms-conditions>
            </div>                      

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../']">Return to Seizure Details</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Schedule Review</button>
                    </div>
                </div>
            </div>	
        </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page *ngIf="isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
        <div class="d-flex flex-column">
            <h1>Seizure {{reviewContext}} Details</h1>
            <div class="d-flex w-100 border-bottom mb-2">
                <h3>No. {{review.seizureNumber}}</h3>
                <div class="countdown">
                    <countdown-timer [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                </div>
            </div>

            <div class="card goa-callout goa--information mt-0">
                <h6 class="mt-0">Collect {{reviewContext}} Details</h6>
                <p>
                    The {{review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview? "registered owner": "client"}} can apply for a {{reviewContext.toLowerCase()}} of the seizure. Collect the following information from the client to submit an application for {{reviewContext.toLowerCase()}}.
                </p>
            </div>

            <div class="card border-0 mt-2" *ngIf="showReleaseDateWarning">
                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Vehicle Release Date is Approaching</h6>
                    <p>
                        Be advised that the decision may not be completed by the vehicle release date.
                    </p>
                </div>
            </div>

            <div class="card border-0 mt-2" *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview && vehicleSeizure.secondarySeizureNumber">
                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Your vehicle was seized as a result of two seizure notices</h6>
                    <p>
                        This application for relief will address both seizure notices
                    </p>
                </div>
            </div>

            <app-registry-agent-information [registryAgentInformation]="registryAgentInformation"></app-registry-agent-information>

            <ng-container *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview">
                
                <app-registry-applicant-information
                    [reviewApplicant]="review.reviewApplicant"
                    [registryAgentInformation]="registryAgentInformation"
                    [isSubmitClicked]="requestForm.submitted"
                    sectionHeader="Requester"
                    (vehicleInterestTypeChange)="onVehicleInterestTypeChange()">
                </app-registry-applicant-information>

                <app-registered-owner-information [registeredOwner]="vehicleSeizure.vehicle.registeredOwner">
                </app-registered-owner-information>

            </ng-container>
            
            <ng-container *ngIf="review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview">
                <app-registry-payer-information
                    [registryAgentInformation]="registryAgentInformation"
                    [isFormSubmitted]="requestForm.submitted"
                    (requesterTypeChange)="onRequesterTypeChange($event)"
                    sectionHeader="Requester"
                    sectionLabel="Who is applying for review?">
                </app-registry-payer-information>

                <app-representation *ngIf="!review.isSelfSubmit" [model]="review.representation"
                [isSubmitClicked]="requestForm.submitted"
                [hideRepresenationMessage]="true"
                [isYouth]="vehicleSeizure.isRecipientYouth"
                [isRSGPortal]="isRSGPortal"
                [reviewTypeId]="review.reviewTypeId"
                [registryAgentInformation]="registryAgentInformation"
                [showSameAsRequester]="true"></app-representation>

                <app-driver-information [recipient]="vehicleSeizure.recipient">
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="driverEmail">Email Address</label>
                            <input type="text" class="form-control" id="driverEmail" name="driverEmail"
                                #driverEmail="ngModel" [(ngModel)]="review.driverEmail" email
                                [ngClass]="{ 'is-invalid': requestForm.submitted && driverEmail.invalid }">
                            <div class="invalid-feedback">
                                <div *ngIf="driverEmail.errors?.required">Email Address is required</div>
                                <div *ngIf="driverEmail.errors?.email">Please provide a valid email address</div>
                            </div>
                        </div>
                    </div>
                </app-driver-information>
            </ng-container>

            <app-vehicle-seizure-information [vehicleSeizure]="vehicleSeizure"></app-vehicle-seizure-information>

            <app-vehicle-information [vehicle]="vehicleSeizure.vehicle"></app-vehicle-information>
            
            <div class="card mt-4">
                <div class="card-header">
                    Review Details
                </div>
                <div class="card-body">
                    <p class="mt-0">Please ask the client to provide the following information:</p>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="hearingFeeAmount">Review Fee Amount</label>
                            <input readonly type="text" readonly class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="reviewGrounds" class="required">Grounds for Review</label>
                            <textarea class="form-control" id="reviewGrounds" name="reviewGrounds" 
                                rows="6" maxlength="5000"
                                required noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="reviewItem.reviewGrounds"
                                [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                placeholder="The client must provide their reason for requesting a review (Max 5000 Characters)"></textarea>
                            <div class="invalid-feedback">
                                <div>Grounds for Review is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="additionalNotes">Additional Request Notes</label>
                            <textarea class="form-control" id="additionalNotes" name="additionalNotes" 
                                rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                placeholder="The client may provide any additional notes for the request (Max 5000 Characters)">
                            </textarea>
                        </div>
                    </div>
                    <ng-container *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview">
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="recipientRelationship" class="required">Describe the Relationship with the Driver</label>
                                <textarea class="form-control" id="recipientRelationship" name="recipientRelationship" 
                                    rows="6" maxlength="5000"
                                    required noWhiteSpace #recipientRelationship="ngModel" [(ngModel)]="review.recipientRelationship"
                                    [ngClass]="{ 'is-invalid': requestForm.submitted && recipientRelationship.invalid }"
                                    placeholder="The client must describe their relationship with the driver (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Relationship with the Recipient is required</div>
                                </div>
                            </div>
                        </div>              
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="isCommercialVehicle" class="required">Is this vehicle registered under a company?</label>
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="commercialVehicleYes" name="isCommercialVehicle"
                                            required #isCommercialVehicle="ngModel" [value]="true" [(ngModel)]="review.isCommercialVehicle">
                                        <label class="custom-control-label" for="commercialVehicleYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="commercialVehicleNo" name="isCommercialVehicle"
                                            required [value]="false" [(ngModel)]="review.isCommercialVehicle">
                                        <label class="custom-control-label" for="commercialVehicleNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && isCommercialVehicle.errors?.required">
                                            Please indicate whether the vehicle was registered under a company
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="isVehicleTakenWithoutPermission" class="required">Was the Vehicle Taken without Your Permission?</label>
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="accompainedYes" name="isVehicleTakenWithoutPermission"
                                            required #isVehicleTakenWithoutPermission="ngModel" [value]="true" [(ngModel)]="review.isVehicleTakenWithoutPermission">
                                        <label class="custom-control-label" for="accompainedYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="accompainedNo" name="isVehicleTakenWithoutPermission"
                                            required [value]="false" [(ngModel)]="review.isVehicleTakenWithoutPermission">
                                        <label class="custom-control-label" for="accompainedNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && isVehicleTakenWithoutPermission.errors?.required">
                                            Please indicate whether the vehicle was taken without your permission
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="isStolenVehicle" class="required">Was the Vehicle Stolen?</label>
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="stolenYes" name="isStolenVehicle"
                                            required #isStolenVehicle="ngModel" [value]="true" [(ngModel)]="review.isVehicleStolen">
                                        <label class="custom-control-label" for="stolenYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="stolenNo" name="isStolenVehicle"
                                            required [value]="false" [(ngModel)]="review.isVehicleStolen">
                                        <label class="custom-control-label" for="stolenNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && isStolenVehicle.errors?.required">Please indicate whether the vehicle was stolen</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="review.reviewApplicant?.vehicleInterestTypeId == VehicleInterestTypes.Lessor">
                            <div class="col-12">
                                <label for="hasRentalAgreement" class="required">Did You Have a Rental Agreement with the Recipient/Driver?</label>
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="rentalAgreementYes" name="hasRentalAgreement"
                                            required #hasRentalAgreement="ngModel" [value]="true" [(ngModel)]="review.hasRentalAgreement">
                                        <label class="custom-control-label" for="rentalAgreementYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="rentalAgreementNo" name="hasRentalAgreement"
                                            required [value]="false" [(ngModel)]="review.hasRentalAgreement">
                                        <label class="custom-control-label" for="rentalAgreementNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && hasRentalAgreement.errors?.required">
                                            Please indicate whether you have a rental agreement with the Recipient/Driver
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="card card-callout border-0 mt-2">
                                    <div class="goa-callout goa--info mt-1">
                                        <h6 class="mt-0">Supporting Documents</h6>
                                        <p>
                                            In order for the applicant to obtain relief as owner from the effects of a seizure, 
                                            the requirements of Section 11.1(3) and, where applicable, 
                                            Section 11.1(4) of the <i><a href="https://kings-printer.alberta.ca/documents/Regs/2006_251.pdf" target="_blank">Vehicle Seizure and Removal Regulation</a></i> 
                                            must be met. Please carefully review these provisions. Satisfying the applicable provisions may require the applicant to provide one or more of the following:
                                        </p>
                                        <ul>
                                            <li>
                                                Sworn affidavit or statement detailing:
                                                <ul>
                                                    <li>The circumstances in which the driver was provided access to the vehicle;</li>
                                                    <li>The relationship between owner and driver including whether they reside in the same household and whether the vehicle was owned for the driver’s benefit;</li>
                                                    <li>Where the owner is a corporation, whether the driver had joint ownership or control of the corporation, holds a significant number of shares or otherwise has significant control; and documentary proof thereof;</li>
                                                    <li>Whether the owner verified that the driver had a valid and subsisting operator’s license before providing the driver with access to the vehicle.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                Stolen vehicle – stolen vehicle report
                                            </li>
                                            <li>
                                                Commercial carrier vehicle – driver abstract
                                            </li>
                                            <li>
                                                Rental vehicle – rental agreement including terms and conditions
                                            </li>
                                            <li>
                                                Proof that the driver is not a registered owner of the vehicle 
                                            </li>                                            
                                        </ul>
                                        <p>
                                            It is the applicant’s responsibility to ensure that the application for relief is complete and all required documents have been provided.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            
            <app-contact-information [context]="reviewContext.toLowerCase()" [model]="review" [isSubmitClicked]="requestForm.submitted"></app-contact-information>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../terms']">Return to Terms & Conditions</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Schedule Review</button>
                    </div>
                </div>
            </div>	
        </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>
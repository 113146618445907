import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContraventionSearch } from '@apis/shared/models/contravention-search.model';
import { DriverService } from '../../../shared/services/driver.service';
import { JWTTokenService } from '../../../shared/services/jwttoken.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LiteSearchResult } from '@apis/shared/models/lite-search-result.model';
import { NgForm } from '@angular/forms';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Constants } from '@apis/shared/helpers/constants';


@Component({
  selector: 'app-contravention-lookup',
  templateUrl: './contravention-lookup.component.html',
  styleUrls: ['./contravention-lookup.component.scss']
})
export class ContraventionLookupComponent implements OnInit {
  searchCriteria = new ContraventionSearch();
  searching = false;
  isContraventiondetailsNotFound: boolean;
  isContraventionNotFound: boolean;
  isAccessForbidden: boolean;
  isServerErr: boolean;
  errorMessage: string;
  captchaError: string;
  driverBirthDate: Date;
  contraventionOccurrenceDate: Date;
  searchResult: LiteSearchResult;  

  datePickerConfig: Partial<BsDatepickerConfig>;
  Constants = Constants;

  constructor(private router: Router,
    private readonly spinner: NgxSpinnerService,
    private jwtTokenService: JWTTokenService,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
    private driverService: DriverService,
    private localStorageService: LocalStorageService) {

    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'YYYY-MM-DD',
        isAnimated: true
      });
  }

  ngOnInit(): void {
    this.spinner.hide();
  }

  onLookupClick(isValid: boolean,searchForm:NgForm): void {
    if (isValid) {
      //Reset search fields
      searchForm.resetForm();

      this.clearSearchResult();      

      // Character casing remains unchanged in control value, so force it to upper case
      this.searchCriteria.contraventionNumber = this.searchCriteria.contraventionNumber.toUpperCase();
      this.spinner.show();
      this.recaptchaV3Service.execute("lookupContravention")
        .subscribe((token) => {
          this.driverService.lookupContraventionAsync(this.searchCriteria.contraventionNumber, token)
            .subscribe(
              (result: any) => {
                this.searchResult = result;                               
                this.spinner.hide();
              },
              (error: any) => {                
                if (error.status == 403) {
                  this.isAccessForbidden = true;
                } else if (error.status == 404) {                
                  this.isContraventionNotFound = (error.error == 1 || error.error == 2);
                } else {
                  this.isServerErr = true;
                  this.errorMessage = "An error occurred while processing your request. Please try again.";
                }
                this.spinner.hide();
              });
        },
          (error) => {
            this.isServerErr = true;
            this.errorMessage = error;
            this.spinner.hide();
          });
    }
  }

  onSearchClick(isValid: boolean) {    
    if (isValid) {
      this.resetFlags();

      // Character casing remains unchanged in control value, so force it to upper case
      this.searchCriteria.contraventionNumber = this.searchCriteria.contraventionNumber.toUpperCase();

      if (this.searchResult != null && this.searchResult.isIssuedToRegisteredOwner) {
        this.searchCriteria.licencePlateNumber = this.searchCriteria.licencePlateNumber.toUpperCase();
        this.searchCriteria.occurrenceDate = new Date(this.contraventionOccurrenceDate);
        this.searchCriteria.occurrenceDate.setMinutes(this.searchCriteria.occurrenceDate.getMinutes()
          - this.searchCriteria.occurrenceDate.getTimezoneOffset());
      }

      // Undo the UTC timezone offset, so that on JSON serialization the date wont change 
      //  when converted to UTC TZ
      this.searchCriteria.birthDate = new Date(this.driverBirthDate);
      this.searchCriteria.birthDate.setMinutes(this.searchCriteria.birthDate.getMinutes()
        - this.searchCriteria.birthDate.getTimezoneOffset());

      this.spinner.show();
      this.recaptchaV3Service.execute("SearchContravention")
        .subscribe((token) => {
          this.driverService.searchContraventionAsync(this.searchCriteria, token)
            .subscribe(
              (result: any) => {
                this.jwtTokenService.setToken(result.accessToken)
                this.localStorageService.setEnableLicencePickupModal(true);
                this.router.navigate(['/contravention', this.searchCriteria.contraventionNumber]);
              },
              (error: any) => {                
                if (error.status == 403) {
                  this.isAccessForbidden = true;
                } else if (error.status == 404) {
                  this.isContraventiondetailsNotFound = (error.error == 1 || error.error == 2);                  
                } else {
                  this.isServerErr = true;
                  this.errorMessage = "An error occurred while processing your request. Please try again.";
                }
                this.spinner.hide();                                
              });
        },
          (error) => {            
            this.isServerErr = true;
            this.errorMessage = error;
            this.spinner.hide();            
          });
    }
  }

  clearSearchResult() {
    this.resetFlags();

    //clear search result
    this.searchResult = null;
  }

  resetFlags()
  {
     // Reset all flags    
     this.isContraventiondetailsNotFound = false;
     this.isContraventionNotFound = false;
     this.isAccessForbidden = false;
     this.isServerErr = false;     
  }

}

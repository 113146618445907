<div class="card mt-4">
    <div class="card-header">
        Vehicle Information
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="col-lg-4">
                <label for="licencePlateNumber">Licence Plate Number</label>
                <input type="text" readonly class="form-control" id="licencePlateNumber" 
                    [ngModel]="vehicle.licencePlateNumber">
            </div>

            <div class="col-lg-4">
                <label for="vehicleIdentificationNumber">Vehicle Identification Number</label>
                <input type="text" readonly class="form-control" id="vehicleIdentificationNumber"
                    [ngModel]="vehicle.vehicleIdentificationNumber">
            </div>

            <div class="col-lg-4">
                <label for="licensedProvince">Licensed Province/State</label>
                <input type="text" readonly class="form-control" id="licensedProvince"
                    [ngModel]="licensedProvince">
            </div>				
        </div>
        <div class="form-group row">
            <div class="col-lg-4">
                <label for="make">Make</label>
                <input type="text" readonly class="form-control" id="make" 
                    [ngModel]="vehicle.make">
            </div>

            <div class="col-lg-4">
                <label for="model">Model</label>
                <input type="text" readonly class="form-control" id="model"
                    [ngModel]="vehicle.model">
            </div>

            <div class="col-lg-4">
                <label for="year">Year</label>
                <input type="text" readonly class="form-control" id="year"
                    [ngModel]="vehicle.year">
            </div>
        </div>
    </div>
</div>
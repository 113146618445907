<app-contravention-page [suppressConfirmation]="true" #masterPage>
    <ng-container content>
        <div class="d-flex flex-column" *ngIf="contravention">
            <div class="row">
                <div class="col-12 col-sm-8">        
                    <h1 class="mb-3">Contravention Details</h1>
                </div>
                <div class="col-12 col-sm-4 d-flex align-self-end justify-content-end" *ngIf="isRSGPortal">
                    <div class="float-right">
                        <div class="print-icon not-for-print" (click)="printSummary()"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span>Notice(s)</span>
                    <h3 class="mt-0 pb-3 border-bottom">{{contraventionNumbers}}</h3>
                </div>
            </div>
            
            <div class="mt-2 accordion" id="accordion">
                <div class="card">
                    <div class="card-header" data-toggle="collapse" href="#divContraventionDetails">
                        <div>Contravention Information</div>                                
                    </div>            
                    <div id="divContraventionDetails" class="collapse show">
                        <div class="card-body">         
                            <div *ngFor="let ci of stopInformation.contraventions; last as isLast">
                                <app-contravention-detail-information [contravention]="ci" [additionalFieldsActive]="additionalFieldsActive"></app-contravention-detail-information>
                                <hr *ngIf="!isLast">
                            </div>      
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" data-toggle="collapse" href="#divOccurrenceInformation">
                        <div>Occurrence Information</div>                                
                    </div>            
                    <div id="divOccurrenceInformation" class="collapse show">
                        <div class="card-body">                                   
                            <app-section-details sectionTitle="Occurrence Time & Location" [sectionDetails]="occurrenceInformation"></app-section-details>                                        
                            <app-section-details sectionTitle="Occurrence Information" [sectionDetails]="stopInformationDetails"></app-section-details>
                            <app-section-details sectionTitle="Location Conditions" *ngIf="isPhase2Submission" [sectionDetails]="locationConditions"></app-section-details>
                            <app-section-details sectionTitle="Witness Information" [sectionDetails]="witnessInformation"></app-section-details>
                            <div class="row" *ngIf="isPhase2Submission && recipient.recipientTypeId!=RecipientTypes.NotInVehicle">
                                <div class="col-12 col-sm-4">
                                    <div class="label">Passengers</div>
                                    <div class="value">{{stopInformation?.isPassengerInVehicle? 'Yes': 'No'}}</div>
                                </div>
                                <ng-container *ngIf="stopInformation?.isPassengerInVehicle">
                                    <div class="col-12 col-sm-4">
                                        <div class="label">Passenger Positions</div>
                                        <div class="value pl-2">
                                            <app-passenger-position [stopInformation]="stopInformation" [isReadOnly]="true"></app-passenger-position>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="label">Additional Passenger Location Details</div>
                                        <div class="value">{{stopInformation?.vehicleOccupantsNotes}}</div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row" *ngIf="isBodycamVideoActive">
                                <div class="col-12 col-sm-4">
                                    <div class="label">Is there any video in relation to this event?</div>
                                    <div class="value" *ngIf="stopInformation?.hasBodycamVideo == null; else bodycamVideoHasValue">-</div>
                                    <ng-template #bodycamVideoHasValue>
                                        <div class="value">{{stopInformation?.hasBodycamVideo? 'Yes': 'No'}}</div>
                                    </ng-template>
                                </div>
                            </div>

                            <app-section-details sectionTitle="Officer Information" [sectionDetails]="officerInformation"></app-section-details>
                            <app-section-details sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>

                            <app-section-details sectionTitle="Contravention Grounds" [sectionDetails]="contraventionGrounds"></app-section-details>
                            <app-section-details sectionTitle="Impairment Screening Information" [sectionDetails]="impairmentScreeningInformation"></app-section-details>
                            <app-section-details sectionTitle="Impairment Screening Details" [sectionDetails]="impairmentScreeningDetails"></app-section-details>
                            <div *ngFor="let additionalTest of additionalScreeningInformation; let index = index;">
                                <app-section-details sectionTitle="Additional Screening Method/Evidentiary Test {{index+1}}" [sectionDetails]="additionalTest"></app-section-details>
                            </div>
                            <app-section-details sectionTitle="Reasonable Grounds to Believe" [sectionDetails]="reasonableGrounds"></app-section-details>
                            <app-section-details *ngIf="irsContravention?.roadsideAppeal" sectionTitle="Roadside Appeal Information" [sectionDetails]="roadsideAppealInformation"></app-section-details>
                            <app-section-details *ngIf="irsContravention?.roadsideAppeal" sectionTitle="Roadside Appeal Details" [sectionDetails]="roadsideAppealDetails"></app-section-details>
                            <app-section-details *ngIf="stopInformation.isSpeedingSelected" sectionTitle="Speeding Contravention Grounds" [sectionDetails]="speedingInformation"></app-section-details>
                            <app-section-details *ngIf="stopInformation.isSpeedingSelected" sectionTitle="Instrument Type & Testing" [sectionDetails]="speedingDeviceInformation"></app-section-details>
                        </div>
                    </div>
                </div>

                <div class="card" *ngIf="isRecipientSectionVisible">
                    <div class="card-header" data-toggle="collapse" href="#divRecipientInformation">
                        <div>Recipient Information</div>                                
                    </div>            
                    <div id="divRecipientInformation" class="collapse show">
                        <div class="card-body">                    
                            <label *ngIf="this.recipient.movesLookupDate">The following recipient information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient.movesLookupDate | date:'mediumDate'}} at {{recipient.movesLookupDate | date:'HH:mm'}}</label>
                            <app-section-details sectionTitle="Recipient Information" [sectionDetails]="recipientInformation"></app-section-details>
                            <app-section-details sectionTitle="Identification Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>

                            <ng-container *ngIf="this.recipient?.movesLookupDate && this.recipientContact?.isAddressDifferentFromMOVES">
                                <app-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="movesRecipientContactInformation"></app-section-details>
                                <label>The following contact information was provided by the recipient to the officer</label>
                                <app-section-details sectionTitle="" [sectionDetails]="recipientContactInformation"></app-section-details>    
                            </ng-container>

                            <ng-container *ngIf="(this.recipient?.movesLookupDate && !this.recipientContact?.isAddressDifferentFromMOVES) || !this.recipient?.movesLookupDate">
                                <app-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="recipientContactInformation"></app-section-details>
                            </ng-container>
                        </div>
                    </div>
                </div>        

                <div class="card" *ngIf="isVehicleSectionVisible">
                    <div class="card-header" data-toggle="collapse" href="#divVehicleDetails">
                        <div>Vehicle Details</div>                                
                    </div>            
                    <div id="divVehicleDetails" class="collapse show">
                        <div class="card-body">                    
                            <label *ngIf="this.vehicle.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>               
                    
                            <ng-container *ngIf="this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate">
                                <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-section-details>
                                <label>The information above did not match the vehicle. The following information is a description of the vehicle involved in the contravention.</label>
                                <app-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-section-details>
                            </ng-container>
        
                            <ng-container *ngIf="(this.vehicle?.movesLookupDate && !this.vehicle?.isStolenPlate) || !this.vehicle?.movesLookupDate">
                                <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                            </ng-container>
                            <app-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-section-details>

                            <app-section-details *ngIf="!isChangeDisplayedInformationActive && vehicle?.registeredOwner?.isSameAsDriver" sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-section-details>
                            <app-section-details *ngIf="recipient.recipientTypeId==RecipientTypes.Passenger" sectionTitle="Driver Information" [sectionDetails]="vehicleDriverInformation"></app-section-details>
                        </div>
                    </div>
                </div>

                <div class="card" *ngIf="isSupportingDocumentsSectionVisible">
                    <div class="card-header" data-toggle="collapse" href="#collapse2">
                        Supporting Documents
                    </div>
                    <div id="collapse2" class="card-body mx-3 collapse show desktop-callout">
                        <div class="row py-2 border-bottom">
                            <ng-container *ngIf="showPublishedDate; else showUploadDateHeader">
                                <ng-container *ngIf="showDocumentSubmitter; else hideDocumentSubmitter">
                                    <div class="col-3 pl-0 font-weight-bold">Type</div>
                                    <div class="col-2 font-weight-bold">Filename</div>
                                    <div class="col-2 font-weight-bold">Published Date</div>
                                    <div class="col-2 font-weight-bold">Upload Date</div>
                                    <div class="col-2 font-weight-bold">Submitter</div>
                                    <div class="col-1 font-weight-bold">File Size</div>
                                </ng-container>
                                <ng-template #hideDocumentSubmitter>
                                    <div class="col-4 pl-0 font-weight-bold">Type</div>
                                    <div class="col-4 font-weight-bold">Filename</div>
                                    <div class="col-2 font-weight-bold">Published Date</div>
                                    <div class="col-2 font-weight-bold">Upload Date</div>
                                </ng-template>
                            </ng-container>
                            <ng-template #showUploadDateHeader>
                                <div class="col-4 pl-0 font-weight-bold">Type</div>
                                <div class="col-6 font-weight-bold">Filename</div>
                                <div class="col-2 font-weight-bold">Date</div>
                            </ng-template>
                        </div>
                        <div *ngFor="let document of supportingDocuments">
                            <div class="row py-3 border-bottom doc-content">
                                <ng-container *ngIf="showPublishedDate; else showUploadDateHeader">
                                    <ng-container *ngIf="showDocumentSubmitter; else hideDocumentSubmitter">
                                        <div class="col-3 pl-0">{{getDocumentTypeName(document)}}</div>
                                        <div class="col-2 text-truncate">
                                            <a class="btn-link doc-content" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                                {{document.documentName}}
                                            </a>
                                        </div>
                                        <div class="col-2 pr-0 doc-content">
                                            {{document.publishedDate | date:'mediumDate' }}
                                        </div>
                                        <div class="col-2 pr-0 doc-content">
                                            {{document.uploadedDate | date:'mediumDate' }}
                                        </div>
                                        <div class="col-2">{{document.uploadedBy}}</div>
                                        <div class="col-1">{{document.documentSize}}</div>
                                    </ng-container>
                                    <ng-template #hideDocumentSubmitter>
                                        <div class="col-4 pl-0">{{getDocumentTypeName(document)}}</div>
                                        <div class="col-4 text-truncate">
                                            <a class="btn-link doc-content" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                                {{document.documentName}}
                                            </a>
                                        </div>
                                        <div class="col-2 pr-0 doc-content">
                                            {{document.publishedDate | date:'mediumDate' }}
                                        </div>
                                        <div class="col-2 pr-0 doc-content">
                                            {{document.uploadedDate | date:'mediumDate' }}
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <ng-template #showUploadDateHeader>
                                    <div class="col-4 pl-0">{{getDocumentTypeName(document)}}</div>
                                    <div class="col-6 text-truncate">
                                        <a class="btn-link doc-content" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                            {{document.documentName}}
                                        </a>
                                    </div>
                                    <div class="col-2 pr-0 doc-content"
                                    *ngIf="document.documentTypeId == DocumentTypes.WitnessStatements && document.publishedDate != null; else showUploadDate">
                                    {{document.publishedDate | date:'mediumDate' }}
                                    </div>
                                    <ng-template #showUploadDate>
                                        <div class="col-2 pr-0 doc-content">
                                        {{document.uploadedDate | date:'mediumDate' }}
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div id="collapse2" class="card-body mx-3 collapse show mobile-callout p-0">
                        <div *ngFor="let document of supportingDocuments">
                            <div class="row py-3 border-bottom doc-content">
                                <div class="col-12 label">Type</div>                                
                                <div class="col-12 text-wrap">
                                    {{getDocumentTypeName(document)}}
                                </div>
                                <div class="col-12 label">Filename</div>
                                <div class="col-12 text-truncate text-wrap">
                                    <a class="btn-link" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                        {{document.documentName}}
                                    </a>
                                </div>
                                
                                <ng-container *ngIf="showPublishedDate; else showUploadDateHeader">
                                    <div class="col-12 label">Published Date</div>
                                    <div class="col-12">
                                        {{document.publishedDate | date:'mediumDate' }}
                                    </div>

                                    <div class="col-12 label">Upload Date</div>
                                    <div class="col-12">
                                        {{document.uploadedDate | date:'mediumDate' }}
                                    </div>
                                </ng-container>
                                <ng-template #showUploadDateHeader>
                                    <div class="col-12 label">Date</div>
                                    <div class="col-12" 
                                    *ngIf="document.documentTypeId == DocumentTypes.WitnessStatements && document.publishedDate != null; else showUploadDate">
                                        {{document.publishedDate | date:'mediumDate' }}
                                    </div>
                                    <ng-template #showUploadDate>
                                        <div class="col-12">
                                            {{document.uploadedDate | date:'mediumDate' }}
                                        </div>
                                    </ng-template>
                                </ng-template>
                                <ng-container *ngIf="showDocumentSubmitter">
                                    <div class="col-12 label">Submitter</div>
                                    <div class="col-12">
                                        {{document.uploadedBy }}
                                    </div>
                                    <div class="col-12 label">File Size</div>
                                    <div class="col-12">
                                        {{document.documentSize }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" *ngIf="showSupportingDocumentDeclarations && isSupportingDocumentDeclarationsSectionVisible">
                    <div class="card-header" data-toggle="collapse" href="#divSupportingDocumentDeclarations">
                        <div>Supporting Document Declarations</div>                                
                    </div>            
                    <div id="divSupportingDocumentDeclarations" class="collapse show">
                        <div class="card-body">                    
                            <app-section-details sectionTitle="" [sectionDetails]="supportingDocumentDeclarations"></app-section-details>
                        </div>
                    </div>
                </div>

                <div class="card" *ngIf="isPoliceServiceSectionVisible">
                    <div class="card-header" data-toggle="collapse" href="#divPoliceServiceInformation">
                        <div>Police Service Information</div>                                
                    </div>            
                    <div id="divPoliceServiceInformation" class="collapse show">
                        <div class="card-body">                    
                            <app-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-section-details>
                            <app-section-details sectionTitle="" [sectionDetails]="officerDeclarations"></app-section-details>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-self-start">
                <div>
                    <button class="goa-button goa--secondary btn-block" [routerLink]="['../']">Back to Contravention Overview</button>
                </div>
            </div>
        </div>
    </ng-container>
</app-contravention-page>

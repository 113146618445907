import { DeclarationTypes } from './../../../../../../libs/shared/enums/app.enum';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { APP_CONFIG } from '@apis/app-config';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ResourceLoader } from '@angular/compiler';

@Injectable()
export class LookupService {
  private readonly apiUrl = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;

  constructor(private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private appConfig: any) { }

  getLookups(fields?: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/lookups`,
      {
        params: {
          "fields": fields
        }
      });
  }

  getHolidayList(): void {
    this.httpClient.get(`${this.apiUrl}/lookups/holidays`,
    {
      params: {
      }
    }).subscribe((result: any) => {
      this.localStorageService.setHolidayList(result);
    });
  }

  getTermsAndConditionsList(): void {
    this.httpClient.get(`${this.apiUrl}/lookups/terms-conditions`,
    {
      params: {
      }
    }).subscribe((result: any) => {
      this.localStorageService.setTermsAndConditionsList(result);
    });
  }

  setLookups(fields?: string): void {
    let params = null;
    if (fields != null && fields.length > 0) {
      params = { "fields": fields }
    }

    this.httpClient.get(`${this.apiUrl}/lookups`, { params: params })
      .subscribe((result: any) => {
        if (result.circumstanceTypes != null) { this.localStorageService.setCircumstanceTypes(result.circumstanceTypes); }
        if (result.clientTypes != null) { this.localStorageService.setClientTypes(result.clientTypes); }
        if (result.contraventionDetailTypes != null) { this.localStorageService.setContraventionDetailTypes(result.contraventionDetailTypes); }
        if (result.contraventionGroundsTypes != null) { this.localStorageService.setContraventionGroundsTypes(result.contraventionGroundsTypes); }
        if (result.contraventionStatusTypes != null) { this.localStorageService.setContraventionStatusTypes(result.contraventionStatusTypes); }
        if (result.contraventionTypes != null) { this.localStorageService.setContraventionTypes(result.contraventionTypes); }
        if (result.countryTypes != null) { this.localStorageService.setCountryTypes(result.countryTypes); }
        if (result.documentTypes != null) { this.localStorageService.setDocumentTypes(result.documentTypes); }
        if (result.driverDeclinedToTestTypes != null) { this.localStorageService.setDriverDeclinedToTestTypes(result.driverDeclinedToTestTypes); }
        if (result.driversLicenceClassTypes != null) { this.localStorageService.setDriversLicenceClassTypes(result.driversLicenceClassTypes); }
        if (result.driversLicenceStatusTypes != null) { this.localStorageService.setDriversLicenceStatusTypes(result.driversLicenceStatusTypes); }
        if (result.genderTypes != null) { this.localStorageService.setGenderTypes(result.genderTypes); }
        if (result.impairmentScreeningTypes != null) { this.localStorageService.setImpairmentScreeningTypes(result.impairmentScreeningTypes); }
        if (result.labTypes != null) { this.localStorageService.setLabTypes(result.labTypes); }
        if (result.locationTypes != null) { this.localStorageService.setLocationTypes(result.locationTypes); }
        if (result.observedByTypes != null) { this.localStorageService.setObservedByTypes(result.observedByTypes); }
        if (result.provinceTypes != null) { this.localStorageService.setProvinceTypes(result.provinceTypes); }
        if (result.seizureTypes != null) { this.localStorageService.setSeizureTypes(result.seizureTypes); }
        if (result.suspectedImpairmentTypes != null) { this.localStorageService.setSuspectedImpairmentTypes(result.suspectedImpairmentTypes); }
        if (result.suspicionTypes != null) { this.localStorageService.setSuspicionTypes(result.suspicionTypes); }
        if (result.testModelTypes != null) { this.localStorageService.setTestModelTypes(result.testModelTypes); }
        if (result.testResultTypes != null) { this.localStorageService.setTestResultTypes(result.testResultTypes); }
        if (result.testTypes != null) { this.localStorageService.setTestTypes(result.testTypes); }
        if (result.vehicleRegistrationStatusTypes != null) { this.localStorageService.setVehicleRegistrationStatusTypes(result.vehicleRegistrationStatusTypes); }
        if (result.contactMethodTypes != null) { this.localStorageService.setContactMethodTypes(result.contactMethodTypes); }
        if (result.incomeLevelTypes != null) { this.localStorageService.setIncomeLevelTypes(result.incomeLevelTypes); }
        if (result.requestStatusTypes != null) { this.localStorageService.setRequestStatusTypes(result.requestStatusTypes); }
        if (result.reviewStatusTypes != null) { this.localStorageService.setReviewStatusTypes(result.reviewStatusTypes); }
        if (result.representationTypes != null) { this.localStorageService.setRepresentationTypes(result.representationTypes); }
        if (result.reviewMethodTypes != null) { this.localStorageService.setReviewMethodTypes(result.reviewMethodTypes); }
        if (result.seizureStatusTypes != null) { this.localStorageService.setSeizureStatusTypes(result.seizureStatusTypes); }
        if (result.vehicleInterestTypes != null) { this.localStorageService.setVehicleInterestTypes(result.vehicleInterestTypes); }
        if (result.decisionTypes != null) { this.localStorageService.setDecisionTypes(result.decisionTypes); }
        if (result.requestDecisionTypes != null) { this.localStorageService.setRequestDecisionTypes(result.requestDecisionTypes); }
        if (result.reviewTypes != null) { this.localStorageService.setReviewTypes(result.reviewTypes); }
        if (result.requestTypes != null) { this.localStorageService.setRequestTypes(result.requestTypes); }
        if (result.agencies != null) { this.localStorageService.setAgencies(result.agencies); }
        if (result.detachments != null) { this.localStorageService.setDetachments(result.detachments); }
        if (result.reServeMethodTypes != null) { this.localStorageService.setReServeMethodTypes(result.reServeMethodTypes); }
        if (result.availabilityTypes != null) { this.localStorageService.setAvailabilityTypes(result.availabilityTypes); }
        if (result.meetingTypes != null) { this.localStorageService.setMeetingTypes(result.meetingTypes); }
        if (result.driverDeclinedToTestTypes != null) {this.localStorageService.setDriverDeclinedToTestTypes(result.driverDeclinedToTestTypes);}
        if (result.speedingOffences != null) {this.localStorageService.setSpeedingOffences(result.speedingOffences);}
        if (result.identificationTypes != null) {this.localStorageService.setIdentificationTypes(result.identificationTypes);}
        if (result.issueServiceTypes != null) {this.localStorageService.setIssueServiceTypes(result.issueServiceTypes);}
        if (result.officerVehicleTypes != null) {this.localStorageService.setOfficerVehicleTypes(result.officerVehicleTypes);}
        if (result.officerTransportationTypes != null) {this.localStorageService.setOfficerTransportationTypes(result.officerTransportationTypes);}
        if (result.officerUniformTypes != null) {this.localStorageService.setOfficerUniformTypes(result.officerUniformTypes);}
        if (result.speedingDeviceTypes != null) {this.localStorageService.setSpeedingDeviceTypes(result.speedingDeviceTypes);}
        if (result.speedingDeviceMakeTypes != null) {this.localStorageService.setSpeedingDeviceMakeTypes(result.speedingDeviceMakeTypes);}
        if (result.speedingDeviceModelTypes != null) {this.localStorageService.setSpeedingDeviceModelTypes(result.speedingDeviceModelTypes);}
        if (result.contraventionTypeMappings != null) {this.localStorageService.setContraventionTypeMappings(result.contraventionTypeMappings);}
        if (result.roadsideAppealOutcomeTypes != null) { this.localStorageService.setRoadsideAppealOutcomeTypes(result.roadsideAppealOutcomeTypes); }
        if (result.noticeCancellationReasonTypes != null) { this.localStorageService.setNoticeCancellationReasonTypes(result.noticeCancellationReasonTypes); }
        if (result.roadConditionTypes != null) { this.localStorageService.setRoadConditionTypes(result.roadConditionTypes); }
        if (result.roadSurfaceTypes != null) { this.localStorageService.setRoadSurfaceTypes(result.roadSurfaceTypes); }
        if (result.weatherConditionTypes != null) { this.localStorageService.setWeatherConditionTypes(result.weatherConditionTypes); }
        if (result.visibilityConditionTypes != null) { this.localStorageService.setVisibilityConditionTypes(result.visibilityConditionTypes); }
        if (result.directionTypes != null) { this.localStorageService.setDirectionTypes(result.directionTypes); }
        if (result.conditionCodeTypes != null) { this.localStorageService.setConditionCodeTypes(result.conditionCodeTypes); }
        if (result.vehicleStatusTypes != null) { this.localStorageService.setVehicleStatusTypes(result.vehicleStatusTypes); }
        if (result.declarationTypes != null) { this.localStorageService.setDeclarationTypes(result.declarationTypes); }
        if (result.reviewOutcomeTypes != null) { this.localStorageService.setReviewOutcomeTypes(result.reviewOutcomeTypes); }
        if (result.highwayCharacteristicsTypes != null) { this.localStorageService.setHighwayCharacteristicsTypes(result.highwayCharacteristicsTypes); }
        if (result.courtTypes != null) { this.localStorageService.setCourtTypes(result.courtTypes); }
        if (result.offenceTypes != null) { this.localStorageService.setOffenceTypes(result.offenceTypes); }
      });
  }
}


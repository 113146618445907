<div class="card mt-4">
    <div class="card-header">
        Registered Owner Information
    </div>
    <div class="card-body" *ngIf="registeredOwner">
        <div class="form-group row">
            <div class="col-lg-4">
                <label for="registeredOwnerSurname">Surname</label>
                <input type="text" readonly class="form-control" id="registeredOwnerSurname" 
                    [value]="registeredOwner.lastName">
            </div>

            <div class="col-lg-4">
                <label for="registeredOwnerFirstName">First Name</label>
                <input type="text" readonly class="form-control" id="registeredOwnerFirstName"
                    [value]="registeredOwner.firstName">
            </div>

            <div class="col-lg-4">
                <label for="registeredOwnerMiddleName">Middle Name</label>
                <input type="text" readonly class="form-control" id="registeredOwnerMiddleName"
                    [value]="registeredOwner.otherName">
            </div>				
        </div>
        <div class="form-group row">
            <div class="col-lg-4">
                <label for="registeredOwnerLicenceNumber">Licence Number</label>
                <input type="text" readonly class="form-control" id="registeredOwnerLicenceNumber" 
                    [value]="registeredOwner.driverLicenceNumber">
            </div>

            <div class="col-lg-4">
                <label for="dateOfBirth">Date of Birth</label>
                <input type="text" readonly class="form-control" id="dateOfBirth"
                    [value]="registeredOwner.dateOfBirth | date:'MM/dd/yyyy'">
            </div>
        </div>
    </div>

    <div class="card-body" *ngIf="!registeredOwner">
        <div class="form-group row">
            <div class="col-lg-4">
                <label for="registeredOwner">Registered Owner</label>
                <input type="text" readonly class="form-control" id="registeredOwner" 
                    value="Unable to determine">
            </div>
        </div>
    </div>
</div>
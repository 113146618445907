import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentDetailsComponent } from '@apis/shared/components/payment-details/payment-details.component';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { PaymentBaseComponent } from '../payment-base.component';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContraventionPageComponent } from '../../contravention-page/contravention-page.component';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent extends PaymentBaseComponent
  implements OnInit {
  @ViewChild('paymentDetails') paymentDetails: PaymentDetailsComponent;
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;
  
  confirmEmailAddress: string;

  constructor(
              router: Router,
              public route: ActivatedRoute,
              localStorage: LocalStorageService
  ) {
    super(router, route, localStorage);
  }

  ngOnInit(): void { }

  continuePayment(isValid) {
    this.masterPage.showSpinner();
    this.paymentDetails.createToken().subscribe(tokenResult => {
      if (isValid && tokenResult) {
        this.paymentRequest.cardLastFour = tokenResult.last4;
        this.paymentRequest.paymentToken = tokenResult.token;
        
        this.SaveState();
        this.router.navigate(['../review'], {relativeTo: this.route });
      } else {
        this.masterPage.hideSpinner();
      }
    });
  }
}

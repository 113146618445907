import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Representation } from '@apis/shared/models/representation.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { PaymentBaseComponent } from '../../../../contravention/payment/payment-base.component';
import { RepresentationTypes } from '@apis/shared/enums/app.enum';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-rsg-payment-information',
  templateUrl: './rsg-payment-information.component.html',
  styleUrls: ['./rsg-payment-information.component.scss'],
})
export class RsgPaymentInformationComponent extends PaymentBaseComponent
  implements OnInit {

  rsgAgentProfile: RsgAgentProfile;
  registryAgentInformation: RegistryAgentInformation;

  errorMessage = '';

  constructor(
    router: Router,
    public route: ActivatedRoute,
    localStorage: LocalStorageService,
    public titleCasePipe: TitleCasePipe
  ) {
    super(router, route, localStorage);
  }

  ngOnInit(): void {
    this.rsgAgentProfile = this.localStorage.getRegistryAgentProfile();

    if (!this.rsgAgentProfile) {
      this.router.navigate(['/unauthorized']);
    }

    // Create a new RegistryAgentInformation object, or load the old one if it exists
    if (this.paymentRequest.registryAgentInformation.length === 0) {
      this.registryAgentInformation = new RegistryAgentInformation();
      this.registryAgentInformation.userName = this.rsgAgentProfile.userName;
      this.registryAgentInformation.organizationCode = this.rsgAgentProfile.organization;
    }
    else {
      this.registryAgentInformation = this.paymentRequest.registryAgentInformation[0];
    }

    this.paymentRequest.isSelfSubmit = true; // We do not create representatives for registry payments
    this.paymentRequest.transactionId = this.rsgAgentProfile.transactionId;
    this.paymentRequest.paymentAmount = this.paymentRequest.amountDue;

    this.paymentRequest.lineItems.forEach(li => {
      //Check which contravention payment is required (only one is allowed in registry portal)
      li.isChecked = li.recordNumber == this.paymentRequest.recordNumber;
    });
  }

  continuePayment(isValid) {
    this.errorMessage = '';
    const atleastOneContraventionSelected = this.paymentRequest.lineItems.some(li => li.isChecked);
    if (!atleastOneContraventionSelected || this.getTotalPaymentAmount() <= 0) {
      this.errorMessage = 'Please select at least one contravention to proceed with the payment.';
      return;
    }

    if(isValid) {
      if (this.paymentRequest.registryAgentInformation.length === 0) {
        this.paymentRequest.registryAgentInformation.push(this.registryAgentInformation);
      }
      else {
        this.paymentRequest.registryAgentInformation[0] = this.registryAgentInformation;
      }

      this.paymentRequest.paymentAmount = this.getTotalPaymentAmount();
      this.SaveState();
      this.router.navigate(['../review'], { relativeTo: this.route });
    }
    else
      window.scroll(0, 0);
  }

  getContravention(recordNumber: string) {
    return this.contraventionOverview.contraventions.find(c => c.contraventionNumber == recordNumber);
  }

  getTotalPaymentAmount() {
    let totalPaymentAmount : number = 0;

    this.paymentRequest.lineItems?.forEach(li => {
      if (li.isChecked && +li.amount > 0) {
        totalPaymentAmount = totalPaymentAmount + (+li.amount);
      }
    })

    return totalPaymentAmount;
  }
}

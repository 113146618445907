


export class ContraventionInformation {
    public contraventionId: number;
    public contraventionNumber: string;
    public contraventionStatusTypeId: number = 0;
    public contraventionTypeId: number = 0;
    public secondaryContraventionTypeId: number;
    public tertiaryContraventionTypeId: number;
    public demerits: number;
    public fineAmount: number = 0;
    public victimFineSurchargeAmount: number = 0;
    public paidAmount: number = 0;
    public dueAmount: number = 0;
    public dueDate: Date = new Date();
    public occurrenceDate: Date;
    public isIssuedByAte: boolean = false;
    public isJudicialReviewServed: boolean = false;
    public isJudicialReviewDecisionPending: boolean = false;
    public licencePickupAddress: string;
    public licencePickupDetachment: string;
    public returnLicenceByMail: boolean = false;

    constructor(init?: Partial<ContraventionInformation>) {
        
        Object.assign(this, init);
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountdownTimerComponent } from '@apis/shared/components/countdown-timer/countdown-timer.component';
import { PaymentDetailsComponent } from '@apis/shared/components/payment-details/payment-details.component';
import { CountdownState } from '@apis/shared/enums/countdown-state.enum';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { ContraventionPageComponent } from '../../../contravention-page/contravention-page.component';
import { ReviewBaseComponent } from '../review-base.component';

@Component({
  selector: 'app-review-payment-method',
  templateUrl: './review-payment-method.component.html',
  styleUrls: ['./review-payment-method.component.scss']
})
export class ReviewPaymentMethodComponent extends ReviewBaseComponent
implements OnInit {
  @ViewChild('paymentDetails') paymentDetails: PaymentDetailsComponent;
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;
  @ViewChild('cd') countdown: CountdownTimerComponent;
  
  confirmEmailAddress: string;
  rsgAgentProfile: RsgAgentProfile;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public schedulerService: SchedulerService
    ) { 
    super(router, activatedRoute, localStorageService, schedulerService);
  }

  ngOnInit(): void {
    if (this.isRSGPortal)
    {
      this.rsgAgentProfile = this.localStorage.getRegistryAgentProfile();

      if (!this.rsgAgentProfile)
        this.router.navigate(['/unauthorized']);

      this.review.paymentRequest.transactionId = this.rsgAgentProfile.transactionId;
    }
  }

  continueRequest(isValid: boolean) {
    this.masterPage.showSpinner();
    this.paymentDetails.createToken().subscribe(tokenResult => {
      if (isValid && tokenResult) {
        this.review.paymentRequest.cardLastFour = tokenResult.last4;
        this.review.paymentRequest.paymentToken = tokenResult.token;
        
        this.saveContext();
        this.router.navigate(['../summary'], {relativeTo: this.activatedRoute });
      } else {
        this.masterPage.hideSpinner();
      }
    });
  }  

  continueRegistryRequest(isValid: boolean) {
    if (isValid) {
      this.saveContext();
      this.router.navigate(['../summary'], {relativeTo: this.activatedRoute });
    }
  }  

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistryService } from '../../../../shared/services/registry.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SeizureUserType } from '@apis/shared/enums/app.enum';

@Component({
  selector: 'app-rsg-vehicle-seizure-lookup',
  templateUrl: './rsg-vehicle-seizure-lookup.component.html',
  styleUrls: ['./rsg-vehicle-seizure-lookup.component.scss']
})
export class RsgVehicleSeizureLookupComponent implements OnInit {
  searching = false;
  isSeizureNotFound: boolean;
  isServerErr: boolean;
  errorMessage: string;
  captchaError: string;
  seizureNumber: string;
  
  constructor(private router: Router,
      private route: ActivatedRoute,
      private readonly spinner: NgxSpinnerService,
      private registryService: RegistryService,      
      private localStorageService: LocalStorageService) {

       }

  ngOnInit(): void {
  }

  onSearchClick(isValid: boolean) {
    if (isValid) {
      // Reset all flags
      this.isSeizureNotFound = false;
      this.isServerErr = false;

      this.spinner.show(); 
      
      var seizureNumber = `10000${this.seizureNumber}`;

      this.registryService.checkVehicleSeizureNumberAsync(seizureNumber)
      .subscribe(
        (result: any) => {
          this.isSeizureNotFound = !result;
          this.spinner.hide();
          if (result)
            this.localStorageService.setEnableLicencePickupModal(true);
            this.router.navigate(['/rsg/vehicle-seizures', seizureNumber]);

            if (result.requireUserTypeSelection) {
              this.router.navigate(
                [`/rsg/vehicle-seizures/${seizureNumber}/user-type`],
                { 
                  relativeTo: this.route,
                  queryParams: { seizuretype: result.seizureTypeId 
                } }); 
            } else {
              this.localStorageService.set(`${seizureNumber}_user_type`, 
                result.seizureUserType ? result.seizureUserType.toString() : SeizureUserType.Driver.toString());
              this.router.navigate(['/rsg/vehicle-seizures', seizureNumber]);
            }  
        },
        (error: any) => {
          if (error.status == 403 || error.status == 401) {
            this.localStorageService.logout();
            this.router.navigate(['/session-expired']);
          }
          else if (error.status == 404) {
            this.isSeizureNotFound = (error.error == 1);
          } else {
            this.isServerErr = true;
            this.errorMessage = "An error occurred while processing your request. Please try again.";
          }

          this.spinner.hide();
        });
      }
  }
}

import { AbstractControl, ValidatorFn } from '@angular/forms';

export function NoWhitespaceValidator(): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } => {

    let hasValue = (control.value || '').length > 0;
    let isWhitespace = hasValue && control.value.trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true }

  };
}
<div class="card mt-4">
    <div class="card-header">
        Applicant Information
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="col-lg-4">
                <label for="applicantSurname" class="required">Surname</label>
                <input type="text" class="form-control" id="applicantSurname" name="applicantSurname"
                    [disabled]="disabled"
                    required #applicantSurname="ngModel" [(ngModel)]="model.lastName"
                    [ngClass]="{ 'is-invalid': isSubmitClicked && applicantSurname.invalid }">
                <div class="invalid-feedback">
                    <div>Surname is required</div>
                </div>
            </div>

            <div class="col-lg-4">
                <label for="applicantFirstName" class="required">First Name</label>
                <input type="text" class="form-control" id="applicantFirstName" name="applicantFirstName"
                    [disabled]="disabled"
                    required #applicantFirstName="ngModel" [(ngModel)]="model.firstName"
                    [ngClass]="{ 'is-invalid': isSubmitClicked && applicantFirstName.invalid }">
                <div class="invalid-feedback">
                    <div>First Name is required</div>
                </div>
            </div>

            <div class="col-lg-4">
                <label for="applicantMiddleName">Middle Name</label>
                <input type="text" class="form-control" id="applicantMiddleName" name="applicantMiddleName"
                    [disabled]="disabled"
                    #applicantMiddleName="ngModel" [(ngModel)]="model.otherName">
            </div>				
        </div>

        <div class="form-group row">
            <div class="col-lg-6">
                <label for="vehicleInterest" class="required">What is Your Interest in the Vehicle?</label>
                <select class="form-control" id="vehicleInterest" name="vehicleInterest" required
                    [disabled]="disabled"
                    [(ngModel)]="model.vehicleInterestTypeId" (change)="onVehicleInterestTypeChange()"
                    #vehicleInterest="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && vehicleInterest.invalid }">
                    <option [ngValue]="null">Select an Option</option>
                    <option *ngFor="let vehicleInterestType of vehicleInterestTypes" 
                        [value]="vehicleInterestType.id">{{vehicleInterestType.name}}</option>
                </select>
                <div class="invalid-feedback">
                    <div>Vehicle Interest is required</div>
                </div>
            </div>
            <div class="col-lg-6" *ngIf="model.vehicleInterestTypeId == 4">
                <label for="otherInterestType" class="required">Specify ‘Other Interest’ Type</label>
                <input type="text" class="form-control" id="otherInterestType" name="otherInterestType"
                    [disabled]="disabled"
                    required #otherInterestType="ngModel" [(ngModel)]="model.otherInterestType"
                    [ngClass]="{ 'is-invalid': isSubmitClicked && otherInterestType.invalid }">
                <div class="invalid-feedback">
                    <div>Other Interest Type is required</div>
                </div>
            </div>
        </div>
    </div>
</div>
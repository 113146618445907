import { addMinutes } from 'date-fns';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { ReviewMethodTypes } from '@apis/shared/enums/app.enum';
import { DriverBaseComponent } from '../../../../driver-base.component';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { DatePipe } from '@angular/common';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-submission-complete',
  templateUrl: './submission-complete.component.html',
  styleUrls: ['./submission-complete.component.scss']
})
export class SubmissionCompleteComponent extends DriverBaseComponent
 implements OnInit {

  // Placeholder values, to be replaced with localstorage review
  public review = {
    reviewNumber: '',
    contraventionNumber: '',
    reviewMethodTypeId: 1,
    reviewTime: '',
    reviewDate: null,
    videoConferenceUrl: ''
  };

  reviewDetails: SectionDetailsModel[];

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private datePipe: DatePipe,
    private contraventionService: ContraventionService,
    private spinner: NgxSpinnerService
    ) {
      super(router);
    }

  ngOnInit(): void {
    const createdReview = this.localStorageService.getCreatedReview();
    const reviewRequest = this.localStorageService.getReviewRequest();
    var reviewMethodTypes = this.localStorageService.getReviewMethodTypes();

    // Clear review request session from localstorage
    this.localStorageService.setReviewRequest(null);

    if (createdReview) {
      this.review = createdReview;
    }
    else if (reviewRequest) {
      this.review = reviewRequest;

      // If we are in the driver portal, setup localStorage for supporting-documents
      if (!this.isRSGPortal) {
        this.localStorageService.setCreatedReview(this.review);
      }
    }
    else {
      // If createdReview and reviewRequest are null, navigate to contravention-details
      this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
    }

    // Review Details
    if (this.review.reviewMethodTypeId == ReviewMethodTypes.Oral) {
      this.reviewDetails = [];

      this.reviewDetails.push(new SectionDetailsModel('Review Method', `${reviewMethodTypes.find(r => r.id == ReviewMethodTypes.Oral).name} Review`));

      var timeTokens = this.review.reviewTime.split(':');
      var scheduleDate = new Date(this.review.reviewDate);
      var reviewDate = new Date(scheduleDate.setHours(+timeTokens[0], +timeTokens[1], +timeTokens[2], 0));

      this.reviewDetails.push(new SectionDetailsModel('Date', this.datePipe.transform(this.review.reviewDate, 'EEEE, MMMM d, y', 'UTC')));
      this.reviewDetails.push(new SectionDetailsModel('Time (MST)', `${this.datePipe.transform(reviewDate, 'h:mm a')} - ${this.datePipe.transform(addMinutes(reviewDate, 30), 'h:mm a')}`));
      this.reviewDetails.push(new SectionDetailsModel("Video Conference Link", this.review.videoConferenceUrl, true));
    }
  }

  print() {
    window.print();
  }

  onPrintReceiptClick() {
    this.spinner.show();
    this.contraventionService
      .getReviewPaymentReceipt(
        this.review.contraventionNumber,
        this.review.reviewNumber)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Contravention Review - ${this.review.reviewNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }

  continueRequest() {
    this.router.navigate(['../supporting-documents'], { relativeTo: this.activatedRoute, });
  }
}

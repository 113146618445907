import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountdownTimerComponent } from '@apis/shared/components/countdown-timer/countdown-timer.component';
import { ReviewMethodTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { CountdownState } from '@apis/shared/enums/countdown-state.enum';
import { AvailabilityView } from '@apis/shared/models/availability-view';
import { AvailabilityInformation } from '@apis/shared/models/availability-information';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { addHours, parseJSON } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReviewBaseComponent } from '../review-base.component';
import { DateUtil } from '@apis/shared/helpers/date-util';

declare var $: any

@Component({
  selector: 'app-schedule-review',
  templateUrl: './schedule-review.component.html',
  styleUrls: ['./schedule-review.component.scss']
})
export class ScheduleReviewComponent extends ReviewBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('cd') private countdown: CountdownTimerComponent;

  availabilityView: AvailabilityView;

  availabilityViewForWritten: AvailabilityInformation[];

  selAvailableInfoForWritten: AvailabilityInformation;

  datePipe: DatePipe = new DatePipe("en-US");

  originalReviewDate: Date;
  originalReviewTime: string;

  documentSubmissionCutoffDate: Date;

  isAvailabilityRequestFailed: boolean;
  errorMessage: string;
  DateUtil = DateUtil;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public contraventionService: ContraventionService,
    private readonly spinner: NgxSpinnerService,
    public schedulerService: SchedulerService
  ) {
    super(router, activatedRoute, localStorageService, schedulerService);

    this.originalReviewDate = this.review.reviewDate ? new Date(this.review.reviewDate) : null;
    this.originalReviewTime = this.review.reviewTime;

    this.setDocumentSubmissionCutoffDate();
  }

  ngOnInit(): void {
    this.getReviewSchedule();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.countdown.state == CountdownState.Lapsed) {
        this.resetReviewSchedule();

      }
    });
  }

  getReviewSchedule() {
    this.spinner.show();
    this.isAvailabilityRequestFailed = false;

    this.contraventionService.getReviewAvailability(
      this.review.reviewItems[0].recordNumber,
      this.review.reviewTypeId,
      this.review.reviewMethodTypeId,
      this.review.complexity,
      this.review.scheduledEventId)
      .subscribe((result: AvailabilityView) => {
        this.availabilityView = result;
        this.availabilityViewForWritten = this.availabilityView?.availabilityInformationList?.filter(x => x.timeSlots.length > 0);
        if (this.review.reviewDate) {
          this.selAvailableInfoForWritten = this.availabilityViewForWritten.find(x => this.datePipe.transform(new Date(x.date),'dd MMM, yyyy') == this.datePipe.transform(new Date(this.review.reviewDate),'dd MMM, yyyy', 'UTC'));
        }
        this.spinner.hide();
      }, (error: any) => {
        this.spinner.hide();
        this.isAvailabilityRequestFailed = true;
      });
  }

  onDateSelected(availableInfo: AvailabilityInformation) {
    this.errorMessage = '';
    this.selAvailableInfoForWritten = availableInfo;

    this.review.reviewTime = availableInfo.timeSlots[0];
    this.review.reviewDate = parseJSON(availableInfo.date);

    this.setDocumentSubmissionCutoffDate();
  }

  onRefreshClick() {
    this.getReviewSchedule();
  }

  onScheduleDateSelected(e) {
    this.errorMessage = '';

    this.review.reviewTime = this.datePipe.transform(e, "HH:mm:ss", "UTC");
    this.review.reviewDate = new Date(e.setUTCHours(0, 0, 0, 0));

    this.setDocumentSubmissionCutoffDate();
  }

  onSchedulerRefresh() {
    this.getReviewSchedule();
  }

  resetReviewSchedule() {
    this.review.reviewDate = null;
    this.review.reviewTime = null;

    this.originalReviewDate = this.review.reviewDate;
    this.originalReviewTime = this.review.reviewTime;

    this.setDocumentSubmissionCutoffDate();
  }

  validate() {
    this.errorMessage = '';
    var isTimeslotSelected = this.review.reviewDate != null && this.review.reviewTime != null;

    if (!isTimeslotSelected) {
      if (this.review.reviewMethodTypeId == ReviewMethodTypes.Written) {
        this.errorMessage = 'Please select a date.'
      }
      else {
        this.errorMessage = 'Please select a timeslot.'
      }
      return false;
    }

    var isReservedTimeExpired = this.countdown.state == CountdownState.Lapsed;
    var isReviewScheduleChanged = (+this.originalReviewDate != +new Date(this.review.reviewDate)
      || this.originalReviewTime != this.review.reviewTime);

    if (isReservedTimeExpired && !isReviewScheduleChanged) {
      this.resetReviewSchedule();
      return false;
    }

    return true;
  }

  continueRequest(isValid: boolean) {
    isValid = isValid && this.validate();

    if (isValid) {
      var isReviewScheduleChanged = (+this.originalReviewDate != +new Date(this.review.reviewDate)
        || this.originalReviewTime != this.review.reviewTime);

      if (isReviewScheduleChanged) {
        this.reserveReviewSchedule();
      } else {
        this.saveContext();
        this.navigate();
      }
    }
  }

  navigate() {
    this.router.navigate(['../payment-method'], { relativeTo: this.activatedRoute, });
  }

  reserveReviewSchedule() {
    this.spinner.show();

    this.releaseEvent()
      .subscribe(res => {
        this.reserveEvent().subscribe((result: ScheduledEvent) => {
          if (result.scheduledEventId != 0) {
            this.review.scheduledEventId = result.scheduledEventId;
            this.review.scheduledEvent = Object.assign({}, result);

            this.countdown.beginCountdown(this.countdownKey);

            this.saveContext();

            this.navigate();
          }
          else {
            if (this.review.reviewMethodTypeId == ReviewMethodTypes.Written) {
              this.errorMessage = 'This date is no longer available, please select a different date.'
            }
            else {
              this.errorMessage = 'This timeslot is no longer available, please select a different timeslot.'
            }
            this.countdown.stopCountdown();
            this.resetReviewSchedule();
            this.getReviewSchedule();
          }
          this.spinner.hide();
        }, (error: any) => {
          if (this.review.reviewMethodTypeId == ReviewMethodTypes.Written) {
            this.errorMessage = 'This date is no longer available, please select a different date.'
          }
          else {
            this.errorMessage = 'This timeslot is no longer available, please select a different timeslot.'
          }

          this.countdown.stopCountdown();
          this.resetReviewSchedule();
          this.getReviewSchedule();

          this.spinner.hide();
        });
      }, (error: any) => {
        if (this.review.reviewMethodTypeId == ReviewMethodTypes.Written) {
          this.errorMessage = 'Error while releasing the date, please try again.'
        }
        else {
          this.errorMessage = 'Error while releasing the timeslot, please try again.'
        }
        this.spinner.hide();
      });
  }

  setDocumentSubmissionCutoffDate() {
    this.documentSubmissionCutoffDate = null;

    if (this.review.reviewDate) {
      var reviewDate = new Date(this.review.reviewDate);

      this.documentSubmissionCutoffDate = addHours(reviewDate, -72); // 3 days before review. Do not use addDays, because it will subtract 23 or 25 hours on a daylight savings change.
    }
  }
}

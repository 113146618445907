import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Review } from '@apis/shared/models/review.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DriverBaseComponent } from '../../../../driver-base.component';

@Component({
  selector: 'app-reschedule-complete',
  templateUrl: './reschedule-complete.component.html',
  styleUrls: ['./reschedule-complete.component.scss']
})
export class RescheduleCompleteComponent extends DriverBaseComponent implements OnInit {
  review: Review;
  contraventionNumber: string;
  reviewDateTime: string;
  reviewSequence: number;
  reviewTypeId: number;
  contraventionOverview: ContraventionOverview;

  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService) { super(router);}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
      this.reviewSequence = Number.parseInt(paramMap.get('reviewSequence'));
      this.reviewTypeId = Number.parseInt(paramMap.get('reviewType'));

      this.contraventionOverview = this.localStorageService.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      this.review = this.localStorageService.getItem<Review>(`Review_${this.reviewSequence}_${this.reviewTypeId}`);

      // TODO: Check if the review status is COMPLETE then navigate back to the contravention.
      if (this.review == null) {
        this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
      }
    });
  }

}

<!-- Driver Portal View -->
<app-contravention-page *ngIf="!isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
        <div class="d-flex flex-column">
            <div class="d-flex w-100 border-bottom pb-2 mb-2">
                <h1>Review Details</h1>
                <div class="countdown">
                    <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                </div>
            </div>

            <div class="card goa-callout goa--information my-2 desktop-callout" *ngIf="hasMultipleContraventions">
                <h6 class="mt-0">Select Contraventions to Review</h6>
                <p>
                    To review multiple contraventions, check the box next to the contravention number.
                </p>
            </div>

            <div class="card mt-3 mt-lg-3">
                <div class="card-header">
                    Representation Type
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label for="reviewType">Are you the Recipient of the Contravention Notice?</label>
                            <select class="form-control" id="reviewType" name="reviewType" 
                                [(ngModel)]="review.isSelfSubmit" (change)="onReviewTypeChange()">
                                <option [ngValue]="true">Yes, I am submitting this application on behalf of myself</option>
                                <option [ngValue]="false">No, I am submitting this application on behalf of another</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <app-representation *ngIf="!review.isSelfSubmit" [model]="review.representation"
                [isSubmitClicked]="requestForm.submitted"
                [hideRepresenationMessage]="review.reviewMethodTypeId == ReviewMethodTypes.Written"
                [isYouth]="contraventionOverview.isRecipientYouth"></app-representation>

            
            <div class="card mt-4" *ngIf="this.review.reviewTypeId == ReviewTypes.IrsReview">
                <div class="card-header">                    
                    Impaired Roadside Sanction (IRS) Contravention Review
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Contravention Number: {{this.review.reviewItems[0].recordNumber}}</label>
                            <div class="contravention-description">
                                {{getPrimaryCharge(this.review.reviewItems[0].recordNumber)}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="reviewMethod" class="required">Review Method</label>
                            <div class="ml-1 question-icon" data-toggle="modal" data-target="#aboutRreviewMethodModal">
                                <i class="far fa-question-circle fa-lg"></i>
                            </div>
                            <select class="form-control" id="reviewMethod" name="reviewMethod" required
                                [(ngModel)]="review.reviewMethodTypeId" (change)="onReviewMethodChange()"
                                #reviewMethod="ngModel" [ngClass]="{ 'is-invalid': requestForm.submitted && reviewMethod.invalid }">
                                <option [ngValue]="null">Select a Method</option>
                                <option *ngFor="let reviewMethodType of reviewMethodTypes" [ngValue]="reviewMethodType.id">{{reviewMethodType.name}}</option>
                            </select>
                            <div class="pl-2" *ngIf="review.reviewMethodTypeId == 2">
                                Oral reviews will be recorded by SafeRoads Alberta.
                            </div>
                            <div class="invalid-feedback">
                                <div>Review Method is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="hearingFeeAmount">Selected Review Fee Amount</label>
                            <input readonly type="text" class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == ReviewMethodTypes.Oral">
                        <div class="col-12">
                            <app-failed-attendance-declaration
                            [review]="review"
                            [isFormSubmitted]="requestForm.submitted"
                            [isRSGPortal]="isRSGPortal">
                            </app-failed-attendance-declaration>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="reviewGrounds" class="required">Grounds for Review</label>
                            <p class="mt-0 mb-1">
                                Financial hardship and inconvenience are not grounds for cancellation of your Notice of Administrative Penalty.
                                An adjudicator cannot consider arguments such as:
                            </p>
                            <ul class="mb-1">
                                <li>You do not have the financial means to pay for the fine and/or tow fees.</li>
                                <li>You need your vehicle to get to and from work.</li>
                                <li>You need your vehicle to take your kids to school or a family member to medical appointments.</li>
                            </ul>
                            <p class="mt-0 mb-2">
                                For more information on the grounds to cancel a Notice of Administrative Penalty, 
                                please refer to Section 4 of the <a href="https://kings-printer.alberta.ca/documents/Regs/2020_224.pdf" target="_blank">SafeRoads Alberta Regulation</a>.
                            </p>
                            <textarea class="form-control" id="reviewGrounds" name="reviewGrounds"
                                rows="6" maxlength="5000"
                                required noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="this.review.reviewItems[0].reviewGrounds"
                                [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                placeholder="Please Provide Your Reason For Requesting a Review (Max 5000 Characters)"></textarea>
                            <div class="invalid-feedback">
                                <div>Grounds for Review is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="additionalNotes">Additional Request Notes</label>
                            <textarea class="form-control" id="additionalNotes" name="additionalNotes"
                                rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                placeholder="Please Provide Any Additional Notes for Your Request (Max 5000 Characters)">
                            </textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="hasInterpreter" class="required">Will You Be Accompanied by an Interpreter?</label>
                            <div class="mt-2">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="accompainedYes" name="hasInterpreter"
                                        required #hasInterpreter="ngModel" [value]="true" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="custom-control-label" for="accompainedYes">Yes</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="accompainedNo" name="hasInterpreter"
                                        required [value]="false" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="custom-control-label" for="accompainedNo">No</label>
                                </div>
                                <div>
                                    <div class="text-danger" *ngIf="requestForm.submitted && hasInterpreter.errors?.required">Please indicate whether you will be accompanied by an interpreter</div>
                                </div>
                                <div>
                                    <p>
                                        You are permitted to bring an interpreter to the oral review if you require translation to the English language. You are responsible for securing an interpreter prior to the review and responsible for any costs incurred in obtaining an interpreter.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="this.review.reviewTypeId == ReviewTypes.TrafficReview || this.review.reviewTypeId == ReviewTypes.AteReview">
                <div class="card-header">                    
                    Traffic Contravention(s) Review
                </div>
                <div class="card-body">
                    <div *ngFor="let reviewItem of this.review.reviewItems; let i = index;" class="border-bottom mb-3">
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <div class="checkbox-lg">
                                    <input type="checkbox"
                                        *ngIf="this.review.reviewItems.length > 1"
                                        id="chkReviewItem_{{reviewItem.recordNumber}}"
                                        name="chkReviewItem_{{reviewItem.recordNumber}}"
                                        [(ngModel)]="reviewItem.isSelected"
                                        (change)="updateReviewFee()">
                                    <label for="chkReviewItem_{{reviewItem.recordNumber}}">Contravention Number: {{reviewItem.recordNumber}}</label>
                                </div>
                                <div class="contravention-description mt-2">
                                    {{getPrimaryCharge(reviewItem.recordNumber)}}
                                </div>
                                <div class="contravention-description mt-2">
                                    {{getSecondaryCharge(reviewItem.recordNumber)}}
                                </div>
                                <div class="contravention-description mt-2">
                                    {{getTertiaryCharge(reviewItem.recordNumber)}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <label for="reviewGrounds" class="required">Grounds for Review</label>
                                    </div>
                                </div>
                                <p class="mt-0 mb-1">
                                    Financial hardship and inconvenience are not grounds for cancellation of your Notice of Administrative Penalty.
                                    An adjudicator cannot consider arguments such as:
                                </p>
                                <ul class="mb-1">
                                    <li>You do not have the financial means to pay for the fine and/or tow fees.</li>
                                    <li>You need your vehicle to get to and from work.</li>
                                    <li>You need your vehicle to take your kids to school or a family member to medical appointments.</li>
                                </ul>
                                <p class="mt-0 mb-2">
                                    For more information on the grounds to cancel a Notice of Administrative Penalty, 
                                    please refer to Section 4 of the <a href="https://kings-printer.alberta.ca/documents/Regs/2020_224.pdf" target="_blank">SafeRoads Alberta Regulation</a>.
                                </p>
                                <textarea class="form-control" 
                                    id="reviewGrounds_{{reviewItem.recordNumber}}" 
                                    name="reviewGrounds_{{reviewItem.recordNumber}}"
                                    rows="6" maxlength="5000"                                    
                                    [required]="reviewItem.isSelected"
                                    noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="reviewItem.reviewGrounds"
                                    [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                    placeholder="Please Provide Your Reason For Requesting a Review (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Grounds for Review is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="reviewMethod" class="required">Review Method</label>
                            <div class="ml-1 question-icon" data-toggle="modal" data-target="#aboutRreviewMethodModal">
                                <i class="far fa-question-circle fa-lg"></i>
                            </div>
                            <select class="form-control" id="reviewMethod" name="reviewMethod" required
                                [(ngModel)]="review.reviewMethodTypeId"
                                #reviewMethod="ngModel" [ngClass]="{ 'is-invalid': requestForm.submitted && reviewMethod.invalid }">
                                <option [ngValue]="null">Select a Method</option>
                                <option *ngFor="let reviewMethodType of reviewMethodTypes" [ngValue]="reviewMethodType.id">{{reviewMethodType.name}}</option>
                            </select>
                            <div class="pl-2" *ngIf="review.reviewMethodTypeId == 2">
                                Oral reviews will be recorded by SafeRoads Alberta.
                            </div>
                            <div class="invalid-feedback">
                                <div>Review Method is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="hearingFeeAmount">Selected Review Fee Amount</label>
                            <input readonly type="text" class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                        </div>
                    </div>                    
                    <div class="form-group row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == ReviewMethodTypes.Oral">
                        <div class="col-12">
                            <app-failed-attendance-declaration
                            [review]="review"
                            [isFormSubmitted]="requestForm.submitted"
                            [isRSGPortal]="isRSGPortal">
                            </app-failed-attendance-declaration>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="additionalNotes">Additional Request Notes</label>
                            <textarea class="form-control" id="additionalNotes" name="additionalNotes"
                                rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                placeholder="Please Provide Any Additional Notes for Your Request (Max 5000 Characters)">
                            </textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="hasInterpreter" class="required">Will You Be Accompanied by an Interpreter?</label>
                            <div class="mt-2">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="accompainedYes" name="hasInterpreter"
                                        required #hasInterpreter="ngModel" [value]="true" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="custom-control-label" for="accompainedYes">Yes</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="accompainedNo" name="hasInterpreter"
                                        required [value]="false" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="custom-control-label" for="accompainedNo">No</label>
                                </div>
                                <div>
                                    <div class="text-danger" *ngIf="requestForm.submitted && hasInterpreter.errors?.required">Please indicate whether you will be accompanied by an interpreter</div>
                                </div>
                                <div>
                                    <p>
                                        You are permitted to bring an interpreter to the oral review if you require translation to the English language. You are responsible for securing an interpreter prior to the review and responsible for any costs incurred in obtaining an interpreter.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div>
                <span class="error">{{errorMessage}}</span>
            </div>
            
            <app-contact-information [model]="review" [isSubmitClicked]="requestForm.submitted"></app-contact-information>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../terms']">Return to Terms & Conditions</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Schedule Review</button>
                    </div>
                </div>
            </div>	
        </div>
        </form>
    </ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page *ngIf="isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
        <div class="d-flex flex-column">
            <h1>Review Details</h1>
            <div class="border-bottom my-2">
                <div class="row">
                    <div class="col-12">
                        <span>Notice(s)</span>
                        <h3 class="my-0">{{getContraventionsInReview()}}</h3>
                    </div>
                    <div class="countdown">
                        <countdown-timer [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                    </div>
                </div>
            </div>
           
            <div class="card goa-callout goa--information mt-0">
                <h6 class="mt-0">Collect Review Details</h6>
                <p>
                    The client can apply for a review if they wish to dispute their penalty. Collect the following information from the client to submit an application for review.
                </p>
            </div>

            <app-registry-agent-information [registryAgentInformation]="registryAgentInformation"></app-registry-agent-information>
    
            <app-registry-payer-information
            [registryAgentInformation]="registryAgentInformation"
            [isFormSubmitted]="requestForm.submitted"
            (requesterTypeChange)="onRequesterTypeChange($event)"
            sectionHeader="Requester"
            sectionLabel="Who is applying for review?">
            </app-registry-payer-information>

            <app-representation *ngIf="!review.isSelfSubmit" [model]="review.representation"
                [isSubmitClicked]="requestForm.submitted"
                [hideRepresenationMessage]="review.reviewMethodTypeId == ReviewMethodTypes.Written"
                [isYouth]="contraventionOverview.isRecipientYouth"
                [isRSGPortal]="isRSGPortal"
                [reviewTypeId]="review.reviewTypeId"
                [registryAgentInformation]="registryAgentInformation"
                [showSameAsRequester]="true"></app-representation>

            <div class="card mt-4">
                <div class="card-header">
                    Recipient Information
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="recipientSurname">Surname</label>
                            <input type="text" readonly class="form-control" id="recipientSurname" 
                                [value]="contraventionOverview.recipientLastName">
                        </div>
            
                        <div class="col-lg-4">
                            <label for="recipientFirstName">First Name</label>
                            <input type="text" readonly class="form-control" id="recipientFirstName"
                                [value]="contraventionOverview.recipientFirstName">
                        </div>
            
                        <div class="col-lg-4">
                            <label for="recipientMiddleName">Middle Name</label>
                            <input type="text" readonly class="form-control" id="recipientMiddleName"
                                [value]="contraventionOverview.recipientMiddleName">
                        </div>				
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="recipientEmail">Email Address</label>
                            <input type="text" class="form-control" id="recipientEmail" name="recipientEmail"
                                #recipientEmail="ngModel" [(ngModel)]="review.driverEmail" email
                                [ngClass]="{ 'is-invalid': requestForm.submitted && recipientEmail.invalid }">
                            <div class="invalid-feedback">
                                <div *ngIf="recipientEmail.errors?.required">Email Address is required</div>
                                <div *ngIf="recipientEmail.errors?.email">Please provide a valid email address</div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>

            <div class="card mt-4" *ngIf="this.review.reviewTypeId == ReviewTypes.IrsReview">
                <div class="card-header">                    
                    Impaired Roadside Sanction (IRS) Contravention Review
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Contravention Number: {{this.review.reviewItems[0].recordNumber}}</label>
                            <div class="contravention-description">
                                {{getPrimaryCharge(this.review.reviewItems[0].recordNumber)}}
                            </div>
                        </div>
                    </div>
                    <label class="w-100">Please ask the client to provide the following information:</label>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="reviewMethod" class="required">Review Method</label>
                            <div class="ml-1 question-icon" data-toggle="modal" data-target="#aboutRreviewMethodModal">
                                <i class="far fa-question-circle fa-lg"></i>
                            </div>
                            <select class="form-control" id="reviewMethod" name="reviewMethod" required
                                [(ngModel)]="review.reviewMethodTypeId" (change)="onReviewMethodChange()"
                                #reviewMethod="ngModel" [ngClass]="{ 'is-invalid': requestForm.submitted && reviewMethod.invalid }">
                                <option [ngValue]="null">Select a Method</option>
                                <option *ngFor="let reviewMethodType of reviewMethodTypes" [ngValue]="reviewMethodType.id">{{reviewMethodType.name}}</option>
                            </select>
                            <div class="pl-2" *ngIf="review.reviewMethodTypeId == 2">
                                Oral reviews will be recorded by SafeRoads Alberta.
                            </div>
                            <div class="invalid-feedback">
                                <div>Review Method is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="hearingFeeAmount">Selected Review Fee Amount</label>
                            <input readonly type="text" class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == ReviewMethodTypes.Oral">
                        <div class="col-12">
                            <app-failed-attendance-declaration
                            [review]="review"
                            [isFormSubmitted]="requestForm.submitted"
                            [isRSGPortal]="isRSGPortal">
                            </app-failed-attendance-declaration>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="reviewGrounds" class="required">Grounds for Review</label>
                            <p class="mt-0 mb-1">
                                Please advise the client that <strong>hardship is not a prescribed ground for review</strong>. If a recipient, lawyer, or agent submits an 
                                application for review and the <strong>only</strong> ground for review is that meeting the requirements of the Notice of Administrative 
                                Penalty will cause financial or other hardship to the recipient, the Notice of Administrative Penalty will be <strong>confirmed</strong>.
                            </p>
                            
                            <textarea class="form-control" id="reviewGrounds" name="reviewGrounds"
                                rows="6" maxlength="5000"
                                required noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="this.review.reviewItems[0].reviewGrounds"
                                [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                placeholder="The client must provide their reason for requesting a review (Max 5000 Characters)"></textarea>
                            <div class="invalid-feedback">
                                <div>Grounds for Review is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="additionalNotes">Additional Request Notes</label>
                            <textarea class="form-control" id="additionalNotes" name="additionalNotes"
                                rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                placeholder="The client may provide additional notes for the request (Max 5000 Characters)">
                            </textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="hasInterpreter" class="required">Will the client be accompanied by an interpreter?</label>
                            <p>
                                Please advise the client that they are permitted to bring an interpreter to the oral review if they require translation to the 
                                English language. They are responsible for securing an interpreter prior to the review and responsible for any costs incurred in obtaining an interpreter.
                            </p>
                            <div class="mt-2">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="accompainedYes" name="hasInterpreter"
                                        required #hasInterpreter="ngModel" [value]="true" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="custom-control-label" for="accompainedYes">Yes</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="accompainedNo" name="hasInterpreter"
                                        required [value]="false" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="custom-control-label" for="accompainedNo">No</label>
                                </div>
                                <div>
                                    <div class="text-danger" *ngIf="requestForm.submitted && hasInterpreter.errors?.required">Please indicate whether they will be accompanied by an interpreter</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="this.review.reviewTypeId == ReviewTypes.TrafficReview">
                <div class="card-header">                    
                    Traffic Contravention(s) Review
                </div>
                <div class="card-body">
                    <div *ngFor="let reviewItem of this.review.reviewItems; let i = index;" class="border-bottom mb-3">
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <div class="checkbox-lg">
                                    <input type="checkbox"
                                        *ngIf="this.review.reviewItems.length > 1"
                                        id="chkReviewItem_{{reviewItem.recordNumber}}"
                                        name="chkReviewItem_{{reviewItem.recordNumber}}"
                                        [(ngModel)]="reviewItem.isSelected"
                                        (change)="updateReviewFee()">
                                    <label for="chkReviewItem_{{reviewItem.recordNumber}}">Contravention Number: {{reviewItem.recordNumber}}</label>
                                </div>
                                <div class="contravention-description mt-2">
                                    {{getPrimaryCharge(reviewItem.recordNumber)}}
                                </div>
                                <div class="contravention-description mt-2">
                                    {{getSecondaryCharge(reviewItem.recordNumber)}}
                                </div>
                                <div class="contravention-description mt-2">
                                    {{getTertiaryCharge(reviewItem.recordNumber)}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <label for="reviewGrounds" class="required">Grounds for Review</label>
                                    </div>
                                </div>
                                <p class="mt-0 mb-1">
                                    Please advise the client that <strong>hardship is not a prescribed ground for review</strong>. If a recipient, lawyer, or agent submits an 
                                    application for review and the <strong>only</strong> ground for review is that meeting the requirements of the Notice of Administrative 
                                    Penalty will cause financial or other hardship to the recipient, the Notice of Administrative Penalty will be <strong>confirmed</strong>.
                                </p>
                                <textarea class="form-control" 
                                    id="reviewGrounds_{{reviewItem.recordNumber}}" 
                                    name="reviewGrounds_{{reviewItem.recordNumber}}"
                                    rows="6" maxlength="5000"                                    
                                    [required]="reviewItem.isSelected"
                                    noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="reviewItem.reviewGrounds"
                                    [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                    placeholder="The client must provide their reason for requesting a review (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Grounds for Review is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label class="w-100">Please ask the client to provide the following information:</label>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="reviewMethod" class="required">Review Method</label>
                            <div class="ml-1 question-icon" data-toggle="modal" data-target="#aboutRreviewMethodModal">
                                <i class="far fa-question-circle fa-lg"></i>
                            </div>
                            <select class="form-control" id="reviewMethod" name="reviewMethod" required
                                [(ngModel)]="review.reviewMethodTypeId"
                                #reviewMethod="ngModel" [ngClass]="{ 'is-invalid': requestForm.submitted && reviewMethod.invalid }">
                                <option [ngValue]="null">Select a Method</option>
                                <option *ngFor="let reviewMethodType of reviewMethodTypes" [ngValue]="reviewMethodType.id">{{reviewMethodType.name}}</option>
                            </select>
                            <div class="pl-2" *ngIf="review.reviewMethodTypeId == 2">
                                Oral reviews will be recorded by SafeRoads Alberta.
                            </div>
                            <div class="invalid-feedback">
                                <div>Review Method is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="hearingFeeAmount">Selected Review Fee Amount</label>
                            <input readonly type="text" class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                        </div>
                    </div>                    
                    <div class="form-group row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == ReviewMethodTypes.Oral">
                        <div class="col-12">
                            <app-failed-attendance-declaration
                            [review]="review"
                            [isFormSubmitted]="requestForm.submitted"
                            [isRSGPortal]="isRSGPortal">
                            </app-failed-attendance-declaration>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="additionalNotes">Additional Request Notes</label>
                            <textarea class="form-control" id="additionalNotes" name="additionalNotes"
                                rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                placeholder="The client may provide additional notes for the request (Max 5000 Characters)">
                            </textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="hasInterpreter" class="required">Will the client be accompanied by an interpreter?</label>
                            
                            <p>
                                Please advise the client that they are permitted to bring an interpreter to the oral review if they require translation to the 
                                English language. They are responsible for securing an interpreter prior to the review and responsible for any costs incurred in obtaining an interpreter.
                            </p>
                            
                            <div class="mt-2">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="accompainedYes" name="hasInterpreter"
                                        required #hasInterpreter="ngModel" [value]="true" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="custom-control-label" for="accompainedYes">Yes</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="accompainedNo" name="hasInterpreter"
                                        required [value]="false" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="custom-control-label" for="accompainedNo">No</label>
                                </div>
                                <div>
                                    <div class="text-danger" *ngIf="requestForm.submitted && hasInterpreter.errors?.required">Please indicate whether they will be accompanied by an interpreter</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div>
                <span class="error">{{errorMessage}}</span>
            </div>
            
            <app-contact-information [model]="review" [isSubmitClicked]="requestForm.submitted"></app-contact-information>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../terms']">Return to Terms & Conditions</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Technical Materials Library</button>
                    </div>
                </div>
            </div>	
        </div>
        </form>
    </ng-container>
</app-contravention-page>

<!-- Modal -->
<div class="modal" id="confirmReviewModal" tabindex="-1" role="dialog" aria-labelledby="confirmReviewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="confirmReviewModalLabel">
                    Please confirm the contraventions you have selected to review:
                </span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0">
                <div *ngFor="let reviewItem of this.review.reviewItems; let i = index;" class="mb-3">
                    <div *ngIf="reviewItem.isSelected">
                        <div class="contravention-description">
                            <strong>{{reviewItem.recordNumber}}</strong>
                        </div>
                        <div class="contravention-description">
                            {{getPrimaryCharge(reviewItem.recordNumber)}}
                        </div>
                        <div class="contravention-description">
                            {{getSecondaryCharge(reviewItem.recordNumber)}}
                        </div>
                        <div class="contravention-description mt-2">
                            {{getTertiaryCharge(reviewItem.recordNumber)}}
                        </div>
                    </div>
                </div>

                <p class="font-weight-bold">There are other contraventions from this incident that you haven't selected to review.</p>
                <p class="font-weight-bold">Do you want to proceed?</p>
            </div>
            <div class="modal-footer border-0 d-flex justify-content-between">
                <button type="button" class="goa-button goa--secondary mt-2 btn-confirm" 
                    data-dismiss="modal">Select more contraventions</button>
                <button type="button" class="goa-button mt-2 btn-confirm" 
                    data-dismiss="modal" (click)="proceed()">Proceed</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="aboutRreviewMethodModal" tabindex="-1" role="dialog" aria-labelledby="aboutRreviewMethodModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="aboutRreviewMethodModal">
                    Oral vs. Written Review Methods
                </span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0" *ngIf="!isRSGPortal">
                <h4>Oral Review</h4>
                <p>You will meet with an adjudicator by videoconference or by phone. 
                    A videoconferencing link or telephone number will be provided to connect at the scheduled review time. 
                    Your review will be 30 minutes in length.</p>
                <h4>Written Review</h4>
                <p>You will provide a written submission with your reasons for disputing the Notice of Administrative Penalty, 
                    along with other supporting documents, if applicable. 
                    You do not meet with an adjudicator or attend the review.</p>
                <h4>Decision</h4>
                <p>For both review methods, the adjudicator will make their decision to confirm 
                    or cancel your Notice of Administrative Penalty in writing, 
                    within 30 days from the date you were issued the Notice(s).</p>
                <p>
                    For details, visit <a target="_blank" href="https://www.saferoads.com">SafeRoads Alberta</a>.
                </p>
            </div>
            <div class="modal-body py-0" *ngIf="isRSGPortal">
                <h4>Oral Review</h4>
                <p>The client will meet with an adjudicator by videoconference or by phone. 
                    A videoconferencing link or telephone number will be provided to connect at the scheduled review time. 
                    The review will be 30 minutes in length.</p>
                <h4>Written Review</h4>
                <p>The client will provide a written submission with their reasons for disputing the Notice of Administrative Penalty, 
                    along with other supporting documents, if applicable. 
                    The client does not meet with an adjudicator or attend the review.</p>
                <h4>Decision</h4>
                <p>For both review methods, the adjudicator will make their decision to confirm or cancel the Notice 
                    of Administrative Penalty in writing, within 30 days from the date the client was issued the Notice(s).</p>
            </div>
        </div>
    </div>
</div>
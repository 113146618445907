import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ReviewBaseComponent } from 'apps/driver/src/app/components/contravention/review/apply/review-base.component';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';

@Component({
  selector: 'app-review-tech-material-library',
  templateUrl: './review-tech-material-library.component.html',
  styleUrls: ['./review-tech-material-library.component.scss']
})
export class ReviewTechMaterialLibraryComponent extends ReviewBaseComponent implements OnInit {

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public schedulerService: SchedulerService
  ) { 
    super(router, activatedRoute, localStorageService, schedulerService);
  }

  ngOnInit(): void {
  }

  onProceedClick()
  {
    this.router.navigate(['../schedule-review'], { relativeTo: this.activatedRoute, });
  }
}

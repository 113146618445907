import { Component, OnInit } from '@angular/core';
import { SeizureReviewDetailsBaseComponent } from '../seizure-review-details-base.component';
import { ActivatedRoute, Router } from '@angular/router';   
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ReviewService } from 'apps/driver/src/app/shared/services/review.service';

@Component({
  selector: 'app-seizure-reschedule-complete',
  templateUrl: './seizure-reschedule-complete.component.html',
  styleUrls: ['./seizure-reschedule-complete.component.scss']
})
export class SeizureRescheduleCompleteComponent extends SeizureReviewDetailsBaseComponent implements OnInit {

  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public reviewService: ReviewService,
    public localStorageService: LocalStorageService) { super(router, activatedRoute, localStorageService, reviewService);}

  ngOnInit(): void {
    super.onInit();
  }
  onInitComplete(): void {    
  }

}

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/of';

import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { APP_CONFIG } from '@apis/app-config';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Review } from '@apis/shared/models/review.model';
import { map } from 'rxjs/operators';
import { User } from '@apis/shared/models/user.model';
import { ReviewDocumentsEditState } from '@apis/shared/enums/app.enum';

@Injectable()
export class ReviewService {
  private readonly apiUrl = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;
  private contravention: Contravention = null; // for caching
  public review: Review = null;
  private user: User;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {}

  addOrUpdateReviewDocuments(review: Review) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.put(
      `${this.apiUrl}/reviews/${review.reviewNumber}/documents`,
      review,
      httpOptions
    );
  }

  updateReview(review: Review) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.put(
      `${this.apiUrl}/reviews/${review.reviewNumber}`,
      review,
      httpOptions
    );
  }

  cancelReview(review: Review) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.put(
      `${this.apiUrl}/reviews/${review.reviewNumber}/cancel`,
      review,
      httpOptions
    );
  }

  reScheduleReview(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    
    return this.httpClient.put(`${this.apiUrl}/reviews/${review.reviewNumber}/reschedule`, review, httpOptions)
      .pipe(
        map(response => new Review(response))
      );
  }   

  scheduleReReview(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    
    return this.httpClient.put(`${this.apiUrl}/reviews/${review.reviewNumber}/schedule`, review, httpOptions)
      .pipe(
        map(response => new Review(response))
      );
  }   
  
  getSeizureReviewInSequenceAsync(seizureNumber: string, reviewSequence: number, reviewTypeId: number): Observable<Review> {
    return this.httpClient.get<Review>(`${this.apiUrl}/vehicle-seizures/${seizureNumber}/reviews/${reviewSequence}/${reviewTypeId}`,
    {
        params: {
        }
    });
  }

  canSubmitReviewDocuments(review: Review): Observable<ReviewDocumentsEditState> {
    const subject = new Subject<any>();

    this.httpClient.get(`${this.apiUrl}/reviews/${review.reviewNumber}/can-edit-documents`, {
      params: {
          "reviewNumber": review.reviewNumber
      }
    }).subscribe((documentsEditState: ReviewDocumentsEditState) => {
      if (documentsEditState == ReviewDocumentsEditState.EditAllowed) {
        subject.next();
      }
      else if (documentsEditState == ReviewDocumentsEditState.DeadlinePassed) {
        subject.error("Unable to submit supporting document. Submission deadline has passed.");
      }
      else if (documentsEditState == ReviewDocumentsEditState.ReviewCompleted) {
        subject.error("Unable to submit supporting document. Review has been completed.");
      }
      else if (documentsEditState == ReviewDocumentsEditState.ReviewCancelled) {
        subject.error("Unable to submit supporting document. Review has been cancelled.");
      }
      else {
        subject.error("Unable to submit the file. Please try again.");
      }
    },
    (error: any) => {
      subject.error("Unable to submit the file. Please try again.");
    });

    return subject.asObservable();
  }

  canRemoveReviewDocuments(review: Review): Observable<ReviewDocumentsEditState> {
    const subject = new Subject<any>();

    this.httpClient.get(`${this.apiUrl}/reviews/${review.reviewNumber}/can-edit-documents`, {
      params: {
          "reviewNumber": review.reviewNumber
      }
    }).subscribe((documentsEditState: ReviewDocumentsEditState) => {
      if (documentsEditState == ReviewDocumentsEditState.EditAllowed) {
        subject.next();
      }
      else if (documentsEditState == ReviewDocumentsEditState.DeadlinePassed) {
        subject.error("Unable to remove supporting document. Submission deadline has passed.");
      }
      else if (documentsEditState == ReviewDocumentsEditState.ReviewCompleted) {
        subject.error("Unable to remove supporting document. Review has been completed.");
      }
      else if (documentsEditState == ReviewDocumentsEditState.ReviewCancelled) {
        subject.error("Unable to remove supporting document. Review has been cancelled.");
      }
      else {
        subject.error("Unable to remove the file. Please try again.");
      }
    },
    (error: any) => {
      subject.error("Unable to remove the file. Please try again.");
    });

    return subject.asObservable();
  }
}

<!-- Driver Portal View -->
<app-contravention-page #masterPage *ngIf="!isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form #paymentForm="ngForm" [mustMatch]="['recipientEmail', 'recipientConfirmEmail']">
        <div class="d-flex flex-column">
            <h1>Payment Method</h1>
            <div class="d-flex w-100 border-bottom mb-2">
                <h3>Notice(s). {{this.getContraventionsInReview()}}</h3>
                <div class="countdown">
                    <countdown-timer [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                </div>
            </div>
            <div class="card mt-4">
                <div class="card-header">
                    Payment Details
                </div>
                <div class="card-body">  
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Application for Review</div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Amount Total</h5></div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Method
                </div>
                <div class="card-body">  
                    <app-payment-details #paymentDetails [isSubmitClicked]="paymentForm.submitted"
                    [(paymentRequest)]="review.paymentRequest"></app-payment-details>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Receipt
                </div>
                <div class="card-body">  
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="recipientEmail" class="required">Send a Receipt to Email</label>
                            <input type="text" [(ngModel)]="review.paymentRequest.recipientEmail" required email
                                id="recipientEmail" name="recipientEmail" #recipientEmail="ngModel"
                                [ngClass]="{ 'is-invalid': paymentForm.submitted && recipientEmail.invalid }"
                                class="form-control" placeholder="Enter your preferred email address">
                            <div class="invalid-feedback">
                                <div>Email is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="recipientConfirmEmail" class="required">Confirm Email</label>
                            <input type="text" [(ngModel)]="confirmEmailAddress" required email
                                id="recipientConfirmEmail" name="recipientConfirmEmail" #recipientConfirmEmail="ngModel"
                                [ngClass]="{ 'is-invalid': paymentForm.submitted && recipientConfirmEmail.invalid }"
                                class="form-control" placeholder="Confirm email address">
                            <div class="invalid-feedback">
                                <div>Confirm Email is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../schedule-review']">Back to Schedule Review</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button btn-block" (click)="continueRequest(paymentForm.valid)">Proceed to Application Summary</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page #masterPage *ngIf="isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form #paymentForm="ngForm" [mustMatch]="['recipientEmail', 'recipientConfirmEmail']">
        <div class="d-flex flex-column">
            <h1>Payment</h1>
            <div class="d-flex w-100 border-bottom mb-2">
                <h3>Notice(s). {{this.getSelectedContraventionsInReview()}}</h3>
                <div class="countdown">
                    <countdown-timer [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                </div>
            </div>
            
            <div class="card border-0">
                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Payments are non-refundable</h6>
                    <p>
                        Advise the client that all payments to SafeRoads are final. No refunds will be issued.
                    </p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Amount
                </div>
                <div class="card-body">  
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Application for Review</div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Amount Total</h5></div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header big-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Total Payment Amount</h5></div>
                        <div><h5>{{ review.paymentRequest.paymentAmount | currency }}</h5></div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Transaction ID</h5></div>
                        <div><h5>{{ review.paymentRequest.transactionId }}</h5></div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../schedule-review']">Back to Schedule Review</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button btn-block" (click)="continueRegistryRequest(paymentForm.valid)">Proceed to Application Summary</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-contravention-page>

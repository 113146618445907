import { Component, OnInit } from '@angular/core';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { Representation } from '@apis/shared/models/representation.model';
import { SeizureLateReviewBaseComponent } from '../seizure-late-review-base.component';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { ContactMethodTypes, RepresentationTypes } from '@apis/shared/enums/app.enum';

@Component({
  selector: 'app-seizure-late-review-details',
  templateUrl: './seizure-late-review-details.component.html',
  styleUrls: ['./seizure-late-review-details.component.scss']
})
export class SeizureLateReviewDetailsComponent extends SeizureLateReviewBaseComponent
  implements OnInit {
  
  genderTypes: GenderType[];
  rsgAgentProfile: RsgAgentProfile;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService
  ) {
    super(router, activatedRoute, localStorageService);
  }

  ngOnInit(): void {
    this.genderTypes = this.localStorageService.getGenderTypes();
    // Registry Portal - If logged in user is registry agent then set the RA profile information in representation object
    
    if (this.isRSGPortal && !this.lateReviewRequest.representation)
    {
      this.rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
      if (!this.rsgAgentProfile)
        this.router.navigate(['/unauthorized']);
    }
  }

  onReviewTypeChange() {
    this.lateReviewRequest.representation = this.lateReviewRequest.isSelfSubmit
      ? null
      : new Representation({
        representationTypeId: null
      });
  }

  continueRequest(isValid: boolean) {
    if (isValid) {
      this.saveContext();
      this.router.navigate(['../supporting-documents'], {
        relativeTo: this.activatedRoute,
      });
    }
  }
}

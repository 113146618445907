import { Component, OnInit } from '@angular/core';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { Contravention } from '@apis/shared/models/contravention.model';
import { ContraventionService } from '../../../shared/services/contravention.service';
import { Recipient } from '@apis/shared/models/recipient.model';
import { DatePipe } from '@angular/common';
import { PaymentAdditionalTimeRequest2 } from '@apis/shared/models/payment-additional-time-request2.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DriverBaseComponent } from '../../driver-base.component';
import { ContraventionInformation } from '@apis/shared/models/contravention-information.model';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { RepresentationTypes } from '@apis/shared/enums/app.enum';


@Component({
  selector: 'app-request-additional-time-submitted',
  templateUrl: './request-additional-time-submitted.component.html',
  styleUrls: ['./request-additional-time-submitted.component.scss']
})
export class RequestAdditionalTimeSubmittedComponent extends DriverBaseComponent implements OnInit {
  requestInformation: SectionDetailsModel[] = [];
  representationInformation: SectionDetailsModel[] = [];
  recipientInformation: SectionDetailsModel[] = [];
  recipientIdentificationInformation: SectionDetailsModel[] = [];
  contraventionInformation: SectionDetailsModel[] = [];
  termsConditionsInformation: SectionDetailsModel[] = [];
  contraventionsInRequest: ContraventionInformation[] = [];

  recipient: Recipient;
  paymentAdditionalTimeRequest2: PaymentAdditionalTimeRequest2;
  contraventionOverview: ContraventionOverview;
  dueDate: Date;

  constructor(
              router: Router,
              private activatedRoute: ActivatedRoute,
              private localStorageService: LocalStorageService,
              private datePipe: DatePipe) {
    super(router);
  }

  ngOnInit(): void {
    this.contraventionOverview = this.localStorageService.getItem<ContraventionOverview>(
      'ContraventionOverview'
    );

    this.paymentAdditionalTimeRequest2 = this.localStorageService.getItem<PaymentAdditionalTimeRequest2>("PaymentAdditionalTimeRequest");

    if (this.contraventionOverview == null || this.paymentAdditionalTimeRequest2 == null) {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }

    // Clear session
    this.localStorageService.remove("PaymentAdditionalTimeRequest");

    // Request Information
    this.requestInformation.push(new SectionDetailsModel('Request Type', "Additional Time to Pay"));
    this.requestInformation.push(new SectionDetailsModel('Date Submitted', 
      this.datePipe.transform(new Date(), 'mediumDate')));

    if (this.paymentAdditionalTimeRequest2.representation) {
      // Representation Informaton
      const repTypeId = this.paymentAdditionalTimeRequest2.representation.representationTypeId;

      const repTypeName = this.localStorageService.getRepresentationTypes()
                              .find(x=>x.id == repTypeId)?.name;

      this.representationInformation.push(new SectionDetailsModel('Type of Acting Representation', repTypeName, true));

      this.representationInformation.push(new SectionDetailsModel(`Representative First Name`, this.paymentAdditionalTimeRequest2.representation.firstName));
      this.representationInformation.push(new SectionDetailsModel(`Representative Last Name`, this.paymentAdditionalTimeRequest2.representation.lastName));

      if (+repTypeId === RepresentationTypes.Agent || +repTypeId === RepresentationTypes.Lawyer){
        this.representationInformation.push(new SectionDetailsModel(`Practice Name`, this.paymentAdditionalTimeRequest2.representation.practiceName, true));
      }

      if (+repTypeId === RepresentationTypes.Other){
        this.representationInformation.push(new SectionDetailsModel('Other Representation Type', this.paymentAdditionalTimeRequest2.representation.otherRepresentationType, true));
      }
    }

    // Contravention Information
    this.paymentAdditionalTimeRequest2.contraventionList.forEach(c => {
      this.contraventionsInRequest.push(this.contraventionOverview.contraventions.find(ci => ci.contraventionNumber == c));
    });

    this.dueDate = DateUtil.addDays(new Date(this.contraventionsInRequest[0].dueDate), 180);
  }

  getChargeCodes(c: ContraventionInformation) {
    var contraventionType = this.localStorageService.getContraventionTypes().find(ct => ct.id == c.contraventionTypeId);

    var chargeCodes = contraventionType.formattedChargeCode;

    if (c.secondaryContraventionTypeId) {
      contraventionType = this.localStorageService.getContraventionTypes().find(ct => ct.id == c.secondaryContraventionTypeId);

      chargeCodes = chargeCodes + ', ' + contraventionType.formattedChargeCode;
    }

    if (c.tertiaryContraventionTypeId) {
      contraventionType = this.localStorageService.getContraventionTypes().find(ct => ct.id == c.tertiaryContraventionTypeId);

      chargeCodes = chargeCodes + ', ' + contraventionType.formattedChargeCode;
    }

    return chargeCodes;
  }
}

import { DatePipe, CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contravention } from '@apis/shared/models/contravention.model';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { OnlinePaymentRequest } from '@apis/shared/models/online-payment-request.model';
import { Guid } from 'guid-typescript';
import { Review } from '@apis/shared/models/review.model';
import { ReviewScheduleRequest } from '@apis/shared/models/review-schedule-request.model';
import { DecisionTypes, DocumentTypes, EventTypes, IdentificationTypes, RecipientTypes, RequestDecisionTypes, RequestTypes, ReviewMethodTypes, ReviewOutcomeTypes, ReviewState, ReviewTypes, SeizureTypes, SeizureUserType, ReviewStatusTypes, SeizureStatusTypes, VehicleRegistrationStatusTypes } from '@apis/shared/enums/app.enum';
import { ReviewApplicant } from '@apis/shared/models/review-applicant.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleSeizureService } from '../../../shared/services/vehicle-seizure.service';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { Document } from '@apis/shared/models/document.model';
import { DriverBaseComponent } from '../../driver-base.component';
import { ReviewItem } from '@apis/shared/models/review-item.model';
import { LateReviewRequestItem } from '@apis/shared/models/late-review-request-item.model';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { Constants } from '@apis/shared/helpers/constants';
import { RecipientIdentification } from '@apis/shared/models/recipient-identification.model';
import { MovesVehicle } from '@apis/shared/models/moves-vehicle.model';
import { ContraventionService } from '../../../shared/services/contravention.service';
import { SeizureStatusType } from '@apis/shared/models/types/seizure-status-type.model';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { TimePipe } from '@apis/shared/pipes/time.pipe';
import { VehicleStatusType } from '@apis/shared/models/types/vehicle-status-type.model';
declare var $: any

@Component({
  selector: 'app-vehicle-seizure-details',
  templateUrl: './vehicle-seizure-details.component.html',
  styleUrls: ['./vehicle-seizure-details.component.scss'],
})
export class VehicleSeizureDetailsComponent extends DriverBaseComponent implements OnInit {
  ReviewMethodTypes = ReviewMethodTypes;
  isDriverSectionVisible = true;
  documentRefTypeNumber = "";

  vehicleSeizure: VehicleSeizure;
  recipient: Recipient;
  recipientIdentification: RecipientIdentification;
  seizureNumber: string;
  seizureStatusTypeName: string;
  seizureUserType: string;
  seizureGroupName: string;
  isReviewAbandoned: boolean;
  recipientType: string;
  movesVehicle: MovesVehicle;

  SeizureUserType = SeizureUserType;
  ReviewState = ReviewState;
  DecisionTypes = DecisionTypes;
  DocumentTypes = DocumentTypes;
  ReviewStatusTypes = ReviewStatusTypes;
  
  recipientInformation: SectionDetailsModel[] = [];
  recipientIdentificationInformation: SectionDetailsModel[] = [];
  policeNarrativeDetails: SectionDetailsModel[] = [];
  recipientContactInformation: SectionDetailsModel[] = [];
  movesRecipientContactInformation: SectionDetailsModel[] = [];
  contraventionInformation: SectionDetailsModel[] = [];
  contraventionType: SectionDetailsModel[] = [];
  stopInformation: SectionDetailsModel[] = [];
  witnessInformation: SectionDetailsModel[] = [];
  contraventionGrounds: SectionDetailsModel[] = [];
  impairmentScreeningInformation: SectionDetailsModel[] = [];
  impairmentScreeningDetails: SectionDetailsModel[] = [];
  additionalScreeningInformation: SectionDetailsModel[][] = [];
  roadsideAppealInformation: SectionDetailsModel[] = [];
  roadsideAppealDetails: SectionDetailsModel[] = [];
  reasonableGrounds: SectionDetailsModel[] = [];
  policeNarrative: SectionDetailsModel[] = [];
  supportingDocumentDeclarations: SectionDetailsModel[] = [];
  seizureInformation: SectionDetailsModel[] = [];
  vehicleInformation: SectionDetailsModel[] = [];
  movesVehicleInformation: SectionDetailsModel[] = [];
  trailerInformation: SectionDetailsModel[] = [];
  registeredOwnerInformation: SectionDetailsModel[] = [];
  towLotInformation: SectionDetailsModel[] = [];
  supportingDocuments: DocumentModel[] = [];
  policeServiceInformation: SectionDetailsModel[] = [];
  officerDeclarations: SectionDetailsModel[] = [];
  isImmidiatelyCancelled: boolean = false;
  showPublishedDate: boolean = false;
  additionalFieldsActive: boolean = false;
  showSeizureTypeActive: boolean = false;
  isChangeDisplayedInformationActive: boolean = false;
  showDocumentSubmitter: boolean = false;
  Adjudication:any=Constants.Adjudication;

  isReviewCancelled: boolean = false;
  
  showSupportingDocumentDeclarations: boolean;
  cancelledDate: string = '';
  SeizureTypes = SeizureTypes;
  hasTransactionId:boolean=false;
  canShowApplyForReviewButton:boolean=true;
  canShowApplyForReviewMessage:boolean=false;
  rsgAgentProfile: RsgAgentProfile;
  isPhase2Submission: boolean = false;
  timePipe: TimePipe;

  constructor(
              private route: ActivatedRoute,
              router: Router,
              private seizureService: VehicleSeizureService,
              private contraventionService: ContraventionService,
              private documentService: DocumentService,
              private localStorageService: LocalStorageService,
              private readonly spinner: NgxSpinnerService,
              private datePipe: DatePipe,
    ) {
    super(router);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
    this.hasTransactionId = this.rsgAgentProfile && this.rsgAgentProfile.transactionId != null && this.rsgAgentProfile.transactionId != "";
    this.route.paramMap.subscribe((paramMap) => {
      this.seizureNumber = paramMap.get('seizureNumber');

      this.seizureUserType = this.localStorageService.get(`${this.seizureNumber}_user_type`);

      // Route to search page if seizure user type is empty
      if (!this.seizureUserType) {
        this.spinner.hide();
        this.router.navigate(['../search']);
      } else {
        this.loadSeizure();
      }
    });
    this.timePipe = new TimePipe(); 
  }

  loadSeizure() {
    this.seizureService.getByNumber(this.seizureNumber, true)
      .subscribe((vehicleSeizure: VehicleSeizure) => {
      if (vehicleSeizure) {
        this.vehicleSeizure = vehicleSeizure;
        this.showPublishedDate = new Date(this.vehicleSeizure.submissionDate) >= new Date(this.Adjudication.DOCUMENT_PUBLISHED_DATE_EFFECTIVE_DATE);
        this.showDocumentSubmitter = new Date(this.vehicleSeizure.submissionDate) >= new Date(this.Adjudication.DOCUMENT_SUBMITTER_EFFECTIVE_DATE);
        this.additionalFieldsActive = new Date(this.vehicleSeizure.submissionDate) >= new Date(+Constants.Driver.PUBLISH_ADDITIONAL_FIELDS_EFFECTIVE_DATE.substring(0, 4), +Constants.Driver.PUBLISH_ADDITIONAL_FIELDS_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Driver.PUBLISH_ADDITIONAL_FIELDS_EFFECTIVE_DATE.substring(8, 10));
        this.showSeizureTypeActive = new Date(this.vehicleSeizure.submissionDate) >= new Date(+Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(0, 4), +Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(5, 7) - 1, +Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(8, 10));
        this.isChangeDisplayedInformationActive = new Date(this.vehicleSeizure.submissionDate) >= new Date(+Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(0, 4), +Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(8, 10));
        this.isPhase2Submission = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE2;
        this.recipient = this.vehicleSeizure.recipient;
        this.recipientIdentification = this.vehicleSeizure.recipient.recipientIdentification;
        this.documentRefTypeNumber = this.vehicleSeizure.contraventionNumber
                                      ? this.vehicleSeizure.contraventionNumber 
                                      : this.vehicleSeizure.seizureNumber;

        this.isImmidiatelyCancelled = this.vehicleSeizure.events && this.vehicleSeizure.events.filter(x => x.eventTypeId == EventTypes.ImmediatelyCancelled).length > 0;                              
        if (this.isImmidiatelyCancelled)
          this.cancelledDate = this.datePipe.transform(this.vehicleSeizure.events.find(x => x.eventTypeId == EventTypes.ImmediatelyCancelled).eventDate, 'MMM dd, yyyy');

        // set a few flags needed for UI logic
        this.setStatusFlags();
        
        this.localStorageService.setItem<VehicleSeizure>(
          this.vehicleSeizure.seizureNumber, 
          this.vehicleSeizure);

        // Get seizure status type name to show it in the badge
        this.seizureStatusTypeName = this.localStorageService.getSeizureStatusTypes()
          .find(x => x.id == this.vehicleSeizure.seizureStatusTypeId)?.name;

        // Seizure group name
        if (this.vehicleSeizure.seizureTypeId == SeizureTypes.IRSContraventionSelected) {
          if (this.showSeizureTypeActive) {
            const contraventionType = this.localStorageService.getContraventionTypes().find(x => x.id == this.vehicleSeizure.contraventionTypeId);
            this.seizureGroupName = contraventionType.name;
          }
          else {
            this.seizureGroupName = 'IRS Contravention Seizure';
          }
        }
        else {
          this.seizureGroupName = "Suspended Drivers Program Seizure";
        }

        switch (+this.recipient.recipientTypeId) {
          case RecipientTypes.Driver:
            this.recipientType = "Driver";
            break;
          case RecipientTypes.RegisteredOwner:
            this.recipientType = "Registered Owner";
            break;
          case RecipientTypes.Passenger:
            this.recipientType = "Passenger";
            break;
          case RecipientTypes.NotInVehicle:
            this.recipientType = "Not In Vehicle";
            break;
        }

        if (+this.seizureUserType == SeizureUserType.Driver)
        {
            if (this.isPhase2Submission) {
              this.recipientInformation.push(new SectionDetailsModel("Recipient Type", this.recipientType));

              if (this.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence)
                this.recipientInformation.push(new SectionDetailsModel("Identity verified by", "Driver's Licence"));
              else {
                this.recipientInformation.push(new SectionDetailsModel("Identity verified by", this.localStorageService.getIdentificationTypes().find(x => x.id == this.recipientIdentification.identificationTypeId)?.name));
                if (this.recipientIdentification.identificationTypeId == IdentificationTypes.Other)
                  this.recipientInformation.push(new SectionDetailsModel("Identification Type", this.recipientIdentification?.identificationOther));
              }
            }

            if (this.recipientIdentification.identificationTypeId != IdentificationTypes.Verbal) {
              this.recipientIdentificationInformation.push(new SectionDetailsModel('ID Number', this.recipient.recipientIdentification?.identificationNumber));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Issuing Country', this.recipient.recipientIdentification?.issuingCountryId == null ? "" : this.localStorageService.getCountryTypes().find(x => x.id == this.recipient.recipientIdentification?.issuingCountryId)?.name));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Issuing State/Province', (this.recipient.recipientIdentification?.issuingCountryId == 40 || this.recipient.recipientIdentification?.issuingCountryId == 236) ? (this.recipient.recipientIdentification?.issuingProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient.recipientIdentification?.issuingProvinceId)?.name) : this.recipient.recipientIdentification?.issuingProvinceOther));
            }

            if (this.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence) {
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Class', this.recipient.recipientIdentification?.driversLicenceClassTypeId == null ? this.recipient.recipientIdentification?.driversLicenceClass : this.localStorageService.getDriversLicenceClassTypes().find(x => x.id == this.recipient.recipientIdentification?.driversLicenceClassTypeId)?.name));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Status', this.localStorageService.getDriversLicenceStatusTypes().find(x => x.id == this.recipient.recipientIdentification?.driversLicenceStatusTypeId)?.name));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Expiry', this.datePipe.transform(this.recipient.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('MVID', this.recipient.recipientIdentification?.motorVehicleIdentificationNumber));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Is GDL/Novice Driver', this.recipient.recipientIdentification?.isGDLDriver == null ? "" : (this.recipient.recipientIdentification?.isGDLDriver ? "Yes" : "No")));
            }

            this.recipientIdentificationInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.recipient.recipientIdentification?.genderId)?.name));
            this.recipientIdentificationInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.recipient.recipientIdentification?.dateOfBirth, 'dd MMM, yyyy')));
            if (this.isPhase2Submission) {
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Condition Codes',Array.prototype.map.call(this.localStorageService.getConditionCodeTypes().filter(x => this.recipient?.recipientIdentification?.conditionCodes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join("")));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Eye Color', this.recipient.eyeColor));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Hair Color', this.recipient.hairColor));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Weight (kg)', this.recipient.weight));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Height (cm)', this.recipient.height));
              this.recipientIdentificationInformation.push(new SectionDetailsModel('Restrictions', this.recipient.recipientIdentification?.restrictions, true));
            }
        }

        this.recipientInformation.push(new SectionDetailsModel('Surname', this.recipient.lastName));
        this.recipientInformation.push(new SectionDetailsModel('First Name', this.recipient.firstName));
        this.recipientInformation.push(new SectionDetailsModel('Middle Name', this.recipient.otherName));

        this.recipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient.recipientContact?.addressLine1));
        this.recipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient.recipientContact?.addressLine2));
        this.recipientContactInformation.push(new SectionDetailsModel('City', this.recipient.recipientContact?.city));
        this.recipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient.recipientContact?.countryId == 40 || this.recipient.recipientContact?.countryId == 236) ? (this.recipient.recipientContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient.recipientContact?.provinceId)?.name) : this.recipient.recipientContact?.province));
        this.recipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.recipient.recipientContact?.countryId)?.name));
        this.recipientContactInformation.push(new SectionDetailsModel('Postal Code', this.recipient.recipientContact?.postalCode));
        this.recipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient.recipientContact?.phoneNumber1));
        this.recipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.recipient.recipientContact?.phoneNumber3));
        this.recipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient.recipientContact?.phoneNumber2));
        this.recipientContactInformation.push(new SectionDetailsModel('Email Address', this.recipient.recipientContact?.emailAddress));
        this.recipientContactInformation.push(new SectionDetailsModel('Address Different from Moves', this.recipient.recipientContact?.isAddressDifferentFromMOVES ? "Yes" : "No"));
        this.recipientContactInformation.push(new SectionDetailsModel('No Fixed Address', this.recipient.recipientContact?.isNoFixedAddress? "Yes" : "No"));

        this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient.movesRecipientContact?.addressLine1));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient.movesRecipientContact?.addressLine2));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('City',this.recipient.movesRecipientContact?.city));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient.movesRecipientContact?.countryId == 40 || this.recipient.movesRecipientContact?.countryId == 236)? (this.recipient.movesRecipientContact?.provinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.recipient.movesRecipientContact?.provinceId)?.name) : this.recipient.movesRecipientContact?.province));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x=> x.id == this.recipient.movesRecipientContact?.countryId)?.name));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Postal Code',this.recipient.movesRecipientContact?.postalCode));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient.movesRecipientContact?.phoneNumber1));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.recipient.movesRecipientContact?.phoneNumber3));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient.movesRecipientContact?.phoneNumber2));

        // Vehicle Seizure Section
        if (this.showSeizureTypeActive) {
          const contraventionType = this.localStorageService.getContraventionTypes().find(x => x.id == this.vehicleSeizure.contraventionTypeId);
          if (contraventionType.isIRS) {
            this.seizureInformation.push(new SectionDetailsModel('Seizure Type', contraventionType.formattedChargeCode + ' ' + contraventionType.name));
          }
          else {
            const seizureType = this.localStorageService.getSeizureTypes().find(x => x.id == this.vehicleSeizure.seizureTypeId);
            this.seizureInformation.push(new SectionDetailsModel('Seizure Type', contraventionType.formattedChargeCode + ' ' + seizureType.name));
          }
        }
        this.seizureInformation.push(new SectionDetailsModel('Seizure Date', this.datePipe.transform(this.vehicleSeizure?.seizureDate, 'dd MMM, yyyy')));
        this.seizureInformation.push(new SectionDetailsModel('Seizure Time', this.timePipe.transform(this.vehicleSeizure?.seizureTime, 'shortTime24h')));
        this.seizureInformation.push(new SectionDetailsModel('Immediate Seizure Duration', this.vehicleSeizure.seizureDuration.toString() + ' ' + this.vehicleSeizure.seizureDurationType));
        this.seizureInformation.push(new SectionDetailsModel('Vehicle Release Date', this.datePipe.transform(this.vehicleSeizure?.vehicleReleaseDate, 'dd MMM, yyyy')));
        this.seizureInformation.push(new SectionDetailsModel('Location of Seizure', this.localStorageService.getLocationTypes().find(x => x.id == this.vehicleSeizure?.locationTypeId)?.name));
        this.seizureInformation.push(new SectionDetailsModel('Additional Location Information', this.vehicleSeizure?.additionalLocationDetails, true));

        //Police Narrative Additional Note
        var policeNarrative = this.vehicleSeizure.additionalNotes;
        if ((policeNarrative != null || policeNarrative?.trim() != '')) {
          this.policeNarrativeDetails.push(new SectionDetailsModel('',  policeNarrative, true));
        }

        this.vehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.vehicleSeizure?.vehicle?.licencePlateNumber));
        this.vehicleInformation.push(new SectionDetailsModel("Plate Doesn't Match/Stolen Plate", this.vehicleSeizure?.vehicle?.isStolenPlate? "Yes": "No"));
        this.vehicleInformation.push(new SectionDetailsModel("No Plate", this.vehicleSeizure?.vehicle?.isNoPlate? "Yes": "No"));
        this.vehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.vehicleSeizure?.vehicle?.vehicleIdentificationNumber));
        this.vehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.vehicleRegistrationStatusTypeId)?.name));
        this.vehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.clientTypeId)?.name));
        this.vehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.licensedCountryId)?.name));
        this.vehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.vehicleSeizure?.vehicle?.licensedCountryId == 40 || this.vehicleSeizure?.vehicle?.licensedCountryId == 236)? (this.vehicleSeizure?.vehicle?.licensedProvinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.vehicleSeizure?.vehicle?.licensedProvinceId)?.name) : this.vehicleSeizure?.vehicle?.licensedProvince));
        this.vehicleInformation.push(new SectionDetailsModel('Make', this.vehicleSeizure?.vehicle?.make));
        this.vehicleInformation.push(new SectionDetailsModel('Model', this.vehicleSeizure?.vehicle?.model));
        this.vehicleInformation.push(new SectionDetailsModel('Year', this.vehicleSeizure?.vehicle?.year?.toString()));
        this.vehicleInformation.push(new SectionDetailsModel('Colour', this.vehicleSeizure?.vehicle?.colour));
        this.vehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.vehicleSeizure?.vehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));
        if (!(this.vehicleSeizure?.vehicle?.movesLookupDate && this.vehicleSeizure?.vehicle?.isStolenPlate))
        {
          this.vehicleInformation.push(new SectionDetailsModel('Class of Plate', this.vehicleSeizure?.vehicle?.licencePlateClass));
          this.vehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.vehicleSeizure?.vehicle?.vehicleStyle));
          this.vehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.vehicleSeizure?.vehicle?.vehicleWeight));
          this.vehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.vehicleSeizure?.vehicle?.vehicleSeatingCapacity));
          this.vehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.vehicleStatusTypeId)?.name));
          this.vehicleInformation.push(new SectionDetailsModel('Last Service Type', this.vehicleSeizure?.vehicle?.lastServiceType));
          this.vehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.vehicleSeizure?.vehicle?.lastServiceDate, 'dd MMM, yyyy')));
        }

        this.movesVehicle = this.vehicleSeizure?.vehicle?.movesVehicle;

        //Moves Vehicle Information
        this.movesVehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.movesVehicle?.licencePlateNumber));
        this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.movesVehicle?.vehicleIdentificationNumber));
        this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.movesVehicle?.vehicleRegistrationStatusTypeId)?.name));
        this.movesVehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.movesVehicle?.clientTypeId)?.name));
        this.movesVehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.movesVehicle?.licensedCountryId)?.name));
        this.movesVehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.movesVehicle?.licensedCountryId == 40 || this.movesVehicle?.licensedCountryId == 236)? (this.movesVehicle?.licensedProvinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.movesVehicle?.licensedProvinceId)?.name) : this.movesVehicle?.licensedProvince));
        this.movesVehicleInformation.push(new SectionDetailsModel('Make', this.movesVehicle?.make));
        this.movesVehicleInformation.push(new SectionDetailsModel('Model', this.movesVehicle?.model));
        this.movesVehicleInformation.push(new SectionDetailsModel('Year', this.movesVehicle?.year?.toString()));
        this.movesVehicleInformation.push(new SectionDetailsModel('Colour', this.movesVehicle?.colour));
        this.movesVehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.movesVehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));
        this.movesVehicleInformation.push(new SectionDetailsModel('Class of Plate', this.movesVehicle?.licencePlateClass));
        this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.movesVehicle?.vehicleStyle));
        this.movesVehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.movesVehicle?.vehicleWeight));
        this.movesVehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.movesVehicle?.vehicleSeatingCapacity));
        this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.movesVehicle?.vehicleStatusTypeId)?.name));
        this.movesVehicleInformation.push(new SectionDetailsModel('Last Service Type', this.movesVehicle?.lastServiceType));
        this.movesVehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.movesVehicle?.lastServiceDate, 'dd MMM, yyyy')));

        // Trailer Information
        if (this.isPhase2Submission) {
          this.trailerInformation.push(new SectionDetailsModel("Trailer Involved", this.vehicleSeizure?.vehicle?.isTrailerInvolved ? "Yes" : "No"));
        }
        if (this.vehicleSeizure?.vehicle?.isTrailerInvolved) {
          this.trailerInformation.push(new SectionDetailsModel('Trailer Licence Plate Number', this.vehicleSeizure?.vehicle?.trailerLicencePlateNumber));
          this.trailerInformation.push(new SectionDetailsModel('Additional Trailer Information', this.vehicleSeizure?.vehicle?.additionalTrailerInformation));
          if (this.vehicleSeizure?.vehicle?.isSecondTrailerInvolved)
          {
            this.trailerInformation.push(new SectionDetailsModel('Second Trailer Licence Plate Number', this.vehicleSeizure?.vehicle?.secondTrailerLicencePlateNumber));
            this.trailerInformation.push(new SectionDetailsModel('Additional Second Trailer Information', this.vehicleSeizure?.vehicle?.additionalSecondTrailerInformation));
          }
        }

        if (this.vehicleSeizure?.vehicle?.isNoRegisteredOwner)
          this.registeredOwnerInformation.push(new SectionDetailsModel('Registered Owner', "Unable to determine"));
        else
        {
          this.registeredOwnerInformation.push(new SectionDetailsModel('Driver Licence Number', this.vehicleSeizure?.vehicle?.registeredOwner?.driverLicenceNumber));
          this.registeredOwnerInformation.push(new SectionDetailsModel('M.V.I.D', this.vehicleSeizure?.vehicle?.registeredOwner?.motorVehicleIdentificationNumber));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Surname', this.vehicleSeizure?.vehicle?.registeredOwner?.lastName));
          this.registeredOwnerInformation.push(new SectionDetailsModel('First Name', this.vehicleSeizure?.vehicle?.registeredOwner?.firstName));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Middle Name', this.vehicleSeizure?.vehicle?.registeredOwner?.otherName));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x=>x.id == this.vehicleSeizure?.vehicle?.registeredOwner?.genderId)?.name));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.vehicleSeizure?.vehicle?.registeredOwner?.dateOfBirth, 'dd MMM, yyyy')));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Telephone Number', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.phoneNumber1));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Address 1', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine1));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Address 2', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine2));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.countryId)?.name));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Province/State', (this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 40 || this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 236)? (this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId)?.name) : this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.province));
          this.registeredOwnerInformation.push(new SectionDetailsModel('City', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.city));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Postal Code', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.postalCode));
        }

        this.towLotInformation.push(new SectionDetailsModel('Towing Company', this.vehicleSeizure?.towCompanyName));
        this.towLotInformation.push(new SectionDetailsModel('Name of Towing Company', this.vehicleSeizure?.towCompanyName));
        this.towLotInformation.push(new SectionDetailsModel('Tow Lot Address', this.vehicleSeizure?.towLotAddress));
        this.towLotInformation.push(new SectionDetailsModel('City/Town/Village', this.vehicleSeizure?.towLotCity));
        this.towLotInformation.push(new SectionDetailsModel('Postal Code', this.vehicleSeizure?.towLotPostalCode));
        this.towLotInformation.push(new SectionDetailsModel('Telephone Number', this.vehicleSeizure?.towLotPhoneNumber));
        this.towLotInformation.push(new SectionDetailsModel('Fax Number', this.vehicleSeizure?.towLotFaxNumber));
        if (!this.isPhase2Submission)
        {
          this.towLotInformation.push(new SectionDetailsModel('Received By (Towing Company Representative)', this.vehicleSeizure?.towLotRepresentative));
        }

        // Supporting Documents Section
        if (+this.seizureUserType == SeizureUserType.RegisteredOwner) {
          var seizureDocument = this.getRegisteredOwnerSeizureNotice();

          if (seizureDocument) {
            var documentType : DocumentType = this.localStorageService.getDocumentTypes().find(x=> x.id == seizureDocument.documentTypeId);
            this.supportingDocuments.push(
              new DocumentModel(
                documentType?.name,
                seizureDocument.documentName,
                seizureDocument.documentExtension,
                seizureDocument.contentGuid,
                !documentType?.isSubmittableAtLaterDate,
                seizureDocument.uploadedDate,
                seizureDocument.publishedDate,
                seizureDocument.documentTypeId,
                seizureDocument.documentLocation,
                seizureDocument.documentSize,
                seizureDocument.uploadedBy,
                seizureDocument.version));
          }

          if (this.isChangeDisplayedInformationActive) {
            this.vehicleSeizure.documents
              .filter(d => d.documentTypeId == DocumentTypes.PoliceNarrative || d.documentTypeId == DocumentTypes.PoliceNotes)
              .forEach(document => {
              this.supportingDocuments.push(
                new DocumentModel(
                  this.getDocumentTypeName(document),
                  document.documentName,
                  document.documentExtension,
                  document.contentGuid,
                  !documentType?.isSubmittableAtLaterDate,
                  document.uploadedDate,
                  document.publishedDate,
                  document.documentTypeId,
                  document.documentLocation,
                  document.documentSize,
                  document.uploadedBy,
                  document.version));
            });
          }
        }
        else {
          this.vehicleSeizure.documents
            .filter(d => d.documentTypeId != DocumentTypes.SeizureNoticeRegisteredOwnerCopy 
              || (d.documentTypeId == DocumentTypes.SeizureNoticeRegisteredOwnerCopy && this.vehicleSeizure.vehicle?.registeredOwner?.isSameAsDriver == true))
            .forEach(document => {
            this.supportingDocuments.push(
              new DocumentModel(
                this.getDocumentTypeName(document),
                document.documentName,
                document.documentExtension,
                document.contentGuid,
                !documentType?.isSubmittableAtLaterDate,
                document.uploadedDate,
                document.publishedDate,
                document.documentTypeId,
                document.documentLocation,
                document.documentSize,
                document.uploadedBy,
                document.version));
          });
        }

        // Supporting Document Declarations

        var declarationTypes = this.localStorageService.getDeclarationTypes();

        this.vehicleSeizure.policeServiceInformation.forEach(p => {
          if (p.declarationInformation != null) {
            var declaration = declarationTypes.find(d => d.id == p.declarationInformation.declarationTypeId);
            this.supportingDocumentDeclarations.push(new SectionDetailsModel('Name of Submitter', p.declarationInformation.policeOfficerName));
            this.supportingDocumentDeclarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(p.declarationInformation.declarationDate, 'dd MMM, yyyy')));
            this.supportingDocumentDeclarations.push(new SectionDetailsModel(declaration.description, 'Yes', true));         
            this.showSupportingDocumentDeclarations = true;
          }
        });

        // Police Service Section      
        let policeOfficerName: string;
        let regimentalNumber: string;
        let agencyId: number = 0;
        let detachmentId: number = 0;

        var declarationTypes = this.localStorageService.getDeclarationTypes();

        var officerSubmissionDeclaration = declarationTypes?.find(d => d.id == this.vehicleSeizure.officerSubmissionDeclarationTypeId).description;

        if (this.vehicleSeizure.policeServiceInformation?.length > 0) {
          policeOfficerName = this.vehicleSeizure.policeServiceInformation[0].policeOfficerName;
          regimentalNumber = this.vehicleSeizure.policeServiceInformation[0].regimentalNumber;
          agencyId = this.vehicleSeizure.policeServiceInformation[0].detachment?.agencyId;
          detachmentId = this.vehicleSeizure.policeServiceInformation[0].detachmentId;
        }
        
        this.policeServiceInformation.push(new SectionDetailsModel('Name of Police Officer', policeOfficerName));
        this.policeServiceInformation.push(new SectionDetailsModel('Police File Number', this.vehicleSeizure.policeFileNumber));
        this.policeServiceInformation.push(new SectionDetailsModel('Regimental Number', regimentalNumber));
        this.policeServiceInformation.push(new SectionDetailsModel('Police Service', this.localStorageService.getAgencies().find(x => +x.agencyId == +agencyId)?.agencyName));
        this.policeServiceInformation.push(new SectionDetailsModel('Detachment', this.localStorageService.getDetachments().find(x => +x.detachmentId == +detachmentId)?.detachmentName));
        if (this.additionalFieldsActive) {
          this.officerDeclarations.push(new SectionDetailsModel('Name of Police Officer', policeOfficerName));
          this.officerDeclarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(this.vehicleSeizure.submissionDate, 'dd MMM, yyyy')));
        }
        this.officerDeclarations.push(new SectionDetailsModel(officerSubmissionDeclaration, this.vehicleSeizure.isTrueInformation? "Yes": "No", true));

        if (this.vehicleSeizure.officerAdditionalDetailsDeclarationTypeId) {
          const officerAdditionalDetailsDeclaration = declarationTypes?.find(d => d.id == this.vehicleSeizure.officerAdditionalDetailsDeclarationTypeId).description;
          this.officerDeclarations.push(new SectionDetailsModel('Name of Police Officer', policeOfficerName));
          this.officerDeclarations.push(new SectionDetailsModel('Date of Additional Details', this.datePipe.transform(this.vehicleSeizure.additionalDetailsDate, 'dd MMM, yyyy')));
          this.officerDeclarations.push(new SectionDetailsModel(officerAdditionalDetailsDeclaration, "Yes", true));
        }
      } else {
        this.router.navigate(['../search']);
      }
      this.spinner.hide();
    });
  }

  setStatusFlags() {
    let thisReview: Review;

    if (+this.seizureUserType == SeizureUserType.RegisteredOwner) {
      thisReview = this.vehicleSeizure.relief;
    } else {
      thisReview = this.vehicleSeizure.review;
    }

    if (thisReview && thisReview.outcome) {
      this.isReviewAbandoned = (thisReview.outcome.reviewOutcomeTypeId == ReviewOutcomeTypes.DeemedAbandoned);
    }

    // check if review was cancelled
    if (thisReview != null) {
      this.isReviewCancelled = thisReview.reviewStatusTypeId == ReviewStatusTypes.Cancelled;
    }
  }

  getRegisteredOwnerSeizureNotice() : Document {
    var seizureDocument = this.vehicleSeizure.documents.find(d => 
      d.documentTypeId == DocumentTypes.SeizureNoticeRegisteredOwnerCopy && d.documentReferenceNumber == this.seizureNumber);

    if (seizureDocument == null) {
      seizureDocument = this.vehicleSeizure.documents.find(d => 
        d.documentTypeId == DocumentTypes.OriginalPaperSeizure && d.documentReferenceNumber == this.seizureNumber);
    }

    if (seizureDocument == null) {
      seizureDocument = this.vehicleSeizure.documents.find(d => d.documentTypeId == DocumentTypes.OriginalPaperSeizure);
    }

    return seizureDocument;
  }

  downloadDocument(supportingDocument: DocumentModel) {
    var documentRefTypeName: string;
    var documentRefTypeNumber: string;

    documentRefTypeName = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? this.getDocumentRefName() : "Stop";
    documentRefTypeNumber = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? this.getDocumentRefNumber() : this.vehicleSeizure.stopInformationId.toString();

    var storageFileName = `${supportingDocument.contentGuid}.${supportingDocument.documentExtension}`;

    if (supportingDocument.version == Constants.DocumentVersion.VERSION3)
    {
     this.documentService.getDocumentSasUri("", documentRefTypeName, documentRefTypeNumber, storageFileName , supportingDocument.documentName)
     .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
     });

      return;
    }

    this.documentService.downloadDocument("", documentRefTypeName, documentRefTypeNumber, storageFileName , supportingDocument.documentName, supportingDocument.documentLocation)
      .subscribe((result: any) => {
        if (result) {
          fileSaver.saveAs(new Blob([result]), supportingDocument.documentName);
        }
      });
  }

  getDocumentRefNumber(): string {
    if (this.vehicleSeizure.seizureTypeId == SeizureTypes.IRSContraventionSelected)
      return this.vehicleSeizure.contraventionNumber;

    return this.vehicleSeizure.seizureNumber;
  }

  getDocumentRefName(): string {
    if (this.vehicleSeizure.seizureTypeId == SeizureTypes.IRSContraventionSelected)
      return "Contraventions";

    return "VehicleSeizures";
  }

  startLateReviewRequest() {
    const requestTypes = this.localStorageService.getRequestTypes();
    let requestFeeAmount = 0;

    requestFeeAmount = requestTypes.find(t => t.id == RequestTypes.LateSeizureReview).requestFee;

    // Initialize late review request
    const lateReviewRequest= new LateReviewRequest({
      feeAmount: requestFeeAmount,
      isSelfSubmit: true,
      contactMethodTypeId: null,
      paymentRequest: new OnlinePaymentRequest({
        paymentAmount: requestFeeAmount
      }),
      lateReviewRequestItems: [], 
      termsConditionsVersion: this.getLatestTermsAndConditionsVersion()
    });

    lateReviewRequest.lateReviewRequestItems.push(new LateReviewRequestItem({
      recordNumber: this.vehicleSeizure.seizureNumber,
    }));

    // Save request to local storage
    this.localStorageService.setLateReviewRequest(lateReviewRequest);

    //Initialize temporary folder location to upload documents
    this.localStorageService.setTempFolderName(Guid.create().toString())

    // Navigate to late review request landing page
    if (this.isRSGPortal)
      this.router.navigate(['late-review-request/apply/terms'], {relativeTo: this.route}); 
    else
      this.router.navigate(['late-review-request/apply/details'], {relativeTo: this.route}); 
  }

  startReviewApplication() {
    if (+this.seizureUserType == SeizureUserType.RegisteredOwner) {
      this.startReliefRequest();
    } else {
      this.startReviewRequest();
    }
  }

  startReliefRequest() {
    const reviewTypes = this.localStorageService.getReviewTypes();
    const reviewFeeAmount = reviewTypes.find(t => t.id == ReviewTypes.ThirdPartySeizureReview).reviewFee;

    // Initialize review request
    const review = new Review({
      seizureNumber: this.vehicleSeizure.seizureNumber,
      reviewTypeId: ReviewTypes.ThirdPartySeizureReview,
      reviewFeeAmount: reviewFeeAmount,
      reviewMethodTypeId: ReviewMethodTypes.Written,
      isSelfSubmit: true,
      isVehicleStolen: null,
      isVehicleTakenWithoutPermission: null,
      hasRentalAgreement: null,
      contactMethodTypeId: null,      
      paymentRequest: new OnlinePaymentRequest({
        paymentAmount: reviewFeeAmount
      }),
      reviewApplicant: new ReviewApplicant({
        vehicleInterestTypeId: null
      }),
      reviewItems: [],
      termsConditionsVersion: this.getLatestTermsAndConditionsVersion()
    });

    review.reviewItems.push((
      new ReviewItem({
        recordNumber: this.vehicleSeizure.seizureNumber,
        isSelected: true
      })
    ));

    // Save request to local storage
    this.localStorageService.setReviewRequest(review);

    //Initialize temporary folder location to upload documents
    this.localStorageService.setTempFolderName(Guid.create().toString())

    // Navigate to review request landing page
    if (this.isRSGPortal)
      this.router.navigate(['relief/apply/terms'], {relativeTo: this.route}); 
    else
      this.router.navigate(['relief/apply/details'], {relativeTo: this.route}); 
  }

  startReviewRequest() {
    const reviewTypes = this.localStorageService.getReviewTypes();
    const reviewFeeAmount = reviewTypes.find(t => t.id == ReviewTypes.SuspendedDriverVehicleSeizureReview).reviewFee;

    // Initialize review request
    const review= new Review({
      seizureNumber: this.vehicleSeizure.seizureNumber,
      reviewTypeId: ReviewTypes.SuspendedDriverVehicleSeizureReview,
      reviewFeeAmount: reviewFeeAmount,
      reviewMethodTypeId: ReviewMethodTypes.Written,
      isSelfSubmit: true,
      isVehicleStolen: null,
      isVehicleTakenWithoutPermission: null,
      hasRentalAgreement: null,
      contactMethodTypeId: null,      
      paymentRequest: new OnlinePaymentRequest({
        paymentAmount: reviewFeeAmount
      }),
      reviewApplicant: new ReviewApplicant({
        vehicleInterestTypeId: null
      }),
      reviewItems: [],
      termsConditionsVersion: this.getLatestTermsAndConditionsVersion()
    });

    review.reviewItems.push(new ReviewItem({
      recordNumber: this.vehicleSeizure.seizureNumber,
      isSelected: true
    }));

    // Save request to local storage
    this.localStorageService.setReviewRequest(review);

    //Initialize temporary folder location to upload documents
    this.localStorageService.setTempFolderName(Guid.create().toString())

    // Navigate to review request landing page
    if (this.isRSGPortal)
      this.router.navigate(['review/apply/terms'], {relativeTo: this.route}); 
    else
      this.router.navigate(['review/apply/details'], {relativeTo: this.route}); 
  }

  getDocumentTypeName(supportingDocument: Document): string {
    var documentTypeName = `${this.localStorageService.getDocumentTypes().find(x => x.id == supportingDocument.documentTypeId)?.name}
      ${(supportingDocument.documentDescription == null || supportingDocument.documentDescription == "")? "" : " " +  supportingDocument.documentDescription}`;

    return documentTypeName;
  }

  getLatestTermsAndConditionsVersion(): string {
    return this.localStorageService
               .getTermsAndConditionsList()
               .find(t => t.expiryDate == null)
               .version;
  }

  viewActivityHistory() {
    this.router.navigate(['activity-history'], {relativeTo: this.route});
  }

  onSafeRoadsContactClick()
  {
    $('#safeRoadsContactModal').modal();
  }

  viewReviewDetails() {
    if (+this.seizureUserType == SeizureUserType.RegisteredOwner) {
      this.router.navigate(['review', this.vehicleSeizure.relief.reviewSequence, 'details'], {relativeTo: this.route});
    } else {
      this.router.navigate(['review', this.vehicleSeizure.review.reviewSequence, 'details'], {relativeTo: this.route});
    }    
  }
}

<h1 class="mb-3">Activity History</h1>
<h3 class="mt-0 mb-3">No. {{this.seizureNumber}}</h3>
<div class="border-top mb-4"></div>
<div class="card">
    <div class="card-header">
        Activity History
    </div>
    <div class="card-body">
        <div *ngIf="activityHistory">
            <div class="goa-table history-table mt-0">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th class="border-0">Activity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of activityHistory.activities">
                            <td class="date-cell">{{ a.activityDate | date:'mediumDate' }}</td>
                            <td class="text-wrap">
                                <div *ngIf="a.activityType == ActivityType.ReviewCancelledByRecipient">
                                    Review cancelled by recipient
                                </div>
                                <div *ngIf="a.activityType == ActivityType.ReviewDecisionUploaded">
                                    <a class="btn-link doc-content" title="{{a.document.documentName}}" (click)="downloadActivityDocument(a)">
                                        Review decision
                                    </a> uploaded
                                </div>
                                <div *ngIf="a.activityType == ActivityType.AppliedForReview">
                                    Review requested 
                                    <a class="btn-link doc-content" (click)="viewReviewFeePaymentReceipt(a)" *ngIf="!isRSGPortal">
                                        (Download Receipt)
                                    </a>
                                </div>
                                <div *ngIf="a.activityType == ActivityType.LateReviewRequested">
                                    Late review requested
                                    <a class="btn-link doc-content" (click)="viewRequestFeePaymentReceipt(a)" *ngIf="!isRSGPortal">
                                        (Download Receipt)
                                    </a>
                                </div>
                                <div *ngIf="a.activityType == ActivityType.LateReviewDecisionUploaded">
                                    <a class="btn-link doc-content" title="{{a.document.documentName}}" (click)="downloadActivityDocument(a)">
                                        Late review decision
                                    </a> uploaded
                                </div>
                                <div *ngIf="a.activityType == ActivityType.JudicialReviewServed">
                                    Judicial review served
                                </div>
                                <div *ngIf="a.activityType == ActivityType.JudicialReviewDecisionIssued">
                                    <a class="btn-link doc-content" title="{{a.document.documentName}}" (click)="downloadActivityDocument(a)">
                                        Judicial review decision
                                    </a> rendered
                                </div>
                            </td>
                        </tr>                            
                        <tr *ngIf="activityHistory.activities == null || activityHistory.activities.length == 0">
                            <td colspan="2">No history found.</td>
                        </tr>
                    </tbody>                        
                </table>
            </div>
        </div>            
    </div>
</div>
<div class="d-flex align-self-start">
    <div>
        <button class="goa-button goa--secondary btn-block" [routerLink]="['../']">Back to Vehicle Seizure Details</button>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/observable/of';

import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { APP_CONFIG } from '@apis/app-config';
import { PaymentTimeRequest } from '@apis/shared/models/payment-time-request.model';
import { PaymentAdditionalTimeRequest } from '@apis/shared/models/payment-additional-time-request.model';
import { PaymentRequest } from '@apis/shared/models/payment-request.model';
import { PaymentResponse } from '@apis/shared/models/payment-response.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Review } from '@apis/shared/models/review.model';
import { map, catchError } from 'rxjs/operators';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { AvailabilityView } from '@apis/shared/models/availability-view';

@Injectable()
export class VehicleSeizureService {
  private readonly apiUrl = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;
  private vehicleSeizure: VehicleSeizure = null; // for caching

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {}

  handleError(error: any): Observable<never> {
    if(error && error.status === 401) {
      window.location.href = '/vehicle-seizures/search';
    }

    return throwError(error);
  }

  checkBirthDate(seizureNumber: string, birthDate: string, userType: string): Observable<any> {
    return this.httpClient.head(`${this.apiUrl}/driver/vehicle-seizures/${seizureNumber}`,
    {
        params: {
          "birthDate": birthDate,
          'userType': userType
        }        
    }).pipe(catchError(this.handleError));
  }

  getByNumber(seizureNumber: string, forceReload: boolean = false): Observable<VehicleSeizure> {
    if (this.vehicleSeizure && this.vehicleSeizure.seizureNumber === seizureNumber && !forceReload) {
      return Observable.of(this.vehicleSeizure);
    }

    return this.httpClient.get<VehicleSeizure>(`${this.apiUrl}/driver/vehicle-seizures/${seizureNumber}`,
    {
        params: {
        }
    }).pipe(catchError(this.handleError));
  }

  requestReview(review: Review) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/vehicle-seizures/${review.seizureNumber}/reviews`,
      review,
      httpOptions
    );
  }

  requestLateReview(seizureNumber: string, review: LateReviewRequest) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/vehicle-seizures/${seizureNumber}/late-review-requests`,
      review,
      httpOptions
    );
  }

  getActivityHistory(seizureNumber: string, userType: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/vehicle-seizures/${seizureNumber}/activity-history`,
      {
          params: {
          'userType': userType
        }
      });
  }

  getReviewPaymentReceipt(seizureNumber: string, reviewNumber: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/vehicle-seizures/${seizureNumber}/reviews/${reviewNumber}/payment-receipt`,
      {
          params: {
          },
          responseType: 'blob'
      });
  }

  getLateReviewRequestPaymentReceipt(seizureNumber: string, requestNumber: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/vehicle-seizures/${seizureNumber}/late-review-requests/${requestNumber}/payment-receipt`,
      {
          params: {
          },
          responseType: 'blob'
      });
  }

  getReviewAvailability(seizureNumber: string, reviewTypeId: number, reviewMethodTypeId: number, currentScheduledEventId: number): Observable<AvailabilityView> {
    let params = new HttpParams();
    params = params.append('reviewTypeId', reviewTypeId.toString());
    params = params.append('reviewMethodTypeId', reviewMethodTypeId.toString());
    if (currentScheduledEventId) {
      params = params.append('currentScheduledEventId', currentScheduledEventId.toString());
    }

    return this.httpClient.get<AvailabilityView>(`${this.apiUrl}/vehicle-seizures/${seizureNumber}/review/availability`, { 
      params: params      
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { PaymentBaseComponent } from '../../../../contravention/payment/payment-base.component';
import { ContraventionPageComponent } from '../../../../contravention/contravention-page/contravention-page.component';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { RegistryService } from 'apps/driver/src/app/shared/services/registry.service';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';

declare var $: any

@Component({
  selector: 'app-rsg-payment-review',
  templateUrl: './rsg-payment-review.component.html',
  styleUrls: ['./rsg-payment-review.component.scss'],
})
export class RsgPaymentReviewComponent extends PaymentBaseComponent
  implements OnInit {
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;
  
  isPaymentFailed: boolean;
  payerInformation: SectionDetailsModel[] = [];
  registryAgentInformation: RegistryAgentInformation;
  errorMessage = '';

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    localStorage: LocalStorageService,
    public registryService: RegistryService
  ) {
    super(router, route, localStorage);
  }

  ngOnInit(): void {
    if (!this.localStorage.getRegistryAgentProfile())
      this.router.navigate(['/unauthorized']);

    this.registryAgentInformation = this.paymentRequest.registryAgentInformation[0];

    // Payer Section
    this.payerInformation.push(new SectionDetailsModel('First Name', this.registryAgentInformation.payerFirstName));
    this.payerInformation.push(new SectionDetailsModel('Last Name', this.registryAgentInformation.payerLastName));
    this.payerInformation.push(new SectionDetailsModel('MVID', this.registryAgentInformation.payerMVID));
  }

  onSubmitPaymentClick(isValid: boolean) {
    if (isValid) {
      this.submitPayment();
    }
  }

  onConfirmClick() {
    this.submitPayment();
  }

  submitPayment() {
    this.masterPage.showSpinner();
    this.paymentRequest.paymentAmount = Number.parseFloat(
      this.paymentRequest.paymentAmount.toString()
    );

    //Filter only the selected items
    this.paymentRequest.lineItems = this.paymentRequest.lineItems.filter(s => s.isChecked);
    
    this.registryService
      .processPayment(this.paymentRequest)
        .subscribe((result) => {
        if (!result.isTransactionApproved) {      
          this.isPaymentFailed = true;    
          
          // If overpayment or partial payment then set error message and clear session
          if(result.errorCode == 6002 || result.errorCode == 6003 || result.errorCode == 6004 || result.errorCode == 1101 || result.errorCode == 1102) {
            this.errorMessage = result.errorMessage;
            this.localStorage.setContraventionPaymentRequest(null);
          }

          this.masterPage.hideSpinner();

          window.scroll(0, 0);
          } else {
            this.localStorage.setContraventionPaymentRequest(null);

          // Clear TransactionId to avoid any further transaction with same fulfillment ID
          var rsgAgentProfile = this.localStorage.getRegistryAgentProfile();
          if (rsgAgentProfile)
          {
            rsgAgentProfile.transactionId = null;
            this.localStorage.setRegistryAgentProfile(rsgAgentProfile);
          }

          this.router.navigate(['../success'], {
            relativeTo: this.route,
            queryParams: { transId: result.financialTransactionId },
          });
        }
      },
      (error: any) => {
        this.masterPage.hideSpinner();
        if (error.status == 403 || error.status == 401) {
          this.localStorage.logout();
          this.router.navigate(['/session-expired']);
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistryService } from '../../../../shared/services/registry.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-rsg-contravention-lookup',
  templateUrl: './rsg-contravention-lookup.component.html',
  styleUrls: ['./rsg-contravention-lookup.component.scss']
})
export class RsgContraventionLookupComponent implements OnInit {
  searching = false;
  isContraventionNotFound: boolean;
  isServerErr: boolean;
  errorMessage: string;
  captchaError: string;
  contraventionNumber: string;
  
  constructor(private router: Router,
      private readonly spinner: NgxSpinnerService,
      private registryService: RegistryService,
      private localStorageService: LocalStorageService) {

       }

  ngOnInit(): void {
  }

  onSearchClick(isValid: boolean) {
    if (isValid) {
      this.resetFlags();

      this.spinner.show(); 
      
      this.contraventionNumber = this.contraventionNumber.toUpperCase();

      this.registryService.checkContraventionNumberAsync(this.contraventionNumber)
      .subscribe(
        (result: boolean) => {
          this.isContraventionNotFound = !result;
          this.spinner.hide();
          if (result) {
            this.localStorageService.setEnableLicencePickupModal(true);
            this.router.navigate(['/rsg/contravention', this.contraventionNumber]);
          }
        },
        (error: any) => {
          if (error.status == 403 || error.status == 401) {
            this.localStorageService.logout();
            this.router.navigate(['/session-expired']);
          }
          else {
            this.isServerErr = true;
            this.errorMessage = "An error occurred while processing your request. Please try again.";
          }
          this.spinner.hide();
      });
    }
  }

  resetFlags() {
    this.isContraventionNotFound = false;
    this.isServerErr = false;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Recipient } from '@apis/shared/models/recipient.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { SeizureLateReviewBaseComponent } from '../seizure-late-review-base.component';
import { RegisteredOwner } from '@apis/shared/models/registered-owner.model';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { VehicleSeizureService } from 'apps/driver/src/app/shared/services/vehicle-seizure.service';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { VehicleSeizurePageComponent } from '../../vehicle-seizure-page/vehicle-seizure-page.component';
import { Document } from '@apis/shared/models/document.model';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from 'file-saver';
import { RegistryService } from 'apps/driver/src/app/shared/services/registry.service';
import { RepresentationTypes } from '@apis/shared/enums/app.enum';

@Component({
  selector: 'app-seizure-late-review-summary',
  templateUrl: './seizure-late-review-summary.component.html',
  styleUrls: ['./seizure-late-review-summary.component.scss']
})
export class SeizureLateReviewSummaryComponent extends SeizureLateReviewBaseComponent
implements OnInit {
  @ViewChild('masterPage') masterPage: VehicleSeizurePageComponent;
  
  reviewTypeInformation: SectionDetailsModel[] = [];
 representationInformation: SectionDetailsModel[] = [];
 reviewsDetailsInformation: SectionDetailsModel[] = [];
 requestInformation: SectionDetailsModel[] = [];
 recipientInformation: SectionDetailsModel[] = [];
 recipientIdentificationInformation: SectionDetailsModel[] = [];
 contraventionInformation: SectionDetailsModel[] = [];
 reasonForRequestInformation: SectionDetailsModel[] = [];
 preferredContactInformation: SectionDetailsModel[] = [];
 termsConditionsInformation: SectionDetailsModel[] = [];
 preferredReviewTimesSection: SectionDetailsModel[] = [];
 applicantInformation: SectionDetailsModel[] = [];
 registeredOwnerInformation: SectionDetailsModel[] = [];
 vehicleInformation: SectionDetailsModel[] = [];
 vehicleSeizureDetails: SectionDetailsModel[] = [];
 requesterInformation: SectionDetailsModel[] = [];
 supportingDocuments: DocumentModel[] = [];

 recipient: Recipient;
 registeredOwner: RegisteredOwner;

 isRequestFailed: boolean;
 isPaymentFailed: boolean;
 isSubmitClicked: boolean = false;
 
 constructor(
   public router: Router,
   private datePipe: DatePipe,
   private currencyPipe: CurrencyPipe,
   public activatedRoute: ActivatedRoute,
   public localStorageService: LocalStorageService,
   private vehicleSeizureService: VehicleSeizureService,
   private registryService: RegistryService,
    private documentService: DocumentService,
    private readonly spinner: NgxSpinnerService
    ) { 
   super(router, activatedRoute, localStorageService);
 }

 ngAfterViewInit(): void {
   if (!this.isRSGPortal)
   {
    const cardLogoDiv = document.getElementById("cardLogoDiv") as HTMLImageElement;
    cardLogoDiv.style.backgroundImage = this.lateReviewRequest.paymentRequest['cardLogo'];
   }
 }

 ngOnInit(): void {
   this.recipient = this.vehicleSeizure.recipient;
   this.registeredOwner = this.vehicleSeizure.vehicle.registeredOwner;      

   // Request Information
   this.reviewTypeInformation.push(new SectionDetailsModel('Request Type', 
     this.lateReviewRequest.isSelfSubmit ? "I am submitting this application on behalf of myself" : 
                                "I am submitting this application on behalf of another",
     true
   ));

   if (this.lateReviewRequest.representation) {
     // Representation Informaton
     const repTypeId = this.lateReviewRequest.representation.representationTypeId;

     const repTypeName = this.localStorageService.getRepresentationTypes()
                             .find(x=>x.id == repTypeId)?.name;

     this.representationInformation.push(new SectionDetailsModel('Type of Acting Representation', repTypeName, true));

     this.representationInformation.push(new SectionDetailsModel(`Representative First Name`, this.lateReviewRequest.representation.firstName));
     this.representationInformation.push(new SectionDetailsModel(`Representative Last Name`, this.lateReviewRequest.representation.lastName));

     if (+repTypeId === RepresentationTypes.Agent || +repTypeId === RepresentationTypes.Lawyer){
       this.representationInformation.push(new SectionDetailsModel(`Practice Name`, this.lateReviewRequest.representation.practiceName, true));
     }

     if (+repTypeId === RepresentationTypes.Other){
       this.representationInformation.push(new SectionDetailsModel('Other Representation Type', this.lateReviewRequest.representation.otherRepresentationType, true));
     }

     /*
     //Requester Information
     this.requesterInformation.push(new SectionDetailsModel('Applicant', 
     this.lateReviewRequest.representation.isPaidByRecipient ? "Recipient (Driver)" : "Someone else", true));

     if (!this.lateReviewRequest.representation.isPaidByRecipient)
     {
       this.requesterInformation.push(new SectionDetailsModel('Surname', this.lateReviewRequest.representation.payerLastName));
       this.requesterInformation.push(new SectionDetailsModel('First Name', this.lateReviewRequest.representation.payerFirstName));
       this.requesterInformation.push(new SectionDetailsModel('MVID', this.lateReviewRequest.representation.payerMVID));
     }
     */
   }

  // Driver Information
  this.recipientInformation.push(new SectionDetailsModel('Surname', this.recipient.lastName));
  this.recipientInformation.push(new SectionDetailsModel('First Name', this.recipient.firstName));
  this.recipientInformation.push(new SectionDetailsModel('Middle Name', this.recipient.otherName));
  this.recipientInformation.push(new SectionDetailsModel('Email Address', this.lateReviewRequest.driverEmail));

  // Driver Licence Information
  this.recipientIdentificationInformation.push(new SectionDetailsModel('Driver Licence Number', this.recipient.recipientIdentification?.identificationNumber));
  this.recipientIdentificationInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.recipient.recipientIdentification?.dateOfBirth, 'mediumDate')));
  this.recipientIdentificationInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x=>x.id == this.recipient.recipientIdentification?.genderId)?.name));

   // Vehicle Seizure Details
   this.vehicleSeizureDetails.push(new SectionDetailsModel('Seizure Number', this.vehicleSeizure?.seizureNumber));
   this.vehicleSeizureDetails.push(new SectionDetailsModel('Seizure Date', this.datePipe.transform(this.vehicleSeizure?.seizureDate, 'dd MMM, yyyy')));
   this.vehicleSeizureDetails.push(new SectionDetailsModel('Seizure Time', this.vehicleSeizure?.seizureTime));
   this.vehicleSeizureDetails.push(new SectionDetailsModel('Immediate Seizure Duration', this.vehicleSeizure.seizureDuration.toString() + ' ' + this.vehicleSeizure.seizureDurationType));
   this.vehicleSeizureDetails.push(new SectionDetailsModel('Vehicle Release Date', this.datePipe.transform(this.vehicleSeizure?.vehicleReleaseDate, 'dd MMM, yyyy')));
   this.vehicleSeizureDetails.push(new SectionDetailsModel('Location of Seizure', this.localStorageService.getLocationTypes().find(x => x.id == this.vehicleSeizure?.locationTypeId)?.name, true));

   // Vehicle Information
   this.vehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.vehicleSeizure?.vehicle?.licencePlateNumber));
   this.vehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.vehicleSeizure?.vehicle?.vehicleIdentificationNumber));
   this.vehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.vehicleSeizure?.vehicle?.licensedCountryId == 40 || this.vehicleSeizure?.vehicle?.licensedCountryId == 236)? (this.vehicleSeizure?.vehicle?.licensedProvinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.vehicleSeizure?.vehicle?.licensedProvinceId)?.name) : this.vehicleSeizure?.vehicle?.licensedProvince));
   this.vehicleInformation.push(new SectionDetailsModel('Make', this.vehicleSeizure?.vehicle?.make));
   this.vehicleInformation.push(new SectionDetailsModel('Model', this.vehicleSeizure?.vehicle?.model));
   this.vehicleInformation.push(new SectionDetailsModel('Year', this.vehicleSeizure?.vehicle?.year?.toString()));

   // Review Details Information
   this.reviewsDetailsInformation.push(new SectionDetailsModel('Review Fee Amount', 
      this.currencyPipe.transform(this.lateReviewRequest.paymentRequest.paymentAmount), true));
   this.reviewsDetailsInformation.push(new SectionDetailsModel('Describe the Reason for Your Request', 
       this.lateReviewRequest.requestReason, true));
       
   // Preferred Contact Information
   this.preferredContactInformation.push(new SectionDetailsModel('Preferred Contact Method', 
    CommonUtil.getContactMethodTypeName(this.lateReviewRequest.contactMethodTypeId)));
   if (this.lateReviewRequest.phoneNumber) {
     this.preferredContactInformation.push(new SectionDetailsModel('Phone Number', this.lateReviewRequest.phoneNumber));
   }
   else {
     this.preferredContactInformation.push(new SectionDetailsModel('Email Address', this.lateReviewRequest.email));
   }

  // Supporting Documents Section
  this.lateReviewRequest.documents?.forEach(document => {
    var documentType : DocumentType = this.localStorageService.getDocumentTypes().find(x=> x.id == document.documentTypeId);
    this.supportingDocuments.push(new DocumentModel(documentType?.name, document.documentName, document.documentExtension, document.contentGuid, false));
  });

   // Terms & Conditions
   this.termsConditionsInformation.push(new SectionDetailsModel(this.isRSGPortal? 'The Registry Agent provided the Terms & Conditions to the client': 'I acknowledge the SafeRoads Alberta Terms and Conditions.', "Yes",true));

 }

 submitRequest() {
    this.masterPage.showSpinner();

    // Set temp folder
    this.lateReviewRequest.tempFolderName = this.tempFolderName;

    // Set document uploaded_date and uploaded_by
    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.lateReviewRequest.documents?.filter(x=>(x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
      document.uploadedDate = today;
      document.uploadedBy = "Recipient";
    });

    this.vehicleSeizureService
      .requestLateReview(this.seizureNumber, this.lateReviewRequest)
      .subscribe(
        (result: any) => {
          this.masterPage.hideSpinner();

        // Update late review request number
        this.lateReviewRequest.lateReviewRequestNumber = result.recordNumber;

        this.saveContext();

          this.router.navigate(['../submit-complete'], {
            relativeTo: this.activatedRoute,
          });
        },
        (error) => {
          if (error.error && error.error.errorCode == "6001") {
            this.isPaymentFailed = true;
          } else {
            this.isRequestFailed = true;
          }
          this.masterPage.hideSpinner();

          window.scroll(0,0);
        }
      );
 }

 submitRegistryRequest(isValid: boolean) {
  this.isSubmitClicked=true;
  if (isValid)
  {
    this.masterPage.showSpinner();

    // Set temp folder
    this.lateReviewRequest.tempFolderName = this.tempFolderName;

    // Set document uploaded_date and uploaded_by
    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.lateReviewRequest.documents?.filter(x=>(x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
      document.uploadedDate = today;
      document.uploadedBy = "Registry";
    });

    this.registryService
      .requestSeizureLateReview(this.seizureNumber, this.lateReviewRequest)
      .subscribe(
        (result: any) => {
          this.masterPage.hideSpinner();

          // Update late review request number
          this.lateReviewRequest.lateReviewRequestNumber = result.recordNumber;
          this.saveContext();

          // Clear TransactionId to avoid any further transaction with same fulfillment ID
          var rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
          if (rsgAgentProfile)
          {
            rsgAgentProfile.transactionId = null;
            this.localStorageService.setRegistryAgentProfile(rsgAgentProfile);
          }

          this.router.navigate(['../submit-complete'], {
            relativeTo: this.activatedRoute,
          });
        },
        (error) => {
          this.isRequestFailed = true;
          this.masterPage.hideSpinner();
          window.scroll(0,0);
        }
      );
    }
  }

 DownloadDocument(document: Document)
  {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;
      this.documentService.downloadDocument(this.tempFolderName, "", "", storageFileName , document.documentName)
      .subscribe((result: any) => {
        if (result)
        { 
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      });
  }
}


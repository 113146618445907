import { HostListener } from '@angular/core';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  ActivatedRoute,
  Data,
  NavigationEnd,
  ResolveEnd,
  Route,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SettingService } from '@apis/shared/services/setting.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LookupService } from './shared/services/lookup.service';
import { Constants } from "@apis/shared/helpers/constants"
import { Setting } from '@apis/shared/models/setting.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  routerSub: any;
  routeData: any;
  showStepper = true;
  routeSnapshot = new BehaviorSubject<any>(null);

  constructor(
    private readonly router: Router,
    private readonly lookupService: LookupService,
    private readonly localStorageService: LocalStorageService,
    private readonly settingService: SettingService
  ) {}

  ngOnInit() {
    this.settingService.getSettings()
    .subscribe((settings: Setting[]) => {
      if (settings) {
        const lookupVersion = settings.find(s => s.settingName == Constants.Settings.LOOKUP_VERSION)?.settingValue;

        // Compare the version of lookups in client and server
        //  If the versions are different, clear localstorage and get latest lookups.
        if (this.localStorageService.get(Constants.Settings.LOOKUP_VERSION) != lookupVersion) {
          // Clear localstorage          
          localStorage.clear();

          // Get lookups
          this.lookupService.setLookups();
          this.lookupService.getHolidayList();
          this.lookupService.getTermsAndConditionsList();

          // Update the lookup version in client
          this.localStorageService.set(Constants.Settings.LOOKUP_VERSION, lookupVersion);
        }
      }
    },
    error => {
      // TODO: Display Error Page
    });

    this.routerSub = this.router.events.subscribe((evt) => {
      if (evt instanceof RoutesRecognized) {
        this.routeData = {};

        this.findStateData(evt.state.root.firstChild);

        if (this.routeData && this.routeData['showStepper']) {
          this.showStepper = this.routeData['showStepper'];
        } else {
          this.showStepper = false;
        }

        this.routeSnapshot.next(evt.state.root.firstChild);
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }

  @HostListener("window:onbeforeunload",["$event"])
  clearLocalStorage(event){
      localStorage.clear();
  }

  findStateData(firstChild: any) {
    if (!firstChild) {
      return;
    }
    if (firstChild.data) {
      for (let key in firstChild.data) {
        if (firstChild.data.hasOwnProperty(key)) {
          this.routeData[key] = firstChild.data[key];
        }
      }
    }
    this.findStateData(firstChild.firstChild);
  }
}

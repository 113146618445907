<div>
    <div class="row">
        <div class="col contravention-section">
            <span class="contravention-number">Contravention Number: <a [routerLink]="['detail']">{{contraventionInformation.contraventionNumber}}</a></span>
            <span class="badge badge-pill badge-status" 
            [class.badge-light]="contraventionInformation.contraventionStatusTypeId == ContraventionStatusTypes.Issued"
            [class.badge-warning]="contraventionInformation.contraventionStatusTypeId == ContraventionStatusTypes.Unpaid"
            [class.badge-review]="contraventionInformation.contraventionStatusTypeId == ContraventionStatusTypes.InReview"
            [class.badge-danger]="contraventionInformation.contraventionStatusTypeId == ContraventionStatusTypes.Overdue"
            [class.badge-success]="contraventionInformation.contraventionStatusTypeId == ContraventionStatusTypes.Paid"
            [class.badge-secondary]="contraventionInformation.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled"
            [class.badge-info]="contraventionInformation.contraventionStatusTypeId == ContraventionStatusTypes.Stay"
            [class.badge-uncollectable]="contraventionInformation.contraventionStatusTypeId == ContraventionStatusTypes.Uncollectable">{{contraventionStatusTypeName | uppercase}}</span>
            <span *ngIf="contraventionInformation.isJudicialReviewServed" class="jr-badge">
                <img class="jr-badge-flag" src="assets/images/triangular-flag-white.svg" />
                JR Served
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5 d-flex flex-column">
            <span class="heading">Traffic Safety Act Section</span>
            <div class="charge-section d-flex flex-column">
                <span class="charge-code">{{getChargeCode(contraventionInformation.contraventionTypeId)}}</span>
                <span>{{getChargeDescription(contraventionInformation.contraventionTypeId)}}</span>
            </div>
            <div class="charge-section d-flex flex-column" *ngIf="contraventionInformation.secondaryContraventionTypeId">
                <span class="charge-code">{{getChargeCode(contraventionInformation.secondaryContraventionTypeId)}}</span>
                <span>{{getChargeDescription(contraventionInformation.secondaryContraventionTypeId)}}</span>
            </div>
            <div class="charge-section d-flex flex-column" *ngIf="contraventionInformation.tertiaryContraventionTypeId">
                <span class="charge-code">{{getChargeCode(contraventionInformation.tertiaryContraventionTypeId)}}</span>
                <span>{{getChargeDescription(contraventionInformation.tertiaryContraventionTypeId)}}</span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="d-flex flex-column">
                <div class="d-flex flex-column demerits-section" *ngIf="!isIrs">
                    <span class="heading">Demerits</span>
                    <span *ngIf="contraventionInformation.demerits; else nullContent" >{{contraventionInformation.demerits}}</span>
                    <ng-template #nullContent>-</ng-template>
                </div>
                <div class="d-flex flex-column flex-grow-1 fine-setion">
                    <span class="heading">Fine</span>
                    <div class="fine-field">
                        <span>Fine Amount</span>
                        <span>{{contraventionInformation.fineAmount | currency}}</span>
                    </div>
                    <div class="fine-field">
                        <span>VFS</span>
                        <span>{{contraventionInformation.victimFineSurchargeAmount | currency}}</span>
                    </div>
                    <div class="fine-field">
                        <span>Paid</span>
                        <span>({{contraventionInformation.paidAmount | currency}})</span>
                    </div>
                    <hr class="fine-ruler">
                    <div class="fine-field value-strong">
                        <span>Balance Owing</span>
                        <span>{{contraventionInformation.dueAmount | currency}}</span>
                    </div>
                </div>
            </div>            
        </div>
        <div class="col d-flex flex-column">
            <div class="d-flex flex-row">
                <span class="heading pt-1 mb-0">Payment Due</span>
            </div>
            <span class="value-strong mt-1">{{ contraventionInformation.dueDate | date:'longDate' }}</span>
        </div>
    </div>
    <div class="row" *ngIf="contraventionInformation.licencePickupAddress || contraventionInformation.returnLicenceByMail">
        <div class="col">
            <p *ngIf="contraventionInformation.returnLicenceByMail; else pickupAtAddress">
                <strong>Driver's Licence Pick Up:</strong> Your driver's licence will be returned to you by regular mail
                upon the expiry of the driver's licence suspension.
            </p>
            <ng-template #pickupAtAddress>
                <p>
                    <strong>Driver's Licence Pick Up:</strong> Your driver's licence will be returned to you upon the
                    expiry of the driver's licence suspension. If you do not pick up your driver's licence within 7 days
                    at {{this.contraventionInformation.licencePickupDetachment}}, {{this.contraventionInformation.licencePickupAddress}}
                    following the expiry of the driver's licence suspension, your driver's licence will be destroyed. In this case, you
                    must attend an Alberta Registry Agent to purchase and obtain a new driver's licence.
                </p>
            </ng-template>
        </div>
    </div>
</div>

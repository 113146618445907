<!-- Driver Portal View -->
<div class="card mt-4" *ngIf="!isRSGPortal">
    <div class="card-header">
        Preferred Contact Information
    </div>
    <div class="card-body">
        <div class="mb-3">            
            Due to the potential for delayed or missed messages sent via email and SMS, it is your responsibility to check the status of your request in the SafeRoads portal.
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label for="contactMethod" class="required">Preferred Contact Method</label>
                <select class="form-control" id="contactMethod" name="contactMethod" required
                    [(ngModel)]="model.contactMethodTypeId" (change)="onContactMethodChange()"
                    #contactMethod="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && contactMethod.invalid }">
                    <option [ngValue]="null">Select a Method</option>
                    <option *ngFor="let contactMethodType of contactMethodTypes" [value]="contactMethodType.id">{{contactMethodType.name}}</option>
                </select>
                <div class="invalid-feedback">
                    <div>Preferred Contact Method is required</div>
                </div>
            </div>		
        </div>
        <div class="form-group row">
            <ng-container *ngIf="model.contactMethodTypeId == 2 || model.contactMethodTypeId == 3">
                <div class="col-lg-6">
                    <label for="contactPhoneNumber" class="required">Phone Number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend2">+1</span>
                        </div>
                        <input type="text" class="form-control" id="contactPhoneNumber" name="contactPhoneNumber"
                            [(ngModel)]="model.tempPhoneNumber" (ngModelChange)="model.phoneNumber = '1'+model.tempPhoneNumber" 
                            placeholder="numbers only" aria-describedby="inputGroupPrepend2" 
                            required pattern="[0-9]{10}$" maxlength="10"
                            #contactPhoneNumber="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && contactPhoneNumber.invalid }">
                        <div class="invalid-feedback">
                            <div *ngIf="contactPhoneNumber.errors?.required">Phone Number is required</div>
                            <div *ngIf="contactPhoneNumber.errors?.pattern">Invalid Phone Number</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="confirmContactPhoneNumber" class="required">Confirm Phone Number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend3">+1</span>
                        </div>
                        <input type="text" class="form-control" id="confirmContactPhoneNumber" name="confirmContactPhoneNumber"
                            [(ngModel)]="confirmPhoneNumber"
                            placeholder="numbers only" aria-describedby="inputGroupPrepend3" 
                            required pattern="[0-9]{10}$" maxlength="10"
                            #confirmContactPhoneNumber="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && confirmContactPhoneNumber.invalid }">
                        <div class="invalid-feedback">
                            <div *ngIf="confirmContactPhoneNumber.errors?.required">Confirm Phone Number is required</div>
                            <div *ngIf="confirmContactPhoneNumber.errors?.pattern">Invalid Phone Number</div>
                            <div *ngIf="confirmContactPhoneNumber.errors?.mustMatch">Phone Numbers must match</div>
                        </div>
                    </div>
                </div>		
            </ng-container>

            <ng-container *ngIf="model.contactMethodTypeId == 1">
                <div class="col-lg-6">
                    <label for="contactEmailAddress" class="required">Email Address</label>
                    <input type="text" class="form-control" id="contactEmailAddress" name="contactEmailAddress"
                        [(ngModel)]="model.email" required email
                        #contactEmailAddress="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && contactEmailAddress.invalid }">
                    <div class="invalid-feedback">
                        <div *ngIf="contactEmailAddress.errors?.required">Email Address is required</div>
                        <div *ngIf="contactEmailAddress.errors?.email">Please provide a valid email address</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="confirmContactEmailAddress" class="required">Confirm Email Address</label>
                    <input type="text" class="form-control" id="confirmContactEmailAddress" name="confirmContactEmailAddress"
                        [(ngModel)]="confirmEmail" required email
                        #confirmContactEmailAddress="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && confirmContactEmailAddress.invalid }">
                    <div class="invalid-feedback">
                        <div *ngIf="confirmContactEmailAddress.errors?.required">Confirm Email Address is required</div>
                        <div *ngIf="confirmContactEmailAddress.errors?.email">Please provide a valid email address</div>
                        <div *ngIf="confirmContactEmailAddress.errors?.mustMatch">Emails must match</div>
                    </div>
                </div>	                	
            </ng-container>
        </div>

        <ng-container *ngIf="model.contactMethodTypeId ==  1">
            <div class="form-group row">
                <div class="col-12">
                    <p class="mt-0">
                        By selecting email, your {{context}} decision when available, will be uploaded and accessible online.
                    </p>
                </div>  
            </div>
        </ng-container>

        <ng-container *ngIf="model.contactMethodTypeId ==  2">
            <div class="form-group row">
                <div class="col-12">
                    <p class="mt-0">
                        By selecting text, your {{context}} decision when available, will be uploaded and accessible online.
                    </p>
                </div>  
            </div>
        </ng-container>

        <ng-container *ngIf="model.contactMethodTypeId == 3">
            <div class="form-group row">
                <div class="col-12">
                    <p class="mt-0">
                        By selecting phone, your {{context}} decision will be mailed to your address. Please provide the address you wish to receive your {{context}} decision letter.
                        <br>
                        Note: This process will take a longer time than email or text.
                    </p>
                </div>  
            </div>
            <div class="form-group row">
                <div class="col-lg-6">
                    <label for="address1" class="required">Address 1</label>
                    <input type="text" maxlength="44" class="form-control" required
                        id="address1" name="address1" placeholder="Ex. 123 Street or PO Box, Range Road or Rural Route" 
                        [(ngModel)]="model.contactAddress.addressLine1" #address1="ngModel"
                        [ngClass]="{'is-invalid': isSubmitClicked && address1.invalid}">
                    <div class="invalid-feedback">
                        <div>Address 1 is required</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="address2">Address 2</label>
                    <input type="text" maxlength="44" class="form-control" 
                        id="address2" name="address2" placeholder="Ex. Unit Number" [(ngModel)]="model.contactAddress.addressLine2">
                </div>
            </div>
            
            <div class="form-group">
                <app-country-province (onChangeEvent)="refreshContactCountryProvince($event)" [countryProvince]="contactCountryProvince"></app-country-province>
            </div>

            <div class="form-group row">
                <div class="col-lg-6">
                    <label for="city">City/Town/Village <span class="label-hint">(Required)</span></label>
                    <input required #cityControl="ngModel" maxlength="30" type="text" class="form-control" id="city" name="city" placeholder="City/Town/Village" [(ngModel)]="model.contactAddress.city" [ngClass]="{'is-invalid': isSubmitClicked && cityControl.invalid}">
                    <div class="invalid-feedback">
                        <div>City/Town/Village is required</div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <label for="postalCode">Postal Code <span class="label-hint">(Required)</span></label>
                    <input required #postalCodeControl="ngModel" maxlength="10" type="text" class="form-control" id="postalCode" name="postalCode" [(ngModel)]="model.contactAddress.postalCode" [ngClass]="{'is-invalid': isSubmitClicked && postalCodeControl.invalid}">
                    <div class="invalid-feedback">
                        <div>Postal Code is required</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- Registry Portal View -->
<div class="card mt-4" *ngIf="isRSGPortal">
    <div class="card-header">
        Preferred Contact Information
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="col-lg-6">
                <label for="contactMethod" class="required">Preferred Contact Method</label>
                <select class="form-control" id="contactMethod" name="contactMethod" required
                    [(ngModel)]="model.contactMethodTypeId" (change)="onContactMethodChange()"
                    #contactMethod="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && contactMethod.invalid }">
                    <option [ngValue]="null">Select a Method</option>
                    <option *ngFor="let contactMethodType of contactMethodTypes" [value]="contactMethodType.id">{{contactMethodType.name}}</option>
                </select>
                <div class="invalid-feedback">
                    <div>Preferred Contact Method is required</div>
                </div>
            </div>		
        </div>
        <div class="form-group row">
            <ng-container *ngIf="model.contactMethodTypeId == 2 || model.contactMethodTypeId == 3">
                <div class="col-lg-6">
                    <label for="contactPhoneNumber" class="required">Phone Number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend2">+1</span>
                        </div>
                        <input type="text" class="form-control" id="contactPhoneNumber" name="contactPhoneNumber"
                            [(ngModel)]="model.tempPhoneNumber" (ngModelChange)="model.phoneNumber = '1'+model.tempPhoneNumber" 
                            placeholder="numbers only" aria-describedby="inputGroupPrepend2" 
                            required pattern="[0-9]{10}$" maxlength="10"
                            #contactPhoneNumber="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && contactPhoneNumber.invalid }">
                        <div class="invalid-feedback">
                            <div *ngIf="contactPhoneNumber.errors?.required">Phone Number is required</div>
                            <div *ngIf="contactPhoneNumber.errors?.pattern">Invalid Phone Number</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="confirmContactPhoneNumber" class="required">Confirm Phone Number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend3">+1</span>
                        </div>
                        <input type="text" class="form-control" id="confirmContactPhoneNumber" name="confirmContactPhoneNumber"
                            [(ngModel)]="confirmPhoneNumber"
                            placeholder="numbers only" aria-describedby="inputGroupPrepend3" 
                            required pattern="[0-9]{10}$" maxlength="10"
                            #confirmContactPhoneNumber="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && confirmContactPhoneNumber.invalid }">
                        <div class="invalid-feedback">
                            <div *ngIf="confirmContactPhoneNumber.errors?.required">Confirm Phone Number is required</div>
                            <div *ngIf="confirmContactPhoneNumber.errors?.pattern">Invalid Phone Number</div>
                            <div *ngIf="confirmContactPhoneNumber.errors?.mustMatch">Phone Numbers must match</div>
                        </div>
                    </div>
                </div>		
            </ng-container>

            <ng-container *ngIf="model.contactMethodTypeId == 1">
                <div class="col-lg-6">
                    <label for="contactEmailAddress" class="required">Email Address</label>
                    <input type="text" class="form-control" id="contactEmailAddress" name="contactEmailAddress"
                        [(ngModel)]="model.email" required email
                        #contactEmailAddress="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && contactEmailAddress.invalid }">
                    <div class="invalid-feedback">
                        <div *ngIf="contactEmailAddress.errors?.required">Email Address is required</div>
                        <div *ngIf="contactEmailAddress.errors?.email">Please provide a valid email address</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="confirmContactEmailAddress" class="required">Confirm Email Address</label>
                    <input type="text" class="form-control" id="confirmContactEmailAddress" name="confirmContactEmailAddress"
                        [(ngModel)]="confirmEmail" required email
                        #confirmContactEmailAddress="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && confirmContactEmailAddress.invalid }">
                    <div class="invalid-feedback">
                        <div *ngIf="confirmContactEmailAddress.errors?.required">Confirm Email Address is required</div>
                        <div *ngIf="confirmContactEmailAddress.errors?.email">Please provide a valid email address</div>
                        <div *ngIf="confirmContactEmailAddress.errors?.mustMatch">Emails must match</div>
                    </div>
                </div>	                	
            </ng-container>
        </div>

        <ng-container *ngIf="model.contactMethodTypeId ==  1">
            <div class="form-group row">
                <div class="col-12">
                    <p class="mt-0">
                        By selecting email, the review decision will be uploaded and accessible online when available.
                    </p>
                </div>  
            </div>
        </ng-container>

        <ng-container *ngIf="model.contactMethodTypeId ==  2">
            <div class="form-group row">
                <div class="col-12">
                    <p class="mt-0">
                        By selecting text, the review decision will be uploaded and accessible online when available.
                    </p>
                </div>  
            </div>
        </ng-container>

        <ng-container *ngIf="model.contactMethodTypeId == 3">
            <div class="form-group row">
                <div class="col-12">
                    <p class="mt-0">
                        By selecting phone, the review decision will be mailed to the address provided. Ask the client to provide the address they wish to receive the review decision letter.
                        <br>
                        Note: This process will take a longer time than email or text.
                    </p>
                </div>  
            </div>
            <div class="form-group row">
                <div class="col-lg-6">
                    <label for="address1" class="required">Address 1</label>
                    <input type="text" maxlength="44" class="form-control" required
                        id="address1" name="address1" placeholder="Ex. 123 Street or PO Box, Range Road or Rural Route" 
                        [(ngModel)]="model.contactAddress.addressLine1" #address1="ngModel"
                        [ngClass]="{'is-invalid': isSubmitClicked && address1.invalid}">
                    <div class="invalid-feedback">
                        <div>Address 1 is required</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="address2">Address 2</label>
                    <input type="text" maxlength="44" class="form-control" 
                        id="address2" name="address2" placeholder="Ex. Unit Number" [(ngModel)]="model.contactAddress.addressLine2">
                </div>
            </div>
            
            <div class="form-group">
                <app-country-province (onChangeEvent)="refreshContactCountryProvince($event)" [countryProvince]="contactCountryProvince"></app-country-province>
            </div>

            <div class="form-group row">
                <div class="col-lg-6">
                    <label for="city">City/Town/Village <span class="label-hint">(Required)</span></label>
                    <input required #cityControl="ngModel" maxlength="30" type="text" class="form-control" id="city" name="city" placeholder="City/Town/Village" [(ngModel)]="model.contactAddress.city" [ngClass]="{'is-invalid': isSubmitClicked && cityControl.invalid}">
                    <div class="invalid-feedback">
                        <div>City/Town/Village is required</div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <label for="postalCode">Postal Code <span class="label-hint">(Required)</span></label>
                    <input required #postalCodeControl="ngModel" maxlength="10" type="text" class="form-control" id="postalCode" name="postalCode" [(ngModel)]="model.contactAddress.postalCode" [ngClass]="{'is-invalid': isSubmitClicked && postalCodeControl.invalid}">
                    <div class="invalid-feedback">
                        <div>Postal Code is required</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
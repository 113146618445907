


export class VehicleSeizureSearch {
    public firstName: string;
    public lastName: string;
    public seizureNumber: string;
    public seizureDate: Date;
    public seizureTime: string;

    constructor(init?: Partial<VehicleSeizureSearch>) {
        
        Object.assign(this, init);
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Document } from '@apis/shared/models/document.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { FileUploadModel } from '@apis/shared/models/file-upload.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { AdditionalDocumentCategory } from '@apis/shared/enums/additional-document-category.enum';
import { Constants } from '@apis/shared/helpers/constants';
import { SeizureLateReviewBaseComponent } from '../seizure-late-review-base.component';
import { DocumentTypes } from '@apis/shared/enums/app.enum';

declare var $: any

@Component({
  selector: 'app-seizure-late-review-supporting-documents',
  templateUrl: './seizure-late-review-supporting-documents.component.html',
  styleUrls: ['./seizure-late-review-supporting-documents.component.scss']
})
export class SeizureLateReviewSupportingDocumentsComponent extends SeizureLateReviewBaseComponent
  implements OnInit {

  documents: Document[] = [];
  documentTypes: DocumentType[];
  fileUploadModel: FileUploadModel;
  errorMessage: string = "";

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService
  ) { 
    super(router, activatedRoute, localStorageService);
  }

  ngOnInit(): void {
    //Get Types
    this.documentTypes = this.localStorageService.getDocumentTypes();

    //Initialize file upload model and a temporary file storage location
    this.fileUploadModel = new FileUploadModel();
    this.fileUploadModel.tempFileFolder = this.tempFolderName;
    this.fileUploadModel.additionalDocumentsCategory = AdditionalDocumentCategory.LateReview;

    this.documents = this.lateReviewRequest.documents;

    if (this.lateReviewRequest.representation && !this.isRSGPortal) {
      this.addPlaceHolder(DocumentTypes.RecipientsConsentOfRepresentation, true);
    }

    //scroll to top with tab change
    window.scroll(0,0);
  }

  addPlaceHolder(documentType: DocumentTypes, isOptional: boolean = false) {
    let document = null;

    if (this.lateReviewRequest.documents) {
      document = this.lateReviewRequest.documents.find(x => x.documentTypeId == +documentType);

      if (document == null) {
        document = new Document({
          documentTypeId: +documentType,
          isOptional: isOptional,
          isPublished: true
        });
        this.documents.push(document);
      }
    }
  }

  trackDocument(index: number, obj: any): any {
    return index;
  }

  AddOptionalDocument() {
    var document = new Document()
    document.documentTypeId = null;
    document.isOptional = true;
    document.isPublished = true;
    this.documents.push(document);
  }

  onCancelDcoument(document: Document)
  {
    this.documents.splice(this.documents.findIndex(x => x.contentGuid==document.contentGuid), 1)
  }

  validateDocuments(): boolean {
    this.errorMessage = ""

    if (this.lateReviewRequest.representation && !this.isRSGPortal) {
      if (this.documents.filter(x=> x && x.documentTypeId == DocumentTypes.RecipientsConsentOfRepresentation
        && x.documentName != null && x.documentName.trim().length > 0).length == 0) 
      {   
        this.errorMessage = "A Consent to Representation is required.";
        return false;
      }
    }

    //Check if uploaded documents are under the maximum document count limit
    for (let index = 0; index < this.documentTypes.length; index++) {
      var uploadedDocumentsCount = this.documents.filter(x => x.documentTypeId == this.documentTypes[index].id).length;
      if (uploadedDocumentsCount > this.documentTypes[index].maximumFileCount)
      {
        this.errorMessage =  `You can upload maximum of ${this.documentTypes[index].maximumFileCount} document(s) of type ${this.documentTypes[index].name.trim()}. But there are ${uploadedDocumentsCount} documents uploaded.`;
        return false;
      }
    }

    return true;
  }

  continueRequest(isValid: boolean) {
    if(this.validateDocuments() && isValid) {
      // Include only the uploaded documents.
      this.lateReviewRequest.documents =  this.documents.filter(d => d.documentName != null && d.documentName.length > 0);

      this.saveContext();
      this.router.navigate(['../payment-method'], { relativeTo: this.activatedRoute, });
    }
  }

}

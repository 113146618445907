<!-- Driver Portal View -->
<app-vehicle-seizure-page [suppressConfirmation]="true" *ngIf="review && !isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column" *ngIf="review">
            <div class="mb-4 border-bottom d-flex justify-content-between">
                <div>
                    <h1>Review Details</h1>
                    <h3 class="mb-3 mt-2">No. {{review.reviewNumber}}</h3>
                </div>
                <div *ngIf="maxReviewSequence > 1">
                    <button class="goa-button mt-3" (click)="onNewerReviewsclick()"
                        [disabled]="reviewSequence == maxReviewSequence">
                        <i class="fa fa-chevron-left"></i> Newer Reviews
                    </button>
                    <button class="goa-button mt-3" (click)="onPastReviewsclick()"
                        [disabled]="reviewSequence == 1">
                        Past Reviews <i class="fa fa-chevron-right"></i>
                    </button>
                </div>
            </div>

            <div *ngIf="!isDecisionAvailable">
                <p class="mt-0 mb-3">
                    If you require assistance rescheduling a review or submitting supporting documents, please contact 
                    <a [routerLink]="['../../../email-saferoads']">SafeRoads Alberta</a>.
                </p>
            </div>

            <div class="content">
                <div class="review-section">
                    <div class="card">
                        <div class="card-header">
                            Review Details                            
                            <button class="float-right goa-button btn-block btn-small" 
                            *ngIf="review.isRescheduleAllowed" [routerLink]="['../reschedule']">                             
                                <i class="fa fa-edit"></i>
                                Edit / Reschedule
                            </button>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div class="label">Review Type</div>
                                    <div class="value">{{ reviewMethodName }} Review</div>
                                </div>
                                <div class="col-6" *ngIf="review.scheduledEvent?.iCalendarUrl">
                                    <a href="{{review.scheduledEvent.iCalendarUrl}}" class="float-right">+ Add to Calendar</a>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-5">
                                    <div class="label">Date</div>
                                    <div class="value">{{ reviewDate }}</div>
                                </div>
                                <div *ngIf="review.reviewMethodTypeId==2" class="col-5">
                                    <div class="label">Time (MT)</div>
                                    <div class="value">{{ reviewTime }}</div>
                                </div>                                
                            </div>

                            <div class="row" *ngIf="isReviewAvailable()">
                                <div class="col-12">
                                    <div class="label">Video Conference URL</div>
                                    <div class="value" *ngIf="review.videoConferenceUrl == null; else confLink">
                                        -                                        
                                    </div>
    
                                    <ng-template #confLink>
                                        <a target="_blank" href="{{review.videoConferenceUrl}}">{{review.videoConferenceUrl}}</a>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="d-flex justify-content-between mt-4">
                                <div>
                                    <a class="d-block mb-2" 
                                    href="assets/{{termCondition.documentName}}" target="_blank">Terms & Conditions ({{termCondition.documentDate | date:'mediumDate'}})</a>
                                    <div *ngIf="review.isCancelAllowed">
                                        <a class="cancel-link" (click)="onCancelReviewClick()">
                                            Abandon (Cancel) Review
                                        </a>
                                    </div>
                                </div>
                                <div *ngIf="review.videoConferenceUrl && isReviewAvailable()">
                                    <button class="goa-button btn-block mt-0"
                                    (click)="joinMeeting()">
                                    Join the Meeting Room
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="card-header">
                            Request Details
                            <button class="float-right goa-button btn-block btn-small" 
                            *ngIf="review.isEditAllowed" [routerLink]="['../edit']">
                                <i class="fa fa-edit"></i>
                                Edit
                            </button>
                        </div>
                        <div class="card-body">
                            <div *ngIf="isThirdPartySeizure">
                                <app-section-details sectionTitle="" [sectionDetails]="requesterInformation"></app-section-details>
                            </div>

                            <app-section-details 
                            sectionTitle="" [sectionDetails]="representationInformation"></app-section-details>

                            <div class="row">
                                <div class="col-12">
                                    <div class="label">
                                        Grounds for Review
                                    </div>
                                    <div class="value">
                                        {{ review.reviewItems[0].reviewGrounds }}
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="review.additionalRequestNotes">
                                <div class="col-12">
                                    <div class="label">
                                        Additional notes
                                    </div>
                                    <div class="value">
                                        {{ review.additionalRequestNotes }}
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="review.recipientRelationship">
                                <div class="col-12">
                                    <div class="label">
                                        Relationship with the Recipient
                                    </div>
                                    <div class="value">
                                        {{ review.recipientRelationship }}
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview">
                                
                                <div class="row">
                                    <div class="col-12">
                                        <div class="label">
                                            Is the vehicle registered under a company?
                                        </div>
                                        <div class="value">
                                            {{ review.isCommercialVehicle ? "Yes" : "No" }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="label">
                                            Was the Vehicle Taken without your Permission?
                                        </div>
                                        <div class="value">
                                            {{ review.isVehicleTakenWithoutPermission ? "Yes" : "No" }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="label">
                                            Was the Vehicle stolen?
                                        </div>
                                        <div class="value">
                                            {{ review.isVehicleStolen ? "Yes" : "No" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="">
                        <div class="card card-action">
                            <div class="card-header">
                                Supporting Documents
                            </div>
                            <div class="card-body">
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <a class="d-block extern" href="technical-material" target="_blank">Technical Materials Library</a>
                                    </div>
                                </div>

                                <div class="row" *ngFor="let document of supportingDocuments">
                                    <div class="col-12">
                                        <p>{{document.documentType}}</p>
                                        <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                            <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                            ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                        </div>
                                        <ng-template #doNotShowMetadata>
                                            <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row" *ngIf="reviewUploads?.length > 0">
                                    <div class="col-12">
                                        <p>Review Uploads.</p>
                                        <div class="row" *ngFor="let document of reviewUploads">
                                            <div class="col-12 mb-2">
                                                <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                                    <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                                    ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                                </div>
                                                <ng-template #doNotShowMetadata>
                                                    <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                                </ng-template>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-12">
                                        <button class="btn-block goa-button" *ngIf="review.isEditSupportingDocumentsAllowed"
                                            [routerLink]="['../supporting-documents']">Add or Modify Documents</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="card card-action">
                            <div class="card-header">
                                Consent to Representation
                            </div>
                            <div class="card-body">
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <a class="d-block" download="Consent to Representation"
                                        href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" target="_blank">Consent to Representation</a>
                                    </div>
                                </div>

                                <div class="row my-2" *ngFor="let document of consentToRepresentationDocuments">
                                    <div class="col-12">
                                        <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                            <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                            ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                        </div>
                                        <ng-template #doNotShowMetadata>
                                            <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                        </ng-template>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-12">
                                        <button class="btn-block goa-button" *ngIf="review.isEditAllowed"
                                            (click)="onAddConsentFormClick()">Add Consent Form</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.Complete && review.reviewItems[0].decisionTypeId">
                <div class="card">
                    <div class="card-header">
                        Review Decision
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-5">
                                <div class="label">Review Decision</div>
                                <div class="value">{{ reviewDecision }}</div>
                            </div>
                            <div class="col-5">
                                <div class="label">Date Decision Issued</div>
                                <div class="value">{{ review.decisionDate | date:'fullDate' }}</div>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12">
                                <div class="label">Decision Letter</div>
                                <div class="value" *ngIf="decisionLetterDocument == null; else docLink">
                                    -                                        
                                </div>

                                <ng-template #docLink>
                                    <a class="d-block value" [routerLink]="[]" (click)="DownloadDocument(decisionLetterDocument)">
                                        {{decisionLetterDocument.documentName}}
                                    </a>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="cancelReviewModal" tabindex="-1" role="dialog" aria-labelledby="cancelReviewModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content p-4">
                        <div class="modal-header border-0 pt-2">
                            <span class="modal-title h4" id="cancelReviewModalLabel">
                                Are you sure you wish to abandon (cancel) your review?
                            </span>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="far fa-times-circle fa-lg"></i>
                            </button>
                        </div>
                        <div class="modal-body py-0">
                            <p>
                                Abandoning (cancelling) your review will confirm your Vehicle Seizure Notice. 
                                The fee to apply for a review is non-refundable.
                            </p>
                            
                            <div class="mt-2">
                                <div class="goa-form p-0">
                                    <div class="goa-field">
                                        <div class="goa-option mt-0 mb-4">
                                            <input type="checkbox" id="consent" name="consent" [(ngModel)]="isConsentAccepted">
                                            <label class="consent-label" for="consent">
                                                I understand that by abandoning (cancelling) my review, I am confirming my Vehicle Seizure Notice.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-0 d-flex justify-content-between">
                            <button type="button" class="goa-button goa--secondary mt-2" 
                                data-dismiss="modal">Cancel</button>
                            <button type="button" class="goa-button mt-2" [disabled]="isConsentAccepted == false" 
                                (click)="onConfirmClick()">Confirm Cancellation</button>
                        </div>

                        <div class="text-danger" *ngIf="isCancelFailed">
                            Review cannot be cancelled after commencement (or) decision was rendered.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page [suppressConfirmation]="true" *ngIf="review && isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column" *ngIf="review">
            <div class="border-bottom">
                <div class="d-flex justify-content-between">
                    <h1>Review Details</h1>
                    <div>
                        <div class="d-flex align-items-center">
                            <div *ngIf="maxReviewSequence > 1">
                                <button class="goa-button mt-3" (click)="onNewerReviewsclick()"
                                    [disabled]="reviewSequence == maxReviewSequence">
                                    <i class="fa fa-chevron-left"></i> Newer Reviews
                                </button>
                                <button class="goa-button mt-3" (click)="onPastReviewsclick()"
                                    [disabled]="reviewSequence == 1">
                                    Past Reviews <i class="fa fa-chevron-right"></i>
                                </button>
                            </div>
                            <div class="print-icon not-for-print" (click)="printSummary()"></div>
                        </div>
                    </div>
                </div>
                <h3 class="mb-3 mt-2">No. {{review.reviewNumber}}</h3>
            </div>

            <div class="content">
                <div class="review-section">
                    <div class="card">
                        <div class="card-header">
                            Review Details
                            <button class="float-right goa-button btn-block btn-small" 
                            *ngIf="review.isRescheduleAllowed" [routerLink]="['../reschedule']">                             
                                <i class="fa fa-edit"></i>
                                Edit / Reschedule
                            </button>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-5">
                                    <div class="label">Review Type</div>
                                    <div class="value">{{ reviewMethodName }} Review</div>
                                </div>
                                <div class="col-5">
                                    <div class="label">Review Duration</div>
                                    <div class="value">30 Minutes</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-5">
                                    <div class="label">Date</div>
                                    <div class="value">{{ reviewDate }}</div>
                                </div>
                                <div *ngIf="review.reviewMethodTypeId==2" class="col-5">
                                    <div class="label">Time (MT)</div>
                                    <div class="value">{{ reviewTime }}</div>
                                </div>
                            </div>

                            <div class="row" *ngIf="isReviewAvailable()">
                                <div class="col-12">
                                    <div class="label">Video Conference URL</div>
                                    <div class="value" *ngIf="review.videoConferenceUrl == null; else confLink">
                                        -                                        
                                    </div>
    
                                    <ng-template #confLink>
                                        <a target="_blank" href="{{review.videoConferenceUrl}}">{{review.videoConferenceUrl}}</a>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="d-flex justify-content-between mt-4">
                                <div>
                                    <a class="d-block mb-2" 
                                    href="assets/{{termCondition.documentName}}" target="_blank">Terms & Conditions ({{termCondition.documentDate | date:'mediumDate'}})</a>
                                    <div *ngIf="review.isCancelAllowed">
                                        <a class="cancel-link" (click)="onCancelReviewClick()">
                                            Abandon (Cancel) Review
                                        </a>
                                    </div>
                                </div>
                                <!-- <div *ngIf="review.videoConferenceUrl && isReviewAvailable()">
                                    <button class="btn button-primary goa-button btn-block mt-0"
                                    (click)="joinMeeting()">
                                    Join the Meeting Room
                                </button>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="mt-4" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.Complete && review.reviewItems[0].decisionTypeId">
                        <div class="card">
                            <div class="card-header">
                                Review Decision
                            </div>
                            <div class="card-body">
                                <div class="row mb-2">
                                    <div class="col-5">
                                        <div class="label">Review Decision</div>
                                        <div class="value">{{ reviewDecision }}</div>
                                    </div>
                                    <div class="col-5">
                                        <div class="label">Date Decision Issued</div>
                                        <div class="value">{{ review.decisionDate | date:'fullDate' }}</div>
                                    </div>
                                </div>
        
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <div class="label">Decision Letter</div>
                                        <div class="value" *ngIf="decisionLetterDocument == null; else docLink">
                                            -                                        
                                        </div>
        
                                        <ng-template #docLink>
                                            <a class="d-block value" [routerLink]="[]" (click)="DownloadDocument(decisionLetterDocument)">
                                                {{decisionLetterDocument.documentName}}
                                            </a>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4">
                        <div class="card-header">
                            Request Details
                            <button class="float-right goa-button btn-block btn-small not-for-print" 
                            *ngIf="review.isEditAllowed" [routerLink]="['../edit']">
                                <i class="fa fa-edit"></i>
                                Edit
                            </button>
                        </div>
                        <div class="card-body">

                            <app-section-details 
                            sectionTitle="" [sectionDetails]="requesterInformation"></app-section-details>

                            <app-section-details
                            sectionTitle="" [sectionDetails]="registryAgentInformation"></app-section-details>

                            <app-section-details
                            sectionTitle="" [sectionDetails]="representationInformation"></app-section-details>

                            <div class="row">
                                <div class="col-12">
                                    <div class="label">
                                        Grounds for Review
                                    </div>
                                    <div class="value">
                                        {{ review.reviewItems[0].reviewGrounds }}
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="review.additionalRequestNotes">
                                <div class="col-12">
                                    <div class="label">
                                        Additional notes
                                    </div>
                                    <div class="value">
                                        {{ review.additionalRequestNotes }}
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="review.recipientRelationship">
                                <div class="col-12">
                                    <div class="label">
                                        Relationship with the Recipient
                                    </div>
                                    <div class="value">
                                        {{ review.recipientRelationship }}
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview">
                                
                                <div class="row">
                                    <div class="col-12">
                                        <div class="label">
                                            Is the vehicle registered under a company?
                                        </div>
                                        <div class="value">
                                            {{ review.isCommercialVehicle ? "Yes" : "No" }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="label">
                                            Was the Vehicle Taken without your Permission?
                                        </div>
                                        <div class="value">
                                            {{ review.isVehicleTakenWithoutPermission ? "Yes" : "No" }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="label">
                                            Was the Vehicle stolen?
                                        </div>
                                        <div class="value">
                                            {{ review.isVehicleStolen ? "Yes" : "No" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="">
                        <div class="card card-action">
                            <div class="card-header">
                                Supporting Documents
                            </div>
                            <div class="card-body">
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <a class="d-block extern" href="technical-material" target="_blank">Technical Materials Library</a>
                                    </div>
                                </div>

                                <div class="row" *ngFor="let document of supportingDocuments">
                                    <div class="col-12">
                                        <p>{{document.documentType}}</p>
                                        <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                            <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                            ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                        </div>
                                        <ng-template #doNotShowMetadata>
                                            <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row" *ngIf="reviewUploads?.length > 0">
                                    <div class="col-12">
                                        <p>Review Uploads.</p>
                                        <div class="row" *ngFor="let document of reviewUploads">
                                            <div class="col-12 mb-2">
                                                <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                                    <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                                    ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                                </div>
                                                <ng-template #doNotShowMetadata>
                                                    <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                                </ng-template>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                                
                                <div class="row not-for-print">
                                    <div class="col-12">
                                        <button class="btn-block goa-button" *ngIf="review.isEditSupportingDocumentsAllowed"
                                            [routerLink]="['../supporting-documents']">Add or Modify Documents</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="card card-action">
                            <div class="card-header">
                                Consent to Representation
                            </div>
                            <div class="card-body">
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <a class="d-block" download="Consent to Representation"
                                        href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" target="_blank">Consent to Representation</a>
                                    </div>
                                </div>

                                <div class="row my-2" *ngFor="let document of consentToRepresentationDocuments">
                                    <div class="col-12">
                                        <div *ngIf="showDocumentMetadataActive; else doNotShowMetadata">
                                            <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                            ({{document.documentSize}}) submitted on {{document.uploadedDate | date:"mediumDate"}}
                                        </div>
                                        <ng-template #doNotShowMetadata>
                                            <a [routerLink]="[]" (click)="DownloadDocument(document)">{{document.documentName}}</a>
                                        </ng-template>
                                    </div>
                                </div>
                                
                                <div class="row not-for-print">
                                    <div class="col-12">
                                        <button class="btn-block goa-button" *ngIf="review.isEditAllowed"
                                            (click)="onAddConsentFormClick()">Add Consent Form</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="cancelReviewModal" tabindex="-1" role="dialog" aria-labelledby="cancelReviewModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content p-4">
                        <div class="modal-header border-0 pt-2">
                            <span class="modal-title h4" id="cancelReviewModalLabel">
                                Are you sure you wish to abandon (cancel) the review?
                            </span>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="far fa-times-circle fa-lg"></i>
                            </button>
                        </div>
                        <div class="modal-body py-0">
                            <p>
                                Please advise the client that abandoning (cancelling) the review will confirm the Vehicle Seizure Notice. 
                                 The fee to apply for a review is non-refundable.
                            </p>
                            
                            <div class="mt-2">
                                <div class="goa-form p-0">
                                    <div class="goa-field">
                                        <div class="goa-option mt-0 mb-4">
                                            <input type="checkbox" id="consent" name="consent" [(ngModel)]="isConsentAccepted">
                                            <label class="consent-label" for="consent">
                                                <b>The client confirms that they wish to abandon (cancel) the review.</b> The client understands the Vehicle Seizure Notice will be confirmed.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-0 d-flex justify-content-between">
                            <button type="button" class="goa-button goa--secondary mt-2" 
                                data-dismiss="modal">Cancel</button>
                            <button type="button" class="goa-button mt-2" [disabled]="isConsentAccepted == false" 
                                (click)="onConfirmClick()">Confirm Cancellation</button>
                        </div>

                        <div class="text-danger" *ngIf="isCancelFailed">
                            Review cannot be cancelled after commencement (or) decision was rendered.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>
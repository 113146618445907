<app-contravention-page>
    <ng-container content>
        <form #paymentForm="ngForm">
        <div class="d-flex flex-column">
            <h1 class="mb-4">Payment Information</h1>
            <h3 class="border-bottom mt-0 mb-0">No. {{paymentRequest.recordNumber}}</h3>

            <app-registry-agent-information [registryAgentInformation]="registryAgentInformation"></app-registry-agent-information>

            <app-registry-payer-information
            [registryAgentInformation]="registryAgentInformation"
            [isFormSubmitted]="paymentForm.submitted"
            sectionHeader="Payer"
            sectionLabel="Who is making this payment?">
            </app-registry-payer-information>

            <div *ngFor="let li of paymentRequest.lineItems;">
                <app-contravention-line-item 
                    [contraventionInformation]="getContravention(li.recordNumber)" 
                    [lineItem]="li"
                    [isLocked]="true"
                    [isSubmitClicked]="paymentForm.submitted"></app-contravention-line-item>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Total Payment Amount</h5></div>
                        <div><h5>{{ getTotalPaymentAmount() | currency }}</h5></div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Transaction ID</h5></div>
                        <div><h5>{{ paymentRequest.transactionId }}</h5></div>
                    </div>
                </div>
            </div>

            <div>
                <span class="error">{{errorMessage}}</span>
            </div>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../../']">Back to Contravention Overview</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button goa--primary btn-block" (click)="continuePayment(paymentForm.valid)">Review Payment</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-contravention-page>
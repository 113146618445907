import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { JWTTokenService } from '../services/jwttoken.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
      private auth: JWTTokenService,
      private localStorageService: LocalStorageService,
      private router: Router
      ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      request = this.addAuthHeader(request);

      let newHeaders = request.headers.set("client-version", environment.clientVersion);
      request = request.clone({
        headers: newHeaders
      });

      return next.handle(request).pipe(
        tap({
            error: (res) => {   
              if (this.localStorageService.getRegistryAgentProfile() && (res.status == "401" || res.status == "403"))
              {
                this.localStorageService.logout();
                this.router.navigate(['/session-expired'])
              }
              if (res.status == "401")
                this.router.navigate(['/contraventions/search'])   
              if (res.status == "503")
                this.router.navigate(['/scheduled-maintenance'])   
              if (res.statusText == "Unknown Error")
                this.router.navigate(['/internal-server-error'])
              throwError(res);
            }
          })
        );
    }

    private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
      const authToken: string = this.auth.getToken() || '';
  
      request = request.clone({
        setHeaders: { Authorization: 'Bearer ' + authToken }
      });
  
      return request;
    }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverBaseComponent } from '../../driver-base.component';

@Component({
  selector: 'app-contravention-page',
  templateUrl: './contravention-page.component.html',
  styleUrls: ['./contravention-page.component.scss']
})
export class ContraventionPageComponent extends DriverBaseComponent implements OnInit {
  @Input() suppressConfirmation: boolean;
  @Output() onBeforeNavigate = new EventEmitter();

  contraventionNumber: string;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly spinner: NgxSpinnerService) {
    super(router);
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
    });
  }

  continue() {
    this.onBeforeNavigate.emit();
    if (this.isRSGPortal) {
      this.router.navigate(['/rsg/contravention', this.contraventionNumber]);
    } else {
      this.router.navigate(['/contravention', this.contraventionNumber]);
    }
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

}

import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { NoWhitespaceValidator } from '../validators/no-white-space.validator';

@Directive({
    selector: '[noWhiteSpace]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NoWhiteSpaceDirective, multi: true }]
})
export class NoWhiteSpaceDirective implements Validator {
    private valFn = NoWhitespaceValidator();
	validate(control: AbstractControl): { [key: string]: any } {
		return this.valFn(control);
	}
}
<form #lookupForm="ngForm">
<div class="d-flex flex-column">
    <div class="mb-4">
        <a class="lnk-back" [routerLink]="['../../']">Back to Home Page</a>
    </div>
    <h1>Find a Vehicle Seizure</h1>
    <div class="card border-0 mt-3">
        <h3 class="mt-0">Search for a vehicle seizure number</h3>
        
        <div class="goa-callout goa--information mt-0 mb-3">
            <h6 class="mt-0">Vehicle Seizure Number</h6>
            <p>
                The vehicle seizure number is a 17-digit number on the top right corner of the Seizure Notice, starting with 10000, for example "10000<b>123456789876</b>"
            </p>
        </div>

        <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isServerErr">
            <h6 class="mt-0">Error</h6>
            <p>
                {{errorMessage}}
            </p>
        </div>

        <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isSeizureNotFound && lookupForm.submitted">
            <h6 class="mt-0">Vehicle Seizure Not Found</h6>
            <p>
                The seizure number cannot be found. Please check if seizure number is entered correctly.
            </p>
        </div>

    </div>    
    <div class="card border-0 mt-4">
        <div class="form-group row">
            <div class="col-12 col-sm-12">
                <label for="seizureNumber" class="required">Vehicle Seizure Number</label>
                <div class="row">
                    <div class="col-1 mt-2">
                        <label class="m-0">10000</label>
                    </div>
                    <div class="col-5 pl-4">
                        <input type="text" required class="form-control" id="seizureNumber" name="seizureNumber"
                            #seizureNumberControl="ngModel" [(ngModel)]="seizureNumber" 
                            [ngClass]="{ 'is-invalid': lookupForm.submitted && seizureNumberControl.invalid }"
                            minlength="12" maxlength="12"
                            placeholder="enter the 12 digits after 10000">
                        <div class="invalid-feedback">
                            <div *ngIf="seizureNumberControl.errors?.required">Seizure Number is required</div>
                            <div *ngIf="!seizureNumberControl.errors?.required">A valid seizure number is required</div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row form-group">
            <div class="col-12 col-sm-6">  
                <button class="btn-block goa-button mt-2"
                (click)="onSearchClick(lookupForm.valid)">
                    Search
                </button>
            </div>
        </div>
    </div>
</div>
</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Searching... </p></ngx-spinner>
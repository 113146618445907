import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SchedulerComponent } from '@apis/shared/components/scheduler/scheduler.component';
import { DecisionTypes } from '@apis/shared/enums/app.enum';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { SeizureReviewBaseComponent } from '../seizure-review-base.component';

declare var $: any

@Component({
  selector: 'app-seizure-preferred-review-times',
  templateUrl: './seizure-preferred-review-times.component.html',
  styleUrls: ['./seizure-preferred-review-times.component.scss']
})
export class SeizurePreferredReviewTimesComponent extends SeizureReviewBaseComponent
 implements OnInit {
   reviewPeriodStartDate: Date;
   reviewPeriodEndDate: Date;
   
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public schedulerService: SchedulerService
  ) { 
    super(router, activatedRoute, localStorageService, schedulerService);
  }

  ngOnInit(): void {
    this.determineSchedulePeriod();
  }

  isLimitedWindowToUpload() {
    // No limited window check for third party seizure reviews.
    if (this.review.reviewTypeId == this.ReviewTypes.ThirdPartySeizureReview) {
      return false;
    } 

    const requestDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    let selectedTimeSlots: number[][] = JSON.parse(this.review.reviewScheduleRequest.availabilityTimeSlots);

    // check if selected review date is 3 days from the request date
    for (let index = 0; index < 4; index++) {
      if (+DateUtil.addDays(requestDate, index) >= +this.review.reviewScheduleRequest.startDate) {
        let dayIndex = (+DateUtil.addDays(requestDate, index) - +this.review.reviewScheduleRequest.startDate);

        if (selectedTimeSlots[dayIndex].includes(1)) {
          return true;
        }
      } 
    }

    return false;
  }

  continueRequest(isValid: boolean) {
    if(this.isRequiredTimeslotsSelected() && isValid) {
      if (this.isLimitedWindowToUpload()) {
        $('#confirmReviewDateModal').modal();
      } else {
        this.navigate();
      }
    }
  }

  navigate() {
    this.saveContext();
    this.router.navigate(['../supporting-documents'], { relativeTo: this.activatedRoute, });
  }

  determineSchedulePeriod() {
    let startDate: Date;

    if (this.review.reviewTypeId == this.ReviewTypes.ThirdPartySeizureReview) {
      // 1 business day between day of request and review date
      startDate = DateUtil.getNextBusinessDay(new Date(), this.localStorageService.getHolidayList());

      // Start date will be the next day 
      this.reviewPeriodStartDate = DateUtil.addDays(startDate, 1);

      // Available dates are shown up to 21 days
      this.reviewPeriodEndDate = DateUtil.addDays(this.reviewPeriodStartDate, 21);

    } else {
      
      const requestDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

      // No reviews until Day 4 from issuance
      startDate = DateUtil.addDays(new Date(this.vehicleSeizure.seizureDate), 4);

      // Set start date to request date if greater than Day 4
      if (+requestDate > +startDate) {
        startDate = requestDate;
      }

      // Available dates are shown up to Day 21
      let endDate = DateUtil.addDays(new Date(this.vehicleSeizure.seizureDate), 21);

      // For late reviews
      //  - start date = request date
      //  - end date = Date of Late Review Request Approval + 18 Days”
      if (this.vehicleSeizure.lateReviewRequestItems?.length > 0 
        && this.vehicleSeizure.lateReviewRequestItems[0]?.lateReviewRequest.requestDecisionTypeId == DecisionTypes.Confirm) {
          startDate = requestDate;        
          endDate = DateUtil.addDays(new Date(this.vehicleSeizure.lateReviewRequestItems[0].lateReviewRequest.decisionDate), 21);
        }

      // Move to next 1 clear business day
      startDate = DateUtil.getNextBusinessDay(startDate, this.localStorageService.getHolidayList());
      
      // Add 1 calendar day
      startDate = DateUtil.addDays(startDate, 1);

      // Start date will be the next day 
      this.reviewPeriodStartDate = DateUtil.addDays(startDate, 1);

      this.reviewPeriodEndDate = endDate;
    }    

    // Set review schedule start and end dates (Trim TIME part otherwise TimeZone issues will occur)
    this.review.reviewScheduleRequest.startDate =  new Date(this.reviewPeriodStartDate.getFullYear()
                                                          , this.reviewPeriodStartDate.getMonth()
                                                          , this.reviewPeriodStartDate.getDate());
    this.review.reviewScheduleRequest.endDate = new Date(this.reviewPeriodEndDate.getFullYear()
                                                        , this.reviewPeriodEndDate.getMonth()
                                                        , this.reviewPeriodEndDate.getDate());
  }

  isRequiredTimeslotsSelected() : boolean {

    return this.review.reviewScheduleRequest.availabilityTimeSlots
            && this.review.reviewScheduleRequest.availabilityTimeSlots.split("],[").filter(x=> x.includes("1")).length >= 3;
  }
}

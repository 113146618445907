import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ReviewBaseComponent } from '../review-base.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { Document } from '@apis/shared/models/document.model';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { VehicleSeizurePageComponent } from '../../../../vehicle-seizure/vehicle-seizure-page/vehicle-seizure-page.component';
import { RegistryService } from 'apps/driver/src/app/shared/services/registry.service';
import { CountdownTimerComponent } from '@apis/shared/components/countdown-timer/countdown-timer.component';
import { CountdownState } from '@apis/shared/enums/countdown-state.enum';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { ContraventionInformation } from '@apis/shared/models/contravention-information.model';
import { RepresentationTypes, ReviewMethodTypes } from '@apis/shared/enums/app.enum';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-review-summary',
  templateUrl: './review-summary.component.html',
  styleUrls: ['./review-summary.component.scss']
})
export class ReviewSummaryComponent extends ReviewBaseComponent
 implements OnInit {
  @ViewChild('masterPage') masterPage: VehicleSeizurePageComponent;
  @ViewChild('cd') private countdown: CountdownTimerComponent;
  
  reviewTypeInformation: SectionDetailsModel[] = [];
  representationInformation: SectionDetailsModel[] = [];
  reviewsDetailsInformation: SectionDetailsModel[] = [];
  requestInformation: SectionDetailsModel[] = [];
  recipientInformation: SectionDetailsModel[] = [];
  recipientIdentificationInformation: SectionDetailsModel[] = [];
  contraventionInformation: SectionDetailsModel[] = [];
  reasonForRequestInformation: SectionDetailsModel[] = [];
  preferredContactInformation: SectionDetailsModel[] = [];
  termsConditionsInformation: SectionDetailsModel[] = [];
  preferredReviewTimesSection: SectionDetailsModel[] = [];
  requesterInformation: SectionDetailsModel[] = [];
  supportingDocuments: DocumentModel[] = [];
  contraventionsInReview: ContraventionInformation[] = [];

  preferredTimes: SectionDetailsModel[] = [];
  isRequestFailed: boolean;
  isPaymentFailed: boolean;
  isReviewAppliedAfterDeadline: boolean;
  isReviewRequestNotAvailable: boolean;
  isSummaryPrinted: boolean = false;
  isSubmitClicked: boolean = false;
  isReviewTimeExpired: boolean = false;
  
  reviewMethod: string;
  reviewTime: string;

  constructor(
    public router: Router,
    private currencyPipe: CurrencyPipe,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private contraventionService: ContraventionService,
    private registryService: RegistryService,
    private documentService: DocumentService,
    private readonly spinner: NgxSpinnerService,
    public schedulerService: SchedulerService,
    private sanitizer: DomSanitizer
    ) { 
    super(router, activatedRoute, localStorageService, schedulerService);
  }

  ngAfterViewInit(): void {
   if (!this.isRSGPortal)
    {
      const cardLogoDiv = document.getElementById("cardLogoDiv") as HTMLImageElement;
      cardLogoDiv.style.backgroundImage = this.review.paymentRequest['cardLogo'];
    }
  }

  ngOnInit(): void {
    // Request Information
    this.reviewTypeInformation.push(new SectionDetailsModel('Request Type', 
    this.review.isSelfSubmit ? "I am submitting this application on behalf of myself" : 
                              "I am submitting this application on behalf of another",
    true
    ));

    this.recipientInformation.push(new SectionDetailsModel('Surname', this.contraventionOverview.recipientLastName));
    this.recipientInformation.push(new SectionDetailsModel('First Name', this.contraventionOverview.recipientFirstName));
    this.recipientInformation.push(new SectionDetailsModel('Middle Name', this.contraventionOverview.recipientMiddleName));
    this.recipientInformation.push(new SectionDetailsModel('Email', this.review.driverEmail));

    if (this.review.representation) {
      // Representation Informaton
      const repTypeId = this.review.representation.representationTypeId;

      const repTypeName = this.localStorageService.getRepresentationTypes()
                              .find(x=>x.id == repTypeId)?.name;

      this.representationInformation.push(new SectionDetailsModel('Type of Acting Representation', repTypeName, true));

      this.representationInformation.push(new SectionDetailsModel(`Representative First Name`, this.review.representation.firstName));
      this.representationInformation.push(new SectionDetailsModel(`Representative Last Name`, this.review.representation.lastName));

      if (+repTypeId === RepresentationTypes.Agent || +repTypeId === RepresentationTypes.Lawyer){
        this.representationInformation.push(new SectionDetailsModel(`Practice Name`, this.review.representation.practiceName, true));
      }

      if (+repTypeId === RepresentationTypes.Other){
        this.representationInformation.push(new SectionDetailsModel('Other Representation Type', this.review.representation.otherRepresentationType, true));
      }
    }

    //Requester Information
    if (this.isRSGPortal) {
      const registryAgentInformation = this.review.registryAgentInformation[0];
      if (registryAgentInformation.isPaidByRecipient) {
        this.requesterInformation.push(new SectionDetailsModel('Applicant', "Recipient (Driver)", true));
      }
      else {
        this.requesterInformation.push(new SectionDetailsModel('Applicant', "Someone else", true));
        this.requesterInformation.push(new SectionDetailsModel('Surname', registryAgentInformation.payerLastName));
        this.requesterInformation.push(new SectionDetailsModel('First Name', registryAgentInformation.payerFirstName));
        this.requesterInformation.push(new SectionDetailsModel('MVID', registryAgentInformation.payerMVID));
      }
    }

    // Contravention Information
    for (let index = 0; index < this.review.reviewItems.length; index++) {
      const r = this.review.reviewItems[index];
      if (r.isSelected) {
        var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == r.recordNumber);
        this.contraventionsInReview.push(contravention);
      }
    }    

    // Review Details Information
    const reviewMethodType = this.localStorageService.getReviewMethodTypes().find(x => x.id == this.review.reviewMethodTypeId);

    this.reviewsDetailsInformation.push(new SectionDetailsModel('Review Method', reviewMethodType?.name));
    this.reviewsDetailsInformation.push(new SectionDetailsModel('Selected Review Fee Amount', 
        this.currencyPipe.transform(this.review.paymentRequest.paymentAmount)));

    // Display failed attendance declaration, if the review was requested after the feature's release
    if (new Date(this.review.requestDate) >= new Date(+Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(0, 4), +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(5, 7)-1, +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(8, 10))) {
      if (reviewMethodType.id == ReviewMethodTypes.Oral) {
        const declarationText = this.localStorageService.getDeclarationTypes().find(x => x.id == this.review.failedAttendanceDeclarationTypeId)?.formattedDescription;
        const declarationSafeHTML = this.sanitizer.bypassSecurityTrustHtml(declarationText);
        this.reviewsDetailsInformation.push(new SectionDetailsModel('If you fail to attend the review hearing, how do you elect to proceed?',
          declarationSafeHTML, true, false, true));
      }
    }

    for (let index = 0; index < this.review.reviewItems.length; index++) {
      const r = this.review.reviewItems[index];
      if (r.isSelected) {
        var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == r.recordNumber);
        var contraventionDetails = contravention.contraventionNumber + ' - ' + this.getChargeCodes(contravention); 

        this.reviewsDetailsInformation.push(new SectionDetailsModel('Grounds for Review', 
          contraventionDetails, true));
        this.reviewsDetailsInformation.push(new SectionDetailsModel('', 
          r.reviewGrounds, true));
      }
    }

    this.reviewsDetailsInformation.push(new SectionDetailsModel('Additional Request Notes', 
        this.review.additionalRequestNotes, true));
    this.reviewsDetailsInformation.push(new SectionDetailsModel(`${this.isRSGPortal? 'Will the Driver Be ':''} Accompanied by an Interpreter?`, 
        this.review.isAccompainedByInterpreter ? 'Yes': 'No', true));
        
    // Preferred Contact Information
    this.preferredContactInformation.push(new SectionDetailsModel('Preferred Contact Method', 
      CommonUtil.getContactMethodTypeName(this.review.contactMethodTypeId)));
    if (this.review.phoneNumber) {
      this.preferredContactInformation.push(new SectionDetailsModel('Phone Number', this.review.phoneNumber));
    }
    else {
      this.preferredContactInformation.push(new SectionDetailsModel('Email Address', this.review.email));
    }

    // Supporting Documents Section
    this.review.documents?.forEach(document => {
      var documentType : DocumentType = this.localStorageService.getDocumentTypes().find(x=> x.id == document.documentTypeId);
      this.supportingDocuments.push(new DocumentModel(documentType?.name, document.documentName, document.documentExtension, document.contentGuid, !documentType?.isSubmittableAtLaterDate));
    });

    // Terms & Conditions
    this.termsConditionsInformation.push(new SectionDetailsModel(this.isRSGPortal? 'The Registry Agent provided the Terms & Conditions to the client': 'I acknowledge the SafeRoads Alberta Terms and Conditions.', "Yes", true));

    // Review Details
    this.reviewMethod = reviewMethodType?.name;

    if (this.review.reviewTime) {
      const timeTokens = this.review.reviewTime.split(':');
      const reviewTimeDate = new Date();
      reviewTimeDate.setHours(+timeTokens[0], +timeTokens[1], +timeTokens[2], 0);

      this.reviewTime = this.datePipe.transform(reviewTimeDate, 'shortTime');
    }
  }

  validate() {
    if(this.countdown.state == CountdownState.Lapsed) {
      this.isReviewTimeExpired = true;
      window.scroll(0, 0);
      return false;
    }

    return true;
  }

  submitRequest() {
    var isValid = this.validate();

    // Return submission if request is in-valid
    if (!isValid) return;

    this.isSubmitClicked=true;
    this.masterPage.showSpinner();

    //Filter only the selected review items
    this.review.reviewItems = this.review.reviewItems.filter(s => s.isSelected);

    // Set temp folder
    this.review.tempFolderName = this.tempFolderName;

    // Set document uploaded_date and uploaded_by
    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.review.documents?.filter(x=>(x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
      document.uploadedDate = today;
      document.uploadedBy = "Recipient";
    });

    this.contraventionService
        .requestReview(this.review)
        .subscribe(
          (result: any) => {
            this.masterPage.hideSpinner();

            // Update local review information
            this.review.reviewNumber = result.recordNumber;
            this.review.reviewId = result.reviewId;
            this.review.reviewSequence = result.reviewSequence;

            this.saveContext();

            this.router.navigate(['../submit-complete'], {
              relativeTo: this.activatedRoute,
            });
          },
          (error) => {
            if (error.error && error.error.errorCode == "6001") {
              this.isPaymentFailed = true;
            } else if (error.error && error.error.errorCode == "8001") {
              this.isReviewTimeExpired = true;
            } else if (error.error && error.error.errorCode == "2005") {
              this.isReviewAppliedAfterDeadline = true;
            } else if (error.error && error.error.errorCode == "2007") {
              this.isReviewRequestNotAvailable = true;
            } else {
              this.isRequestFailed = true;
            }
            this.masterPage.hideSpinner();

            window.scroll(0,0);
          });
  }

  submitRegistryRequest(isValid: boolean)
  {
    this.isSubmitClicked=true;
    
    isValid = isValid && this.validate();

    if (isValid)
    {
      this.masterPage.showSpinner();
      
      //Filter only the selected review items
      this.review.reviewItems = this.review.reviewItems.filter(s => s.isSelected);
      
      // Set temp folder
      this.review.tempFolderName = this.tempFolderName;

      // Set document uploaded_date and uploaded_by
      var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      this.review.documents?.filter(x=>(x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
        document.uploadedDate = today;
        document.uploadedBy = "Registry";
      });

      this.registryService
          .requestReview(this.review)
          .subscribe(
            (result: any) => {
              this.masterPage.hideSpinner();

              // Update review  number
              this.review.reviewNumber = result.recordNumber;
              this.review.scheduledEvent.meetingKey = result.meetingKey;
              this.review.videoConferenceUrl = result.videoConferenceUrl;

              this.saveContext();

              // Clear TransactionId to avoid any further transaction with same fulfillment ID
              var rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
              if (rsgAgentProfile)
              {
                rsgAgentProfile.transactionId = null;
                this.localStorageService.setRegistryAgentProfile(rsgAgentProfile);
              }

              this.router.navigate(['../submit-complete'], {
                relativeTo: this.activatedRoute,
              });
            },
            (error) => {
              if (error.error && error.error.errorCode == "2005") {
                this.isReviewAppliedAfterDeadline = true;
              } else if (error.error && error.error.errorCode == "2007") {
                this.isReviewRequestNotAvailable = true;
              } else {
                this.isRequestFailed = true;
              }
              this.masterPage.hideSpinner();
              window.scroll(0,0);
            });
    }
  }

  DownloadDocument(document: Document)
  {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;
      this.documentService.downloadDocument(this.tempFolderName, "", "", storageFileName , document.documentName)
      .subscribe((result: any) => {
        if (result)
        { 
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      });
  }

  getChargeCodes(c: ContraventionInformation) {
    var contraventionType = this.localStorageService.getContraventionTypes().find(ct => ct.id == c.contraventionTypeId);

    var chargeCodes = contraventionType.formattedChargeCode;

    if (c.secondaryContraventionTypeId) {
      contraventionType = this.localStorageService.getContraventionTypes().find(ct => ct.id == c.secondaryContraventionTypeId);

      chargeCodes = chargeCodes + ', ' + contraventionType.formattedChargeCode;
    }

    if (c.tertiaryContraventionTypeId) {
      contraventionType = this.localStorageService.getContraventionTypes().find(ct => ct.id == c.tertiaryContraventionTypeId);

      chargeCodes = chargeCodes + ', ' + contraventionType.formattedChargeCode;
    }

    return chargeCodes;
  }
}

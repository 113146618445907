import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ReviewBaseComponent } from 'apps/driver/src/app/components/contravention/review/apply/review-base.component';
import { Constants } from '@apis/shared/helpers/constants';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { ReviewItem } from '@apis/shared/models/review-item.model';
import { ReviewTypes } from '@apis/shared/enums/app.enum';

@Component({
  selector: 'app-review-terms-and-conditions',
  templateUrl: './review-terms-and-conditions.component.html',
  styleUrls: ['./review-terms-and-conditions.component.scss']
})
export class ReviewTermsAndConditionsComponent extends ReviewBaseComponent implements OnInit {
  reviewTypeId: number;
  hasMultipleReviewTypes: boolean;
  ReviewTypes=ReviewTypes;
  Constants=Constants;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public schedulerService: SchedulerService
  ) { 
    super(router, activatedRoute, localStorageService, schedulerService);
  }

  ngOnInit(): void {
    this.reviewTypeId = this.review.reviewTypeId;
    
    this.hasMultipleReviewTypes = (this.contraventionOverview.irsReviewEligibleContraventions?.length > 0
      && this.contraventionOverview.tsaReviewEligibleContraventions?.length > 0);

  }

  onProceedClick(isValid: boolean)
  {
    if (this.reviewTypeId != this.review.reviewTypeId) {
      this.review.reviewItems = [];
    }

    if (this.review.reviewItems.length == 0) {
      const contraventionsForReview = this.review.reviewTypeId == ReviewTypes.IrsReview
        ? this.contraventionOverview.irsReviewEligibleContraventions
        : this.contraventionOverview.tsaReviewEligibleContraventions;

      contraventionsForReview.forEach(cn => {
        this.review.reviewItems.push(new ReviewItem({
          recordNumber: cn,
          isSelected: contraventionsForReview.length == 1
        }));
      });
    }

    this.saveContext();

    if(isValid)
      this.router.navigate(['../details'], { relativeTo: this.activatedRoute, });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from 'file-saver';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { DriverBaseComponent } from '../../../driver-base.component';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { Review } from '@apis/shared/models/review.model';
import { OnlinePaymentRequest } from '@apis/shared/models/online-payment-request.model';
import { ReviewTypes } from '@apis/shared/enums/app.enum';
import { Guid } from 'guid-typescript';
import { ReviewApplicant } from '@apis/shared/models/review-applicant.model';

@Component({
  selector: 'app-late-review-decision',
  templateUrl: './late-review-decision.component.html',
  styleUrls: ['./late-review-decision.component.scss']
})
export class LateReviewDecisionComponent extends DriverBaseComponent implements OnInit {
  contraventionOverview: ContraventionOverview;
  lateReviewRequests: LateReviewRequest[] = [];
  decisionLetterDocument: DocumentModel;
  TypeTable = TypeTable;

  contraventionNumber: string;
  
  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private localStorageService: LocalStorageService
  ) {
    super(router);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
      this.contraventionOverview = this.localStorageService.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      if (this.contraventionOverview.lateReviewRequests?.length > 0) {
        this.lateReviewRequests = this.contraventionOverview.lateReviewRequests;
      } else {
        this.router.navigate(['../../']);
      }
    });
  }

  startReviewRequest() {
    // Initialize review request
    const review= new Review({
      contraventionNumber: this.contraventionNumber,
      reviewMethodTypeId: null,
      isSelfSubmit: true,
      isAccompainedByInterpreter: null,
      contactMethodTypeId: null,
      reviewScheduleRequest: null,
      paymentRequest: new OnlinePaymentRequest(),
      reviewApplicant: new ReviewApplicant({
        vehicleInterestTypeId: null
      }),
      termsConditionsVersion: this.getLatestTermsAndConditionsVersion()
    });

    if (this.contraventionOverview.irsReviewEligibleContraventions?.length > 0
      && (this.contraventionOverview.tsaReviewEligibleContraventions == null
        || this.contraventionOverview.tsaReviewEligibleContraventions?.length == 0))
    {
      review.reviewTypeId = ReviewTypes.IrsReview;  
    }

    if (this.contraventionOverview.tsaReviewEligibleContraventions?.length > 0
      && (this.contraventionOverview.irsReviewEligibleContraventions == null
        || this.contraventionOverview.irsReviewEligibleContraventions?.length == 0))
    {
      review.reviewTypeId = ReviewTypes.TrafficReview;  
    }

    // Save request to local storage
    this.localStorageService.setReviewRequest(review);

    // Initialize temporary folder location to upload documents
    this.localStorageService.setTempFolderName(Guid.create().toString())

    // Navigate to review request landing page
    this.router.navigate(['../review/apply/terms'], {relativeTo: this.activatedRoute});
  }

  getLatestTermsAndConditionsVersion(): string {
    return this.localStorageService
               .getTermsAndConditionsList()
               .find(t => t.expiryDate == null)
               .version;
  }
}

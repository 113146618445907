import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { LateReviewBaseComponent } from '../late-review-base.component';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-late-review-confirmation',
  templateUrl: './late-review-confirmation.component.html',
  styleUrls: ['./late-review-confirmation.component.scss']
})
export class LateReviewConfirmationComponent extends LateReviewBaseComponent
  implements OnInit {

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private contraventionService: ContraventionService,
    private spinner: NgxSpinnerService
  ) {
    super(router, activatedRoute, localStorageService);
  }

  ngOnInit(): void {
    // Clear late review request session
    this.localStorage.setLateReviewRequest(null);
  }

  onPrintReceiptClick() {
    this.spinner.show();
    this.contraventionService
      .getLateReviewRequestPaymentReceipt(
        this.contraventionNumber,
        this.lateReviewRequest.lateReviewRequestNumber)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Contravention Late Review Request - ${this.lateReviewRequest.lateReviewRequestNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactMethodTypes, RepresentationTypes, ReviewTypes, VehicleInterestTypes } from '@apis/shared/enums/app.enum';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';
import { Representation } from '@apis/shared/models/representation.model';
import { ReviewItem } from '@apis/shared/models/review-item.model';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { ContactMethodType } from '@apis/shared/models/types/contact-method-type.model';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { SeizureReviewBaseComponent } from '../seizure-review-base.component';

@Component({
  selector: 'app-seizure-review-details',
  templateUrl: './seizure-review-details.component.html',
  styleUrls: ['./seizure-review-details.component.scss']
})
export class SeizureReviewDetailsComponent extends SeizureReviewBaseComponent
  implements OnInit {

  genderTypes: GenderType[];
  contactMethodTypes: ContactMethodType[];
  contraventionDetails: number[];
  showReleaseDateWarning: boolean;
  VehicleInterestTypes = VehicleInterestTypes;
  ReviewTypes = ReviewTypes;
  rsgAgentProfile: RsgAgentProfile;
  registryAgentInformation: RegistryAgentInformation;
  reviewItem: ReviewItem;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public schedulerService: SchedulerService
  ) { 
    super(router, activatedRoute, localStorageService, schedulerService);
  }

  ngOnInit(): void {
    if (this.isRSGPortal)
    {
      this.rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
      if (!this.rsgAgentProfile)
        this.router.navigate(['/unauthorized']);

      if (this.review.registryAgentInformation.length === 0) {
        this.registryAgentInformation = new RegistryAgentInformation();
        this.registryAgentInformation.userName = this.rsgAgentProfile.userName;
        this.registryAgentInformation.organizationCode = this.rsgAgentProfile.organization;
      }
      else {
        this.registryAgentInformation = this.review.registryAgentInformation[0];
      }

      this.review.contactMethodTypeId = ContactMethodTypes.Email;
    }
    
    this.genderTypes = this.localStorageService.getGenderTypes();
    this.contactMethodTypes = this.localStorageService.getContactMethodTypes();

    // Show warning if Vehicle Release Date is less than 7 days
    if (+DateUtil.addDays(new Date(), 7) > +new Date(this.vehicleSeizure.vehicleReleaseDate)) {
      this.showReleaseDateWarning = true;
    }
    
    this.reviewItem = this .review.reviewItems[0];
  }

  onReviewTypeChange() {
    this.review.representation = this.review.isSelfSubmit ? null : new Representation({
      representationTypeId: null
    });
  }

  onRequesterTypeChange() {
    this.review.representation = this.registryAgentInformation.isPaidByRecipient ? null : new Representation({
      representationTypeId: null
    });
    this.review.isSelfSubmit = this.registryAgentInformation.isPaidByRecipient;
  }

  onVehicleInterestTypeChange() {
    this.review.hasRentalAgreement = null;
    if (this.isRSGPortal) {
      this.review.isSelfSubmit = this.registryAgentInformation.isPaidByRecipient;
    }
  }

  continueRequest(isValid: boolean) {
    if(isValid) {
      this.updateReviewModel();
      this.saveContext();
      this.router.navigate(['../schedule-review'], { relativeTo: this.activatedRoute, });
    }
  }

  updateReviewModel() {
    if (this.isRSGPortal) {
      // Update registryAgentInformation
      if (this.review.registryAgentInformation.length === 0) {
        this.review.registryAgentInformation.push(this.registryAgentInformation);
      }
      else {
        this.review.registryAgentInformation[0] = this.registryAgentInformation;
      }

      // Set applicant name
      if (+this.review.reviewTypeId === ReviewTypes.SuspendedDriverVehicleSeizureReview && this.review.isSelfSubmit) {
        this.review.reviewApplicant.firstName = this.vehicleSeizure.recipient.firstName;
        this.review.reviewApplicant.lastName = this.vehicleSeizure.recipient.lastName;
        this.review.reviewApplicant.otherName = this.vehicleSeizure.recipient.otherName;
      }
      else {
        this.review.reviewApplicant.firstName = this.registryAgentInformation.payerFirstName;
        this.review.reviewApplicant.lastName = this.registryAgentInformation.payerLastName;
        this.review.reviewApplicant.otherName = '';
      }

      // Set representative
      if (this.review.representation?.sameAsRequester) {
        this.review.representation.firstName = this.registryAgentInformation.payerFirstName;
        this.review.representation.lastName = this.registryAgentInformation.payerLastName;
      }

      if (+this.review.reviewTypeId === ReviewTypes.ThirdPartySeizureReview) {
        if (this.review.isSelfSubmit) { // If interest type is registered owner, do not include a representative
          this.review.representation = null;
        }
        else { // If interest type is not registered owner, create new representative based on applicant
          const vehicleInterestTypes = this.localStorageService.getVehicleInterestTypes();
          const selectedInterestType = vehicleInterestTypes.find(x =>
            x.id === +this.review.reviewApplicant.vehicleInterestTypeId
          );

          let interestTypeName: string;
          if (selectedInterestType.id === VehicleInterestTypes.Other) {
            interestTypeName = this.review.reviewApplicant.otherInterestType;
          } else {
            interestTypeName = selectedInterestType.name;
          }

          this.review.representation = new Representation({
            representationTypeId: RepresentationTypes.Other,
            otherRepresentationType: interestTypeName,
            firstName: this.review.reviewApplicant.firstName,
            lastName: this.review.reviewApplicant.lastName
          });
        }
      }
    }
    else {
      // Set applicant name
      if (+this.review.reviewTypeId === ReviewTypes.SuspendedDriverVehicleSeizureReview) {
        if (this.review.isSelfSubmit) {
          this.review.reviewApplicant.firstName = this.vehicleSeizure.recipient.firstName;
          this.review.reviewApplicant.lastName = this.vehicleSeizure.recipient.lastName;
          this.review.reviewApplicant.otherName = this.vehicleSeizure.recipient.otherName;
        }
        else {
          this.review.reviewApplicant.firstName = this.review.representation.firstName;
          this.review.reviewApplicant.lastName = this.review.representation.lastName;
          this.review.reviewApplicant.otherName = '';
        }
      }
    }
  }

}

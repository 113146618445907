import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@apis/shared/shared.module';
import { APP_CONFIG } from '@apis/app-config';
import { environment } from '../environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { JWTTokenService } from './shared/services/jwttoken.service';
import { LookupService } from './shared/services/lookup.service';
import { DriverService } from './shared/services/driver.service';
import { PaymentService } from './shared/services/payment.service';
import { ContraventionService } from './shared/services/contravention.service';
import { VehicleSeizureService } from './shared/services/vehicle-seizure.service';
import { TokenInterceptor } from './shared/interceptors/token-interceptor';
import { SpinnerHttpInterceptor } from 'libs/shared/interceptors/spinner-http-interceptor';
import { ReviewService } from './shared/services/review.service';
import { RegistryService } from './shared/services/registry.service';
import { SchedulerService } from './shared/services/scheduler.service';

import { ContraventionLookupComponent } from './components/contravention/contravention-lookup/contravention-lookup.component';
import { ContraventionDetailsComponent } from './components/contravention/contravention-details/contravention-details.component';
import { ContraventionDetailsPendingComponent } from './components/contravention/contravention-details-pending/contravention-details-pending.component';
import { EmailSaferoadsComponent } from './components/email/email-saferoads/email-saferoads.component';
import { EmailSentComponent } from './components/email/email-sent/email-sent.component';
import { NotificationPreferencesSetComponent } from './components/contravention/notification-preferences-set/notification-preferences-set.component';
import { PaymentAmountComponent } from './components/contravention/payment/payment-amount/payment-amount.component';
import { PaymentMethodComponent } from './components/contravention/payment/payment-method/payment-method.component';
import { PaymentReviewComponent } from './components/contravention/payment/payment-review/payment-review.component';
import { PaymentSuccessComponent } from './components/contravention/payment/payment-success/payment-success.component';
import { RequestAdditionalTimeComponent } from './components/contravention/request-additional-time/request-additional-time.component';
import { RequestAdditionalTimeSubmittedComponent } from './components/contravention/request-additional-time-submitted/request-additional-time-submitted.component';
import { RequestTimeComponent } from './components/contravention/request-time/request-time.component';
import { RequestTimeSubmittedComponent } from './components/contravention/request-time-submitted/request-time-submitted.component';
import { ReviewDetailsComponent } from './components/contravention/review/apply/review-details/review-details.component';
import { ReviewSummaryComponent } from './components/contravention/review/apply/review-summary/review-summary.component';
import { ReviewPaymentMethodComponent } from './components/contravention/review/apply/review-payment-method/review-payment-method.component';
import { SubmissionCompleteComponent } from './components/contravention/review/apply/submission-complete/submission-complete.component';
import { SupportingDocumentsComponent } from './components/contravention/review/apply/supporting-documents/supporting-documents.component';
import { ReviewDetailsViewComponent } from './components/contravention/review/view/review-details-view/review-details-view.component';
import { SubmitAdditionalDocumentsComponent } from './components/contravention/review/view/submit-additional-documents/submit-additional-documents.component';
import { VehicleSeizureDetailsComponent } from './components/vehicle-seizure/vehicle-seizure-details/vehicle-seizure-details.component';
import { VehicleSeizureLookupComponent } from './components/vehicle-seizure/vehicle-seizure-lookup/vehicle-seizure-lookup.component';
import { DriverInformationComponent } from './shared/components/driver-information/driver-information.component';
import { ContraventionInformationComponent } from './shared/components/contravention-information/contravention-information.component';
import { LateReviewDetailsComponent } from './components/contravention/request-late-review/late-review-details/late-review-details.component';
import { LateReviewPaymentMethodComponent } from './components/contravention/request-late-review/late-review-payment-method/late-review-payment-method.component';
import { LateReviewSummaryComponent } from './components/contravention/request-late-review/late-review-summary/late-review-summary.component';
import { LateReviewConfirmationComponent } from './components/contravention/request-late-review/late-review-confirmation/late-review-confirmation.component';
import { ContactInformationComponent } from './shared/components/contact-information/contact-information.component';
import { RepresentationComponent } from './shared/components/representation/representation.component';
import { FoipComponent } from './shared/components/foip/foip.component';
import { PaymentHistoryComponent } from './components/contravention/payment/payment-history/payment-history.component';
import { ContraventionPageComponent } from './components/contravention/contravention-page/contravention-page.component';
import { SeizureLateReviewDetailsComponent } from './components/vehicle-seizure/late-review/seizure-late-review-details/seizure-late-review-details.component';
import { SeizureLateReviewPaymentMethodComponent } from './components/vehicle-seizure/late-review/seizure-late-review-payment-method/seizure-late-review-payment-method.component';
import { SeizureLateReviewSummaryComponent } from './components/vehicle-seizure/late-review/seizure-late-review-summary/seizure-late-review-summary.component';
import { SeizureLateReviewConfirmationComponent } from './components/vehicle-seizure/late-review/seizure-late-review-confirmation/seizure-late-review-confirmation.component';
import { VehicleSeizurePageComponent } from './components/vehicle-seizure/vehicle-seizure-page/vehicle-seizure-page.component';
import { ApplicantInformationComponent } from './shared/components/applicant-information/applicant-information.component';
import { RegisteredOwnerInformationComponent } from './shared/components/registered-owner-information/registered-owner-information.component';
import { VehicleInformationComponent } from './shared/components/vehicle-information/vehicle-information.component';
import { VehicleSeizureInformationComponent } from './shared/components/vehicle-seizure-information/vehicle-seizure-information.component';
import { DriverHeaderComponent } from './shared/components/driver-header/driver-header.component';
import { DriverFooterComponent } from './shared/components/driver-footer/driver-footer.component';
import { LateReviewDecisionComponent } from './components/contravention/request-late-review/late-review-decision/late-review-decision.component';
import { SeizureReviewDetailsComponent } from './components/vehicle-seizure/review/apply/seizure-review-details/seizure-review-details.component';
import { SeizureReviewPaymentMethodComponent } from './components/vehicle-seizure/review/apply/seizure-review-payment-method/seizure-review-payment-method.component';
import { SeizureReviewSummaryComponent } from './components/vehicle-seizure/review/apply/seizure-review-summary/seizure-review-summary.component';
import { SeizureReviewSubmissionCompleteComponent } from './components/vehicle-seizure/review/apply/seizure-review-submission-complete/seizure-review-submission-complete.component';
import { SeizureReviewSupportingDocumentsComponent } from './components/vehicle-seizure/review/apply/seizure-review-supporting-documents/seizure-review-supporting-documents.component';
import { SeizurePreferredReviewTimesComponent } from './components/vehicle-seizure/review/apply/seizure-preferred-review-times/seizure-preferred-review-times.component';
import { SeizureReviewDetailsViewComponent } from './components/vehicle-seizure/review/view/seizure-review-details-view/seizure-review-details-view.component';
import { SeizureReviewAdditionalDocumentsComponent } from './components/vehicle-seizure/review/view/seizure-review-additional-documents/seizure-review-additional-documents.component';
import { ScheduleReviewComponent } from './components/contravention/review/apply/schedule-review/schedule-review.component';

import { MustMatchDirective } from "./shared/directives/must-match.directive";
import { NoWhiteSpaceDirective } from "./shared/directives/no-white-space.directive";
import { RangeDirective } from "./shared/directives/range.directive";

import { NgxMaskDirective } from 'ngx-mask';
import { SeizureLateReviewDecisionComponent } from './components/vehicle-seizure/late-review/seizure-late-review-decision/seizure-late-review-decision.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TermsConditionsComponent } from './shared/components/terms-conditions/terms-conditions.component';
import { TechnicalMaterialsLibraryComponent } from './components/technical-materials-library/technical-materials-library.component';
import { VehicleSeizureUserTypeComponent } from './components/vehicle-seizure/vehicle-seizure-user-type/vehicle-seizure-user-type.component';
import { ScheduledMaintenanceComponent } from './components/scheduled-maintenance/scheduled-maintenance.component';
import { InternalServerErrorComponent } from './components/internal-server-error/internal-server-error.component';
import { LateReviewSupportingDocumentsComponent } from './components/contravention/request-late-review/late-review-supporting-documents/late-review-supporting-documents.component';
import { SeizureLateReviewSupportingDocumentsComponent } from './components/vehicle-seizure/late-review/seizure-late-review-supporting-documents/seizure-late-review-supporting-documents.component';
import { UploadReviewConsentModalComponent } from './shared/components/modals/upload-review-consent-modal/upload-review-consent-modal.component';
import { ReviewDetailsEditComponent } from './components/contravention/review/view/review-details-edit/review-details-edit.component';
import { SeizureReviewDetailsEditComponent } from './components/vehicle-seizure/review/view/seizure-review-details-edit/seizure-review-details-edit.component';
import { ContraventionActivityHistoryComponent } from './components/contravention/contravention-activity-history/contravention-activity-history.component';
import { VehicleSeizureActivityHistoryComponent } from './components/vehicle-seizure/vehicle-seizure-activity-history/vehicle-seizure-activity-history.component';
import { SeizureScheduleReviewComponent } from './components/vehicle-seizure/review/apply/seizure-schedule-review/seizure-schedule-review.component';

import { RsgHomePageComponent } from './components/registry/rsg-home-page/rsg-home-page.component';
import { RsgContraventionLookupComponent } from './components/registry/contravention/rsg-contravention-lookup/rsg-contravention-lookup.component';
import { RsgPaymentInformationComponent } from './components/registry/contravention/rsg-payment/rsg-payment-information/rsg-payment-information.component';
import { RsgPaymentReviewComponent } from './components/registry/contravention/rsg-payment/rsg-payment-review/rsg-payment-review.component';
import { RsgPaymentSuccessComponent } from './components/registry/contravention/rsg-payment/rsg-payment-success/rsg-payment-success.component';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { RsgVehicleSeizureLookupComponent } from './components/registry/vehicle-seizure/rsg-vehicle-seizure-lookup/rsg-vehicle-seizure-lookup.component';
import { ReviewTermsConditionsComponent } from './shared/components/review-terms-conditions/review-terms-conditions.component';
import { ReviewTermsAndConditionsComponent } from './components/contravention/review/apply/review-terms-and-conditions/review-terms-and-conditions.component';
import { ReviewTechMaterialLibraryComponent } from './components/registry/contravention/review/apply/review-tech-material-library/review-tech-material-library.component';
import { LateReviewTermsAndConditionsComponent } from './components/registry/contravention/late-review/late-review-terms-and-conditions/late-review-terms-and-conditions.component';
import { SeizureReviewTermsAndConditionsComponent } from './components/registry/vehicle-seizure/review/apply/seizure-review-terms-and-conditions/seizure-review-terms-and-conditions.component';
import { SeizureLateReviewTermsAndConditionsComponent } from './components/registry/vehicle-seizure/late-review/seizure-late-review-terms-and-conditions/seizure-late-review-terms-and-conditions.component';
import { DailyBalanceReportComponent } from './components/registry/reports/daily-balance-report/daily-balance-report.component';
import { ReviewDetailsRescheduleComponent } from './components/contravention/review/view/review-details-reschedule/review-details-reschedule.component';
import {SeizureReviewDetailsRescheduleComponent} from './components/vehicle-seizure/review/view/seizure-review-details-reschedule/seizure-review-details-reschedule.component';
import { RescheduleCompleteComponent } from './components/contravention/review/view/reschedule-complete/reschedule-complete.component';
import { SeizureRescheduleCompleteComponent } from './components/vehicle-seizure/review/view/seizure-reschedule-complete/seizure-reschedule-complete.component';
import { ContraventionOverviewComponent } from './components/contravention/contravention-overview/contravention-overview.component';
import { ContraventionInformation2Component } from './components/contravention/contravention-overview/components/contravention-information2/contravention-information2.component';
import { ContraventionLineItemComponent } from './components/contravention/payment/components/contravention-line-item/contravention-line-item.component';
import { ContraventionDetailInformationComponent } from './components/contravention/contravention-details/components/contravention-detail-information/contravention-detail-information.component';
import { LateReviewRequestComponent } from './components/contravention/request-late-review/late-review-decision/components/late-review-request/late-review-request.component';
import { SeizureInformationComponent } from './components/contravention/contravention-overview/components/seizure-information/seizure-information.component';

@NgModule({
  declarations: [
    AppComponent,
    ContraventionLookupComponent,
    ContraventionDetailsComponent,
    ContraventionDetailsPendingComponent,
    EmailSaferoadsComponent,
    EmailSentComponent,
    NotificationPreferencesSetComponent,
    PaymentAmountComponent,
    PaymentMethodComponent,
    PaymentReviewComponent,
    PaymentSuccessComponent,
    RequestAdditionalTimeComponent,
    RequestAdditionalTimeSubmittedComponent,
    RequestTimeComponent,
    RequestTimeSubmittedComponent,
    ReviewDetailsComponent,
    ReviewPaymentMethodComponent,
    ReviewSummaryComponent,
    SubmissionCompleteComponent,
    SupportingDocumentsComponent,
    ReviewDetailsViewComponent,
    SubmitAdditionalDocumentsComponent,
    VehicleSeizureDetailsComponent,
    VehicleSeizureLookupComponent,
    DriverInformationComponent,
    ContraventionInformationComponent,
    LateReviewDetailsComponent,
    LateReviewPaymentMethodComponent,
    LateReviewSummaryComponent,
    LateReviewConfirmationComponent,
    ContactInformationComponent,
    RepresentationComponent,
    FoipComponent,
    PaymentHistoryComponent,
    ContraventionPageComponent,
    SeizureLateReviewDetailsComponent,
    SeizureLateReviewPaymentMethodComponent,
    SeizureLateReviewSummaryComponent,
    SeizureLateReviewConfirmationComponent,
    VehicleSeizurePageComponent,
    ApplicantInformationComponent,
    RegisteredOwnerInformationComponent,
    VehicleInformationComponent,
    VehicleSeizureInformationComponent,
    DriverHeaderComponent,
    DriverFooterComponent,
    DriverHeaderComponent,
    DriverFooterComponent,
    LateReviewDecisionComponent,
    SeizureReviewDetailsComponent,
    SeizureReviewPaymentMethodComponent,
    SeizureReviewSummaryComponent,
    SeizureReviewSubmissionCompleteComponent,
    SeizureReviewSupportingDocumentsComponent,
    SeizurePreferredReviewTimesComponent,
    SeizureReviewDetailsViewComponent,
    SeizureReviewAdditionalDocumentsComponent,
    MustMatchDirective,
    NoWhiteSpaceDirective,
    RangeDirective,
    SeizureLateReviewDecisionComponent,
    PageNotFoundComponent,
    TermsConditionsComponent,
    TechnicalMaterialsLibraryComponent,
    VehicleSeizureUserTypeComponent,
    ScheduledMaintenanceComponent,
    InternalServerErrorComponent,
    LateReviewSupportingDocumentsComponent,
    SeizureLateReviewSupportingDocumentsComponent,
    UploadReviewConsentModalComponent,
    ReviewDetailsEditComponent,
    SeizureReviewDetailsEditComponent,
    ContraventionActivityHistoryComponent,
    RsgHomePageComponent,
    RsgContraventionLookupComponent,
    RsgPaymentInformationComponent,
    RsgPaymentReviewComponent,
    RsgPaymentSuccessComponent,
    SessionExpiredComponent,
    VehicleSeizureActivityHistoryComponent,
    RsgVehicleSeizureLookupComponent,
    ReviewTermsConditionsComponent,
    ReviewTermsAndConditionsComponent,
    ReviewTechMaterialLibraryComponent,
    LateReviewTermsAndConditionsComponent,
    SeizureReviewTermsAndConditionsComponent,
    SeizureLateReviewTermsAndConditionsComponent,
    DailyBalanceReportComponent,
    ScheduleReviewComponent,
    SeizureScheduleReviewComponent,    
    ReviewDetailsRescheduleComponent,
    SeizureReviewDetailsRescheduleComponent,
    RescheduleCompleteComponent,
    SeizureRescheduleCompleteComponent,
    ContraventionOverviewComponent,
    ContraventionInformation2Component,
    ContraventionLineItemComponent,
    ContraventionDetailInformationComponent,
    LateReviewRequestComponent,
    SeizureInformationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,    
    SharedModule,
    HttpClientModule,    
    FlexLayoutModule, 
    RecaptchaV3Module,
    NgxMaskDirective
  ],
  providers: [
    { provide: APP_CONFIG, useValue: environment},
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },     
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerHttpInterceptor, multi: true },
    JWTTokenService,
    DriverService,
    LookupService,
    PaymentService,
    ContraventionService,
    VehicleSeizureService,
    ReviewService,
    RegistryService,
    SchedulerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Review } from '@apis/shared/models/review.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { ReviewTypes, SeizureUserType } from '@apis/shared/enums/app.enum';
import { OnInit } from '@angular/core';
import { DriverBaseComponent } from '../../../driver-base.component';
import { ReviewService } from 'apps/driver/src/app/shared/services/review.service';

export abstract class SeizureReviewDetailsBaseComponent extends DriverBaseComponent{
  review: Review;
  vehicleSeizure: VehicleSeizure;

  seizureNumber: string;
  tempFolderName: string;
  seizureUserType: string;
  reviewContext = 'Review';
  reviewSequence: number;
  maxReviewSequence: number;

  ReviewTypes = ReviewTypes;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public reviewService: ReviewService
  ) {
    super(router);
  }
  
  onInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.seizureNumber = paramMap.get('seizureNumber');
      this.reviewSequence = Number.parseInt(paramMap.get('reviewSequence'));

      this.vehicleSeizure = this.localStorageService.getItem<VehicleSeizure>(
        this.seizureNumber
      );

      this.seizureUserType = this.localStorageService.get(
        `${this.seizureNumber}_user_type`
      );

      if (
        this.seizureUserType == null ||
        this.vehicleSeizure == null ||
        this.vehicleSeizure.reviewItems.length === 0
      ) {
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      }

      if (+this.seizureUserType == SeizureUserType.Driver) {
        this.loadReview(+ReviewTypes.SuspendedDriverVehicleSeizureReview);
        this.maxReviewSequence = this.vehicleSeizure.review.reviewSequence;
      } else {
        this.loadReview(+ReviewTypes.ThirdPartySeizureReview);
        this.maxReviewSequence = this.vehicleSeizure.relief.reviewSequence;
        this.reviewContext = "Relief";
      }
    });
  }

  private loadReview(reviewTypeId) {
    this.reviewService.getSeizureReviewInSequenceAsync(this.seizureNumber, this.reviewSequence, reviewTypeId)
      .subscribe((review) => {
        this.review = review;
        this.onInitComplete();
      },
      (error) => {
        this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
      });
  }

  protected abstract onInitComplete(): void;
}

import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Representation } from '@apis/shared/models/representation.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { PaymentBaseComponent } from '../payment-base.component';
import { TransactionLineItem } from '@apis/shared/models/transaction-line-item.model';

@Component({
  selector: 'app-payment-amount',
  templateUrl: './payment-amount.component.html',
  styleUrls: ['./payment-amount.component.scss'],
})
export class PaymentAmountComponent extends PaymentBaseComponent
  implements OnInit {

  isPaymentAmountReadonly: boolean;
  errorMessage = '';

  constructor(
    router: Router,
    public route: ActivatedRoute,
    localStorage: LocalStorageService,
  ) {
    super(router, route, localStorage);
  }

  ngOnInit(): void {    
  }

  continuePayment(isValid) {
    this.errorMessage = '';
    
    const atleastOneContraventionSelected = this.paymentRequest.lineItems.some(li => li.isChecked);
    if (!atleastOneContraventionSelected) {
      this.errorMessage = 'Please select at least one contravention to proceed with the payment.';
      return;
    }

    if(isValid) {
      this.paymentRequest.paymentAmount = this.getTotalPaymentAmount();
      
      this.SaveState();
      this.router.navigate(['../method'], { relativeTo: this.route });
    }
  }

  onRepresentationTypeChange() {
    this.paymentRequest.representation = this.paymentRequest.isSelfSubmit
      ? null
      : new Representation({
        representationTypeId: null
      });
  }

  getContravention(recordNumber: string) {
    return this.contraventionOverview.contraventions.find(c => c.contraventionNumber == recordNumber);
  }

  getTotalPaymentAmount() {
    let totalPaymentAmount : number = 0;

    this.paymentRequest.lineItems?.forEach(li => {
      if (li.isChecked && +li.amount > 0) {
        totalPaymentAmount = totalPaymentAmount + (+li.amount);
      }
    })

    return totalPaymentAmount;
  }
}

<app-vehicle-seizure-page>
    <ng-container content>
        <form #tncForm="ngForm">
            <div class="d-flex flex-column">
                <h1 class="">Terms & Conditions</h1>            
                <h3 class="border-bottom pb-4 mb-2">No. {{review.seizureNumber}}</h3>
                <app-review-terms-conditions *ngIf="review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview"
                [infoSheetName]="Constants.Registry.TRANS22_INFO_SHEET_NAME" 
                [infoSheetLink]="Constants.Registry.TRANS22_INFO_SHEET_LINK"
                [isSubmitClicked]="tncForm.submitted"></app-review-terms-conditions>    

                <app-review-terms-conditions *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview"
                [infoSheetName]="Constants.Registry.TRANS23_INFO_SHEET_NAME" 
                [infoSheetLink]="Constants.Registry.TRANS23_INFO_SHEET_LINK"
                [isSubmitClicked]="tncForm.submitted"></app-review-terms-conditions>    
        
                <div class="form-group">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../']">Return to Seizure Details</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">                
                            <button class="goa-button goa--primary btn-block" (click)="onProceedClick(tncForm.valid)">Proceed to Seizure {{reviewContext}} Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>
import { Component, Input, OnInit } from '@angular/core';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-vehicle-seizure-information',
  templateUrl: './vehicle-seizure-information.component.html',
  styleUrls: ['./vehicle-seizure-information.component.scss']
})
export class VehicleSeizureInformationComponent implements OnInit {
  @Input() vehicleSeizure: VehicleSeizure;

  locationTypeName: string;
  seizureDuration: string;

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.seizureDuration = `${this.vehicleSeizure.seizureDuration} ${this.vehicleSeizure.seizureDurationType}`;
    this.locationTypeName = this.localStorageService.getTypeItemDescriptionById(this.vehicleSeizure.locationTypeId, TypeTable.LocationType);
  }

}

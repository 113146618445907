import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentRequest } from '@apis/models/payment-request.model'
import { PaymentDetailsComponent } from '@apis/shared/components/payment-details/payment-details.component';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { LateReviewBaseComponent } from '../late-review-base.component';

@Component({
  selector: 'app-late-review-payment-method',
  templateUrl: './late-review-payment-method.component.html',
  styleUrls: ['./late-review-payment-method.component.scss']
})
export class LateReviewPaymentMethodComponent extends LateReviewBaseComponent
 implements OnInit {
  @ViewChild('paymentDetails') paymentDetails: PaymentDetailsComponent;

  confirmEmail: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService
  ) { 
    super(router, activatedRoute, localStorageService);
  }

  ngOnInit(): void {
    if (this.isRSGPortal)
    {
      var rsgAgentProfile = this.localStorage.getRegistryAgentProfile();

      if (!rsgAgentProfile)
        this.router.navigate(['/unauthorized']);

      this.lateReviewRequest.paymentRequest.transactionId = rsgAgentProfile.transactionId;
    }
  }

  continueRequest(isValid: boolean) {
    this.paymentDetails.createToken().subscribe(tokenResult => {
      if (isValid && tokenResult) {
        this.lateReviewRequest.paymentRequest.cardLastFour = tokenResult.last4;
        this.lateReviewRequest.paymentRequest.paymentToken = tokenResult.token;
        
        this.saveContext();
        this.router.navigate(['../summary'], {relativeTo: this.activatedRoute });
      }
    });
  }  

  continueRegistryRequest(isValid: boolean) {
    if (isValid) {
      this.saveContext();
      this.router.navigate(['../summary'], {relativeTo: this.activatedRoute });
    }
  }  

}

<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="upload-modal-title">Upload Consent Form</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <file-upload [document]="documents[0]" [fileUploadModel]="fileUploadModel" [isPrimaryDocument]="true"></file-upload>
        </div>
        <div *ngIf="errorMessage" class="d-flex flex-column flex-md-row">  
            <span class="error">{{errorMessage}}</span>
        </div>
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()">Cancel</div>
            <div id="confirmButton" class="confirm-button" (click)="onConfirmModalClick()">
                Submit
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DriverBaseComponent } from '../driver-base.component';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends DriverBaseComponent implements OnInit {
  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {
  }
}

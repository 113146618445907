import { EventEmitter, Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AdditionalDocumentCategory } from '@apis/shared/enums/additional-document-category.enum';
import { FileUploadModel } from '@apis/shared/models/file-upload.model';
import { Review } from '@apis/shared/models/review.model';
import { Guid } from 'guid-typescript';
import { ReviewService } from '../../../services/review.service';
import { Document } from '@apis/shared/models/document.model';
import { DocumentTypes } from '@apis/shared/enums/app.enum';
import { DriverBaseComponent } from 'apps/driver/src/app/components/driver-base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-review-consent-modal',
  templateUrl: './upload-review-consent-modal.component.html',
  styleUrls: ['./upload-review-consent-modal.component.scss']
})
export class UploadReviewConsentModalComponent extends DriverBaseComponent implements OnInit {
  @Input() review: Review;

  bodyElement: JQuery<HTMLElement>;
  modalOverlay: JQuery<HTMLElement>;
  confirmButton: JQuery<HTMLElement>;

  errorMessage: string;
  fileUploadModel: FileUploadModel;
  documents: Document[] = [];

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(router: Router,
    private readonly reviewService: ReviewService) {
    super(router);
  }

  ngOnInit(): void {
    this.bodyElement = $(document.body);
    this.bodyElement.addClass('overflow-hidden');
    this.modalOverlay = $('.modal-overlay');
    this.confirmButton = $('.confirm-button');

    this.initializeDocuments();
  }

  initializeDocuments() {
    //Initialize file upload model and a temporary file storage location
    this.fileUploadModel = new FileUploadModel();
    this.fileUploadModel.tempFileFolder = Guid.create().toString();
    this.fileUploadModel.additionalDocumentsCategory = AdditionalDocumentCategory.ContraventionReview;
    this.fileUploadModel.documentRefTypeName = "Reviews";
    this.fileUploadModel.documentRefTypeNumber = this.review.reviewNumber;

    var document = new Document();
    document.documentTypeId = DocumentTypes.RecipientsConsentOfRepresentation;
    document.isOptional = false;

    this.documents.push(document);
  }

  onCloseIconClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  onConfirmModalClick(): void {
    if (this.confirmButton.hasClass('disabled')) {
      return;
    }

    // Validation check
    let hasValidDoc = this.documents.filter(d => d && d.contentGuid && d.documentName).length > 0;
    if (!hasValidDoc) {
      this.errorMessage = "Consent of representation document is required";
      return;
    }

    this.confirmButton.addClass('disabled');
    this.confirmButton.addClass('saving');
  
    // Set temp folder
    this.review.tempFolderName = this.fileUploadModel.tempFileFolder;
  
    // Update documents
    this.review.documents = this.documents.filter(d => d && d.contentGuid && d.documentName);
  
    // Set document uploaded_date and uploaded_by
    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.review.documents?.filter(x=>(x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
      document.uploadedDate = today;
      document.uploadedBy = this.isRSGPortal? "Registry": "Recipient";
    });
  
    this.reviewService
        .addOrUpdateReviewDocuments(this.review)
        .subscribe((result: any) => {
          if (result != null) {
            this.removeOverflow();
            this.close.emit(result);
          }
        }, (error: any) => {
          this.confirmButton.removeClass("saving");
          this.confirmButton.removeAttr("disabled");
          this.showErrors(error);
        });
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  removeOverflow(): void {
    this.bodyElement.removeClass('overflow-hidden');
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-vehicle-information',
  templateUrl: './vehicle-information.component.html',
  styleUrls: ['./vehicle-information.component.scss']
})
export class VehicleInformationComponent implements OnInit {
  @Input() vehicle: Vehicle;

  licensedProvince: string;

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    if (!this.vehicle.licensedProvinceId) {
      this.licensedProvince = this.vehicle.licensedProvince;
    } else {
      this.licensedProvince = this.localStorageService.getTypeItemDescriptionById(this.vehicle.licensedProvinceId, TypeTable.ProvinceType);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SeizureReviewBaseComponent } from 'apps/driver/src/app/components/vehicle-seizure/review/apply/seizure-review-base.component';
import { Constants } from '@apis/shared/helpers/constants';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';

@Component({
  selector: 'app-seizure-review-terms-and-conditions',
  templateUrl: './seizure-review-terms-and-conditions.component.html',
  styleUrls: ['./seizure-review-terms-and-conditions.component.scss']
})
export class SeizureReviewTermsAndConditionsComponent extends SeizureReviewBaseComponent implements OnInit {
  Constants=Constants;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public schedulerService: SchedulerService 
  ) { 
    super(router, activatedRoute, localStorageService, schedulerService);
  }

  ngOnInit(): void {
  }

  onProceedClick(isValid: boolean)
  {
    if(isValid)
      this.router.navigate(['../details'], { relativeTo: this.activatedRoute, });
  }
}

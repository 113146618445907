import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/observable/of';

import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { APP_CONFIG } from '@apis/app-config';
import { PaymentTimeRequest } from '@apis/shared/models/payment-time-request.model';
import { PaymentAdditionalTimeRequest } from '@apis/shared/models/payment-additional-time-request.model';
import { PaymentRequest } from '@apis/shared/models/payment-request.model';
import { PaymentResponse } from '@apis/shared/models/payment-response.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Review } from '@apis/shared/models/review.model';
import { catchError } from 'rxjs/operators';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { ContraventionSearch } from '@apis/shared/models/contravention-search.model';
import { DailyBalanceRequest } from '@apis/shared/models/daily-balance-request.model';
import { DailyBalanceResponse } from '@apis/shared/models/daily-balance-response.model';

@Injectable()
export class RegistryService {
  private readonly apiUrl = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;
  private contravention: Contravention = null; // for caching
  public review: Review = null;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {}

  validateRSGTokenAsync(token: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/rsg/validate`,
    {
        headers: {
            "AccessToken": token
        }
    });
  }

  checkContraventionNumberAsync(contraventionNumber: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.apiUrl}/rsg/contraventions/exists/${contraventionNumber}`,
    {
        params: {
        }
    });
  }

  checkVehicleSeizureNumberAsync(seizureNumber: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/rsg/vehicle-seizures/exists/${seizureNumber}`,
    {
        params: {
        }
    });
  }

  checkContraventionPaymentsAsync(contraventionNumber: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.apiUrl}/rsg/contraventions/checkpaymentstatus/${contraventionNumber}`,
    {
        params: {
        }
    });
  }


  pingPaymentStatusAsync(contraventionNumber: string): Observable<string> {
    return this.httpClient.get<string>(`${this.apiUrl}/rsg/contraventions/ping/${contraventionNumber}`,
    {
        params: {
        }
    });
  }

  
  checkVehicleSeizurePaymentsAsync(vehicleSeizureNumber: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.apiUrl}/rsg/vehicle-seizures/checkpaymentstatus/${vehicleSeizureNumber}`,
    {
        params: {
        }
    });
  }

  processPayment(paymentRequest: PaymentRequest): Observable<PaymentResponse> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post<PaymentResponse>(
      `${this.apiUrl}/rsg/contraventions/${paymentRequest.recordNumber}/payments`,
      paymentRequest,
      httpOptions
    );
  }

  getPaymentTransaction(contraventionNumber: string, transactionId: number): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/rsg/contraventions/${contraventionNumber}/payments/${transactionId}`,
      {
          params: {
          }
      });
  }

  requestReview(review: Review) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/rsg/contraventions/${review.contraventionNumber}/reviews`,
      review,
      httpOptions
    );
  }

  requestLateReview(contraventionNumber: string, review: LateReviewRequest) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/rsg/contraventions/${contraventionNumber}/late-review-requests`,
      review,
      httpOptions
    );
  }

  requestSeizureReview(review: Review) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/rsg/vehicle-seizures/${review.seizureNumber}/reviews`,
      review,
      httpOptions
    );
  }

  requestSeizureLateReview(seizureNumber: string, review: LateReviewRequest) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/rsg/vehicle-seizures/${seizureNumber}/late-review-requests`,
      review,
      httpOptions
    );
  }

  getDailyBalanceReport(dailyBalanceRequest: DailyBalanceRequest): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/rsg/daily-balance-report`,
    {
        params: {
          "FromDate": dailyBalanceRequest.fromDate.toDateString(),
          "ToDate": dailyBalanceRequest.toDate.toDateString(),
          "RegistryAgentId": (dailyBalanceRequest.registryAgentId??'').trim(),
          "RegistryAgentOrganizationId": dailyBalanceRequest.registryAgentOrganizationId,
          "RecordNumber": (dailyBalanceRequest.recordNumber??'').trim(),
          "TransactionId": (dailyBalanceRequest.transactionId??'').trim(),
          "IsReviewTransactionRequired": String(dailyBalanceRequest.IsReviewTransactionRequired),
          "IsFineTransactionRequired": String(dailyBalanceRequest.IsFineTransactionRequired)
        }
    });
  }
}

import { NgModule, inject, resolveForwardRef } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { AppComponent } from './app.component';
import { ContraventionLookupComponent } from './components/contravention/contravention-lookup/contravention-lookup.component';
import { VehicleSeizureLookupComponent } from './components/vehicle-seizure/vehicle-seizure-lookup/vehicle-seizure-lookup.component';
import { ContraventionDetailsComponent } from './components/contravention/contravention-details/contravention-details.component';
import { ReviewDetailsComponent } from './components/contravention/review/apply/review-details/review-details.component';
import { ReviewDetailsViewComponent } from './components/contravention/review/view/review-details-view/review-details-view.component';
import { SubmitAdditionalDocumentsComponent } from './components/contravention/review/view/submit-additional-documents/submit-additional-documents.component';
import { ScheduleReviewComponent } from './components/contravention/review/apply/schedule-review/schedule-review.component';
import { SupportingDocumentsComponent } from './components/contravention/review/apply/supporting-documents/supporting-documents.component';
import { PaymentMethodComponent } from './components/contravention/payment/payment-method/payment-method.component';
import { ReviewSummaryComponent } from './components/contravention/review/apply/review-summary/review-summary.component';
import { SubmissionCompleteComponent } from './components/contravention/review/apply/submission-complete/submission-complete.component';
import { PaymentAmountComponent } from './components/contravention/payment/payment-amount/payment-amount.component';
import { ReviewPaymentMethodComponent } from './components/contravention/review/apply/review-payment-method/review-payment-method.component';
import { PaymentReviewComponent } from './components/contravention/payment/payment-review/payment-review.component';
import { PaymentSuccessComponent } from './components/contravention/payment/payment-success/payment-success.component';
import { RequestAdditionalTimeComponent } from './components/contravention/request-additional-time/request-additional-time.component';
import { RequestTimeComponent } from './components/contravention/request-time/request-time.component';
import { RequestTimeSubmittedComponent } from './components/contravention/request-time-submitted/request-time-submitted.component';
import { RequestAdditionalTimeSubmittedComponent } from './components/contravention/request-additional-time-submitted/request-additional-time-submitted.component';
import { VehicleSeizureDetailsComponent } from './components/vehicle-seizure/vehicle-seizure-details/vehicle-seizure-details.component';
import { LateReviewDetailsComponent } from './components/contravention/request-late-review/late-review-details/late-review-details.component';
import { LateReviewPaymentMethodComponent } from './components/contravention/request-late-review/late-review-payment-method/late-review-payment-method.component';
import { LateReviewSummaryComponent } from './components/contravention/request-late-review/late-review-summary/late-review-summary.component';
import { LateReviewConfirmationComponent } from './components/contravention/request-late-review/late-review-confirmation/late-review-confirmation.component';
import { PaymentHistoryComponent } from './components/contravention/payment/payment-history/payment-history.component';
import { EmailSaferoadsComponent } from './components/email/email-saferoads/email-saferoads.component';
import { SeizureLateReviewDetailsComponent } from './components/vehicle-seizure/late-review/seizure-late-review-details/seizure-late-review-details.component';
import { SeizureLateReviewConfirmationComponent } from './components/vehicle-seizure/late-review/seizure-late-review-confirmation/seizure-late-review-confirmation.component';
import { SeizureLateReviewPaymentMethodComponent } from './components/vehicle-seizure/late-review/seizure-late-review-payment-method/seizure-late-review-payment-method.component';
import { SeizureLateReviewSummaryComponent } from './components/vehicle-seizure/late-review/seizure-late-review-summary/seizure-late-review-summary.component';
import { LateReviewDecisionComponent } from './components/contravention/request-late-review/late-review-decision/late-review-decision.component';
import { SeizurePreferredReviewTimesComponent } from './components/vehicle-seizure/review/apply/seizure-preferred-review-times/seizure-preferred-review-times.component';
import { SeizureReviewDetailsComponent } from './components/vehicle-seizure/review/apply/seizure-review-details/seizure-review-details.component';
import { SeizureReviewPaymentMethodComponent } from './components/vehicle-seizure/review/apply/seizure-review-payment-method/seizure-review-payment-method.component';
import { SeizureReviewSummaryComponent } from './components/vehicle-seizure/review/apply/seizure-review-summary/seizure-review-summary.component';
import { SeizureReviewSubmissionCompleteComponent } from './components/vehicle-seizure/review/apply/seizure-review-submission-complete/seizure-review-submission-complete.component';
import { SeizureReviewSupportingDocumentsComponent } from './components/vehicle-seizure/review/apply/seizure-review-supporting-documents/seizure-review-supporting-documents.component';
import { SeizureReviewDetailsViewComponent } from './components/vehicle-seizure/review/view/seizure-review-details-view/seizure-review-details-view.component';
import { SeizureReviewAdditionalDocumentsComponent } from './components/vehicle-seizure/review/view/seizure-review-additional-documents/seizure-review-additional-documents.component';
import { SeizureLateReviewDecisionComponent } from './components/vehicle-seizure/late-review/seizure-late-review-decision/seizure-late-review-decision.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TechnicalMaterialsLibraryComponent } from './components/technical-materials-library/technical-materials-library.component';
import { VehicleSeizureUserTypeComponent } from './components/vehicle-seizure/vehicle-seizure-user-type/vehicle-seizure-user-type.component';
import { ScheduledMaintenanceComponent } from './components/scheduled-maintenance/scheduled-maintenance.component';
import { InternalServerErrorComponent } from './components/internal-server-error/internal-server-error.component';
import { LateReviewSupportingDocumentsComponent } from './components/contravention/request-late-review/late-review-supporting-documents/late-review-supporting-documents.component';
import { SeizureLateReviewSupportingDocumentsComponent } from './components/vehicle-seizure/late-review/seizure-late-review-supporting-documents/seizure-late-review-supporting-documents.component';
import { ReviewDetailsEditComponent } from './components/contravention/review/view/review-details-edit/review-details-edit.component';
import { SeizureReviewDetailsEditComponent } from './components/vehicle-seizure/review/view/seizure-review-details-edit/seizure-review-details-edit.component';
import { ContraventionActivityHistoryComponent } from './components/contravention/contravention-activity-history/contravention-activity-history.component';

import { RsgHomePageComponent } from './components/registry/rsg-home-page/rsg-home-page.component';
import { RsgContraventionLookupComponent } from './components/registry/contravention/rsg-contravention-lookup/rsg-contravention-lookup.component';
import { RsgPaymentInformationComponent } from './components/registry/contravention/rsg-payment/rsg-payment-information/rsg-payment-information.component';
import { RsgPaymentReviewComponent } from './components/registry/contravention/rsg-payment/rsg-payment-review/rsg-payment-review.component';
import { RsgPaymentSuccessComponent } from './components/registry/contravention/rsg-payment/rsg-payment-success/rsg-payment-success.component';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { UnauthorizedComponent } from '@apis/shared/components/unauthorized/unauthorized.component';
import { VehicleSeizureActivityHistoryComponent } from './components/vehicle-seizure/vehicle-seizure-activity-history/vehicle-seizure-activity-history.component';
import { RsgAuthGuard } from '@apis/shared/security/rsg-auth.guard';
import { RsgVehicleSeizureLookupComponent } from './components/registry/vehicle-seizure/rsg-vehicle-seizure-lookup/rsg-vehicle-seizure-lookup.component';
import { ReviewTermsAndConditionsComponent } from './components/contravention/review/apply/review-terms-and-conditions/review-terms-and-conditions.component';
import { ReviewTechMaterialLibraryComponent } from './components/registry/contravention/review/apply/review-tech-material-library/review-tech-material-library.component';
import { LateReviewTermsAndConditionsComponent } from './components/registry/contravention/late-review/late-review-terms-and-conditions/late-review-terms-and-conditions.component';
import { SeizureReviewTermsAndConditionsComponent } from './components/registry/vehicle-seizure/review/apply/seizure-review-terms-and-conditions/seizure-review-terms-and-conditions.component';
import { SeizureLateReviewTermsAndConditionsComponent } from './components/registry/vehicle-seizure/late-review/seizure-late-review-terms-and-conditions/seizure-late-review-terms-and-conditions.component';
import { DailyBalanceReportComponent } from './components/registry/reports/daily-balance-report/daily-balance-report.component';
import { SeizureScheduleReviewComponent } from './components/vehicle-seizure/review/apply/seizure-schedule-review/seizure-schedule-review.component';
import {ReviewDetailsRescheduleComponent} from './components/contravention/review/view/review-details-reschedule/review-details-reschedule.component';
import {SeizureReviewDetailsRescheduleComponent} from './components/vehicle-seizure/review/view/seizure-review-details-reschedule/seizure-review-details-reschedule.component';
import { RescheduleCompleteComponent } from './components/contravention/review/view/reschedule-complete/reschedule-complete.component';
import { SeizureRescheduleCompleteComponent } from './components/vehicle-seizure/review/view/seizure-reschedule-complete/seizure-reschedule-complete.component';
import { ContraventionOverviewComponent } from './components/contravention/contravention-overview/contravention-overview.component';

const routes: Routes = [
  { path: '', redirectTo: 'contraventions/search', pathMatch: 'full' },
  { path: 'contraventions/search', component: ContraventionLookupComponent },
  {
    path: 'contravention/:contraventionNumber',
    component: ContraventionOverviewComponent,
    pathMatch: 'full',
  },
  {
    path: 'contravention/:contraventionNumber/detail',
    component: ContraventionDetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'technical-material',
    component: TechnicalMaterialsLibraryComponent,
    pathMatch: 'full'
  },
  {
    path: 'contravention/:contraventionNumber',
    children: [
      { path: 'activity-history', component: ContraventionActivityHistoryComponent },
      { path: 'email-saferoads', component: EmailSaferoadsComponent },
      { path: 'time-to-pay-request/apply', component: RequestTimeComponent },
      { path: 'time-to-pay-request', component: RequestTimeSubmittedComponent },
      { path: 'additional-time-to-pay-request/apply', component: RequestAdditionalTimeComponent },
      { path: 'additional-time-to-pay-request', component: RequestAdditionalTimeSubmittedComponent },
      { path: 'payment', redirectTo: 'payment/amount', pathMatch: 'full' },
      {
        path: 'payment',
        data: { showStepper: true },
        children: [
          {
            path: 'amount',
            component: PaymentAmountComponent,
            data: { label: 'Select a Payment Amount' },
          },
          {
            path: 'method',
            component: PaymentMethodComponent,
            data: { label: 'Payment Method' },
          },
          {
            path: 'review',
            component: PaymentReviewComponent,
            data: { label: 'Review Payment' },
          },
          {
            path: 'success',
            component: PaymentSuccessComponent,
            data: { label: 'Payment Submitted' },
          },
        ],
      },
      { path: 'payment/history', component: PaymentHistoryComponent },
      { path: 'late-review-requests', component: LateReviewDecisionComponent},
      {
        path: 'late-review-request/apply',
        data: { showStepper: true },
        children: [
          {
            path: 'details',
            component: LateReviewDetailsComponent,
            data: { label: 'Late Review Request Form' },
          },
          {
            path: 'supporting-documents',
            component: LateReviewSupportingDocumentsComponent,
            data: { label: 'Supporting Documents' },
          },
          {
            path: 'payment-method',
            component: LateReviewPaymentMethodComponent,
            data: { label: 'Payment Method' },
          },
          {
            path: 'summary',
            component: LateReviewSummaryComponent,
            data: { label: 'Review & Payment' },
          },
          {
            path: 'submit-complete',
            component: LateReviewConfirmationComponent,
            data: { label: 'Request Submitted' },
          },
        ],
      },
      {
        path: 'review',
        children: [
          {
            path: 'apply',
            data: { showStepper: true },
            children: [
              {
                path: 'terms',
                component: ReviewTermsAndConditionsComponent,
                data: { label: 'Terms & Conditions' },
              },
              {
                path: 'details',
                component: ReviewDetailsComponent,
                data: { label: 'Review Details' },
              },
              {
                path: 'schedule-review',
                component: ScheduleReviewComponent,
                data: { label: 'Schedule Review' },
              },
              {
                path: 'payment-method',
                component: ReviewPaymentMethodComponent,
                data: { label: 'Payment Method' },
              },
              {
                path: 'summary',
                component: ReviewSummaryComponent,
                data: { label: 'Review & Payment' },
              },
              {
                path: 'submit-complete',
                component: SubmissionCompleteComponent,
                data: { label: 'Application Submitted' },
              },
              {
                path: 'supporting-documents',
                component: SupportingDocumentsComponent,
                data: { label: 'Supporting Documents' },
              }
            ],
          },
          {
            path: ':reviewSequence/:reviewType',
            children: [
              { path: 'details', component: ReviewDetailsViewComponent },
              { path: 'edit', component: ReviewDetailsEditComponent },
              { path: 'reschedule', component: ReviewDetailsRescheduleComponent },
              { path: 'reschedule-complete', component: RescheduleCompleteComponent },
              {
                path: 'supporting-documents',
                component: SubmitAdditionalDocumentsComponent,
              },
            ]
          }          
        ],
      },
    ],
  },
  { path: 'vehicle-seizures/search', component: VehicleSeizureLookupComponent },
  { path: 'vehicle-seizures/:seizureNumber/user-type', component: VehicleSeizureUserTypeComponent },
  {
    path: 'vehicle-seizures/:seizureNumber',
    component: VehicleSeizureDetailsComponent
  },
  {
    path: 'vehicle-seizures/:seizureNumber',
    children: [
      { path: 'activity-history', component: VehicleSeizureActivityHistoryComponent },
      { path: 'email-saferoads', component: EmailSaferoadsComponent },
      { path: 'late-review-request/decision', component: SeizureLateReviewDecisionComponent},
      {
        path: 'late-review-request/apply',
        data: { showStepper: true },
        children: [
          {
            path: 'details',
            component: SeizureLateReviewDetailsComponent,
            data: { label: 'Late Review Request Form' },
          },
          {
            path: 'supporting-documents',
            component: SeizureLateReviewSupportingDocumentsComponent,
            data: { label: 'Supporting Documents' },
          },
          {
            path: 'payment-method',
            component: SeizureLateReviewPaymentMethodComponent,
            data: { label: 'Payment Method' },
          },
          {
            path: 'summary',
            component: SeizureLateReviewSummaryComponent,
            data: { label: 'Review & Payment' },
          },
          {
            path: 'submit-complete',
            component: SeizureLateReviewConfirmationComponent,
            data: { label: 'Request Submitted' },
          },
        ],
      },
      {
        path: 'review',
        children: [
          {
            path: 'apply',
            data: { showStepper: true },
            children: [
              {
                path: 'details',
                component: SeizureReviewDetailsComponent,
                data: { label: 'Review Details' },
              },
              {
                path: 'schedule-review',
                component: SeizureScheduleReviewComponent,
                data: { label: 'Schedule Review' },
              },
              {
                path: 'payment-method',
                component: SeizureReviewPaymentMethodComponent,
                data: { label: 'Payment Method' },
              },
              {
                path: 'summary',
                component: SeizureReviewSummaryComponent,
                data: { label: 'Review & Payment' },
              },
              {
                path: 'submit-complete',
                component: SeizureReviewSubmissionCompleteComponent,
                data: { label: 'Application Submitted' },
              },
              {
                path: 'supporting-documents',
                component: SeizureReviewSupportingDocumentsComponent,
                data: { label: 'Supporting Documents' },
              }
            ],
          },
          {
            path: ':reviewSequence',
            children: [
              { path: 'details', component: SeizureReviewDetailsViewComponent },
              { path: 'edit', component: SeizureReviewDetailsEditComponent },
              { path: 'reschedule', component: SeizureReviewDetailsRescheduleComponent },
              { path: 'reschedule-complete', component: SeizureRescheduleCompleteComponent },
              {
                path: 'supporting-documents',
                component: SeizureReviewAdditionalDocumentsComponent,
              },
            ]
          }
        ],
      },
      {
        path: 'relief',
        children: [
          {
            path: 'apply',
            data: { showStepper: true },
            children: [
              {
                path: 'details',
                component: SeizureReviewDetailsComponent,
                data: { label: 'Relief Details' },
              },
              {
                path: 'schedule-review',
                component: SeizureScheduleReviewComponent,
                data: { label: 'Schedule Review' },
              },
              {
                path: 'payment-method',
                component: SeizureReviewPaymentMethodComponent,
                data: { label: 'Payment Method' },
              },
              {
                path: 'summary',
                component: SeizureReviewSummaryComponent,
                data: { label: 'Review & Payment' },
              },
              {
                path: 'submit-complete',
                component: SeizureReviewSubmissionCompleteComponent,
                data: { label: 'Application Submitted' },
              },
              {
                path: 'supporting-documents',
                component: SeizureReviewSupportingDocumentsComponent,
                data: { label: 'Supporting Documents' },
              }
            ],
          },          
        ],
      },
    ]
  },
  { path: 'rsg', component: RsgHomePageComponent },
  { 
    path: 'rsg', 
    children: [
      { path: 'daily-balance-report', component: DailyBalanceReportComponent, canActivate: mapToCanActivate([RsgAuthGuard]) },
      { path: 'contraventions/search', component: RsgContraventionLookupComponent, canActivate: mapToCanActivate([RsgAuthGuard]) },
      { path: 'contravention/:contraventionNumber', component: ContraventionOverviewComponent, canActivate: mapToCanActivate([RsgAuthGuard]), pathMatch: 'full' },
      {
        path: 'contravention/:contraventionNumber', canActivate: mapToCanActivate([RsgAuthGuard]),
        children: [
          { path: 'detail', component: ContraventionDetailsComponent},
          { path: 'activity-history', component: ContraventionActivityHistoryComponent},
          { path: 'payment/history', component: PaymentHistoryComponent},
          { path: 'time-to-pay-request/apply', component: RequestTimeComponent},
          { path: 'time-to-pay-request', component: RequestTimeSubmittedComponent},
          { path: 'additional-time-to-pay-request/apply', component: RequestAdditionalTimeComponent},
          { path: 'additional-time-to-pay-request', component: RequestAdditionalTimeSubmittedComponent},
          { path: 'payment', redirectTo: 'payment/information', pathMatch: 'full' },
          {
            path: 'payment',
            data: { showStepper: true },
            children: [
              {
                path: 'information',
                component: RsgPaymentInformationComponent, 
                data: { label: 'Payment Information' },
              },
              {
                path: 'review',
                component: RsgPaymentReviewComponent, 
                data: { label: 'Review Payment' },
              },
              {
                path: 'success',
                component: RsgPaymentSuccessComponent, 
                data: { label: 'Payment Submitted' },
              },
            ],
          },
          { path: 'late-review-requests', component: LateReviewDecisionComponent},
          {
            path: 'late-review-request/apply',
            data: { showStepper: true },
            children: [
              {
                path: 'terms',
                component: LateReviewTermsAndConditionsComponent,
                data: { label: 'Terms & Conditions' },
              },
              {
                path: 'details',
                component: LateReviewDetailsComponent,
                data: { label: 'Late Review Request Details' },
              },
              {
                path: 'supporting-documents',
                component: LateReviewSupportingDocumentsComponent,
                data: { label: 'Supporting Documents' },
              },
              {
                path: 'payment-method',
                component: LateReviewPaymentMethodComponent,
                data: { label: 'Payment' },
              },
              {
                path: 'summary',
                component: LateReviewSummaryComponent,
                data: { label: 'Request Summary' },
              },
              {
                path: 'submit-complete',
                component: LateReviewConfirmationComponent,
                data: { label: 'Request Submitted' },
              },
            ],
          },
          {
            path: 'review',
            children: [
              {
                path: 'apply',
                data: { showStepper: true },
                children: [
                  {
                    path: 'terms',
                    component: ReviewTermsAndConditionsComponent,
                    data: { label: 'Terms & Conditions' },
                  },
                  {
                    path: 'details',
                    component: ReviewDetailsComponent,
                    data: { label: 'Review Details' },
                  },
                  {
                    path: 'tech-material-library',
                    component: ReviewTechMaterialLibraryComponent,
                    data: { label: 'Technical Materials Library' },
                  },
                  {
                    path: 'schedule-review',
                    component: ScheduleReviewComponent,
                    data: { label: 'Schedule Review' },
                  },
                  {
                    path: 'payment-method',
                    component: ReviewPaymentMethodComponent,
                    data: { label: 'Payment' },
                  },
                  {
                    path: 'summary',
                    component: ReviewSummaryComponent,
                    data: { label: 'Application Summary' },
                  },
                  {
                    path: 'submit-complete',
                    component: SubmissionCompleteComponent,
                    data: { label: 'Application Submitted' },
                  },
                ],
              },
              {
                path: ':reviewSequence/:reviewType',
                children: [
                  { path: 'details', component: ReviewDetailsViewComponent},
                  { path: 'edit', component: ReviewDetailsEditComponent},
                  { path: 'reschedule', component: ReviewDetailsRescheduleComponent },
                  { path: 'reschedule-complete', component: RescheduleCompleteComponent },
                  { path: 'supporting-documents', component: SubmitAdditionalDocumentsComponent},
                ]
              }              
            ],
          },
        ]
      },
      { path: 'vehicle-seizures/search', component: RsgVehicleSeizureLookupComponent, canActivate: mapToCanActivate([RsgAuthGuard]) },
      { path: 'vehicle-seizures/:seizureNumber/user-type', component: VehicleSeizureUserTypeComponent, canActivate: mapToCanActivate([RsgAuthGuard])},
      { path: 'vehicle-seizures/:seizureNumber', component: VehicleSeizureDetailsComponent, canActivate: mapToCanActivate([RsgAuthGuard]) },
      {
        path: 'vehicle-seizures/:seizureNumber', canActivate: mapToCanActivate([RsgAuthGuard]),
        children: [
          { path: 'activity-history', component: VehicleSeizureActivityHistoryComponent},
          { path: 'late-review-request/decision', component: SeizureLateReviewDecisionComponent},
          {
            path: 'late-review-request/apply',
            data: { showStepper: true },
            children: [
              {
                path: 'terms',
                component: SeizureLateReviewTermsAndConditionsComponent,
                data: { label: 'Terms & Conditions' },
              },
              {
                path: 'details',
                component: SeizureLateReviewDetailsComponent,
                data: { label: 'Late Review Request Details' },
              },
              {
                path: 'supporting-documents',
                component: SeizureLateReviewSupportingDocumentsComponent,
                data: { label: 'Supporting Documents' },
              },
              {
                path: 'payment-method',
                component: SeizureLateReviewPaymentMethodComponent,
                data: { label: 'Payment' },
              },
              {
                path: 'summary',
                component: SeizureLateReviewSummaryComponent,
                data: { label: 'Request Summary' },
              },
              {
                path: 'submit-complete',
                component: SeizureLateReviewConfirmationComponent,
                data: { label: 'Request Submitted' },
              },
            ],
          },
          {
            path: 'review',
            children: [
              {
                path: 'apply',
                data: { showStepper: true },
                children: [
                  {
                    path: 'terms',
                    component: SeizureReviewTermsAndConditionsComponent,
                    data: { label: 'Terms & Conditions' },
                  },
                  {
                    path: 'details',
                    component: SeizureReviewDetailsComponent,
                    data: { label: 'Review Details' },
                  },
                  {
                    path: 'schedule-review',
                    component: SeizureScheduleReviewComponent,
                    data: { label: 'Schedule Review' },
                  },
                  {
                    path: 'payment-method',
                    component: SeizureReviewPaymentMethodComponent,
                    data: { label: 'Payment' },
                  },
                  {
                    path: 'summary',
                    component: SeizureReviewSummaryComponent,
                    data: { label: 'Application Summary' },
                  },
                  {
                    path: 'submit-complete',
                    component: SeizureReviewSubmissionCompleteComponent,
                    data: { label: 'Application Submitted' },
                  },
                ],
              },
              {
                path: ':reviewSequence',
                children: [
                  { path: 'details', component: SeizureReviewDetailsViewComponent },
                  { path: 'edit', component: SeizureReviewDetailsEditComponent },
                  { path: 'reschedule', component: SeizureReviewDetailsRescheduleComponent },
                  { path: 'reschedule-complete', component: SeizureRescheduleCompleteComponent },
                  {
                    path: 'supporting-documents',
                    component: SeizureReviewAdditionalDocumentsComponent,
                  },
                ]
              }
            ],
          },
          {
            path: 'relief',
            children: [
              {
                path: 'apply',
                data: { showStepper: true },
                children: [
                  {
                    path: 'terms',
                    component: SeizureReviewTermsAndConditionsComponent,
                    data: { label: 'Terms & Conditions' },
                  },
                  {
                    path: 'details',
                    component: SeizureReviewDetailsComponent,
                    data: { label: 'Relief Details' },
                  },
                  {
                    path: 'schedule-review',
                    component: SeizureScheduleReviewComponent,
                    data: { label: 'Schedule Review' },
                  },
                  {
                    path: 'payment-method',
                    component: SeizureReviewPaymentMethodComponent,
                    data: { label: 'Payment' },
                  },
                  {
                    path: 'summary',
                    component: SeizureReviewSummaryComponent,
                    data: { label: 'Application Summary' },
                  },
                  {
                    path: 'submit-complete',
                    component: SeizureReviewSubmissionCompleteComponent,
                    data: { label: 'Application Submitted' },
                  },
                ],
              },
            ],
          },
        ]
      },    
    ]
  },  
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'session-expired', component: SessionExpiredComponent },
  { path: 'scheduled-maintenance', component: ScheduledMaintenanceComponent},
  { path: 'internal-server-error', component: InternalServerErrorComponent},
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

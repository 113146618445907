import { Review } from "./review.model";
import { ReviewItemCancellationReason } from "./review-item-cancellation-reason.model";

export class ReviewItem {
    public incidentReviewId: number = 0;
    public contraventionTypeId: number = 0;
    public seizureTypeId: number = 0;
    public newSeizureTypeId: number = 0;
    public newContraventionTypeId: number = 0;
    public contraventionId: number;
    public vehicleSeizureId: number;
    public reviewStatusTypeId: number = 0;
    public reviewGrounds: string;
    public additionalRequestNotes: string;
    public decisionTypeId: number;
    public recordNumber: string;
    public isSelected: boolean;
    public isSameAsAbove: boolean;
    public review: Review; 
    public reviewItemCancellationReasons: ReviewItemCancellationReason[] = [];

    constructor(init?: Partial<ReviewItem>) {
        
        Object.assign(this, init);
    }
}

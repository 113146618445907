import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Review } from '@apis/shared/models/review.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { MeetingTypes, ReviewMethodTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { DriverBaseComponent } from '../../../driver-base.component';
import { Observable, of } from 'rxjs';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { DatePipe } from '@angular/common';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
export abstract class ReviewBaseComponent extends DriverBaseComponent {
  review: Review;
  contraventionOverview: ContraventionOverview;
  tempFolderName: string;
  notices: string;
  datePipe: DatePipe = new DatePipe("en-US");

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorage: LocalStorageService,
    public schedulerService: SchedulerService
  ) {
    super(router);
    this.localStorage.setCreatedReview(null); // If we have created a review, clear it before making a new one
    if (!this.review) {
      this.review = this.localStorage.getReviewRequest();

      // Navigate to contravention details page if review request is null
      if (this.review == null) {
        this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
      }

      this.tempFolderName = this.localStorage.getTempFolderName();

      this.contraventionOverview = this.localStorage.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      this.contraventionOverview.contraventions.forEach(c => {
        if (this.notices) {
          this.notices += `, ${c.contraventionNumber}`;
        } else {
          this.notices = c.contraventionNumber;
        }
      });
    }
  }

  public get countdownKey() {
    return `${this.review.contraventionNumber}-C-${this.review.scheduledEventId}`;
  }

  public get lapseMessage() {
    return `Reserved review time expired`;
  }

  public resetReviewSchedule() {
    this.review.scheduledEventId = null;
    this.review.scheduledEvent = null;
    
    this.review.reviewTime = null;
    this.review.reviewDate = null;
  }

  public saveContext() {
    this.localStorage.setReviewRequest(this.review);
  }

  cancelRequest() {
    this.localStorage.setReviewRequest(null);
    this.releaseEvent().subscribe();
  }

  getContraventionsInReview() {
    var contraventionsInReview = '';
    this.review.reviewItems.forEach(r => {
      if (contraventionsInReview) {
        contraventionsInReview += `, ${r.recordNumber}`;
      } else {
        contraventionsInReview = r.recordNumber;
      }
    });  
    return contraventionsInReview;
  }

  getSelectedContraventionsInReview() {
    var contraventionsInReview = '';
    this.review.reviewItems.filter(r => r.isSelected).forEach(r => {
      if (contraventionsInReview) {
        contraventionsInReview += `, ${r.recordNumber}`;
      } else {
        contraventionsInReview = r.recordNumber;
      }
    });  
    return contraventionsInReview;
  }

  getAttendee() {
    return `${this.contraventionOverview.recipientLastName} ${this.contraventionOverview.recipientFirstName} ${this.contraventionOverview.recipientMiddleName??''}`;
  }

  getEventTitle() {
    const recipientLastName = this.contraventionOverview.recipientLastName;
    return `${this.review.contraventionNumber} ${recipientLastName}`;
  }  

  reserveEvent() : Observable<ScheduledEvent> {
    var meetingTypeId = (() => {
      switch (this.review.reviewTypeId) {
        case ReviewTypes.IrsReview:
          return this.review.reviewMethodTypeId == ReviewMethodTypes.Oral 
                  ? MeetingTypes.IRSOralReview 
                  : MeetingTypes.IRSWrittenReview;
        case ReviewTypes.AteReview:
          return this.review.reviewMethodTypeId == ReviewMethodTypes.Oral 
                  ? MeetingTypes.ATEOralReview 
                  : MeetingTypes.ATEWrittenReview;
        case ReviewTypes.TrafficReview:
          return this.review.reviewMethodTypeId == ReviewMethodTypes.Oral 
                  ? MeetingTypes.TSAOralReview
                  : MeetingTypes.TSAWrittenReview;
      }
    })();

    var event = new ScheduledEvent({
      eventDate: this.datePipe.transform(this.review.reviewDate, 'M/d/yyyy', 'UTC'),
      eventTime: this.review.reviewTime,
      duration: 60,
      meetingTypeId: meetingTypeId,
      title: this.getEventTitle(),
      attendees: this.getAttendee(),
      recordNumber: this.review.contraventionNumber,
      complexity: this.review.complexity
    });

    return this.schedulerService.reserveEvent(event);    
  }
  
  releaseEvent() {
    if (this.review.scheduledEventId) {
      return this.schedulerService.releaseEvent(this.review.scheduledEventId)
    }

    return of(true);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleSeizureSearch } from '@apis/shared/models/vehicle-seizure-search.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { DriverService } from '../../../shared/services/driver.service';
import { JWTTokenService } from '../../../shared/services/jwttoken.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SeizureUserType } from '@apis/shared/enums/app.enum';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-vehicle-seizure-lookup',
  templateUrl: './vehicle-seizure-lookup.component.html',
  styleUrls: ['./vehicle-seizure-lookup.component.scss']
})
export class VehicleSeizureLookupComponent implements OnInit {
  searchCriteria = new VehicleSeizureSearch();
  searching = false;
  isSeziureNotAvailable: boolean;
  isSeizureNotFound: boolean;
  isAccessForbidden: boolean;
  isServerErr: boolean;
  errorMessage: string;
  seizureDatePickerConfig: Partial<BsDatepickerConfig>;
  vehicleSeizureDate: Date;

  Constants = Constants;

  constructor(private router: Router,
              public route: ActivatedRoute,
              private readonly spinner: NgxSpinnerService,
              private jwtTokenService: JWTTokenService,
              private readonly recaptchaV3Service: ReCaptchaV3Service,
              private localStorageService: LocalStorageService,
              private driverService: DriverService) { 

                this.seizureDatePickerConfig = Object.assign({}, 
                  {
                    containerClass: 'theme-dark-blue', 
                    showWeekNumbers: false,
                    dateInputFormat: 'YYYY-MM-DD',
                    minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
                    isAnimated: true
                  });

              }

  ngOnInit(): void {
    this.spinner.hide();
  }

  onSearchClick(isValid: boolean) {
    if (isValid) {
      // Undo the UTC timezone offset, so that on JSON serialization the date wont change 
      //  when converted to UTC TZ
      this.searchCriteria.seizureDate = new Date(this.vehicleSeizureDate);
      this.searchCriteria.seizureDate.setMinutes(this.searchCriteria.seizureDate.getMinutes() 
                                                  - this.searchCriteria.seizureDate.getTimezoneOffset());

      // Reset all flags
      this.isSeziureNotAvailable = false;
      this.isSeizureNotFound = false;
      this.isAccessForbidden = false;
      this.isServerErr = false;

      console.log(this.searchCriteria.seizureDate);

      this.spinner.show(); 
      this.recaptchaV3Service.execute("SearchSeizure")
        .subscribe((token) => {
          this.driverService.searchVehicleSeizureAsync(this.searchCriteria, token)
            .subscribe(
              (result: any) => {
                this.jwtTokenService.setToken(result.accessToken)
                this.localStorageService.setEnableLicencePickupModal(true);
                if (result.requireUserTypeSelection) {                  
                  this.router.navigate(
                    [`/vehicle-seizures/${this.searchCriteria.seizureNumber}/user-type`],
                    { 
                      relativeTo: this.route,
                      queryParams: { seizuretype: result.seizureTypeId 
                    } });
                } else {
                  this.localStorageService.set(`${this.searchCriteria.seizureNumber}_user_type`, 
                    result.seizureUserType ? result.seizureUserType.toString() : SeizureUserType.Driver.toString());
                  this.router.navigate(['/vehicle-seizures', this.searchCriteria.seizureNumber]);
                }
              },
              (error: any) => {
                if (error.status == 403) {
                  this.isAccessForbidden = true;
                } else if (error.status == 404) {
                  this.isSeziureNotAvailable = (error.error == 1);
                  this.isSeizureNotFound = (error.error == 2);
                } else {
                  this.isServerErr = true;
                  this.errorMessage = "An error occurred while processing your request. Please try again.";
                }
                this.spinner.hide();
              });
            },
        (error) => {
          this.isServerErr = true;
          this.errorMessage = error;
          this.spinner.hide();
        }); 
    }
  }

}

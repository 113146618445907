import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Recipient } from '@apis/shared/models/recipient.model';

@Component({
  selector: 'app-driver-information',
  templateUrl: './driver-information.component.html',
  styleUrls: ['./driver-information.component.scss']
})
export class DriverInformationComponent {
  @Input() recipient: Recipient;
  
  constructor(
    private localStorageService: LocalStorageService
  ) {}

  getGender(genderTypeId: number)
  {
    return genderTypeId? this.localStorageService.getGenderTypes().find(x => x.id == genderTypeId)?.name: "";
  }
}

<!-- Driver Portal View -->
<app-vehicle-seizure-page #masterPage *ngIf="!isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column">
            <h1>Review Late Request Summary</h1>
            <h3 class="border-bottom pb-4 mb-2">No. {{seizureNumber}}</h3>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isPaymentFailed">
                <h6 class="mt-0">Payment Failed</h6>
                <p>
                    Your payment was unsuccessful. Please re-enter your <a [routerLink]="['../payment-method']">Payment Method Details</a> and try again.
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                <h6 class="mt-0">Late Review Request Failed</h6>
                <p>
                    Your request can't be completed right now. Please try again later. 
                </p>
            </div>

            <div class="card border-0">
                <p>Review the details of your review below before submitting. Once your review request has been submitted, you will not be able to modify your request online.</p>

                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Payments are non-refundable</h6>
                    <p>
                        Be advised that all payments to SafeRoads Alberta are final. No refunds will be issued.
                    </p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Review Summary
                </div>
                <div class="card-body">  
                    <app-section-details sectionTitle="Review Type" [sectionDetails]="reviewTypeInformation"></app-section-details>            
                    <app-section-details *ngIf="!lateReviewRequest.isSelfSubmit"
                        sectionTitle="Representation" [sectionDetails]="representationInformation"></app-section-details>
                    <app-section-details sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                    <app-section-details sectionTitle="Driver Licence Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>
                    <app-section-details sectionTitle="Vehicle Seizure Details" [sectionDetails]="vehicleSeizureDetails"></app-section-details>
                    <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                    <app-section-details sectionTitle="Review Details" [sectionDetails]="reviewsDetailsInformation"></app-section-details>            
                    <app-section-details sectionTitle="Preferred Contact Information" [sectionDetails]="preferredContactInformation"></app-section-details>
                    <app-section-details sectionTitle="Terms & Conditions" [sectionDetails]="termsConditionsInformation"></app-section-details>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Supporting Documents
                </div>
                <div class="card-body">  
                    <div *ngFor="let document of supportingDocuments">
                        <div class="row">
                            <div class="col-12 col-sm-12 mb-0 pb-0">
                                <label>{{document.documentType}} <span *ngIf="document.isRequired" class="label-hint">(Required)</span></label>
                            </div>
                            <div class="col-12 col-sm-12 mb-4">
                                <button class="btn-link" (click)="DownloadDocument(document)">
                                    {{document.documentName}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Details
                </div>
                <div class="card-body">  
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Late Review Request Fee</div>
                        <div><h5>{{lateReviewRequest.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Amount Total</h5></div>
                        <div><h5>{{lateReviewRequest.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Method
                </div>
                <div class="card-body">  
                    <div>
                        <div id="cardLogoDiv"></div>
                        <div>ending in {{lateReviewRequest.paymentRequest.cardLastFour}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../payment-method']">Return to Payment Method</button>
                    </div>
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button btn-block" [disabled]="isPaymentFailed == true" (click)="submitRequest()">Pay & Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page #masterPage *ngIf="isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column">
            <div class="row">
                <div class="col-12 col-sm-11">        
                    <h1>Late Review Request Summary</h1>
                </div>
                <div class="col-12 col-sm-1 d-flex align-self-end justify-content-end">
                    <div class="float-right">
                        <div class="print-icon not-for-print" (click)="printSummary()"></div>
                    </div>
                </div>
            </div>
            <h3 class="border-bottom pb-4 mb-2">No. {{seizureNumber}}</h3>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                <h6 class="mt-0">Late Review Request Failed</h6>
                <p>
                    Your request can't be completed right now. Please try again later. 
                </p>
            </div>

            <div class="card border-0 not-for-print">
                <div class="goa-callout goa--information mt-2">
                    <h6 class="mt-0">Print Review Request Summary</h6>
                    <p>
                        Print a copy of this page and have the client confirm the late review request summary details before submitting. 
                        Once the late review request has been submitted, they will not be able to modify the request. 
                    </p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Review Summary
                </div>
                <div class="card-body">  
                    <app-section-details sectionTitle="Requester" [sectionDetails]="requesterInformation"></app-section-details>
                    <app-section-details sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                    <app-section-details sectionTitle="Driver Licence Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>
                    <app-section-details sectionTitle="Vehicle Seizure Details" [sectionDetails]="vehicleSeizureDetails"></app-section-details>
                    <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                    <app-section-details sectionTitle="Review Details" [sectionDetails]="reviewsDetailsInformation"></app-section-details>            
                    <app-section-details sectionTitle="Preferred Contact Information" [sectionDetails]="preferredContactInformation"></app-section-details>
                    <app-section-details sectionTitle="Terms & Conditions" [sectionDetails]="termsConditionsInformation"></app-section-details>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Supporting Documents
                </div>
                <div class="card-body">  
                    <div *ngFor="let document of supportingDocuments">
                        <div class="row">
                            <div class="col-12 col-sm-12 mb-0 pb-0">
                                <label>{{document.documentType}} <span *ngIf="document.isRequired" class="label-hint">(Required)</span></label>
                            </div>
                            <div class="col-12 col-sm-12 mb-4">
                                <button class="btn-link" (click)="DownloadDocument(document)">
                                    {{document.documentName}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Late Review Request Fee Payment Details
                </div>
                <div class="card-body">  
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Transaction ID {{lateReviewRequest.paymentRequest.transactionId}}</div>
                        <div><h5>{{lateReviewRequest.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Amount Total</h5></div>
                        <div><h5>{{lateReviewRequest.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="checkbox mt-4 not-for-print">
                <input required type="checkbox" id="summaryPrinted" name="summaryPrinted" class="mr-2"
                    #summaryPrinted="ngModel" [(ngModel)]="isSummaryPrinted" [ngClass]="{'is-invalid': isSubmitClicked && summaryPrinted.invalid }">
                <label class="required" for="summaryPrinted">
                    <b>A copy of the review request summary was printed for the driver to review</b>
                </label>        
                <div class="invalid-feedback">
                    <div>Please indicate that a copy of the review request summary was printed for the driver to review</div>
                </div>
            </div>
            
            <div class="form-group not-for-print">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../payment-method']">Return to Payment</button>
                    </div>
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button btn-block" (click)="submitRegistryRequest(!summaryPrinted.invalid)">Submit</button>
                    </div>
                </div>
            </div>

            <div class="card border-0">
                <div class="form-group">
                    <div class="row">
                        <div class="col-12">
                            <p>Due to the potential for delayed or missed messages sent via email and SMS, it is your responsibility to check the status of your request. You can do this online in the SafeRoads portal or at an Alberta Registry Agent. Please contact SafeRoads Alberta at <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> if you have any questions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>
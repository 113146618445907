import { Injectable } from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Injectable()
export class JWTTokenService {
  jwtToken: string;

  constructor(private localStorageService: LocalStorageService) {}

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
      this.localStorageService.set("access_token", this.jwtToken);
    }
  }

  getToken(): string {
    if(!this.jwtToken) {
        this.jwtToken = this.localStorageService.get("access_token");
    }

    return this.jwtToken;
  }
}

<!-- Driver Portal View -->
<app-vehicle-seizure-page *ngIf="!isRSGPortal">
    <ng-container content>
        <form #paymentForm="ngForm" [mustMatch]="['recipientEmail', 'recipientConfirmEmail']">
            <div class="d-flex flex-column">
                <h1>Payment Method</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{seizureNumber}}</h3>
                
                <div class="card goa-callout goa--info">
                    <p class="mt-1">The non-refundable fee to make an application for a late review is $50.</p>
                </div>
                
                <div class="card mt-4">
                    <div class="card-header">
                        Payment Details
                    </div>
                    <div class="card-body">  
                        <div class="d-flex justify-content-between align-items-center">
                            <div>Late Review Request Fee</div>
                            <div><h5>{{lateReviewRequest.paymentRequest.paymentAmount | currency}}</h5></div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <div><h5>Amount Total</h5></div>
                            <div><h5>{{lateReviewRequest.paymentRequest.paymentAmount | currency}}</h5></div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4">
                    <div class="card-header">
                        Payment Method
                    </div>
                    <div class="card-body">  
                        <app-payment-details #paymentDetails [isSubmitClicked]="paymentForm.submitted"
                            [(paymentRequest)]="lateReviewRequest.paymentRequest"></app-payment-details>
                    </div>
                </div>
            
                <div class="card mt-4">
                    <div class="card-header">
                        Payment Receipt
                    </div>
                    <div class="card-body">  
                        <div class="form-group row">
                            <div class="col-lg-6">
                                <label for="recipientEmail" class="required">Send a Receipt to Email</label>
                                <input type="text" [(ngModel)]="lateReviewRequest.paymentRequest.recipientEmail" required email
                                    id="recipientEmail" name="recipientEmail" #recipientEmail="ngModel"
                                    [ngClass]="{ 'is-invalid': paymentForm.submitted && recipientEmail.invalid }"
                                    class="form-control" placeholder="Enter your preferred email address">
                                <div class="invalid-feedback">
                                    <div *ngIf="recipientEmail.errors?.required">Email is required</div>
                                    <div *ngIf="recipientEmail.errors?.email">Please provide a valid email address</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="recipientConfirmEmail" class="required">Confirm Email</label>
                                <input type="text" [(ngModel)]="confirmEmail" required email
                                    id="recipientConfirmEmail" name="recipientConfirmEmail" #recipientConfirmEmail="ngModel"
                                    [ngClass]="{ 'is-invalid': paymentForm.submitted && recipientConfirmEmail.invalid }"
                                    class="form-control" placeholder="Confirm email address">
                                <div class="invalid-feedback">
                                    <div *ngIf="recipientConfirmEmail.errors?.required">Confirm Email is required</div>
                                    <div *ngIf="recipientConfirmEmail.errors?.email">Please provide a valid email address</div>
                                    <div *ngIf="recipientConfirmEmail.errors?.mustMatch">Emails must match</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="form-group">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../supporting-documents']">Back to Supporting Documents</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">                
                            <button class="goa-button btn-block" (click)="continueRequest(paymentForm.valid)">Proceed to Request Summary</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page *ngIf="isRSGPortal">
    <ng-container content>
        <form #paymentForm="ngForm" [mustMatch]="['recipientEmail', 'recipientConfirmEmail']">
            <div class="d-flex flex-column">
                <h1>Payment</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{seizureNumber}}</h3>
                
                <div class="card border-0">
                    <div class="goa-callout goa--important mt-1">
                        <h6 class="mt-0">Payments are non-refundable</h6>
                        <p>
                            Advise the client that all payments to SafeRoads are final. No refunds will be issued.
                        </p>
                    </div>
                </div>
                
                <div class="card mt-4">
                    <div class="card-header">
                        Payment Details
                    </div>
                    <div class="card-body">  
                        <div class="d-flex justify-content-between align-items-center">
                            <div>Late Review Request Fee</div>
                            <div><h5>{{lateReviewRequest.paymentRequest.paymentAmount | currency}}</h5></div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <div><h5>Amount Total</h5></div>
                            <div><h5>{{lateReviewRequest.paymentRequest.paymentAmount | currency}}</h5></div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4">
                    <div class="card-header big-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <div><h5>Total Payment Amount</h5></div>
                            <div><h5>{{ lateReviewRequest.paymentRequest.paymentAmount | currency }}</h5></div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <div><h5>Transaction ID</h5></div>
                            <div><h5>{{ lateReviewRequest.paymentRequest.transactionId }}</h5></div>
                        </div>
                    </div>
                </div>
            
                <div class="form-group">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../supporting-documents']">Back to Supporting Documents</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">                
                            <button class="goa-button btn-block" (click)="continueRegistryRequest(paymentForm.valid)">Proceed to Application Summary</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>
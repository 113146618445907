import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SeizureReviewBaseComponent } from '../seizure-review-base.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Document } from '@apis/shared/models/document.model';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { RegisteredOwner } from '@apis/shared/models/registered-owner.model';
import { ReviewApplicant } from '@apis/shared/models/review-applicant.model';
import { VehicleSeizureService } from 'apps/driver/src/app/shared/services/vehicle-seizure.service';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { ContraventionPageComponent } from '../../../../contravention/contravention-page/contravention-page.component';
import { RepresentationTypes, VehicleInterestTypes } from '@apis/shared/enums/app.enum';
import { RegistryService } from 'apps/driver/src/app/shared/services/registry.service';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { CountdownTimerComponent } from '@apis/shared/components/countdown-timer/countdown-timer.component';
import { CountdownState } from '@apis/shared/enums/countdown-state.enum';

@Component({
  selector: 'app-seizure-review-summary',
  templateUrl: './seizure-review-summary.component.html',
  styleUrls: ['./seizure-review-summary.component.scss']
})
export class SeizureReviewSummaryComponent extends SeizureReviewBaseComponent
 implements OnInit {
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;
  @ViewChild('cd') private countdown: CountdownTimerComponent;
  
  reviewTypeInformation: SectionDetailsModel[] = [];
  representationInformation: SectionDetailsModel[] = [];
  reviewsDetailsInformation: SectionDetailsModel[] = [];
  requestInformation: SectionDetailsModel[] = [];
  recipientInformation: SectionDetailsModel[] = [];
  recipientIdentificationInformation: SectionDetailsModel[] = [];
  contraventionInformation: SectionDetailsModel[] = [];
  reasonForRequestInformation: SectionDetailsModel[] = [];
  preferredContactInformation: SectionDetailsModel[] = [];
  termsConditionsInformation: SectionDetailsModel[] = [];
  preferredReviewTimesSection: SectionDetailsModel[] = [];
  applicantInformation: SectionDetailsModel[] = [];
  registeredOwnerInformation: SectionDetailsModel[] = [];
  vehicleInformation: SectionDetailsModel[] = [];
  vehicleSeizureDetails: SectionDetailsModel[] = [];
  requesterInformation: SectionDetailsModel[] = [];
  supportingDocuments: DocumentModel[] = [];

  recipient: Recipient;
  registeredOwner: RegisteredOwner;
  applicant: ReviewApplicant;

  preferredTimes: SectionDetailsModel[] = [];
  isRequestFailed: boolean;
  isRequestAppliedAfterDeadline: boolean;
  isReviewRequestNotAvailable: boolean;
  isPaymentFailed: boolean;
  isSubmitClicked: boolean = false;
  isReviewTimeExpired: boolean = false;

  vehicleInterestTypeName: string;
  reviewMethod: string;
  reviewTime: string;
  
  constructor(
    public router: Router,
    private currencyPipe: CurrencyPipe,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private vehicleSeizureService: VehicleSeizureService,
    private registryService: RegistryService,
    private documentService: DocumentService,
    private readonly spinner: NgxSpinnerService,
    public schedulerService: SchedulerService
    ) { 
    super(router, activatedRoute, localStorageService, schedulerService);
  }

  ngAfterViewInit(): void {
    if (!this.isRSGPortal)
    {
      const cardLogoDiv = document.getElementById("cardLogoDiv") as HTMLImageElement;
      cardLogoDiv.style.backgroundImage = this.review.paymentRequest['cardLogo'];
    }
  }

  ngOnInit(): void {
    this.recipient = this.vehicleSeizure.recipient;
    this.applicant = this.review.reviewApplicant;
    this.registeredOwner = this.vehicleSeizure.vehicle.registeredOwner;      

    // Request Information
    this.reviewTypeInformation.push(new SectionDetailsModel('Request Type', 
      this.review.isSelfSubmit ? "I am submitting this application on behalf of myself" : 
        (this.review.reviewTypeId == this.ReviewTypes.SuspendedDriverVehicleSeizureReview ?
                                  "I am submitting this application on behalf of the recipient" :
                                  "I am submitting this application on behalf of the registered owner"),
      true
    ));

    if (this.review.representation) {
      // Representation Informaton
      const repTypeId = this.review.representation.representationTypeId;

      const repTypeName = this.localStorageService.getRepresentationTypes()
                              .find(x=>x.id == repTypeId)?.name;

      this.representationInformation.push(new SectionDetailsModel('Type of Acting Representation', repTypeName, true));

      this.representationInformation.push(new SectionDetailsModel(`Representative First Name`, this.review.representation.firstName));
      this.representationInformation.push(new SectionDetailsModel(`Representative Last Name`, this.review.representation.lastName));

      if (+repTypeId === RepresentationTypes.Agent || +repTypeId === RepresentationTypes.Lawyer){
        this.representationInformation.push(new SectionDetailsModel(`Practice Name`, this.review.representation.practiceName, true));
      }

      if (+repTypeId === RepresentationTypes.Other){
        this.representationInformation.push(new SectionDetailsModel('Other Representation Type', this.review.representation.otherRepresentationType, true));
      }
    }

    //Requester Information
    if (this.isRSGPortal) {
      const registryAgentInformation = this.review.registryAgentInformation[0];

      if (this.review.reviewTypeId == this.ReviewTypes.ThirdPartySeizureReview) { // JTI-2120: Display applicant name and interest in vehicle for 3rd party seizures
        const vehicleInterestType = this.localStorageService.getVehicleInterestTypes().find(v => v.id == this.review.reviewApplicant.vehicleInterestTypeId);
        this.vehicleInterestTypeName = vehicleInterestType.name;
        if (+this.review.reviewApplicant.vehicleInterestTypeId == VehicleInterestTypes.Other) {
          this.vehicleInterestTypeName = `${this.vehicleInterestTypeName}, ${this.review.reviewApplicant.otherInterestType}`;
        }

        this.requesterInformation.push(new SectionDetailsModel('Applicant First Name', this.review.reviewApplicant.firstName));
        this.requesterInformation.push(new SectionDetailsModel('Applicant Last Name', this.review.reviewApplicant.lastName));
        this.requesterInformation.push(new SectionDetailsModel('MVID', registryAgentInformation.payerMVID));
        this.requesterInformation.push(new SectionDetailsModel('Interest in Vehicle', this.vehicleInterestTypeName));
      }
      else { // Retain old functionality, if not a 3rd party seizure
        if (registryAgentInformation.isPaidByRecipient) {
          this.requesterInformation.push(new SectionDetailsModel('Applicant', "Recipient (Driver)", true));
        }
        else {
          this.requesterInformation.push(new SectionDetailsModel('Applicant', "Someone else", true));
          this.requesterInformation.push(new SectionDetailsModel('Surname', registryAgentInformation.payerLastName));
          this.requesterInformation.push(new SectionDetailsModel('First Name', registryAgentInformation.payerFirstName));
          this.requesterInformation.push(new SectionDetailsModel('MVID', registryAgentInformation.payerMVID));
        }
      }
    }

    if (this.review.reviewTypeId == this.ReviewTypes.ThirdPartySeizureReview) {
      // Applicant Information
      this.applicantInformation.push(new SectionDetailsModel('Surname', this.applicant.lastName));
      this.applicantInformation.push(new SectionDetailsModel('First Name', this.applicant.firstName));
      this.applicantInformation.push(new SectionDetailsModel('Middle Name', this.applicant.otherName));
      this.applicantInformation.push(new SectionDetailsModel('What is Your Interest in the Vehicle?', 
        this.localStorageService.getTypeItemDescriptionById(this.applicant.vehicleInterestTypeId, TypeTable.VehicleInterestType)));
        // Show only if vehicle interest type is Other
      if (this.applicant.vehicleInterestTypeId == VehicleInterestTypes.Other) {
        this.applicantInformation.push(new SectionDetailsModel('Other Interest', this.applicant.otherInterestType));
      }
    }

    // Registered Owner Information
    if (this.vehicleSeizure?.vehicle?.isNoRegisteredOwner)
      this.registeredOwnerInformation.push(new SectionDetailsModel('Registered Owner', "Unable to determine"));
    else
    {
      this.registeredOwnerInformation.push(new SectionDetailsModel('Surname', this.registeredOwner?.lastName));
      this.registeredOwnerInformation.push(new SectionDetailsModel('First Name', this.registeredOwner?.firstName));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Middle Name', this.registeredOwner?.otherName));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Licence Number', this.registeredOwner?.driverLicenceNumber));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.registeredOwner?.dateOfBirth, 'mediumDate')));
    }

    // Driver Information
    this.recipientInformation.push(new SectionDetailsModel('Surname', this.recipient.lastName));
    this.recipientInformation.push(new SectionDetailsModel('First Name', this.recipient.firstName));
    this.recipientInformation.push(new SectionDetailsModel('Middle Name', this.recipient.otherName));
    this.recipientInformation.push(new SectionDetailsModel('Email', this.review.driverEmail));

    // Driver Licence Information
    this.recipientIdentificationInformation.push(new SectionDetailsModel('Driver Licence Number', this.recipient.recipientIdentification?.identificationNumber));
    this.recipientIdentificationInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.recipient.recipientIdentification?.dateOfBirth, 'mediumDate')));
    this.recipientIdentificationInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x=>x.id == this.recipient.recipientIdentification?.genderId)?.name));

    // Vehicle Seizure Details
    this.vehicleSeizureDetails.push(new SectionDetailsModel('Seizure Number', this.vehicleSeizure?.seizureNumber));
    this.vehicleSeizureDetails.push(new SectionDetailsModel('Seizure Date', this.datePipe.transform(this.vehicleSeizure?.seizureDate, 'dd MMM, yyyy')));
    this.vehicleSeizureDetails.push(new SectionDetailsModel('Seizure Time', this.vehicleSeizure?.seizureTime));
    this.vehicleSeizureDetails.push(new SectionDetailsModel('Immediate Seizure Duration', this.vehicleSeizure.seizureDuration.toString() + ' ' + this.vehicleSeizure.seizureDurationType));
    this.vehicleSeizureDetails.push(new SectionDetailsModel('Vehicle Release Date', this.datePipe.transform(this.vehicleSeizure?.vehicleReleaseDate, 'dd MMM, yyyy')));
    this.vehicleSeizureDetails.push(new SectionDetailsModel('Location of Seizure', this.localStorageService.getLocationTypes().find(x => x.id == this.vehicleSeizure?.locationTypeId)?.name, true));

    // Vehicle Information
    this.vehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.vehicleSeizure?.vehicle?.licencePlateNumber));
    this.vehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.vehicleSeizure?.vehicle?.vehicleIdentificationNumber));
    this.vehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.vehicleSeizure?.vehicle?.licensedCountryId == 40 || this.vehicleSeizure?.vehicle?.licensedCountryId == 236)? (this.vehicleSeizure?.vehicle?.licensedProvinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.vehicleSeizure?.vehicle?.licensedProvinceId)?.name) : this.vehicleSeizure?.vehicle?.licensedProvince));
    this.vehicleInformation.push(new SectionDetailsModel('Make', this.vehicleSeizure?.vehicle?.make));
    this.vehicleInformation.push(new SectionDetailsModel('Model', this.vehicleSeizure?.vehicle?.model));
    this.vehicleInformation.push(new SectionDetailsModel('Year', this.vehicleSeizure?.vehicle?.year?.toString()));

    // Review Details Information
    this.reviewsDetailsInformation.push(new SectionDetailsModel('Selected Review Fee Amount', 
        this.currencyPipe.transform(this.review.paymentRequest.paymentAmount), true));
    this.reviewsDetailsInformation.push(new SectionDetailsModel('Grounds for Review', 
        this.review.reviewItems[0].reviewGrounds, true));
    this.reviewsDetailsInformation.push(new SectionDetailsModel('Additional Request Notes', 
        this.review.additionalRequestNotes, true));
    if (this.review.reviewTypeId == this.ReviewTypes.ThirdPartySeizureReview){
      this.reviewsDetailsInformation.push(new SectionDetailsModel('Describe Your Relationship with the Recipient', 
        this.review.recipientRelationship, true));
      this.reviewsDetailsInformation.push(new SectionDetailsModel('Is this vehicle registered under a company?', 
        this.review.isCommercialVehicle ? 'Yes': 'No'));
      this.reviewsDetailsInformation.push(new SectionDetailsModel('Was the Vehicle Taken without Your Permission?', 
        this.review.isVehicleTakenWithoutPermission ? 'Yes': 'No'));
      this.reviewsDetailsInformation.push(new SectionDetailsModel('Was the Vehicle Stolen?', 
        this.review.isVehicleStolen ? 'Yes': 'No'));
      if (this.review.reviewApplicant?.vehicleInterestTypeId == VehicleInterestTypes.Lessor){
        this.reviewsDetailsInformation.push(new SectionDetailsModel('Did You Have a Rental Agreement with the Recipient/Driver?', 
          this.review.hasRentalAgreement ? 'Yes': 'No'));
      }
    }
        
    // Preferred Contact Information
    this.preferredContactInformation.push(new SectionDetailsModel('Preferred Contact Method', 
      CommonUtil.getContactMethodTypeName(this.review.contactMethodTypeId)));
    if (this.review.phoneNumber) {
      this.preferredContactInformation.push(new SectionDetailsModel('Phone Number', this.review.phoneNumber));
    }
    else {
      this.preferredContactInformation.push(new SectionDetailsModel('Email Address', this.review.email));
    }

    // Terms & Conditions
    this.termsConditionsInformation.push(new SectionDetailsModel(this.isRSGPortal? 'The Registry Agent provided the Terms & Conditions to the client': 'I acknowledge the SafeRoads Alberta Terms and Conditions.', "Yes",true));

    // Review Details
    this.reviewMethod = this.localStorageService.getReviewMethodTypes().find(x=>x.id == this.review.reviewMethodTypeId)?.name;

    if (this.review.reviewTime) {
      const timeTokens = this.review.reviewTime.split(':');
      const reviewTimeDate = new Date();
      reviewTimeDate.setHours(+timeTokens[0], +timeTokens[1], +timeTokens[2], 0);

      this.reviewTime = this.datePipe.transform(reviewTimeDate, 'shortTime');
    }

    // Supporting Documents Section
    this.review.documents.forEach(document => {
      var documentType : DocumentType = this.localStorageService.getDocumentTypes().find(x=> x.id == document.documentTypeId);
      this.supportingDocuments.push(new DocumentModel(documentType?.name, document.documentName, document.documentExtension, document.contentGuid, !documentType?.isSubmittableAtLaterDate));
    });
  }

  validate() {
    if(this.countdown.state == CountdownState.Lapsed) {
      this.isReviewTimeExpired = true;
      window.scroll(0, 0);
      return false;
    }

    return true;
  }

  submitRequest() {
    var isValid = this.validate();

    // Return submission if request is in-valid
    if (!isValid) return;
    
    this.masterPage.showSpinner();

    // Set temp folder
    this.review.tempFolderName = this.tempFolderName;

    // Set document uploaded_date and uploaded_by
    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.review.documents?.filter(x=>(x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
      document.uploadedDate = today;
      document.uploadedBy = "Recipient";
    });

    this.vehicleSeizureService
        .requestReview(this.review)
        .subscribe(
          (result: any) => {
            this.masterPage.hideSpinner();

            // Update review number and sequence
            this.review.reviewNumber = result.recordNumber;
            this.review.reviewSequence = result.reviewSequence;

            this.saveContext();

            this.router.navigate(['../submit-complete'], {
              relativeTo: this.activatedRoute,
            });
          },
          (error) => {
            if (error.error && error.error.errorCode == "6001") {
              this.isPaymentFailed = true;
            } else if (error.error && error.error.errorCode == "2005") {
              this.isRequestAppliedAfterDeadline = true;
            } else if (error.error && error.error.errorCode == "2007") {
              this.isReviewRequestNotAvailable = true;
            }
            else {
              this.isRequestFailed = true;
            }
            this.masterPage.hideSpinner();

            window.scroll(0,0);
          });
  }

  submitRegistryRequest(isValid: boolean) {
    this.isSubmitClicked=true;
    if (isValid)
    {
      this.masterPage.showSpinner();

      // Set temp folder
      this.review.tempFolderName = this.tempFolderName;

      // Set document uploaded_date and uploaded_by
      var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      this.review.documents?.filter(x=>(x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
        document.uploadedDate = today;
        document.uploadedBy = "Registry";
      });

      this.registryService
          .requestSeizureReview(this.review)
          .subscribe(
            (result: any) => {
              this.masterPage.hideSpinner();

              // Update review number
              this.review.reviewNumber = result.recordNumber;

              this.saveContext();

              // Clear TransactionId to avoid any further transaction with same fulfillment ID
              var rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
              if (rsgAgentProfile)
              {
                rsgAgentProfile.transactionId = null;
                this.localStorageService.setRegistryAgentProfile(rsgAgentProfile);
              }
              this.router.navigate(['../submit-complete'], {
                relativeTo: this.activatedRoute,
              });
            },
            (error) => {
              if (error.error && error.error.errorCode == "2005") {
                this.isRequestAppliedAfterDeadline = true;
              } else if (error.error && error.error.errorCode == "2007") {
                this.isReviewRequestNotAvailable = true;
              }
              else {
                this.isRequestFailed = true;
              }
              this.masterPage.hideSpinner();
              window.scroll(0,0);
            });
    }
  }

  DownloadDocument(document: Document)
  {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;
      this.documentService.downloadDocument(this.tempFolderName, "", "", storageFileName , document.documentName)
      .subscribe((result: any) => {
        if (result)
        { 
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, NgForm, ValidatorFn } from '@angular/forms';
import { ContraventionInformation } from '@apis/shared/models/contravention-information.model';
import { TransactionLineItem } from '@apis/shared/models/transaction-line-item.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-contravention-line-item',
  templateUrl: './contravention-line-item.component.html',
  styleUrls: ['./contravention-line-item.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ContraventionLineItemComponent implements OnInit {
  @Input() contraventionInformation: ContraventionInformation;
  @Input() lineItem: TransactionLineItem;
  @Input() isSubmitClicked: boolean;
  @Input() isLocked: boolean;

  isPaymentAmountReadonly: boolean;

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    if (this.contraventionInformation.dueAmount <= 25 || this.isLocked){
      this.lineItem.amount = this.contraventionInformation.dueAmount;
      this.isPaymentAmountReadonly = true;
    }
  }

  getChargeName(contraventionTypeId: number) {
    return this.localStorageService.getContraventionTypes().find( x => x.id == contraventionTypeId).formattedName;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContraventionPayment } from '@apis/shared/models/contravention-payment.model';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { RegistryService } from 'apps/driver/src/app/shared/services/registry.service';

@Component({
  selector: 'app-rsg-payment-success',
  templateUrl: './rsg-payment-success.component.html',
  styleUrls: ['./rsg-payment-success.component.scss'],
})
export class RsgPaymentSuccessComponent implements OnInit {
  contraventionNumber: string;
  transactionId: string;
  paymentTransaction: ContraventionPayment;
  payerInformation: SectionDetailsModel[] = [];
  registryAgentInformation: RegistryAgentInformation;

  constructor(
    private activatedRoute: ActivatedRoute,
    private registryService: RegistryService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.localStorage.getRegistryAgentProfile())
    this.router.navigate(['/unauthorized']);

    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
      this.activatedRoute.queryParams.subscribe((params) => {
        this.transactionId = params['transId'];
        this.loadPaymentTransaction();
      });
    });
  }

  loadPaymentTransaction() {
    this.registryService
      .getPaymentTransaction(this.contraventionNumber,Number.parseInt(this.transactionId))
      .subscribe((result) => {
        this.paymentTransaction = result;
        this.registryAgentInformation = this.paymentTransaction.registryAgentInformation[0];

        // Payer Section
        this.payerInformation.push(new SectionDetailsModel('First Name', this.registryAgentInformation.payerFirstName));
        this.payerInformation.push(new SectionDetailsModel('Last Name', this.registryAgentInformation.payerLastName));
        this.payerInformation.push(new SectionDetailsModel('MVID', this.registryAgentInformation.payerMVID));
      },
      (error: any) => {
        if (error.status == 403 || error.status == 401) {
          this.localStorage.logout();
          this.router.navigate(['/session-expired']);
        }
      });
  }
}

<div class="d-flex flex-column card border-0">
    <h1 class="mb-2">Technical Materials Library</h1>
  
    <p class="border-bottom pb-3">
      Technical information to support Albertans who have requested a review of
      their Immediate Roadside Sanctions Notice of Administrative Penalty
    </p>
  
    <h2>Operational Procedures</h2>
  
    <h3>Alcohol Sensing Devices (ASD)</h3>
  
    <div class="section">
      <a href="assets/materials/ASD-1 Alco-Sensor FST Operator's Manual (2018).pdf" target="_blank">ASD-1 Alco-Sensor FST Operator's Manual</a>
      <p>Manual outlining the theory and operation of the Alco-Sensor FST</p>
    </div>
  
    <div class="section">
      <a href="assets/materials/ASD-2 Alco-Sensor FST Calibrator's Manual (2012).pdf" target="_blank">ASD-2 Alco-Sensor FST Calibrator's Manual</a>
      <p>Manual outlining the theory and calibration of the Alco-Sensor FST</p>
    </div>
  
    <div class="section">
      <a href="assets/materials/ASD 3 - Alco-Sensor FST Sampling Criteria- (Exhibit B of Kerry Lynne Blake - Affidavit - E1).pdf" target="_blank">ASD-3  Alco-Sensor FST Sampling Criteria (Exhibit B of E1 Affidavit of Kerry Lynne Blake)</a>
      <p>Description of sampling requirements for the Alco-Sensor FST</p>
    </div>

    <div class="section">
      <a href="assets/materials/ASD-4 Alco-Sensor FST Instruction Tag.jpeg" target="_blank">ASD-4 Alco-Sensor FST Instruction Tag</a>
      <p>Alco-Sensor FST outlining the steps taken when conducting a test</p>
    </div>
  
    <div class="section">
      <a href="assets/materials/ASD-5 Alco-Sensor FST Information Tag.jpeg" target="_blank">ASD-5 Alco-Sensor FST Instruction Tag</a>
      <p>
        Alco-Sensor FST showing serial number, annual inspection sticker and
        calibration sticker
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/ASD-6 Certificate of Annual Inspection (ASD).pdf" target="_blank">ASD-6 Certificate of Annual Inspection (ASD)</a>
      <p>
        Certificate for Alco-Sensor FST, identified by serial number, date of
        inspection and name of authorized maintenance personnel
      </p>
    </div>

    <div class="section">
      <a href="assets/materials/ASD 7 - Annual Inspection of Approved Screening Device (ASD) (Exhibit G of Kerry Lynne Blake - Affidavit - E1).pdf" target="_blank">
        ASD-7 Annual Inspection of Approved Screening Device (ASD) (Exhibit G of E1 Affidavit of Kerry Lynne Blake)</a>
      <p></p>
    </div>
  
    <h3>Approved Instruments (AI)</h3>
  
    <div class="section">
      <a href="assets/materials/AI-1  Intox EC IR II Resource Reading Material (2018).pdf" target="_blank">AI-1 Intox EC/IR II Resource Reading Material</a>
      <p>Manual outlining the theory and operation of the Intox EC/IR II</p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-2 Intox EC IR II Paperwork Using Dry Gas Alcohol Standard.pdf" target="_blank"
        >AI-2 Intox EC/IR II Paperwork Using Dry Gas Alcohol Standard</a>
      <p>
        Intox EC/IR II Operational Checksheet, Subject Test printout and
        Certificate of a Qualified Technician for a breath test procedure using
        dry gas alcohol standard
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-3 Intox EC IR II Paperwork Using Wet Bath Alcohol Standard.pdf" target="_blank"
        >AI-3 Intox EC/IR II Paperwork Using Wet Bath Alcohol Standard</a>
      <p>
        Intox EC/IR II Operational Checksheet, Subject Test printout and
        Certificate of a Qualified Technician for a breath test procedure using
        wet bath alcohol standard
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-4 Intox EC IR II Paperwork for a Test Refused.pdf" target="_blank">AI-4 Intox EC/IR II Paperwork for a Test Refused</a>
      <p>
        Intox EC/IR II Operational Checksheet, Subject Test printout and Quick
        Test printout for a breath test procedure with a test refused
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-5 Intox EC IR II Paperwork for a Failed to comply with demand.pdf" target="_blank"
        >AI-5 Intox EC/IR II Paperwork for a Failed to comply with demand</a>
      <p>
        Intox EC/IR II Operational Checksheet, Subject Test printout and Quick
        Test printout for a breath test procedure with a failed to comply with
        demand
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-6 Intox EC IR II Paperwork for Three Breath Test Results.pdf" target="_blank"
        >AI-6 Intox EC/IR II Paperwork with Three Breath Test Results</a>
      <p>
        Intox EC/IR II Operational Checksheet and Subject Test printout for a
        breath test procedure with three samples (no Certificate of a Qualified
        Technician issued)
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-7 Certificate of Annual Inspection (AI).pdf" target="_blank">AI-7 Certificate of Annual Inspection (AI)</a>
      <p>
        Certificate for Intox EC/IR II, identified by serial number, date of
        inspection and name of authorized maintenance personnel
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-8 Certificate of Annual Inspection (Simulator).pdf" target="_blank">AI-8 Certificate of Annual Inspection (Simulator)</a>
      <p>
        Certificate for Guth simulator, identified by serial number, date of
        inspection and name of authorized maintenance personnel
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-9 - Certificates of an Analyst (dry gas alcohol standard).pdf" target="_blank">AI-9 Certificates of an Analyst (dry gas alcohol standard)</a>
      <p>
        Two Certificates of an Analyst attesting to the suitability of a dry gas
        alcohol standard, identified by the name of the analyst and the name of
        the manufacturer, lot number and expiry date of the alcohol standard
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-10 Certificates of an Analyst (wet bath alcohol standard).pdf" target="_blank"
        >AI-10 Certificates of an Analyst (wet bath alcohol standard)</a>
      <p>
        Two Certificates of an Analyst attesting to the suitability of a wet bath
        alcohol standard, identified by the name of the analyst and the name of
        the manufacturer, lot number and expiry date of the alcohol standard
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-11 Intox EC IR II Alcohol Standard Change Documents (dry gas).pdf" target="_blank"
        >AI-11 Intox EC/IR II Alcohol Standard Change Documents (dry gas)
      </a>
      <p>
        Documents produced during a dry gas alcohol standard change procedure,
        including the Change Form as well as the Supervisor Test printout and
        Quick Test printout (produced by the instrument)
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/AI-12 Intox EC IR II Alcohol Standard Change Documents (wet bath).pdf" target="_blank"
        >AI-12 Intox EC/IR II Alcohol Standard Change Documents (wet bath)
      </a>
      <p>
        Documents produced during a wet bath alcohol standard change procedure,
        including the Change Form as well as the Supervisor Test printout and
        Quick Test printout (produced by the instrument)
      </p>
    </div>
  
    <h3>Approved Drug Screening Equipment (ADSE)</h3>
  
    <div class="section">
      <a href="assets/materials/ADSE-1 Manual (Resource Reading Material) Approved Drug Screening Equipment (Oral Fluid).pdf" target="_blank">ADSE-1 Manual (Resource Reading Material)</a>
      <p>
        Approved Drug Screening Equipment (oral fluid) – manual outlining the
        theory and operation of the Drager Drug Test 5000
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/ADSE-2 Certificate of Annual Service (ADSE)- Certificate for Drager Drug Test 5000.pdf" target="_blank">ADSE-2 Certificate of Annual Service (ADSE)</a>
      <p>
        Certificate for Drager Drug Test 5000, identified by serial number, date
        of inspection and name of authorized maintenance personnel
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/ADSE-3 Calibration Tag ADSE.JPG" target="_blank">ADSE-3 Calibration Tag ADSE</a>
      <p></p>
    </div>
  
    <div class="section">
      <a href="assets/materials/ADSE-4 Sample Test Printout-Operational Drager Drug Test 5000.pdf" target="_blank">ADSE-4 Sample Test Printout – Operational</a>
      <p></p>
    </div>
  
    <div class="section">
      <a href="assets/materials/ADSE-5 Sample Test Printout- Quality Assurance Drager Drug Test 5000.pdf" target="_blank">ADSE-5 Sample Test Printout – Quality Assurance</a>
      <p></p>
    </div>
  
    <h3>Blood/ Urinalysis (BU)</h3>
  
    <div class="section">
      <a href="assets/materials/BU-1 CSFS ATC Recommended Operational Procedures (2018).pdf" target="_blank">BU-1 CSFS ATC Recommended Operational Procedures</a>
      <p>
        Operational procedures recommended by the Canadian Society of Forensic
        Science Alcohol Test Committee for the use of Approved Instruments,
        Approved Screening Devices and Approved Containers (Blood Samples)
      </p>
    </div>

    <div class="section">
      <a href="assets/materials/BU 2- Blood Containers (Exhibit C of Kerry Lynne Blake- Affidavit - E1).pdf" target="_blank">BU-2 Blood Containers (Exhibit C of E1 Affidavit of Kerry Lynne Blake)</a>
      <p>
        List of approved containers, with a description of their contents and expiry date
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/BU-3 Toxicology Services Analysis Request Check Sheet.pdf" target="_blank">BU-3 Toxicology Services Analysis Request Check Sheet</a>
      <p>
        Form to be completed by police when requesting analysis of blood samples
        in an impaired driving case
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/BU-4 Request for Forensic Laboratory Analysis (C-414).pdf" target="_blank">BU-4 Request for Forensic Laboratory Analysis (C-414)</a>
      <p>
        Form to be completed by police when requesting laboratory analysis from
        the RCMP Forensic Laboratory
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/BU-5 Blood Analysis Papework for Criminal Code Charge- Request for Forensic Laboratory Analysis.pdf" target="_blank">BU-5 Blood Analysis Paperwork for Criminal Code Charge</a>
      <p>
        Paperwork associated with blood sample analysis by RCMP Forensic
        Laboratory
      </p>
    </div>
  
    <h3>Medical (M)</h3>
  
    <div class="section">
      <a href="assets/materials/M1- Hurst Study- Impaired respiratory function with ASD.pdf" target="_blank">M-1 Hurst Study on Impaired Respiratory Function with ASD</a>
      <p>
        Study about the ability of subjects with impaired respiratory function to
        provide a satisfactory breath sample on an ASD
      </p>
    </div>

    <div class="section">
      <a href="assets/materials/M2 - Mouth Alcohol (Exhibit D of Kerry Lynne Blake- Affidavit- E1).pdf" target="_blank">M-2 Mouth Alcohol (Exhibit D of E1 Affidavit of Kerry Lynne Blake)</a>
      <p>
        Discussion of mouth alcohol and safeguards in place to prevent it from affecting breath test results
      </p>
    </div>

    <div class="section">
      <a href="assets/materials/M3 - Alcohol Elimination Rates (Exhibit E of Kerry Lynne Blake - Affidavit- E1).pdf" target="_blank">M-3 Alcohol Elimination Rates (Exhibit E of E1 Affidavit of Kerry Lynne Blake)</a>
      <p>
        Discussion of range of alcohol elimination rates
      </p>
    </div>
  
    <div class="section">
      <a href="assets/materials/M-4 Dubowski's clinical chart- List of stages of acute alcohol influence-intoxication.pdf" target="_blank">M-4 Dubowski’s Clinical Chart</a>
      <p>
        List of stages of acute alcoholic influence/intoxication associating BAC
        (given in g% so multiply by 1000 to convert to mg%) with associated
        signs/symptoms
      </p>
    </div>

    <div class="section">
      <a href="assets/materials/M5- Exhibit F - Impairment and Effects on Driving Ability (Exhibit F of Kerry Lynne Blake- Affidavit- E1).pdf" target="_blank">M-5 Impairment and Effects on Driving Ability (Exhibit F of E1 Affidavit of Kerry Lynne Blake)</a>
      <p>
        Blood alcohol concentration (BAC) at which everyone is impaired in their ability to operate a motor vehicle and description of how alcohol impairs one’s ability to operate a motor vehicle
      </p>
    </div>

    <h3>Expert Evidence</h3>
  
    <div class="section">
      <a href="assets/materials/E1 - Affidavit of Kerry Lynne Blake.pdf" target="_blank">E-1	Affidavit of Kerry Lynne Blake</a>
      <p>
      </p>
    </div>

    <div class="section">
      <a href="assets/materials/E2- Exhibit A - CV of Kerry Lynne Blake.pdf" target="_blank">E-2	Exhibit A – Curriculum Vitae of Kerry Lynne Blake</a>
      <p>
      </p>
    </div>

    <h3>Technical Information</h3>

    <div class="section">
      <a href="assets/materials/T1 - Electronic Transmittal of Documents.pdf" target="_blank">T-1 Electronic Transmittal of Documents</a> Added February 5, 2024
      <p>
        Affidavit of Pauline Wong, Advanced Projects Information System Manager, Alberta Justice, regarding password protection of law enforcement accounts
      </p>
    </div>

    <h3>Other</h3>
  
    <div class="section">
      <a href="assets/materials/SafeRoads Technical Material Audit Log.pdf" target="_blank">Technical Materials Library Update History</a>
      <p>
      </p>
    </div>
  </div>
  
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function RangeValidator(minValue: number, maxValue: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value == null || maxValue < 100) {
        return null;
      }

      let val = Number.parseFloat(control.value);
      if (val < minValue) {
        return { 'invlaidMinValue': { value: control.value } };
      } else if (val > maxValue) {
        return { 'invlaidMaxValue': { value: control.value } };
      } else {
        return null;
      }
    };
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverBaseComponent } from '../../driver-base.component';

@Component({
  selector: 'app-vehicle-seizure-page',
  templateUrl: './vehicle-seizure-page.component.html',
  styleUrls: ['./vehicle-seizure-page.component.scss']
})
export class VehicleSeizurePageComponent extends DriverBaseComponent implements OnInit {
  @Input() suppressConfirmation: boolean;
  @Output() onBeforeNavigate = new EventEmitter();

  seizureNumber: string;
  
  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly spinner: NgxSpinnerService) {
    super(router);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.seizureNumber = paramMap.get('seizureNumber');
    });
  }

  continue() {
    this.onBeforeNavigate.emit();
    if (this.isRSGPortal) {
      this.router.navigate(['/rsg/vehicle-seizures', this.seizureNumber]);
    } else {
      this.router.navigate(['/vehicle-seizures', this.seizureNumber]);
    }
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }
  
}

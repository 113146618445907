


export class SeizureInformation {
    public seizureNumber: string;
    public seizureStatusTypeId: number = 0;
    public seizureTypeId: number = 0;
    public contraventionTypeId: number = 0;

    constructor(init?: Partial<SeizureInformation>) {
        
        Object.assign(this, init);
    }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { Contravention } from '@apis/shared/models/contravention.model';
import { IncomeLevelType } from '@apis/shared/models/types/income-level-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContraventionService } from '../../../shared/services/contravention.service';
import { PaymentAdditionalTimeRequest2 } from '@apis/shared/models/payment-additional-time-request2.model';
import { ContactMethodType } from '@apis/shared/models/types/contact-method-type.model';
import { ContraventionPageComponent } from '../contravention-page/contravention-page.component';
import { Representation } from '@apis/shared/models/representation.model';
import { DriverBaseComponent } from '../../driver-base.component';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { ContraventionInformation } from '@apis/shared/models/contravention-information.model';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';

@Component({
  selector: 'app-request-additional-time',
  templateUrl: './request-additional-time.component.html',
  styleUrls: ['./request-additional-time.component.scss']
})
export class RequestAdditionalTimeComponent extends DriverBaseComponent implements OnInit {
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;
  
  contraventionOverview: ContraventionOverview;
  contraventions: ContraventionInformation[] = [];
  additionalTimeRequest = new PaymentAdditionalTimeRequest2({
    isSelfSubmit: true,
    termsConditionsVersion: this.getLatestTermsAndConditionsVersion()
  });
  confirmPhoneNumber: string;
  isRequestFailed: boolean;
  rsgAgentProfile: RsgAgentProfile;
  registryAgentInformation: RegistryAgentInformation;
  contraventionNumber: string;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private contraventionService: ContraventionService,
    private localStorageService: LocalStorageService
  ) {
    super(router);
  }

  ngOnInit(): void {
    // Registry Portal - If logged in user is registry agent then set the RA profile information in representation object
    if (this.isRSGPortal)
    {
      this.rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
      if (!this.rsgAgentProfile)
        this.router.navigate(['/unauthorized']);

      // Create a new RegistryAgentInformation object, or load the old one if it exists
      if (this.additionalTimeRequest.registryAgentInformation.length === 0) {
        this.registryAgentInformation = new RegistryAgentInformation();
        this.registryAgentInformation.userName = this.rsgAgentProfile.userName;
        this.registryAgentInformation.organizationCode = this.rsgAgentProfile.organization;
      }
      else {
        this.registryAgentInformation = this.additionalTimeRequest.registryAgentInformation[0];
      }

      this.additionalTimeRequest.isSelfSubmit = true; // We do not create representatives for registry portal payment time requests
    }
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
      
      this.contraventionOverview = this.localStorageService.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      if(this.contraventionOverview === null) {
        this.router.navigate(['../../'], {
          relativeTo: this.activatedRoute,
        });
        return;
      }

      this.additionalTimeRequest.contraventionNumber = this.contraventionNumber;

      this.additionalTimeRequest.contraventionList = this.contraventionOverview.timeToPayEligibleContraventions;

      this.additionalTimeRequest.contraventionList.forEach(c => {
        this.contraventions.push(this.contraventionOverview.contraventions.find(ci => ci.contraventionNumber == c));
      });
    });
  }

  onRepresentationTypeChange() {
    this.additionalTimeRequest.representation = this.additionalTimeRequest.isSelfSubmit ? null : new Representation({
      representationTypeId: null
    });
  }

  submitRequest(isValid: boolean) {
    if(isValid) {
      if (this.isRSGPortal) {
        if (this.additionalTimeRequest.registryAgentInformation.length === 0) {
          this.additionalTimeRequest.registryAgentInformation.push(this.registryAgentInformation);
        }
        else {
          this.additionalTimeRequest.registryAgentInformation[0] = this.registryAgentInformation;
        }
      }
      this.masterPage.showSpinner();

      this.additionalTimeRequest.contraventionNumber = this.additionalTimeRequest.contraventionList[0];

      this.contraventionService
        .requestAdditionalTimeToPay(this.additionalTimeRequest)
        .subscribe((result) => {
          this.localStorageService.setItem<PaymentAdditionalTimeRequest2>("PaymentAdditionalTimeRequest", this.additionalTimeRequest);

          this.router.navigate(['../'], {
            relativeTo: this.activatedRoute,
          });
        },
        error => {
          this.isRequestFailed = true;
          this.masterPage.hideSpinner();

          window.scroll(0,0);
        });
    }
  }

  getLatestTermsAndConditionsVersion(): string {
    return this.localStorageService
               .getTermsAndConditionsList()
               .find(t => t.expiryDate == null)
               .version;
  }

  getChargeName(contraventionTypeId: number) {
    return this.localStorageService.getContraventionTypes().find( x => x.id == contraventionTypeId).formattedName;
  }
}

<div class="d-flex flex-column">
    <h1 class="border-bottom pb-4 mb-2">Contact</h1>
    
    <div class="card border-0">
        <p>SafeRoads Alberta is open from 8:15 am to 12:00 pm and 1:00 pm to 4:30 pm, Monday through Friday, and closed weekends and Alberta Public Service paid holidays. For a complete listing of Alberta Public Service paid holidays, see the <a href="https://www.alberta.ca/paid-holidays-directive">Paid holidays directive.</a></p>

        <p>For assistance with the SafeRoads Portal or troubleshooting issues, please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-7233</a> (to connect toll free in Alberta, dial <a href="tel:310-0000">310-0000</a> before the phone number) or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>. SafeRoads Alberta will respond within 2 business days.</p>

        <p>Please be advised requests for review sent by email to SafeRoads Alberta will not be accepted. To dispute an Immediate Roadside Sanctions Notice of Administrative Penalty or a vehicle seizure, the application for review must be submitted online in the SafeRoads Portal.</p>
    </div>
</div>

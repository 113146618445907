import { Component, OnInit } from '@angular/core';
import { ContactMethodType } from '@apis/shared/models/types/contact-method-type.model';
import { ContraventionDetailType } from '@apis/shared/models/types/contravention-detail-type.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { Representation } from '@apis/shared/models/representation.model';
import { LateReviewBaseComponent } from '../late-review-base.component';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { ContactMethodTypes, RepresentationTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { LateReviewRequestItem } from '@apis/shared/models/late-review-request-item.model';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';

@Component({
  selector: 'app-late-review-details',
  templateUrl: './late-review-details.component.html',
  styleUrls: ['./late-review-details.component.scss'],
})
export class LateReviewDetailsComponent extends LateReviewBaseComponent
  implements OnInit {
  genderTypes: GenderType[];
  contraventionTypes: ContraventionType[];
  contraventionDetailTypes: ContraventionDetailType[];
  contactMethodTypes: ContactMethodType[];

  rsgAgentProfile: RsgAgentProfile;
  registryAgentInformation: RegistryAgentInformation;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService
  ) {
    super(router, activatedRoute, localStorageService);
  }

  ngOnInit(): void {
    // Registry Portal - If logged in user is registry agent then set the RA profile information in representation object
    if (this.isRSGPortal)
    {
      this.rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
      if (!this.rsgAgentProfile)
        this.router.navigate(['/unauthorized']);

      // Create a new RegistryAgentInformation object, or load the old one if it exists
      if (this.lateReviewRequest.registryAgentInformation.length === 0) {
        this.registryAgentInformation = new RegistryAgentInformation();
        this.registryAgentInformation.userName = this.rsgAgentProfile.userName;
        this.registryAgentInformation.organizationCode = this.rsgAgentProfile.organization;
      }
      else {
        this.registryAgentInformation = this.lateReviewRequest.registryAgentInformation[0];
      }

      this.lateReviewRequest.contactMethodTypeId = ContactMethodTypes.Email;
    }

    this.genderTypes = this.localStorageService.getGenderTypes();
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    this.contraventionDetailTypes = this.localStorageService.getContraventionDetailTypes();
    this.contactMethodTypes = this.localStorageService.getContactMethodTypes();
  }

  onReviewTypeChange() {
    this.lateReviewRequest.representation = this.lateReviewRequest.isSelfSubmit
      ? null
      : new Representation({
        representationTypeId: null
      });
  }

  onRequesterTypeChange() {
    this.lateReviewRequest.representation = this.registryAgentInformation.isPaidByRecipient ? null : new Representation({
      representationTypeId: null
    });
    this.lateReviewRequest.isSelfSubmit = this.registryAgentInformation.isPaidByRecipient;
  }

  continueRequest(isValid: boolean) {
    if (isValid) {
      this.updateRequest();
      this.saveContext();
      this.router.navigate(['../supporting-documents'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  updateRequest() {
    if (this.isRSGPortal) {
      // Update registryAgentInformation
      if (this.lateReviewRequest.registryAgentInformation.length === 0) {
        this.lateReviewRequest.registryAgentInformation.push(this.registryAgentInformation);
      }
      else {
        this.lateReviewRequest.registryAgentInformation[0] = this.registryAgentInformation;
      }

      // Set applicant name
      if (this.lateReviewRequest.isSelfSubmit) {
        this.lateReviewRequest.reviewApplicant.firstName = this.contraventionOverview.recipientFirstName;
        this.lateReviewRequest.reviewApplicant.lastName = this.contraventionOverview.recipientLastName;
        this.lateReviewRequest.reviewApplicant.otherName = this.contraventionOverview.recipientMiddleName;
      }
      else {
        this.lateReviewRequest.reviewApplicant.firstName = this.registryAgentInformation.payerFirstName;
        this.lateReviewRequest.reviewApplicant.lastName = this.registryAgentInformation.payerLastName;
        this.lateReviewRequest.reviewApplicant.otherName = '';
      }

      // Set representative name
      if (this.lateReviewRequest.representation?.sameAsRequester) {
        this.lateReviewRequest.representation.firstName = this.registryAgentInformation.payerFirstName;
        this.lateReviewRequest.representation.lastName = this.registryAgentInformation.payerLastName;
      }
    }
    else {
      // Set applicant name
      if (this.lateReviewRequest.isSelfSubmit) {
        this.lateReviewRequest.reviewApplicant.firstName = this.contraventionOverview.recipientFirstName;
        this.lateReviewRequest.reviewApplicant.lastName = this.contraventionOverview.recipientLastName;
        this.lateReviewRequest.reviewApplicant.otherName = this.contraventionOverview.recipientMiddleName;
      }
      else {
        this.lateReviewRequest.reviewApplicant.firstName = this.lateReviewRequest.representation.firstName;
        this.lateReviewRequest.reviewApplicant.lastName = this.lateReviewRequest.representation.lastName;
        this.lateReviewRequest.reviewApplicant.otherName = '';
      }
    }
  }

  getPrimaryCharge(contraventionNumber: string) {
    var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == contraventionNumber);

    return this.getChargeDescription(contravention.contraventionTypeId);
  }

  getSecondaryCharge(contraventionNumber: string) {
    var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == contraventionNumber);

    return this.getChargeDescription(contravention.secondaryContraventionTypeId);
  }

  getTertiaryCharge(contraventionNumber: string) {
    var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionNumber == contraventionNumber);

    return this.getChargeDescription(contravention.tertiaryContraventionTypeId);
  }

  getChargeDescription(contraventionTypeId: number) {
    if (contraventionTypeId) {
      return this.contraventionTypes.find(ct => ct.id == contraventionTypeId).formattedName;
    } else {
      return '';
    }
  }
}

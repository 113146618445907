import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleSeizureActivityHistory } from '@apis/shared/models/vehicle-seizure-activity-history.model';
import { ActivityType, SeizureUserType } from '@apis/shared/enums/app.enum';
import * as fileSaver from 'file-saver';
import { DocumentService } from '@apis/shared/services/document.service';
import { Document } from '@apis/shared/models/document.model';
import { Activity } from '@apis/shared/models/activity.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { VehicleSeizureService } from '../../../shared/services/vehicle-seizure.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DriverBaseComponent } from '../../driver-base.component';

@Component({
  selector: 'app-vehicle-seizure-activity-history',
  templateUrl: './vehicle-seizure-activity-history.component.html',
  styleUrls: ['./vehicle-seizure-activity-history.component.scss']
})
export class VehicleSeizureActivityHistoryComponent extends DriverBaseComponent implements OnInit {
  seizureNumber: string;
  seizureUserType: string;
  activityHistory: VehicleSeizureActivityHistory;

  ActivityType = ActivityType;
  SeizureUserType = SeizureUserType;

  constructor(
              router: Router,
              private activatedRoute: ActivatedRoute,
              private seizureService: VehicleSeizureService,
              private documentService: DocumentService,
              private localStorageService: LocalStorageService,
              private readonly spinner: NgxSpinnerService,
              private datePipe: DatePipe) {
    super(router);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.seizureNumber = paramMap.get('seizureNumber');

      this.seizureUserType = this.localStorageService.get(`${this.seizureNumber}_user_type`);

      // Route to search page if seizure user type is empty
      if (!this.seizureUserType) {
        this.spinner.hide();
        this.router.navigate(['../../vehicle-seizures/search']);
      } else {
        this.loadActivityHistory();
      }
    });
  }

  loadActivityHistory() {
    this.spinner.show();

    this.seizureService.getActivityHistory(this.seizureNumber, this.seizureUserType)
      .subscribe((seizureActivityHistory: VehicleSeizureActivityHistory) => {
        this.activityHistory = seizureActivityHistory;

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      });
  }

  downloadActivityDocument(activity: Activity) {
    var storageFileName = `${activity.document.contentGuid}.${activity.document.documentExtension}`;
    var documentRefTypeName = this.getDocumenRefTypeNameByActivityType(+activity.activityType);
    var documentRefTypeNumber = activity.recordNumber;

    this.documentService
      .downloadDocument(
        '',
        documentRefTypeName,
        documentRefTypeNumber,
        storageFileName,
        activity.document.documentName
      )
      .subscribe((result: any) => {
        if (result) {
          fileSaver.saveAs(new Blob([result]), activity.document.documentName);
        }
      });
  }

  viewReviewFeePaymentReceipt(activity: Activity) {
    this.spinner.show();
    this.seizureService.getReviewPaymentReceipt(this.seizureNumber, activity.recordNumber)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Seizure Review - ${activity.recordNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }

  viewRequestFeePaymentReceipt(activity: Activity) {
    this.spinner.show();
    this.seizureService.getLateReviewRequestPaymentReceipt(this.seizureNumber, activity.recordNumber)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Seizure Late Review - ${activity.recordNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }

  getDocumenRefTypeNameByActivityType(activityType: number): string {
    if ([ActivityType.LateReviewDecisionUploaded].includes(activityType)){
      return "Requests";
    } else if ([ActivityType.ReviewDecisionUploaded].includes(activityType)){
      return "Reviews";
    } else if ([ActivityType.JudicialReviewDecisionIssued].includes(activityType)){
      return "JudicialReviews";
    }
  }
}




export class ContraventionSearch {
    public contraventionId: number;
    public firstName: string;
    public lastName: string;
    public birthDate: Date;
    public contraventionNumber: string;
    public driversLicenceNumber: string;
    public licencePlateNumber:string;
    public occurrenceDate:Date; 
    public occurrenceTime: string;
       

    constructor(init?: Partial<ContraventionSearch>) {
        
        Object.assign(this, init);
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { ContraventionStatusTypes } from '@apis/shared/enums/app.enum';
import { ContraventionInformation } from '@apis/shared/models/contravention-information.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-contravention-information2',
  templateUrl: './contravention-information2.component.html',
  styleUrls: ['./contravention-information2.component.scss']
})
export class ContraventionInformation2Component implements OnInit {
  @Input() contraventionInformation: ContraventionInformation;

  contraventionTypeName: string;
  contraventionStatusTypeName: string;
  ContraventionStatusTypes = ContraventionStatusTypes;
  isIrs: boolean = false;

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    // Get contravention status type name to show it in the badge
    this.contraventionStatusTypeName = this.localStorageService.getContraventionStatusTypes().find(x => x.id == this.contraventionInformation.contraventionStatusTypeId)?.name;

    var contraventionType = this.localStorageService.getContraventionTypes().find( x => x.id == this.contraventionInformation.contraventionTypeId);
    this.isIrs = contraventionType.isIRS;
  }

  getChargeCode(contraventionTypeId: number) {
    return this.localStorageService.getContraventionTypes().find( x => x.id == contraventionTypeId).formattedChargeCode;
  }

  getChargeDescription(contraventionTypeId: number) {
    return this.localStorageService.getContraventionTypes().find( x => x.id == contraventionTypeId).formattedName;
  }
}

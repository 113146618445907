<div class="card mt-4">
    <div class="card-header">
        Driver Information
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="col-lg-4">
                <label for="driverSurname">Surname</label>
                <input type="text" readonly class="form-control" id="driverSurname" 
                    [value]="recipient?.lastName">
            </div>

            <div class="col-lg-4">
                <label for="driverFirstName">First Name</label>
                <input type="text" readonly class="form-control" id="driverFirstName"
                    [value]="recipient?.firstName">
            </div>

            <div class="col-lg-4">
                <label for="driverMiddleName">Middle Name</label>
                <input type="text" readonly class="form-control" id="driverMiddleName"
                    [value]="recipient?.otherName">
            </div>				
        </div>
        <ng-content></ng-content>        
    </div>
</div>

<div class="card mt-4">
    <div class="card-header">
        Identification Information
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="col-lg-4">
                <label for="identificationNumber">Identification Number</label>
                <input type="text" readonly class="form-control" id="identificationNumber"
                    [value]="recipient?.recipientIdentification?.identificationNumber">
            </div>

            <div class="col-lg-4">
                <label for="driverBirthDate">Date of Birth</label>
                <input type="text" readonly class="form-control" id="driverBirthDate"
                    [value]="recipient?.recipientIdentification?.dateOfBirth | date:'MM/dd/yyyy'">
            </div>

            <div class="col-lg-4">
                <label for="driverGender">Gender</label>
                <input type="text" readonly class="form-control" id="driverGender"
                    [value]="getGender(recipient?.recipientIdentification?.genderId)">
            </div>				
        </div>
    </div>
</div>
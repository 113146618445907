<div>
    <div class="row">
        <div class="col contravention-section">
            <span class="contravention-number">Seizure Number: <a class="btn-link" (click)="viewVehicleSeizure()">{{seizureInformation.seizureNumber}}</a></span>
            <span class="badge badge-pill badge-status" 
            [class.badge-warning]="seizureInformation.seizureStatusTypeId == SeizureStatusTypes.Seized"
            [class.badge-review]="seizureInformation.seizureStatusTypeId == SeizureStatusTypes.InReview"
            [class.badge-success]="seizureInformation.seizureStatusTypeId == SeizureStatusTypes.Released"
            [class.badge-secondary]="seizureInformation.seizureStatusTypeId == SeizureStatusTypes.Cancelled">{{seizureStatusTypeName | uppercase}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-5 d-flex flex-column" *ngIf="seizureInformation.seizureTypeId == 1">
            <div class="charge-section d-flex flex-column">
                <span class="charge-code">{{getIRSCharge(seizureInformation.contraventionTypeId)}}</span>
            </div>
        </div>
        <div class="col-xl-5 d-flex flex-column" *ngIf="seizureInformation.seizureTypeId != 1">
            <span class="heading">Traffic Safety Act Section</span>
            <div class="charge-section d-flex flex-column">
                <span class="charge-code">{{getChargeCode(seizureInformation.contraventionTypeId)}}</span>
                <span>{{getChargeDescription(seizureInformation.contraventionTypeId)}}</span>
            </div>
        </div>
    </div>
</div>

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Constants } from '@apis/shared/helpers/constants';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { Review } from '@apis/shared/models/review.model';
import { ReviewTypes } from '@apis/shared/enums/app.enum';
import { TermCondition } from '@apis/shared/models/term-condition.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-review-terms-conditions',
  templateUrl: './review-terms-conditions.component.html',
  styleUrls: ['./review-terms-conditions.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ReviewTermsConditionsComponent implements OnInit, AfterViewInit {
  @Input() hideUpdateAlert: boolean;
  @Input() version: string;
  @Input() isSubmitClicked: boolean;
  @Input() infoSheetName: string;
  @Input() infoSheetLink: string;
  @Input() lateEvidenceInfoSheetName: string;
  @Input() lateEvidenceInfoSheetLink: string;
  review: Review;
  ReviewTypes=ReviewTypes;
  
  isTermsAccepted: boolean;
  currentTermCondition: TermCondition = null;

  constructor(
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.review = this.localStorageService.getReviewRequest();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setCurrentTermCondition();
    });
  }

  setCurrentTermCondition() {
    if (this.version) {
      this.currentTermCondition = this.localStorageService
                                    .getTermsAndConditionsList()
                                    .find(t => t.version == this.version);
    } else {
      this.currentTermCondition = this.localStorageService
                                    .getTermsAndConditionsList()
                                    .find(t => t.expiryDate == null)
    }
  }

  canShowUpdateAlert(): boolean {
    return !this.hideUpdateAlert &&
           DateUtil.today() < DateUtil.addDays(new Date(this.currentTermCondition.documentDate),
                                                Constants.Driver.TermConditionNotificationPeriod);
  }
}

<app-contravention-page (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form>
            <div class="d-flex flex-column">
                <h1 class="">Technical Materials Library</h1>
                <div class="d-flex w-100 border-bottom mb-2">
                    <div class="countdown">
                        <countdown-timer [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                    </div>
                </div>
            
                <div class="card border-0">
                    <div class="goa-callout goa--information mt-1">
                        <h6 class="mt-0">Print Technical Materials Library</h6>
                        <p>
                            The Technical Materials Library provides information which can support the client with their review. 
                            Visit the <a href="technical-material" target="_blank">Technical Materials Library</a>, print out the web page, and provide this to the client.
                        </p>
                    </div>
                </div>
        
                <div class="card border-0">
                    <p>Please advise the client that they can access the Technical Materials Library at the URL on the printout. After reviewing the list of documents, the client can return to the registry and ask an agent to download and print any of the documents, and if relevant upload any technical material as supporting documents.</p>
                </div>

                <div class="card border-0">
                    <p>Please advise the client that they can upload technical materials themselves at <a href="https://www.saferoads.com" target="_blank">www.saferoads.com</a> if they do not wish to return to a registry. Uploading technical materials is not a requirement.</p>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">Return to Review Details</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">                
                            <button class="goa-button goa--primary btn-block" (click)="onProceedClick()">Proceed to Schedule Review</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-contravention-page>
<!-- Driver Portal View -->
<app-contravention-page [suppressConfirmation]="true" *ngIf="lateReviewRequests && !isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column">
            <h1 class="border-bottom pb-3 mb-2">Late Review Request</h1>    
        </div>

        <div class="card border-0 mt-2">
            <div class="goa-callout goa--information mt-1">
                <h6 class="mt-0">The decision of an adjudicator is final.</h6>
                <p>
                    If you are not satisfied with the decision of the adjudicator, 
                    you may seek judicial review of decisions rendered by SafeRoads Alberta 
                    by filing an application with the <a href="https://albertacourts.ca/qb/home" target="_blank">Court of King’s Bench of Alberta</a>, 
                    in accordance with section 24(2) of the <i>Provincial Administrative Penalties Act</i>. 
                    An application for judicial review must be made within 30 days of receipt of the 
                    adjudicator’s decision, and you must serve notice of your application to SafeRoads Alberta.
                </p>
            </div>
        </div>

        <div class="mt-4" *ngFor="let lrr of lateReviewRequests">
            <app-late-review-request [lateReviewRequest]="lrr" (onRequestReqview)="startReviewRequest()"></app-late-review-request>
        </div>
    </ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page [suppressConfirmation]="true" *ngIf="lateReviewRequests && isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column">
            <h1 class="border-bottom pb-3 mb-2">Late Review Request</h1>    
        </div>

        <div class="card border-0 mt-2">
            <div class="goa-callout goa--information mt-1">
                <h6 class="mt-0">The decision of an adjudicator is final.</h6>
                <p>
                    If you are not satisfied with the decision of the adjudicator, 
                    you may seek judicial review of decisions rendered by SafeRoads Alberta 
                    by filing an application with the <a href="https://albertacourts.ca/qb/home" target="_blank">Court of King’s Bench of Alberta</a>, 
                    in accordance with section 24(2) of the <i>Provincial Administrative Penalties Act</i>. 
                    An application for judicial review must be made within 30 days of receipt of the 
                    adjudicator’s decision, and you must serve notice of your application to SafeRoads Alberta.
                </p>
            </div>
        </div>

        <div class="mt-4" *ngFor="let lrr of lateReviewRequests">
            <app-late-review-request [lateReviewRequest]="lrr" (onRequestReqview)="startReviewRequest()"></app-late-review-request>
        </div>
    </ng-container>
</app-contravention-page>
<!-- Driver Portal View -->
<div class="d-flex flex-column" *ngIf="contraventionOverview && !isRSGPortal">
    <div class="card goa-callout goa--emergency mb-4 mt-0 mobile-callout" *ngIf="contraventionOverview.hasOverdues">
        <h6 class="mt-0">Penalty Overdue</h6>
        <p>
            Your penalty is currently past due. Late penalties will be incurred and you will experience a restriction in services until your penalty is paid.
        </p>
    </div>
    <div class="card goa-callout goa--information mb-4 mt-0 mobile-callout" *ngIf="contraventionOverview.hasImmediateCancellations">
        <h6 class="mt-0">Notice(s) Cancelled</h6>
        <p>
            One or more of your Notices of Administrative Penalty was cancelled by the issuing officer (see below).<br>
            You do not need to take any further action on the cancelled Notice(s).
        </p>
    </div>

    <h1 class="mb-3">Contravention Overview</h1>
    <div class="row">
        <div class="col">
            <span>Notice(s)</span>
            <h3 class="mt-0">{{notices}}</h3>
        </div>
        <div class="col-md-auto">
            <span>Recipient</span>
            <h3 class="mt-0">{{recipientName}}</h3>
        </div>
    </div>

    <div>
        <div class="summary-item-container border-bottom pb-4">
            <div class="info-item">
                <div class="d-flex flex-row">
                    <div>
                        <div class="icon-circle issue-date">
                        </div>
                    </div>
                    <div class="d-flex flex-column ml-3 pt-1">
                        <div class="summary-item-label">Date Issued</div>
                        <div class="summary-item-value">{{ contraventionOverview.issueDate | date:'mediumDate' }}</div>
                    </div>
                </div>
            </div>    
            <div class="info-item">
                <div class="d-flex flex-row">
                    <div>
                        <div class="icon-circle penalty-amount">
                        </div>
                    </div>
                    <div class="d-flex flex-column ml-3 pt-1">
                        <div class="summary-item-label">Balance Owing</div>
                        <div class="summary-item-value">{{ contraventionOverview.totalDueAmount | currency }}</div>
                    </div>
                </div>
            </div>    
            <div class="info-item" *ngIf="contraventionOverview.fullPaymentDueDate">
                <div class="d-flex flex-row">
                    <div>
                        <div class="icon-circle pay-penalty">
                        </div>
                    </div>
                    <div class="d-flex flex-column ml-3 pt-1">
                        <div class="summary-item-label">Full Payment Due</div>
                        <div class="summary-item-value">{{ contraventionOverview.fullPaymentDueDate | date:'mediumDate' }}</div>
                    </div>
                </div>
            </div> 
            <div class="info-btn-container">
                <button class="btn-block goa-button mb-2 m-0"
                    *ngIf="canMakePayment()"
                   (click)="makePayment()">Pay Now</button>
                <button class="btn-block goa-button goa--secondary m-0"
                   (click)="viewPaymentHistory()">View Payment History</button>
            </div>
        </div>

        <p class="disclaimer">
            Due to the potential for delayed or missed messages sent via email and SMS, 
            it is your responsibility to check the status of your request in the SafeRoads portal. 
            Please contact SafeRoads Alberta at <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> if you have any questions.
        </p>

        <div class="card goa-callout goa--emergency mb-4 mt-0 desktop-callout" *ngIf="contraventionOverview.hasOverdues">
            <h6 class="mt-0">Penalty Overdue</h6>
            <p>
                Your penalty is currently past due. Late penalties will be incurred and you will experience a restriction in services until your penalty is paid.
            </p>
        </div>

        <div class="card goa-callout goa--information mb-4 mt-0 desktop-callout" *ngIf="contraventionOverview.hasImmediateCancellations">
            <h6 class="mt-0">Notice(s) Cancelled</h6>
            <p>
                One or more of your Notices of Administrative Penalty was cancelled by the issuing officer (see below).<br>
                You do not need to take any further action on the cancelled Notice(s).
            </p>
        </div>

        <div class="card mb-4" *ngIf="contraventionOverview.timeToPayState != TimeToPayState.TimeToPayUnAvailable">
            <div class="card-header">
                Request Time to Pay
            </div>
            <div class="card-body" *ngIf="contraventionOverview.timeToPayState == TimeToPayState.TimeToPayAvailable">
                <div class="row">
                    <div class="col-12 col-sm-6 d-flex flex-row">
                        <div>
                            <div class="p-2 icon-circle pay-penalty">
                            </div>
                        </div>
                        <p class="ml-3">You can request more time to pay your penalty.</p>
                    </div>
                    <div class="col-12 col-sm-3 d-flex flex-column">
                        <div class="summary-item-label">Request Deadline</div>
                        <div class="summary-item-value">{{ contraventionOverview.timeToPayRequestDeadLine | date:'mediumDate' }}</div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="RequestTime()">Request Time to Pay</button>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="contraventionOverview.timeToPayState == TimeToPayState.AdditionalTimeToPayAvailable">
                <div class="row">
                    <div class="col-12 col-sm-6 d-flex flex-row">
                        <div>
                            <div class="p-2 icon-circle pay-penalty">
                            </div>
                        </div>
                        <p class="ml-3">You can request more time to pay your penalty.</p>
                    </div>
                    <div class="col-12 col-sm-3 d-flex flex-column">
                        <div class="summary-item-label">Request Deadline</div>
                        <div class="summary-item-value">{{ contraventionOverview.timeToPayRequestDeadLine | date:'mediumDate' }}</div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="RequestAdditionalTime()">Request Time to Pay</button>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="contraventionOverview.timeToPayState == TimeToPayState.TimeToPayRequested">
                <div class="row">
                    <div class="col d-flex flex-row align-items-start">
                        <div>
                            <div class="p-2 icon-circle pay-penalty">
                            </div>
                        </div>
                        <div class="d-flex flex-column ml-3">
                            <p class="my-0">Your payment deadline has been extended by 90 days.</p>
                            <p class="my-0">You can request more time to pay your penalty on {{ contraventionOverview.additionalTimeToPayEligibleOn | date:'mediumDate' }}.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="contraventionOverview.timeToPayState == TimeToPayState.AdditionalTimeToPayRequested">
                <div class="row">
                    <div class="col d-flex flex-row align-items-start">
                        <div>
                            <div class="p-2 icon-circle pay-penalty">
                            </div>
                        </div>
                        <p class="ml-3">Your payment deadline has been extended by 180 days.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="(contraventionOverview.recentIrsReviewState != ReviewState.ReviewRequested && contraventionOverview.recentIrsReviewNumber) 
            || contraventionOverview.recentTsaReviewNumber">
            <div class="card-header">
                Review Status
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col d-flex flex-row align-items-start mb-2">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <div class="d-flex flex-column ml-3" *ngIf="contraventionOverview.recentIrsReviewNumber">
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewScheduled">
                                <div class="summary-item-label">IRS Review Scheduled</div>
                                <div class="summary-item-value" *ngIf="contraventionOverview.recentIrsReviewMethodTypeId == 1">
                                    {{ contraventionOverview.recentIrsReviewScheduleDate | date:'longDate' }}
                                </div>
                                <div class="summary-item-value" *ngIf="contraventionOverview.recentIrsReviewMethodTypeId == 2">
                                    {{ contraventionOverview.recentIrsReviewScheduleDate | date:'longDate' }} at {{ contraventionOverview.recentIrsReviewScheduleTime.substr(0, 5) }}
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewPaymentPending">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    The review payment is pending
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewPendingDecision">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    Your review decision is pending
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewCancelled">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    Your review has been cancelled
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewAbandoned">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    Your review was abandoned
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewDecisionAvailable">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    Your <a [routerLink]="['review', contraventionOverview.recentIrsReviewSequence, ReviewTypes.IrsReview, 'details']">review decision</a> is available
                                </div>
                            </ng-template>
                        </div>
                        <div class="d-flex flex-column ml-3" *ngIf="contraventionOverview.recentTsaReviewNumber">
                            <ng-template [ngIf]="contraventionOverview.recentTsaReviewState == ReviewState.ReviewScheduled">
                                <div class="summary-item-label">Traffic Review Scheduled</div>
                                <div class="summary-item-value" *ngIf="contraventionOverview.recentTsaReviewMethodTypeId == 1">
                                    {{ contraventionOverview.recentTsaReviewScheduleDate | date:'longDate' }}
                                </div>
                                <div class="summary-item-value" *ngIf="contraventionOverview.recentTsaReviewMethodTypeId == 2">
                                    {{ contraventionOverview.recentTsaReviewScheduleDate | date:'longDate' }} at {{ contraventionOverview.recentTsaReviewScheduleTime.substr(0, 5) }}
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentTsaReviewState == ReviewState.ReviewPendingDecision">
                                <div class="summary-item-label">Traffic Review</div>
                                <div class="summary-item-value">
                                    Your review decision is pending
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentTsaReviewState == ReviewState.ReviewCancelled">
                                <div class="summary-item-label">Traffic Review</div>
                                <div class="summary-item-value">
                                    Your review has been cancelled
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewAbandoned">
                                <div class="summary-item-label">Traffic Review</div>
                                <div class="summary-item-value">
                                    Your review was abandoned
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentTsaReviewState == ReviewState.ReviewDecisionAvailable">
                                <div class="summary-item-label">Traffic Review</div>
                                <div class="summary-item-value">
                                    Your <a [routerLink]="['review', contraventionOverview.recentTsaReviewSequence, ReviewTypes.TsaReview, 'deatils']">review decision</a> is available
                                </div>
                            </ng-template>
                        </div>
                    </div>                    
                    <div class="col-12 col-sm-3" *ngIf="contraventionOverview.recentIrsReviewState != ReviewState.ReviewPaymentPending">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="viewReviewDetails()">View Review Details</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="isReviewAvailable">
            <div class="card-header">
                Request a Review
            </div>
            <div class="card-body">
                <div *ngIf="reviewAlreadyExists" class="row">
                    <div class="card goa-callout goa--emergency mt-0">
                        <h6 class="mt-0 font-weight-bold">There is already a review in progress. Please refresh the screen.</h6>
                    </div>
                </div>
                <div *ngIf="!reviewAlreadyExists" class="row">
                    <div class="col-6 d-flex flex-row">
                        <div>
                            <div class="p-2 icon-circle calendar">
                            </div>
                        </div>
                        <p class="ml-3">Apply for a review if you wish to dispute your penalty.</p>
                    </div>
                    <div class="col-3 d-flex flex-column">
                        <div class="summary-item-label">Review Request Deadline</div>
                        <div class="summary-item-value" *ngIf="contraventionOverview.irsReviewRequestDeadLine != null; else tsaReviewRequestDeadLine">
                            {{ contraventionOverview.irsReviewRequestDeadLine | date:'mediumDate' }}
                        </div>
                        <ng-template #tsaReviewRequestDeadLine>
                            <div class="summary-item-value">{{ contraventionOverview.tsaReviewRequestDeadLine | date:'mediumDate' }}</div>
                        </ng-template>
                    </div>
                    <div class="col-3">
                        <button class="btn-block goa-button goa--secondary mt-0" data-toggle="modal" data-target="#confirmReviewModal">Request a Review</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" 
            *ngIf="contraventionOverview.recentIrsReviewNumber && contraventionOverview.isReReview && contraventionOverview.recentIrsReviewState == ReviewState.ReviewRequested">
            <div class="card-header">
                Re-Review
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-9 d-flex flex-row align-items-start">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <p class="ml-3 mt-0">Your 
                            <a [routerLink]="['activity-history']">Re-Review is available.</a> You may now schedule your review.</p>
                    </div>
                    <div class="col-12 col-sm-3">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="viewReviewDetails()">Schedule Review</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="canRequestLateReview()">
            <div class="card-header">
                Request a Late Review
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-6 d-flex flex-row align-items-start">
                        <div>
                            <div class="p-2 icon-circle calendar">
                            </div>
                        </div>
                        <p class="ml-3 mt-0">{{ contraventionOverview.irsReviewState == ReviewState.LateReviewAvailableToMakeLateEvidence ? "Submit a late review request if you wish to make a Late Evidence application." : "Submit a late review request if you wish to dispute your penalty." }}</p>
                    </div>
                    <div class="col-12 col-sm-3 d-flex flex-column">
                        <div class="summary-item-label">Late Review Request Deadline</div>
                        <div class="summary-item-value" *ngIf="contraventionOverview.irsReviewRequestDeadLine != null; else tsaReview">
                            {{ contraventionOverview.irsReviewRequestDeadLine | date:'mediumDate' }}
                        </div>
                        <ng-template #tsaReview>
                            <div class="summary-item-value">{{ contraventionOverview.tsaReviewRequestDeadLine | date:'mediumDate' }}</div>
                        </ng-template>
                    </div>
                    <div class="col-12 col-sm-3">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="startLateReviewRequest()">Request a Late Review</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="contraventionOverview.irsReviewState == ReviewState.LateReviewRequested 
            || contraventionOverview.tsaReviewState == ReviewState.LateReviewRequested">
            <div class="card-header">
                Late Review Status
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-8 d-flex flex-row align-items-start">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <p class="ml-3">Your Late Review Request is pending</p>
                    </div>
                    <div class="col-12 col-sm-4">
                        <button class="btn-block goa-button goa--secondary mt-0" [routerLink]="['late-review-requests']">View Late Review Details</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="contraventionOverview.irsReviewState == ReviewState.LateReviewDenied 
            || contraventionOverview.tsaReviewState == ReviewState.LateReviewDenied">
            <div class="card-header">
                Late Review Status
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-9 d-flex flex-row align-items-start">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <p class="ml-3">Your Late Review Request has been denied.</p>
                    </div>
                    <div class="col-12 col-sm-3">
                        <button class="btn-block goa-button goa--secondary mt-0" [routerLink]="['late-review-requests']">View Decision</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="contraventionOverview.irsReviewState == ReviewState.LateReviewApproved 
            || contraventionOverview.tsaReviewState == ReviewState.LateReviewApproved">
            <div class="card-header">
                Late Review Status
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-6 d-flex flex-row align-items-start">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <p class="ml-3 mt-0">Your Late Review Request has been 
                            <a [routerLink]="['late-review-requests']">approved.</a> You may now apply for a review if you wish to dispute your penalty.</p>
                    </div>
                    <div class="col-12 col-sm-3 d-flex flex-column">
                        <div class="summary-item-label">Review Request Deadline</div>
                        <div class="summary-item-value" *ngIf="contraventionOverview.irsReviewRequestDeadLine != null; else tsaReviewRequestDeadLine">
                            {{ contraventionOverview.irsReviewRequestDeadLine | date:'mediumDate' }}
                        </div>
                        <ng-template #tsaReviewRequestDeadLine>
                            <div class="summary-item-value">{{ contraventionOverview.tsaReviewRequestDeadLine | date:'mediumDate' }}</div>
                        </ng-template>
                    </div>
                    <div class="col-12 col-sm-3">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="startReviewRequest()">Apply for a Review</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="content mb-4">            
            <div class="card">
                <ng-container>
                    <div class="card-header">
                        Contravention Information
                    </div>
                    <div class="card-body">
                        <div *ngFor="let ci of irsContraventions; last as isLast">
                            <app-contravention-information2 [contraventionInformation]="ci"></app-contravention-information2>
                            <hr *ngIf="!isLast">
                        </div>
                        <hr *ngIf="irsSeizures.length > 0">
                        <div *ngFor="let s of irsSeizures; last as isLast">
                            <app-seizure-information [seizureInformation]="s" [contraventionSubmissionDate]="contraventionOverview.submissionDate"></app-seizure-information>
                            <hr *ngIf="!isLast">
                        </div>
                        <hr *ngIf="tsaContraventions.length > 0">
                        <div *ngFor="let ci of tsaContraventions; last as isLast">
                            <app-contravention-information2 [contraventionInformation]="ci"></app-contravention-information2>
                            <hr *ngIf="!isLast">
                        </div>
                        <hr *ngIf="sdpSeizures.length > 0">
                        <div *ngFor="let s of sdpSeizures; last as isLast">
                            <app-seizure-information [seizureInformation]="s"></app-seizure-information>
                            <hr *ngIf="!isLast">
                        </div>
                    </div>                    
                </ng-container>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isIRSPenaltyTableVisible">
        <h2 class="mb-0">Applicable Penalties Under the Immediate Roadside Sanction Program</h2>
        <label>Added {{Constants.Driver.IRS_PENALTY_TABLE_EFFECTIVE_DATE | date:mediumDate}}</label>
        
        <div class="card">
            <div class="card-header display-card-header">
                <div class="d-flex justify-content-start">
                    <div class="card-number mr-3">1</div>
                    <div class="align-self-center">IRS ZERO: Novice (section 88.01 of the <span class="italic-text">Traffic Safety Act</span>)</div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <label>1st and Subsequent Occurrence</label>
                        <ul>
                            <li>30-day driver’s licence suspension</li>
                            <li>7-day vehicle seizure</li>
                            <li>$200 fine plus victim fine surcharge</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header display-card-header">
                <div class="d-flex justify-content-start">
                    <div class="card-number mr-3">2</div>
                    <div class="align-self-center">IRS ZERO: Commercial (section 88.02 of the <span class="italic-text">Traffic Safety Act</span>)</div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <label>1st Occurrence</label>
                        <ul>
                            <li>3-day driver’s licence suspension</li>
                            <li>$300 fine plus victim fine surcharge</li>
                          </ul>
                    </div>
                    <div class="col-12 col-sm-4">
                        <label>2nd Occurrence</label>
                        <ul>
                            <li>15-day driver’s licence suspension</li>
                            <li>$600 fine plus victim fine surcharge</li>
                          </ul>
                    </div>
                    <div class="col-12 col-sm-4">
                        <label>3rd and Subsequent Occurrence</label>
                        <ul>
                            <li>30-day driver’s licence suspension</li>
                            <li>$1,200 fine plus victim fine surcharge</li>
                          </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header display-card-header">
                <div class="d-flex justify-content-start">
                    <div class="card-number mr-3">3</div>
                    <div class="align-self-center">IRS: WARN (section 88.03 of the <span class="italic-text">Traffic Safety Act</span>)</div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <label>1st Occurrence</label>
                        <ul>
                            <li>3-day driver’s licence suspension</li>
                            <li>3-day vehicle seizure</li>
                            <li>$300 fine plus victim fine surcharge</li>
                          </ul>
                    </div>
                    <div class="col-12 col-sm-4">
                        <label>2nd Occurrence</label>
                        <ul>
                            <li>15-day driver’s licence suspension</li>
                            <li>7-day vehicle seizure</li>
                            <li>Crossroads Course (1/2 day)</li>
                            <li>$600 fine plus victim fine surcharge</li>
                          </ul>
                    </div>
                    <div class="col-12 col-sm-4">
                        <label>3rd and Subsequent Occurrence</label>
                        <ul>
                            <li>30-day driver’s licence suspension</li>
                            <li>7-day vehicle seizure</li>
                            <li>Impact Course (2 days)</li>
                            <li>$1,200 fine plus victim fine surcharge</li>
                          </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header display-card-header">
                <div class="d-flex justify-content-start">
                    <div class="card-number mr-3">4</div>
                    <div class="align-self-center">IRS: FAIL (section 88.1 of the <span class="italic-text">Traffic Safety Act</span>)</div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <label>1st Occurrence</label>
                        <ul>
                            <li>90-day driver’s licence suspension, followed by a 12-month participation in the Alberta Ignition Interlock Program</li>
                            <li>30-day vehicle seizure</li>
                            <li>Planning Ahead Course (1 day)</li>
                            <li>$1,000 fine plus victim fine surcharge</li>
                          </ul>
                    </div>
                    <div class="col-12 col-sm-4">
                        <label>2nd Occurrence</label>
                        <ul>
                            <li>90-day driver’s licence suspension, followed by a 36-month participation in the Alberta Ignition Interlock Program</li>
                            <li>30-day vehicle seizure</li>
                            <li>Impact Course (2 days)</li>
                            <li>$2,000 fine plus victim fine surcharge</li>
                            <li>Charged under the Criminal Code (Canada)</li>
                          </ul>
                    </div>
                    <div class="col-12 col-sm-4">
                        <label>3rd and Subsequent Occurrence</label>
                        <ul>
                            <li>90-day driver’s licence suspension, followed by a lifetime participation in the Alberta Ignition Interlock Program</li>
                            <li>30-day vehicle seizure</li>
                            <li>$2,000 fine plus victim fine surcharge</li>
                            <li>Charged under the Criminal Code (Canada)</li>
                          </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-4">
            <div class="card-header display-card-header">
                <div class="d-flex justify-content-start">
                    <div class="card-number mr-3">5</div>
                    <div class="align-self-center">IRS: 24-HOUR (section 88 of the <span class="italic-text">Traffic Safety Act</span>)</div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <label>1st and Subsequent Occurrence</label>
                        <ul>
                            <li>24-hour driver’s licence suspension</li>
                            <li>24-hour vehicle seizure (optional)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="d-flex justify-content-end">
        <div class="info-btn-container mb-4">
            <button class="btn-block goa-button goa--secondary mt-0" (click)="viewActivityHistory()">View Activity History</button>
        </div>
    </div>
</div>

<!-- Registry Portal View -->
<div class="d-flex flex-column" *ngIf="contraventionOverview && isRSGPortal">
    <div class="mb-4 not-for-print">
        <a class="lnk-back" [routerLink]="['../../contraventions/search']">Back to Contravention Lookup</a>
    </div>
    <div class="card goa-callout goa--emergency mb-4 mt-0" *ngIf="contraventionOverview.isPaymentInProgress">
        <h2 class="my-0">Takes time to reset, return to MOVES/try again</h2>
    </div>

    <div class="row">
        <div class="col-12 col-sm-8">        
            <h1 class="mb-3">Contravention Overview</h1>
        </div>
        <div class="col-12 col-sm-4 d-flex align-self-end justify-content-end">
            <div class="float-right">
                <div class="print-icon not-for-print" (click)="printSummary()"></div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-9">
            <span>Notice(s)</span>
            <h3 class="mt-0">{{notices}}</h3>
        </div>
        <div class="col-3 text-right">
            <span>Recipient</span>
            <h3 class="mt-0">{{recipientName}}</h3>
        </div>
    </div>

    <div>
        <div class="summary-item-container border-bottom pb-4">
            <div class="info-item">
                <div class="d-flex flex-row">
                    <div>
                        <div class="icon-circle issue-date">
                        </div>
                    </div>
                    <div class="d-flex flex-column ml-3 pt-1">
                        <div class="summary-item-label">Date Issued</div>
                        <div class="summary-item-value">{{ contraventionOverview.issueDate | date:'mediumDate' }}</div>
                    </div>
                </div>
            </div>    
            <div class="info-item">
                <div class="d-flex flex-row">
                    <div>
                        <div class="icon-circle penalty-amount">
                        </div>
                    </div>
                    <div class="d-flex flex-column ml-3 pt-1">
                        <div class="summary-item-label">Balance Owing</div>
                        <div class="summary-item-value">{{ contraventionOverview.totalDueAmount | currency }}</div>
                    </div>
                </div>
            </div>    
            <div class="info-item" *ngIf="contraventionOverview.fullPaymentDueDate">
                <div class="d-flex flex-row">
                    <div>
                        <div class="icon-circle pay-penalty">
                        </div>
                    </div>
                    <div class="d-flex flex-column ml-3 pt-1">
                        <div class="summary-item-label">Full Payment Due</div>
                        <div class="summary-item-value">{{ contraventionOverview.fullPaymentDueDate | date:'mediumDate' }}</div>
                    </div>
                </div>
            </div> 
            <div class="info-btn-container">
                <button class="btn-block goa-button mb-2 m-0"
                    *ngIf="!contraventionOverview.isPaymentInProgress
                                && hasTransactionId
                                && canMakePayment()"
                   (click)="makeRegistryPayment()">Pay Now</button>
                <button class="btn-block goa-button goa--secondary m-0"
                   (click)="viewPaymentHistory()">View Payment History</button>
            </div>
        </div>

        <p class="disclaimer">
            Due to the potential for delayed or missed messages sent via email and SMS, 
            it is your responsibility to check the status of your request in the SafeRoads portal. 
            Please contact SafeRoads Alberta at <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> if you have any questions.
        </p>

        <div class="card goa-callout goa--emergency mb-4 mt-0 desktop-callout" *ngIf="contraventionOverview.hasOverdues">
            <h6 class="mt-0">Penalty Overdue</h6>
            <p>
                Your penalty is currently past due. Late penalties will be incurred and you will experience a restriction in services until your penalty is paid.
            </p>
        </div>

        <div class="card goa-callout goa--information mb-4 mt-0 desktop-callout" *ngIf="contraventionOverview.hasImmediateCancellations">
            <h6 class="mt-0">Notice(s) Cancelled</h6>
            <p>
                One or more of your Notices of Administrative Penalty was cancelled by the issuing officer (see below).<br>
                You do not need to take any further action on the cancelled Notice(s).
            </p>
        </div>

        <div class="card mb-4" *ngIf="contraventionOverview.timeToPayState != TimeToPayState.TimeToPayUnAvailable">
            <div class="card-header">
                Request Time to Pay
            </div>
            <div class="card-body" *ngIf="contraventionOverview.timeToPayState == TimeToPayState.TimeToPayAvailable">
                <div class="row">
                    <div class="col-6 d-flex flex-row">
                        <div>
                            <div class="p-2 icon-circle pay-penalty">
                            </div>
                        </div>
                        <p class="ml-3">The driver can request more time to pay their penalty.</p>
                    </div>
                    <div class="col-3 d-flex flex-column">
                        <div class="summary-item-label">Request Deadline</div>
                        <div class="summary-item-value">{{ contraventionOverview.timeToPayRequestDeadLine | date:'mediumDate' }}</div>
                    </div>
                    <div class="col-3">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="RequestTime()">Request Time to Pay</button>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="contraventionOverview.timeToPayState == TimeToPayState.AdditionalTimeToPayAvailable">
                <div class="row">
                    <div class="col-6 d-flex flex-row">
                        <div>
                            <div class="p-2 icon-circle pay-penalty">
                            </div>
                        </div>
                        <p class="ml-3">The driver can request additional time to pay their penalty.</p>
                    </div>
                    <div class="col-3 d-flex flex-column">
                        <div class="summary-item-label">Request Deadline</div>
                        <div class="summary-item-value">{{ contraventionOverview.timeToPayRequestDeadLine | date:'mediumDate' }}</div>
                    </div>
                    <div class="col-3">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="RequestAdditionalTime()">Request Time to Pay</button>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="contraventionOverview.timeToPayState == TimeToPayState.TimeToPayRequested">
                <div class="row">
                    <div class="col d-flex flex-row align-items-start">
                        <div>
                            <div class="p-2 icon-circle pay-penalty">
                            </div>
                        </div>
                        <div class="d-flex flex-column ml-3">
                            <p class="my-0">The driver's payment deadline has been extended by 90 days.</p>
                            <p class="my-0">The driver can request more time to pay their penalty on {{ contraventionOverview.additionalTimeToPayEligibleOn | date:'mediumDate' }}.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="contraventionOverview.timeToPayState == TimeToPayState.AdditionalTimeToPayRequested">
                <div class="row">
                    <div class="col-6 d-flex flex-row align-items-start">
                        <div>
                            <div class="p-2 icon-circle pay-penalty">
                            </div>
                        </div>
                        <p class="ml-3">The payment deadline has been extended by 180 days.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="(contraventionOverview.recentIrsReviewState != ReviewState.ReviewRequested && contraventionOverview.recentIrsReviewNumber) 
            || contraventionOverview.recentTsaReviewNumber">
            <div class="card-header">
                Review Status
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col d-flex flex-row align-items-start mb-2">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <div class="d-flex flex-column ml-3" *ngIf="contraventionOverview.recentIrsReviewNumber">
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewScheduled">
                                <div class="summary-item-label">IRS Review Scheduled</div>
                                <div class="summary-item-value" *ngIf="contraventionOverview.recentIrsReviewMethodTypeId == 1">
                                    {{ contraventionOverview.recentIrsReviewScheduleDate | date:'longDate' }}
                                </div>
                                <div class="summary-item-value" *ngIf="contraventionOverview.recentIrsReviewMethodTypeId == 2">
                                    {{ contraventionOverview.recentIrsReviewScheduleDate | date:'longDate' }} at {{ contraventionOverview.recentIrsReviewScheduleTime.substr(0, 5) }}
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewPaymentPending">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    The review payment is pending
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewPendingDecision">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    The review decision is pending
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewCancelled">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    The review has been cancelled
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewAbandoned">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    The review was abandoned
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewDecisionAvailable">
                                <div class="summary-item-label">IRS Review</div>
                                <div class="summary-item-value">
                                    The <a [routerLink]="['review', contraventionOverview.recentIrsReviewSequence, ReviewTypes.IrsReview, 'details']">review decision</a> is available
                                </div>
                            </ng-template>
                        </div>
                        <div class="d-flex flex-column ml-3" *ngIf="contraventionOverview.recentTsaReviewNumber">
                            <ng-template [ngIf]="contraventionOverview.recentTsaReviewState == ReviewState.ReviewScheduled">
                                <div class="summary-item-label">Traffic Review Scheduled</div>
                                <div class="summary-item-value" *ngIf="contraventionOverview.recentTsaReviewMethodTypeId == 1">
                                    {{ contraventionOverview.recentTsaReviewScheduleDate | date:'longDate' }}
                                </div>
                                <div class="summary-item-value" *ngIf="contraventionOverview.recentTsaReviewMethodTypeId == 2">
                                    {{ contraventionOverview.recentTsaReviewScheduleDate | date:'longDate' }} at {{ contraventionOverview.recentTsaReviewScheduleTime.substr(0, 5) }}
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentTsaReviewState == ReviewState.ReviewPendingDecision">
                                <div class="summary-item-label">Traffic Review</div>
                                <div class="summary-item-value">
                                    The review decision is pending
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentTsaReviewState == ReviewState.ReviewCancelled">
                                <div class="summary-item-label">Traffic Review</div>
                                <div class="summary-item-value">
                                    The review has been cancelled
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentIrsReviewState == ReviewState.ReviewAbandoned">
                                <div class="summary-item-label">Traffic Review</div>
                                <div class="summary-item-value">
                                    The review was abandoned
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="contraventionOverview.recentTsaReviewState == ReviewState.ReviewDecisionAvailable">
                                <div class="summary-item-label">Traffic Review</div>
                                <div class="summary-item-value">
                                    The <a [routerLink]="['review', contraventionOverview.recentTsaReviewSequence, ReviewTypes.TsaReview, 'deatils']">review decision</a> is available
                                </div>
                            </ng-template>
                        </div>
                    </div>                    
                    <div class="col-12 col-sm-3" *ngIf="contraventionOverview.recentIrsReviewState != ReviewState.ReviewPaymentPending">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="viewReviewDetails()">View Review Details</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="isReviewAvailable">
            <div class="card-header">
                Request a Review
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-6 d-flex flex-row">
                        <div>
                            <div class="p-2 icon-circle calendar">
                            </div>
                        </div>
                        <p class="ml-3">The driver can apply for a review if they wish to dispute their penalty.</p>
                    </div>
                    <div class="col-3 d-flex flex-column">
                        <div class="summary-item-label">Review Request Deadline</div>
                        <div class="summary-item-value" *ngIf="contraventionOverview.irsReviewRequestDeadLine != null; else tsaReviewRequestDeadLine">
                            {{ contraventionOverview.irsReviewRequestDeadLine | date:'mediumDate' }}
                        </div>
                        <ng-template #tsaReviewRequestDeadLine>
                            <div class="summary-item-value">{{ contraventionOverview.tsaReviewRequestDeadLine | date:'mediumDate' }}</div>
                        </ng-template>
                    </div>
                    <div class="col-3">
                        <button class="btn-block goa-button goa--secondary mt-0" data-toggle="modal" data-target="#confirmReviewModal">Request a Review</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4"
            *ngIf="contraventionOverview.recentIrsReviewNumber && contraventionOverview.isReReview && contraventionOverview.recentIrsReviewState == ReviewState.ReviewRequested">
            <div class="card-header">
                Re-Review
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-9 d-flex flex-row align-items-start">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <p class="ml-3 mt-0">
                            <a [routerLink]="['activity-history']">Re-Review is available.</a> The driver may now schedule their review.
                        </p>
                    </div>
                    <div class="col-12 col-sm-3">
                        <button class="btn-block goa-button goa--secondary mt-0" (click)="viewReviewDetails()">Schedule Review</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="canRequestLateReview()">
            <div class="card-header">
                Request a Late Review
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-6 d-flex flex-row align-items-start">
                        <div>
                            <div class="p-2 icon-circle calendar">
                            </div>
                        </div>
                        <p class="ml-3 mt-0">{{ contraventionOverview.irsReviewState == ReviewState.LateReviewAvailableToMakeLateEvidence ? "Submit a late review request if the driver wishes to make a Late Evidence application." : "Submit a late review request if the driver wishes to dispute their penalty." }}</p>
                    </div>
                    <div class="col-3 d-flex flex-column">
                        <div class="summary-item-label">Late Review Request Deadline</div>
                        <div class="summary-item-value" *ngIf="contraventionOverview.irsReviewRequestDeadLine != null; else tsaReview">
                            {{ contraventionOverview.irsReviewRequestDeadLine | date:'mediumDate' }}
                        </div>
                        <ng-template #tsaReview>
                            <div class="summary-item-value">{{ contraventionOverview.tsaReviewRequestDeadLine | date:'mediumDate' }}</div>
                        </ng-template>
                    </div>
                    <div class="col-3">
                        <button *ngIf="!contraventionOverview.isPaymentInProgress && hasTransactionId" class="btn-block goa-button goa--secondary mt-0" (click)="startLateReviewRequest()">Request a Late Review</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="contraventionOverview.irsReviewState == ReviewState.LateReviewRequested 
            || contraventionOverview.tsaReviewState == ReviewState.LateReviewRequested">
            <div class="card-header">
                Late Review Status
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-8 d-flex flex-row align-items-start">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <p class="ml-3">The late review request is pending</p>
                    </div>
                    <div class="col-4">
                        <button *ngIf="!contraventionOverview.isPaymentInProgress" class="btn-block goa-button goa--secondary mt-0" [routerLink]="['late-review-requests']">View Late Review Details</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="contraventionOverview.irsReviewState == ReviewState.LateReviewDenied 
            || contraventionOverview.tsaReviewState == ReviewState.LateReviewDenied">
            <div class="card-header">
                Late Review Status
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-9 d-flex flex-row align-items-start">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <p class="ml-3">The late review request has been denied.</p>
                    </div>
                    <div class="col-3">
                        <button class="btn-block goa-button goa--secondary mt-0" [routerLink]="['late-review-requests']">View Decision</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="contraventionOverview.irsReviewState == ReviewState.LateReviewApproved 
            || contraventionOverview.tsaReviewState == ReviewState.LateReviewApproved">
            <div class="card-header">
                Late Review Status
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-6 d-flex flex-row align-items-start">
                        <div class="p-2 icon-circle review-scales">
                        </div>
                        <p class="ml-3 mt-0">The late review request has been 
                            <a [routerLink]="['late-review-requests']">approved.</a> The driver may now apply for a review if they wish to dispute the penalty.</p>
                    </div>
                    <div class="col-3 d-flex flex-column">
                        <div class="summary-item-label">Review Request Deadline</div>
                        <div class="summary-item-value" *ngIf="contraventionOverview.irsReviewRequestDeadLine != null; else tsaReviewRequestDeadLine">
                            {{ contraventionOverview.irsReviewRequestDeadLine | date:"mediumDate" }}
                        </div>
                        <ng-template #tsaReviewRequestDeadLine>
                            <div class="summary-item-value" *ngIf="contraventionOverview.tsaReviewRequestDeadLine">
                                {{ contraventionOverview.tsaReviewRequestDeadLine | date:"mediumDate" }}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-3">
                        <button *ngIf="!contraventionOverview.isPaymentInProgress && hasTransactionId" class="btn-block goa-button goa--secondary mt-0" (click)="startReviewRequest()">Request a Review</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="content mb-4">            
            <div class="card">
                <ng-container>
                    <div class="card-header">
                        Contravention Information
                    </div>
                    <div class="card-body">
                        <div *ngFor="let ci of irsContraventions; last as isLast">
                            <app-contravention-information2 [contraventionInformation]="ci"></app-contravention-information2>
                            <hr *ngIf="!isLast">
                        </div>
                        <hr *ngIf="irsSeizures.length > 0">
                        <div *ngFor="let s of irsSeizures; last as isLast">
                            <app-seizure-information [seizureInformation]="s" [contraventionSubmissionDate]="contraventionOverview.submissionDate"></app-seizure-information>
                            <hr *ngIf="!isLast">
                        </div>
                        <hr *ngIf="tsaContraventions.length > 0">
                        <div *ngFor="let ci of tsaContraventions; last as isLast">
                            <app-contravention-information2 [contraventionInformation]="ci"></app-contravention-information2>
                            <hr *ngIf="!isLast">
                        </div>
                        <hr *ngIf="sdpSeizures.length > 0">
                        <div *ngFor="let s of sdpSeizures; last as isLast">
                            <app-seizure-information [seizureInformation]="s"></app-seizure-information>
                            <hr *ngIf="!isLast">
                        </div>
                    </div>                    
                </ng-container>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end">
        <div class="info-btn-container mb-4">
            <button class="btn-block goa-button goa--secondary mt-0" (click)="viewActivityHistory()">View Activity History</button>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="licencePickupModal" tabindex="-1" role="dialog" aria-labelledby="licencePickupModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" *ngIf="contraventionOverview">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="licencePickupModalLabel">
                    Driver's Licence Pick Up
                </span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0">
                <p *ngIf="contraventionOverview.returnLicenceByMail; else pickupAtAddress">
                    Your driver's licence will be returned to you <strong>by regular mail</strong> upon the expiry of the driver's licence suspension.
                </p>
                <ng-template #pickupAtAddress>
                    <p>
                        Your driver's licence will be returned to you upon the expiry of the driver's licence suspension.
                    </p>
                    <p>
                        To pick up your driver's licence, you must attend {{this.contraventionOverview.licencePickupDetachment}} at
                        {{this.contraventionOverview.licencePickupAddress}} <strong>within 7 days</strong> following the expiry of
                        the driver's licence suspension. Otherwise, your driver's licence will be destroyed.
                    </p>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="confirmReviewModal" tabindex="-1" role="dialog" aria-labelledby="confirmReviewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <div class="card goa-callout goa--emergency mt-0">
                    <h6 class="mt-0 font-weight-bold">A review won't reduce fines or penalties</h6>
                    <p>
                        An adjudicator <strong>cannot lower the fine amount</strong> of a contravention, 
                        and they <strong>cannot reduce any associated penalities</strong> (e.g. demerits, licence suspension).
                    </p>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0">
                <p class="font-weight-bold">Do you wish to continue?</p>
            </div>
            <div class="modal-footer border-0 d-flex justify-content-between">
                <button type="button" class="goa-button goa--secondary mt-2 btn-confirm" 
                    data-dismiss="modal">No</button>
                <button type="button" class="goa-button mt-2 btn-confirm" 
                    data-dismiss="modal" (click)="startReviewRequest()">Yes</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true" data-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="confirmModalLabel">
                    {{contraventionOverview?.isReviewInProgress? "Review in progress" : "Right to dispute"}}
                </span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0">
                <p><b>Please read the following statement to your client and confirm acknowledgement from the client prior to proceeding.</b></p>
                <p *ngIf="!contraventionOverview?.isReviewInProgress">By making a payment towards this fine, the Notice of Administrative Penalty will be confirmed and the recipient gives up the right to apply for a review of the Notice of Administrative Penalty.</p>
                <p *ngIf="contraventionOverview?.isReviewInProgress">By making a payment towards this fine, the review will be cancelled, the Notice of Administrative Penalty will be confirmed, and any future right to a review of the Notice of Administrative Penalty will be relinquished.</p>
            </div>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button type="button" class="goa-button goa--secondary btn-block" data-dismiss="modal">Close</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button type="button" class="goa-button goa--primary btn-block" data-dismiss="modal" (click)="makePayment()">Confirm Acknowledgement</button>
                    </div>
                </div>
            </div>	
        </div>
    </div>
</div>

<!--TransactionId Dialog-->
<div class="modal" id="contravention-payment-status-modal">
    <div class="modal-dialog">
      <div class="modal-content">
  
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Payment Transaction Status</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
            Takes time to reset, return to MOVES/try again
        </div>
  
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="goa-button" data-dismiss="modal">Close</button>
        </div>
  
      </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      
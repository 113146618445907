import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/observable/of';

import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { APP_CONFIG } from '@apis/app-config';
import { PaymentTimeRequest } from '@apis/shared/models/payment-time-request.model';
import { PaymentAdditionalTimeRequest2 } from '@apis/shared/models/payment-additional-time-request2.model';
import { PaymentRequest } from '@apis/shared/models/payment-request.model';
import { PaymentResponse } from '@apis/shared/models/payment-response.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { Review } from '@apis/shared/models/review.model';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { AvailabilityView } from '@apis/shared/models/availability-view';
import { StopInformation } from '@apis/shared/models/stop-information.model';

@Injectable()
export class ContraventionService {
  private readonly apiUrl = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;
  private contravention: Contravention = null; // for caching
  public review: Review = null;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {}

  getContraventionOverview(contraventionNumber: string): Observable<ContraventionOverview> {
    return this.httpClient.get<ContraventionOverview>(`${this.apiUrl}/driver/contraventions/${contraventionNumber}/overview`,
    {
        params: {
        }
    });
  }

  getStopInformation(contraventionNumber: string): Observable<StopInformation> {
    return this.httpClient.get<StopInformation>(`${this.apiUrl}/driver/stop-information/${contraventionNumber}`,
    {
        params: {
        }
    });
  }

  getByNumber(contraventionNumber: string, forceReload: boolean = false): Observable<Contravention> {
    if (this.contravention && this.contravention.contraventionNumber === contraventionNumber && !forceReload) {
      return Observable.of(this.contravention);
    }

    return this.httpClient.get<Contravention>(`${this.apiUrl}/driver/contraventions/${contraventionNumber}`,
    {
        params: {
        }
    });
  }

  requestTimeToPay(paymentTimeRequest: PaymentTimeRequest): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/contraventions/${paymentTimeRequest.contraventionNumber}/time-to-pay-requests`,
      paymentTimeRequest,
      httpOptions
    );
  }

  requestAdditionalTimeToPay(paymentAdditionalTimeRequest2: PaymentAdditionalTimeRequest2): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/contraventions/${paymentAdditionalTimeRequest2.contraventionNumber}/additional-time-to-pay-requests`,
      paymentAdditionalTimeRequest2,
      httpOptions
    );
  }

  requestReview(review: Review) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/contraventions/${review.contraventionNumber}/reviews`,
      review,
      httpOptions
    );
  }

  requestLateReview(contraventionNumber: string, review: LateReviewRequest) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/contraventions/${contraventionNumber}/late-review-requests`,
      review,
      httpOptions
    );
  }

  processPayment(paymentRequest: PaymentRequest): Observable<PaymentResponse> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post<PaymentResponse>(
      `${this.apiUrl}/contraventions/${paymentRequest.recordNumber}/payments`,
      paymentRequest,
      httpOptions
    );
  }

  getPaymentHistroy(contraventionNumber: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/contraventions/${contraventionNumber}/payment-history`,
    {
        params: {
        }
    });
  }

  getPaymentTransaction(contraventionNumber: string, transactionId: number): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/contraventions/${contraventionNumber}/payments/${transactionId}`,
      {
          params: {
          }
      });
  }

  getActivityHistory(contraventionNumber: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/contraventions/${contraventionNumber}/activity-history`,
      {
          params: {
          }
      });
  }

  getPaymentReceipt(contraventionNumber: string, transactionId: number): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/contraventions/${contraventionNumber}/payments/${transactionId}/receipt`,
      {
          params: {
          },
          responseType: 'blob'
      });
  }

  getReviewPaymentReceipt(contraventionNumber: string, reviewNumber: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/contraventions/${contraventionNumber}/reviews/${reviewNumber}/payment-receipt`,
      {
          params: {
          },
          responseType: 'blob'
      });
  }

  getLateReviewRequestPaymentReceipt(contraventionNumber: string, requestNumber: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/contraventions/${contraventionNumber}/late-review-requests/${requestNumber}/payment-receipt`,
      {
          params: {
          },
          responseType: 'blob'
      });
  }

  getReviewAvailability(contraventionNumber: string, reviewTypeId: number, reviewMethodTypeId: number, complexity: number, currentScheduledEventId: number, specificUserId?: number, isReschedule?: boolean): Observable<AvailabilityView> {
    let params = new HttpParams();
    params = params.append('reviewTypeId', reviewTypeId.toString());
    params = params.append('reviewMethodTypeId', reviewMethodTypeId.toString());
    if (complexity) {
      params = params.append('complexity', complexity.toString());
    }
    if (currentScheduledEventId) {
      params = params.append('currentScheduledEventId', currentScheduledEventId.toString());
    }
    if (specificUserId) {
      params = params.append('specificUserId', specificUserId.toString());
    }
    if (isReschedule) {
      params = params.append('isReschedule', isReschedule);
    }

    return this.httpClient.get<AvailabilityView>(`${this.apiUrl}/contraventions/${contraventionNumber}/review/availability`, { 
      params: params      
    });
  }

  getReviewInSequenceAsync(contraventionNumber: string, reviewSequence: number, reviewTypeId: number): Observable<Review> {
    return this.httpClient.get<Review>(`${this.apiUrl}/contraventions/${contraventionNumber}/reviews/${reviewSequence}/${reviewTypeId}`,
    {
        params: {
        }
    });
  }

  getReviewComplexity(contraventionNumber: string, contraventionNumbersInReview: string[]): Observable<number> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post<number>(
      `${this.apiUrl}/contraventions/${contraventionNumber}/review/complexity`,
      contraventionNumbersInReview,
      httpOptions
    );
  }
}

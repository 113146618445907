//import { ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeclarationTypes, ReviewMethodTypes, ReviewStatusTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { Constants } from '@apis/shared/helpers/constants';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';
import { Representation } from '@apis/shared/models/representation.model';
import { Review } from '@apis/shared/models/review.model';
import { ContactMethodType } from '@apis/shared/models/types/contact-method-type.model';
import { ContraventionDetailType } from '@apis/shared/models/types/contravention-detail-type.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { ReviewMethodType } from '@apis/shared/models/types/review-method-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ReviewService } from 'apps/driver/src/app/shared/services/review.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { DriverBaseComponent } from '../../../../driver-base.component';

@Component({
  selector: 'app-review-details-edit',
  templateUrl: './review-details-edit.component.html',
  styleUrls: ['./review-details-edit.component.scss']
})
export class ReviewDetailsEditComponent extends DriverBaseComponent implements OnInit {

  genderTypes: GenderType[];
  contraventionTypes: ContraventionType[];
  contraventionDetailTypes: ContraventionDetailType[];
  contactMethodTypes: ContactMethodType[];
  reviewMethodTypes: ReviewMethodType[];

  contraventionNumber: string;
  review: Review;
  reviewSequence: number;
  reviewTypeId: number;
  contraventionOverview: ContraventionOverview;
  hasMultipleContraventions: boolean;
  errorMessage: string;
  failedAttendanceDeclarationActive: boolean = false;
  registryAgentInformation: RegistryAgentInformation;
  isRequestFailed: boolean;
  requestFailedMessage: string = null;

  ReviewTypes = ReviewTypes;
  ReviewMethodTypes = ReviewMethodTypes;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private reviewService: ReviewService,
    private readonly spinner: NgxSpinnerService,
    ) { 
    super(router);
      this.genderTypes = this.localStorageService.getGenderTypes();
      this.contraventionTypes = this.localStorageService.getContraventionTypes();
      this.contraventionDetailTypes = this.localStorageService.getContraventionDetailTypes();
      this.contactMethodTypes = this.localStorageService.getContactMethodTypes();
      this.reviewMethodTypes = this.localStorageService.getReviewMethodTypes();
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
      this.reviewSequence = Number.parseInt(paramMap.get('reviewSequence'));
      this.reviewTypeId = Number.parseInt(paramMap.get('reviewType'));

      this.contraventionOverview = this.localStorageService.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      this.review = this.localStorageService.getItem<Review>(`Review_${this.reviewSequence}_${this.reviewTypeId}`);

      // TODO: Check if the review status is COMPLETE then navigate back to the contravention.
      if (this.review == null) {
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      }

      this.hasMultipleContraventions = this.review.reviewItems.length > 1;

      if (this.review.isSubmittedByRegistry) {
          this.registryAgentInformation = this.review.registryAgentInformation[0];
      }

      this.failedAttendanceDeclarationActive = new Date(this.review.requestDate) >= new Date(+Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(0, 4), +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(5, 7)-1, +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(8, 10));
    });
  }

  onReviewTypeChange() {
      this.review.representation = this.review.isSelfSubmit ? null : new Representation({
        representationTypeId: null
      });
  }

  onSaveClick(isValid: boolean) {
    if (this.review.isRescheduleAllowed && this.failedAttendanceDeclarationActive && this.review.reviewMethodTypeId == ReviewMethodTypes.Oral && this.review.failedAttendanceDeclarationTypeId == null) {
      return;
    }
    if(isValid) {
      this.spinner.show();

      this.reviewService
        .updateReview(this.review)
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            this.router.navigate(['../details'], {
              relativeTo: this.activatedRoute,
            });
          },
          (error) => {
            this.requestFailedMessage = null;
            if (error.status === 400) {
              this.requestFailedMessage = error.error;
            }
            this.isRequestFailed = true;
            window.scroll(0,0);
          });
    }
  }

  getContraventionNumber(contraventionId: number) {
    return this.contraventionOverview.contraventions.find(c => c.contraventionId == contraventionId).contraventionNumber;
  }
  
  getPrimaryCharge(contraventionId: number) {
    var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionId == contraventionId);

    return this.getChargeDescription(contravention.contraventionTypeId);
  }

  getSecondaryCharge(contraventionId: number) {
    var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionId == contraventionId);

    return this.getChargeDescription(contravention.secondaryContraventionTypeId);
  }

  getTertiaryCharge(contraventionId: number) {
    var contravention = this.contraventionOverview.contraventions.find(c => c.contraventionId == contraventionId);

    return this.getChargeDescription(contravention.tertiaryContraventionTypeId);
  }

  getChargeDescription(contraventionTypeId: number) {
    if (contraventionTypeId) {
      return this.contraventionTypes.find(ct => ct.id == contraventionTypeId).formattedName;
    } else {
      return '';
    }
  }

}



import { SearchResultType } from './types/search-result-type.model';

export class LiteSearchResult {
    public searchResultType: SearchResultType;
    public contraventionNumber: string;
    public seizureNumber: string;
    public seizureUserType: number;
    public requireUserTypeSelection: boolean = false;
    public isIssuedToRegisteredOwner:boolean = false;
    public seizureTypeId: number;
    
    constructor(init?: Partial<LiteSearchResult>) {
        
        Object.assign(this, init);
    }
}

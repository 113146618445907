<div class="section">
    <div class="mb-4 not-for-print">
        <a *ngIf="suppressConfirmation && isRSGPortal" class="lnk-back" [routerLink]="['/rsg/vehicle-seizures', seizureNumber]">Back to Seizure Details</a>
        <a *ngIf="suppressConfirmation && !isRSGPortal" class="lnk-back" [routerLink]="['/vehicle-seizures', seizureNumber]">Back to Seizure Details</a>
        <a *ngIf="!suppressConfirmation" class="lnk-back" href="#confirmNavigationModal" data-toggle="modal" data-target="#confirmNavigationModal">Back to Seizure Details</a>
    </div>
    <div class="content">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
  
  <!-- Modal -->
  <div class="modal" id="confirmNavigationModal" tabindex="-1" aria-labelledby="confirmNavigationModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content p-4">
              <div class="modal-header border-0 pt-2">
                  <span class="modal-title h4" id="confirmNavigationModalLabel">
                      Confirm Navigation?
                  </span>
              </div>
              <div class="modal-body py-0">
                  <p>Are you sure you wish to leave this page? If you leave now, any changes you have made will not be saved.</p>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-between">
                  <button type="button" class="goa-button goa--secondary mt-2" data-dismiss="modal">Cancel</button>
                  <button type="button" (click)="continue()" class="goa-button mt-2" data-dismiss="modal">Continue</button>                  
              </div>
          </div>
      </div>
  </div>
  
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Processing... </p></ngx-spinner>      
  
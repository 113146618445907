import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { ReviewTypes } from '@apis/shared/enums/app.enum';
import { DriverBaseComponent } from '../../driver-base.component';

export abstract class SeizureLateReviewBaseComponent extends DriverBaseComponent {
  lateReviewRequest: LateReviewRequest;
  vehicleSeizure: VehicleSeizure;
  ReviewTypes = ReviewTypes;
  tempFolderName: string;
  seizureNumber: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorage: LocalStorageService
  ) {
    super(router);
    if (!this.lateReviewRequest) {
      this.lateReviewRequest = this.localStorage.getLateReviewRequest();

      // Navigate to seizure details page if late review request is null
      if (this.lateReviewRequest == null) {
        this.router.navigate(['../../../'], {
          relativeTo: this.activatedRoute,
        });
      }

      this.seizureNumber = this.lateReviewRequest.lateReviewRequestItems[0].recordNumber;

      this.vehicleSeizure = this.localStorage.getItem<VehicleSeizure>(
        this.seizureNumber
      );
      this.tempFolderName = this.localStorage.getTempFolderName();
    }
  }

  public saveContext() {
    this.localStorage.setLateReviewRequest(this.lateReviewRequest);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@apis/shared/helpers/constants';
import { Setting } from '@apis/shared/models/setting.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SettingService } from '@apis/shared/services/setting.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-driver-header',
  templateUrl: './driver-header.component.html',
  styleUrls: ['./driver-header.component.scss']
})
export class DriverHeaderComponent implements OnInit {
  environmentName: String = "";
  maintenanceMessageHeader: String = "";
  maintenanceMessage: String = "";
  showLogout: boolean = false;
  userName: string;
  
  constructor(private readonly settingService: SettingService,
              private localStorageService: LocalStorageService,
              private router: Router) { 
                this.localStorageService.getLoggedInUserName.subscribe(name => {this.userName = name});
              }

  ngOnInit(): void {
    this.environmentName = environment.name;
    this.getMaintenanceMessage();
  }

  private getMaintenanceMessage()
  {
    this.settingService.getSettings()
    .subscribe((settings: Setting[]) => {
      if (settings) {
        this.maintenanceMessage  = settings.find(s => s.settingName == Constants.Settings.DRIVER_MAINTENANCE_MESSAGE)?.settingValue;
        this.maintenanceMessageHeader  = settings.find(s => s.settingName == Constants.Settings.DRIVER_MAINTENANCE_MESSAGE_HEADER)?.settingValue;
      }
    });
  }

  onLogoutClick()
  {
    this.localStorageService.logout();
    this.router.navigate(['/session-expired']);
  }

  onDailyBalanceReportClick()
  {
    this.router.navigate(['/rsg/daily-balance-report']);
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';

import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { APP_CONFIG } from '@apis/app-config';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { map } from 'rxjs/operators';

@Injectable()
export class SchedulerService {
  private readonly apiUrl = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;

    constructor(
      private readonly httpClient: HttpClient,
      private readonly localStorageService: LocalStorageService,
      @Inject(APP_CONFIG) private appConfig: any
    ) {}

  reserveEvent(scheduledEvent: ScheduledEvent): Observable<ScheduledEvent> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.post(
      `${this.apiUrl}/scheduler/scheduled-events/reserve`,
      scheduledEvent,
      httpOptions
    ).pipe(
      map(response => new ScheduledEvent(response)));
  }

  releaseEvent(scheduledEventId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient.delete(
      `${this.apiUrl}/scheduler/scheduled-events/${scheduledEventId}/release`,
      httpOptions
    ).pipe(
      map(response => response)
    );
  }
}
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ReviewStatusTypes,
  ReviewTypes,
  SeizureUserType,
  VehicleInterestTypes,
} from '@apis/shared/enums/app.enum';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';
import { Representation } from '@apis/shared/models/representation.model';
import { ReviewItem } from '@apis/shared/models/review-item.model';
import { Review } from '@apis/shared/models/review.model';
import { ContactMethodType } from '@apis/shared/models/types/contact-method-type.model';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ReviewService } from 'apps/driver/src/app/shared/services/review.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { SeizureReviewDetailsBaseComponent } from '../seizure-review-details-base.component';

@Component({
  selector: 'app-seizure-review-details-edit',
  templateUrl: './seizure-review-details-edit.component.html',
  styleUrls: ['./seizure-review-details-edit.component.scss'],
})
export class SeizureReviewDetailsEditComponent extends SeizureReviewDetailsBaseComponent
 implements OnInit {  

  genderTypes: GenderType[];
  contactMethodTypes: ContactMethodType[];

  reviewItem: ReviewItem;
  registryAgentInformation: RegistryAgentInformation;
  isRequestFailed: boolean;
  requestFailedMessage: string = null;

  VehicleInterestTypes = VehicleInterestTypes;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public reviewService: ReviewService,
    private readonly spinner: NgxSpinnerService
  ) {
    super(router, activatedRoute, localStorageService, reviewService);    
  }

  ngOnInit(): void {
    super.onInit();
  }

  protected onInitComplete(): void {
    this.genderTypes = this.localStorageService.getGenderTypes();
    this.contactMethodTypes = this.localStorageService.getContactMethodTypes();
    
    this.reviewItem = this.review.reviewItems[0];

    if (this.review.isSubmittedByRegistry) {
        this.registryAgentInformation = this.review.registryAgentInformation[0];
    }
  }

  onReviewTypeChange() {
    this.review.representation = this.review.isSelfSubmit
      ? null
      : new Representation({
          representationTypeId: null,
        });
  }

  onSaveClick(isValid: boolean) {
    if (isValid) {
      this.spinner.show();

      this.reviewService
        .updateReview(this.review)
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            this.review = result;

            this.router.navigate(['../details'], {
              relativeTo: this.activatedRoute,
            });
          },
          (error) => {
            this.requestFailedMessage = null;
            if (error.status === 400) {
              this.requestFailedMessage = error.error;
            }
            this.isRequestFailed = true;
            window.scroll(0, 0);
          }
        );
    }
  }
}

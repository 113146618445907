<app-contravention-page #masterPage>
    <ng-container content>
        <form #paymentForm="ngForm" [mustMatch]="['recipientEmail', 'recipientConfirmEmail']">
        <div class="d-flex flex-column">
            <h1>Payment Method</h1>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Amount
                </div>
                <div>  
                    <ng-template ngFor let-li [ngForOf]="paymentRequest.lineItems">
                        <ng-template [ngIf]="li.isChecked"> 
                            <div class="d-flex justify-content-between align-items-center border-bottom card-row line-item-row">
                                <div>Contravention {{li.recordNumber}}</div>
                                <div><h5>{{li.amount | currency}}</h5></div>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Amount Total</h5></div>
                        <div><h5>{{paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Method
                </div>
                <div class="card-body">  
                    <app-payment-details #paymentDetails [isSubmitClicked]="paymentForm.submitted"
                        [(paymentRequest)]="paymentRequest"></app-payment-details>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Receipt
                </div>
                <div class="card-body">  
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="recipientEmail" class="required">Send a Receipt to Email</label>
                            <input type="text" [(ngModel)]="paymentRequest.recipientEmail" required email
                                id="recipientEmail" name="recipientEmail" #recipientEmail="ngModel"
                                [ngClass]="{ 'is-invalid': paymentForm.submitted && recipientEmail.invalid }"
                                class="form-control" placeholder="Enter your preferred email address">
                            <div class="invalid-feedback">
                                <div *ngIf="recipientEmail.errors?.required">Email is required</div>
                                <div *ngIf="recipientEmail.errors?.email">Please provide a valid email address</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="recipientConfirmEmail" class="required">Confirm Email</label>
                            <input type="text" [(ngModel)]="confirmEmailAddress" required email
                                id="recipientConfirmEmail" name="recipientConfirmEmail" #recipientConfirmEmail="ngModel"
                                [ngClass]="{ 'is-invalid': paymentForm.submitted && recipientConfirmEmail.invalid }"
                                class="form-control" placeholder="Confirm email address">
                            <div class="invalid-feedback">
                                <div *ngIf="recipientConfirmEmail.errors?.required">Confirm Email is required</div>
                                <div *ngIf="recipientConfirmEmail.errors?.email">Please provide a valid email address</div>
                                <div *ngIf="recipientConfirmEmail.errors?.mustMatch">Emails must match</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../amount']">Back to Payment Amount</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button goa--primary btn-block" (click)="continuePayment(paymentForm.valid)">Review Payment</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-contravention-page>
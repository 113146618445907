import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { DriverBaseComponent } from '../../driver-base.component';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';

export abstract class LateReviewBaseComponent extends DriverBaseComponent {
  lateReviewRequest: LateReviewRequest;
  contraventionOverview: ContraventionOverview;
  tempFolderName: string;
  contraventionNumber: string;
  
  constructor(
    router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorage: LocalStorageService
  ) {
    super(router);
    
    if (!this.lateReviewRequest) {
      this.lateReviewRequest = this.localStorage.getLateReviewRequest();

      // Navigate to contravention details page if late review request is null
      if (this.lateReviewRequest == null) {
        this.router.navigate(['../../../'], {
          relativeTo: this.activatedRoute,
        });
      }

      this.contraventionNumber = this.activatedRoute.snapshot.params.contraventionNumber;

      this.tempFolderName = this.localStorage.getTempFolderName();

      this.contraventionOverview = this.localStorage.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );
    }
  }

  public saveContext() {
    this.localStorage.setLateReviewRequest(this.lateReviewRequest);
  }

  public getNotices() {
    let notices: string;

    this.lateReviewRequest.lateReviewRequestItems.forEach(i => {
      if (notices) {
        notices += `, ${i.recordNumber}`;
      } else {
        notices = i.recordNumber;
      }
    });

    return notices;
  }
}

<app-contravention-page #masterPage>
    <ng-container content>
        <form #paymentForm="ngForm">
        <div class="d-flex flex-column">
            <h1>Review Payment</h1>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isPaymentFailed">
                <h6 class="mt-0">Transaction Failed</h6>
                <p *ngIf="errorMessage && errorMessage.length > 0">
                    {{ errorMessage }}
                </p>
                <p *ngIf="errorMessage == null || errorMessage == ''">
                    Your payment was unsuccessful. Please re-enter your <a [routerLink]="['../method']">Payment Method Details</a> and try again.
                </p>
            </div>

            <div class="card goa-callout goa--important mt-3" *ngIf="!isPaymentFailed">
                <h6 class="mt-0">Payments are non-refundable</h6>
                <p>
                    Be advised that all payments to SafeRoads Alberta are final. No refunds will be issued.
                </p>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Details
                </div>
                <div>  
                    <ng-template ngFor let-li [ngForOf]="paymentRequest.lineItems">
                        <ng-template [ngIf]="li.isChecked"> 
                            <div class="d-flex justify-content-between align-items-center border-bottom card-row line-item-row">
                                <div>Contravention {{li.recordNumber}}</div>
                                <div><h5>{{li.amount | currency}}</h5></div>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center h6">
                        <h5>Amount Total</h5>
                        <h5>{{paymentRequest.paymentAmount | currency}}</h5>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Method
                </div>
                <div class="card-body">  
                    <div id="cardLogoDiv"></div>
                    <div>
                        ending in {{paymentRequest.cardLastFour}}
                    </div>
                </div>        
            </div>

            <div class="card mt-4 border-0">
                <h5>
                    Terms & Conditions
                </h5>
                <div class="checkbox">
                    <input required type="checkbox" id="ackPenalty" name="ackPenalty" class="mr-2"
                        #ackPenalty="ngModel" [(ngModel)]="isTermsAcknowledged" 
                        [ngClass]="{'is-invalid': paymentForm.submitted && ackPenalty.invalid }">
                    <label class="required" for="ackPenalty">
                        I acknowledge that by paying my fine, 
                        <b>I am confirming my Notice of Administrative Penalty and I am giving up my right to request a review</b>
                        of the Notice of Administrative Penalty.
                    </label>        
                    <div class="invalid-feedback">
                        <div>Please indicate that you have read and agree to the above condition</div>
                    </div>
                </div>
        
                <div class="mt-2">
                    <app-terms-conditions [hideUpdateAlert]="true" [isSubmitClicked]="paymentForm.submitted"></app-terms-conditions>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../method']">Back to Payment Method</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">                
                        <button class="goa-button goa--primary btn-block" [disabled]="!hasValidToken && !isTransactionComplete"
                        (click)="onSubmitPaymentClick(paymentForm.valid)">Confirm & Submit Payment</button>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="cancelReviewModal" tabindex="-1" role="dialog" aria-labelledby="cancelReviewModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content p-4">
                        <div class="modal-header border-0 pt-2">
                            <span class="modal-title h4" id="cancelReviewModalLabel">
                                Submitting This Payment Will Cancel Your Scheduled Review
                            </span>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="far fa-times-circle fa-lg"></i>
                            </button>
                        </div>
                        <div class="modal-body py-0">
                            <p>Are you sure you wish to proceed with payment? The review fee cannot be refunded.</p>
                            <div class="checkbox mt-2">
                                <input type="checkbox" id="consent" name="consent" class="mr-2"
                                    [(ngModel)]="isConsentAccepted">
                                <label for="consent">
                                        I acknowledge that by paying my fine, I am cancelling my review, confirming my Notice of Administrative Penalty, and giving up my right to a review of the Notice of Administrative Penalty.
                                </label>        
                            </div>
                        </div>
                        <div class="modal-footer border-0 d-flex justify-content-between">
                            <button type="button" class="goa-button goa--secondary mt-2" 
                                data-dismiss="modal" (click)="onCancelClick()">Cancel Payment</button>
                            <button type="button" class="goa-button mt-2" [disabled]="isConsentAccepted == false" 
                                data-dismiss="modal" (click)="onConfirmClick()">Cancel Review & Submit Payment</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="giveUpRightToReviewModal" tabindex="-1" role="dialog" aria-labelledby="giveUpRightToReviewModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content p-4">
                        <div class="modal-header border-0 pt-2">
                            <span class="modal-title h4" id="giveUpRightToReviewModalLabel">
                                WARNING
                            </span>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="far fa-times-circle fa-lg"></i>
                            </button>
                        </div>
                        <div class="modal-body py-0">
                            <p>By proceeding to pay your fine, you will be taken to have given up your right to review and will no longer be able to request a review with SafeRoads Alberta.</p>
                            <p><strong>Do not proceed to pay unless you wish to give up your right to review.</strong></p>
                        </div>
                        <div class="modal-footer border-0 d-flex justify-content-between">
                            <button type="button" class="goa-button goa--secondary mt-2"
                                data-dismiss="modal" (click)="onCancelClick()">Cancel Payment</button>
                            <button type="button" class="goa-button mt-2"
                                data-dismiss="modal" (click)="onConfirmClick()">Proceed to Pay</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </form>
    </ng-container>
</app-contravention-page>
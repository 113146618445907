<div class="card mt-4">
    <div class="card-header checkbox-lg">
        <input type="checkbox" [disabled]="isLocked"
            id="chkLineItem_{{contraventionInformation.contraventionNumber}}"
            name="chkLineItem_{{contraventionInformation.contraventionNumber}}"
            [(ngModel)]="lineItem.isChecked">
        <label for="chkLineItem_{{contraventionInformation.contraventionNumber}}">Contravention Number: {{contraventionInformation.contraventionNumber}}</label>
    </div>
    <div>  
        <div class="d-flex justify-content-between align-items-center border-bottom card-row line-item-row">
            <div class="d-flex flex-column">
                <span>
                    {{getChargeName(contraventionInformation.contraventionTypeId)}}
                </span>
                <span class="mt-1" *ngIf="contraventionInformation.secondaryContraventionTypeId">
                    {{getChargeName(contraventionInformation.secondaryContraventionTypeId)}}
                </span>
                <span class="mt-1" *ngIf="contraventionInformation.tertiaryContraventionTypeId">
                    {{getChargeName(contraventionInformation.tertiaryContraventionTypeId)}}
                </span>
            </div>
            <div>
                {{contraventionInformation.fineAmount | currency}}
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center border-bottom card-row line-item-row"
            *ngIf="contraventionInformation.victimFineSurchargeAmount > 0">
            <div>
                Victim Fine Surcharge (VFS)
            </div>
            <div>
                {{contraventionInformation.victimFineSurchargeAmount | currency}}
            </div>
        </div>            
        <div class="d-flex justify-content-between align-items-center border-bottom card-row line-item-row">
            <div>
                Sum of Previous Payments
            </div>
            <div>
                ({{contraventionInformation.paidAmount | currency}})
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center border-bottom card-row line-item-row">
            <div>
                <strong>Remaining Balance</strong>
            </div>
            <div>
                <strong>{{contraventionInformation.dueAmount | currency}}</strong>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center border-bottom card-row line-item-row">
            <div class="d-flex flex-column">
                <strong>Payment Amount</strong>
                <input type="text" mask="separator.2" thousandSeparator="" separatorLimit="1000"
                    [(ngModel)]="lineItem.amount" #paymentAmount="ngModel"
                    id="paymentAmount_{{contraventionInformation.contraventionNumber}}"
                    name="paymentAmount_{{contraventionInformation.contraventionNumber}}"
                    [required]="lineItem.isChecked"
                    [readOnly]="isPaymentAmountReadonly"
                    [ngClass]="{ 'is-invalid': isSubmitClicked && paymentAmount.invalid }"
                    [range]="contraventionInformation.dueAmount"
                    class="form-control payment-amount mt-2">
                <div class="invalid-feedback">
                    <div *ngIf="paymentAmount.hasError('required')">Payment Amount is required</div>
                    <div *ngIf="paymentAmount.hasError('invlaidMinValue')">Payment Amount must be a minimum of $100</div>
                    <div *ngIf="paymentAmount.hasError('invlaidMaxValue')">Payment Amount cannot be no more than {{contraventionInformation.dueAmount | currency}}</div>
                </div>   
            </div>
            <div>
                <strong>({{(lineItem.amount ? lineItem.amount : 0) | currency}})</strong>
            </div>
        </div>
    </div>
</div>
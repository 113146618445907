

import { RegistryAgentInformation } from './registry-agent-information.model';
import { Representation } from './representation.model';

export class PaymentTimeRequest {
    public paymentTimeRequestId: number = 0;
    public contraventionId: number = 0;
    public isSelfSubmit: boolean = false;
    public representationId: number;
    public contactMethodTypeId: number;
    public phoneNumber: string;
    public email: string;
    public requestDate: Date = new Date();
    public representation: Representation;
    public contraventionNumber: string;
    public contraventionList: string[] = [];
    public termsConditionsVersion: string;
    public registryAgentInformation: RegistryAgentInformation[] = [];
    public isSubmittedByRegistry: boolean;

    constructor(init?: Partial<PaymentTimeRequest>) {
        
        Object.assign(this, init);
    }
}

<!--Driver Portal View-->
<app-contravention-page *ngIf="!isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form #tncForm="ngForm">
            <div class="d-flex flex-column">
                <div class="d-flex w-100 border-bottom pb-2 mb-2">
                    <h1>Terms & Conditions</h1>
                    <div class="countdown">
                        <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                    </div>
                </div>

                <div class="card border-0 my-3" *ngIf="hasMultipleReviewTypes">
                    <div class="custom-card-header">
                        Which contravention type do you wish to request a review for?
                    </div>
                    <div class="custom-card-body py-2">
                        <div class="font-italic">
                            Note: If you wish to request a review for both contravention types, each review must be requested separately.
                        </div>
                    </div>
                    <div class="mt-1">
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="reviewTypeIrs" name="reviewType"
                                required #reviewType="ngModel" [value]="1" [(ngModel)]="review.reviewTypeId">
                            <label class="custom-control-label opt-light" for="reviewTypeIrs">Impaired (IRS)</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="reviewTypeTsa" name="reviewType"
                                required [value]="4" [(ngModel)]="review.reviewTypeId">
                            <label class="custom-control-label opt-light" for="reviewTypeTsa">Traffic Notice(s)</label>
                        </div>
                        <div>
                            <div class="text-danger" *ngIf="tncForm.submitted && reviewType.errors?.required">
                                Please select a contravention type.
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 border-bottom"></div>
                </div>
            
                <div class="card border-0">
                    <app-foip></app-foip>
                </div>
    
                <div class="mt-2">
                    <app-terms-conditions [isSubmitClicked]="tncForm.submitted"></app-terms-conditions>
                </div>  
        
                <div class="form-group">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../']">Return to Contravention Overview</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">                
                            <button class="goa-button goa--primary btn-block" (click)="onProceedClick(tncForm.valid)">Proceed to Review Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-contravention-page>

<!--Registry Portal View-->
<app-contravention-page *ngIf="isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form #tncForm="ngForm">
            <div class="d-flex flex-column">
                <h1 class="">Terms & Conditions</h1>
                <div class="border-bottom my-2">
                    <div class="row">
                        <div class="countdown">
                            <countdown-timer [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                        </div>
                    </div>
                </div>
                
                <div class="card border-0 my-3" *ngIf="hasMultipleReviewTypes">
                    <div class="custom-card-header">
                        Which contravention type does the client wish to request a review for?
                    </div>
                    <div class="custom-card-body py-2">
                        <div class="font-italic">
                            Note: If the client wishes to request a review for both contravention types, each review must be requested separately.
                        </div>
                    </div>
                    <div class="mt-1">
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="reviewTypeIrs" name="reviewType"
                                required #reviewTypeIrs="ngModel" [value]="1" [(ngModel)]="review.reviewTypeId">
                            <label class="custom-control-label opt-light" for="reviewTypeIrs">Impaired (IRS)</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="reviewTypeTsa" name="reviewType"
                                required #reviewTypeTsa="ngModel" [value]="4" [(ngModel)]="review.reviewTypeId">
                            <label class="custom-control-label opt-light" for="reviewTypeTsa">Traffic Notice(s)</label>
                        </div>
                        <div>
                            <div class="text-danger" *ngIf="tncForm.submitted && reviewTypeIrs.errors?.required">
                                Please select a contravention type.
                            </div>
                        </div>
                    </div>
                </div>

                <app-review-terms-conditions *ngIf="review.reviewTypeId==ReviewTypes.IrsReview"
                [infoSheetName]="Constants.Registry.TRANS30_INFO_SHEET_NAME" 
                [infoSheetLink]="Constants.Registry.TRANS30_INFO_SHEET_LINK"
                [lateEvidenceInfoSheetName]="Constants.Registry.LATE_EVIDENCE_INFO_SHEET_NAME" 
                [lateEvidenceInfoSheetLink]="Constants.Registry.LATE_EVIDENCE_INFO_SHEET_LINK"
                [isSubmitClicked]="tncForm.submitted"></app-review-terms-conditions>

                <app-review-terms-conditions *ngIf="review.reviewTypeId==ReviewTypes.TrafficReview"
                [infoSheetName]="Constants.Registry.TRAFFIC_REVIEW_SHEET_NAME" 
                [infoSheetLink]="Constants.Registry.TRAFFIC_REVIEW_SHEET_LINK"
                [isSubmitClicked]="tncForm.submitted"></app-review-terms-conditions>
        
                <div class="form-group">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../']">Return to Contravention Overview</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">                
                            <button class="goa-button goa--primary btn-block" (click)="onProceedClick(tncForm.valid)">Proceed to Review Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-contravention-page>
import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { RangeValidator } from '../validators/range.validator';

@Directive({
    selector: '[range]',
    providers: [{ provide: NG_VALIDATORS, useExisting: RangeDirective, multi: true }]
})
export class RangeDirective implements Validator {
    @Input('range') maxRange: number;

    validate(control: AbstractControl): ValidationErrors {
		  return RangeValidator(100, this.maxRange)(control);
    }
}
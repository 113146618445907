import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-footer',
  templateUrl: './driver-footer.component.html',
  styleUrls: ['./driver-footer.component.scss']
})
export class DriverFooterComponent implements OnInit {
  year: number;

  constructor() { }

  ngOnInit(): void {
    this.year = (new Date()).getFullYear();
  }

}

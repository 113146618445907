import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewTypes, SeizureUserType } from '@apis/shared/enums/app.enum';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { VehicleSeizureService } from 'apps/driver/src/app/shared/services/vehicle-seizure.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverBaseComponent } from '../../../../driver-base.component';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-seizure-review-submission-complete',
  templateUrl: './seizure-review-submission-complete.component.html',
  styleUrls: ['./seizure-review-submission-complete.component.scss']
})
export class SeizureReviewSubmissionCompleteComponent extends DriverBaseComponent
 implements OnInit {

  // Placeholder values
  reviewContext = "Review";
  public review = {
    reviewNumber: '',
    seizureNumber: '',
    reviewSequence: 1
  };

  ReviewTypes = ReviewTypes;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private seizureService: VehicleSeizureService,
    private spinner: NgxSpinnerService,
    public schedulerService: SchedulerService
    ) { 
    super(router);
  }

  ngOnInit(): void {
    const createdReview = this.localStorageService.getCreatedReview();
    const reviewRequest = this.localStorageService.getReviewRequest();

    // Clear review request session from localstorage
    this.localStorageService.setReviewRequest(null);

    if (createdReview) {
      this.review = createdReview;
      const seizureUserType = this.localStorageService.get(`${this.review.seizureNumber}_user_type`);
      if (+seizureUserType != SeizureUserType.Driver) {
        this.reviewContext = "Relief";
      }
    }
    else if (reviewRequest) {

      // Immediately get the review and seizure numbers; required to print a receipt
      this.review.reviewNumber = reviewRequest.reviewNumber;
      this.review.seizureNumber = reviewRequest.seizureNumber;
      this.review.reviewSequence = reviewRequest.reviewSequence;

      const seizureUserType = this.localStorageService.get(`${reviewRequest.seizureNumber}_user_type`);
      if (+seizureUserType != SeizureUserType.Driver) {
        this.reviewContext = "Relief";
      }

      // If we are in the driver portal, setup localStorage for supporting-documents
      if (!this.isRSGPortal) {
        this.seizureService.getByNumber(reviewRequest.seizureNumber, true).subscribe((vehicleSeizure: VehicleSeizure) => {
          if (vehicleSeizure) {
            this.localStorageService.setCreatedReview(this.review);
            this.localStorageService.setItem<VehicleSeizure>(vehicleSeizure.seizureNumber, vehicleSeizure);
          }
        });
      }
    }
    else {
      // If createdReview and reviewRequest are null, navigate to seizure-details
      this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
    }
  }

  onPrintReceiptClick() {
    this.spinner.show();
    this.seizureService
      .getReviewPaymentReceipt(
        this.review.seizureNumber,
        this.review.reviewNumber)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Seizure Review - ${this.review.reviewNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }

  continueRequest() {
    this.router.navigate(['../supporting-documents'], { relativeTo: this.activatedRoute, });
  }
}

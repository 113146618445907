import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ReviewService } from '../../../../..//shared/services/review.service';
import { ReviewMethodType } from '@apis/shared/models/types/review-method-type.model';
import { NgForm, NgModel} from '@angular/forms';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { DatePipe } from "@angular/common";
import { TimePipe } from "@apis/shared/pipes/time.pipe";
import { AvailabilityView } from '@apis/shared/models/availability-view';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverBaseComponent } from '../../../../driver-base.component';
import { Contravention } from '@apis/shared/models/contravention.model';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { AvailabilityInformation } from '@apis/shared/models/availability-information';
import { addHours, parseJSON } from 'date-fns';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { MeetingTypes, ReviewMethodTypes, ReviewStatusTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { Observable } from 'rxjs';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { Constants } from '@apis/shared/helpers/constants';
declare var $: any

@Component({
  selector: 'app-review-details-reschedule',
  templateUrl: './review-details-reschedule.component.html',
  styleUrls: ['./review-details-reschedule.component.scss']
})
export class ReviewDetailsRescheduleComponent extends DriverBaseComponent implements OnInit {
  review: Review;
  contraventionNumber: string;
  formSubmitted: boolean = false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  dateCustomClasses: DatepickerDateCustomClasses[];
  reviewMethodTypes: ReviewMethodType[];
  datePipe: DatePipe = new DatePipe("en-US");
  timePipe: TimePipe = new TimePipe();  
  availabilityView: AvailabilityView;
  availabilityViewForWritten:AvailabilityInformation[];
  selAvailableInfoForWritten: AvailabilityInformation;

  reviewSequence: number;
  reviewTypeId: number;
  contraventionOverview: ContraventionOverview;
  newReviewDateUtc: Date;
  
  isRequestFailed: boolean;
  requestFailedMessage: string = null;
  isAvailabilityRequestFailed: boolean;
  documentSubmissionCutoffDate: Date;
  DateUtil = DateUtil;  
  isSubmitDisabled = true;
  failedAttendanceDeclarationActive: boolean = false;

  selReviewMethod:string='';  
  ReviewStatusTypes = ReviewStatusTypes;
  ReviewTypes = ReviewTypes;
  Constants = Constants;

  @ViewChild("rescheduleReviewForm") rescheduleReviewForm: NgForm;
  @ViewChild("reviewDate") reviewDate: NgModel;
  @ViewChild("reviewTime") reviewTime: NgModel;

  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private contraventionService: ContraventionService,
    private reviewService: ReviewService,
    private readonly spinner: NgxSpinnerService,
    private readonly schedulerService: SchedulerService
  ) {
    super(router);
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true
      });

    this.dateCustomClasses = [
      { date: new Date(), classes: ["bg-secondary", "text-white"] }
    ];    
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
      this.reviewSequence = Number.parseInt(paramMap.get('reviewSequence'));
      this.reviewTypeId = Number.parseInt(paramMap.get('reviewType'));

      this.contraventionOverview = this.localStorageService.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      this.review = this.localStorageService.getItem<Review>(`Review_${this.reviewSequence}_${this.reviewTypeId}`);

      if (this.review == null) {
        this.router.navigate(['../../../../'], { relativeTo: this.activatedRoute });
      }

      this.reviewMethodTypes = this.localStorageService.getReviewMethodTypes()
        .sort((a: ReviewMethodType, b: ReviewMethodType) => {
          return +b.displayOrder - (+a.displayOrder);
        });

      if (this.review.reviewDate) {
        this.newReviewDateUtc = parseJSON(this.review.reviewDate);
      }

      this.getReviewSchedule();
      this.failedAttendanceDeclarationActive = new Date(this.review.requestDate) >= new Date(+Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(0, 4), +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(5, 7)-1, +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(8, 10));
    });
  }

  onReviewMethodChange(): void {  
    $('#confirmModal').modal();      
  }

  changeReviewMethod(): void {   
    this.review.reviewDate=null;
    this.review.reviewTime=null; 
    this.review.failedAttendanceDeclarationTypeId=null;
    this.newReviewDateUtc=null;
    this.documentSubmissionCutoffDate=null;
    this.isSubmitDisabled=true;
    this.getReviewSchedule();
  }

  cancelReviewMethodChange():void{
    if(this.review.reviewMethodTypeId==1)
    {
      this.review.reviewMethodTypeId =2;
    }
    else
    {
      this.review.reviewMethodTypeId =1;
    }
  }
  
  onScheduleRefreshClick(): void {
    this.getReviewSchedule();
  }

  onScheduleDateSelected(e): void {
    this.isSubmitDisabled = true;    
    
    this.review.reviewTime = this.datePipe.transform(e, "HH:mm:ss", "UTC");
    this.newReviewDateUtc = new Date(e.setUTCHours(0, 0, 0, 0));

    $('#confirmDateModal').modal();
  }

  onDateSelected(availableInfo:AvailabilityInformation)
  {   
    this.isSubmitDisabled = true;
    this.selAvailableInfoForWritten = availableInfo;

    this.newReviewDateUtc = parseJSON(availableInfo.date);
    this.review.reviewTime = availableInfo.timeSlots[0];

    $('#confirmDateModal').modal();
  }

  selectReviewDate()
  {
    this.isSubmitDisabled = false;
    this.setDocumentSubmissionCutoffDate();
  }

  setDocumentSubmissionCutoffDate() {
    this.documentSubmissionCutoffDate = null;

    if (this.newReviewDateUtc) {
      const newReviewDateUtc = new Date(this.newReviewDateUtc);
      this.documentSubmissionCutoffDate = addHours(newReviewDateUtc, -72); // 3 days before review. Do not use addDays, because it will subtract 23 or 25 hours on a daylight savings change.
    }
  }

  onRescheduleClick(ev: any): void {
    if (this.rescheduleReviewForm.invalid) {
      return;
    }
    // If failedAttendanceDeclaration radio buttons are enabled and no option is selected, return instead of rescheduling
    if ((this.review.isRescheduleAllowed || this.review.reviewStatusTypeId == ReviewStatusTypes.New)
      && this.failedAttendanceDeclarationActive
      && this.review.reviewMethodTypeId == ReviewMethodTypes.Oral
      && this.review.failedAttendanceDeclarationTypeId == null) {
      return;
    }

    this.formSubmitted = true;

    const currentTermsConditionsVersion = this.localStorageService.getTermsAndConditionsList().find(t => t.expiryDate == null);

    const review = new Review({
      reviewId: +this.review.reviewId,
      reviewNumber: this.review.reviewNumber,
      reviewDate: this.newReviewDateUtc,
      reviewTime: this.review.reviewTime,
      reviewTypeId: this.review.reviewTypeId,
      reviewMethodTypeId: +this.review.reviewMethodTypeId,
      scheduledEventId: this.review.scheduledEventId,
      failedAttendanceDeclarationTypeId: this.review.failedAttendanceDeclarationTypeId,
      termsConditionsVersion: currentTermsConditionsVersion.version
    });    

    this.spinner.show();

    // Reserve time slot
    this.reserveEvent().subscribe((result: ScheduledEvent) => {
      if (result.scheduledEventId != 0) {
        review.scheduledEventId = result.scheduledEventId;
        review.scheduledEvent = Object.assign({}, result);
      }

      if (this.review.reviewStatusTypeId == ReviewStatusTypes.New) {
        this.reviewService.scheduleReReview(review)
        .subscribe(
          (result: Review) => {
            this.localStorageService.setItem(`Review_${this.reviewSequence}_${this.reviewTypeId}`, review);

            this.spinner.hide();        
            this.router.navigate(['../details'], {
              relativeTo: this.activatedRoute,
            });
          },
          (error: any) => {
            this.formSubmitted = false;
            this.isRequestFailed = true;
            this.spinner.hide();
            window.scroll(0, 0);
          });
      } else {
        this.reviewService.reScheduleReview(review)
        .subscribe(
          (result: Review) => {
            this.localStorageService.setItem(`Review_${this.reviewSequence}_${this.reviewTypeId}`, review);

            this.spinner.hide();        
            this.router.navigate(['../reschedule-complete'], {
              relativeTo: this.activatedRoute,
            });
          },
          (error: any) => {
            this.requestFailedMessage = null;
            if (error.status === 400) {
              this.requestFailedMessage = error.error;
            }

            this.formSubmitted = false;
            this.isRequestFailed = true;
            this.spinner.hide();
            window.scroll(0, 0);
          });
      }
    });
  }

  reserveEvent() : Observable<ScheduledEvent> {
    var meetingTypeId = (() => {
      switch (+this.review.reviewTypeId) {
        case ReviewTypes.IrsReview:
          return this.review.reviewMethodTypeId == ReviewMethodTypes.Oral 
                  ? MeetingTypes.IRSOralReview 
                  : MeetingTypes.IRSWrittenReview;
        case ReviewTypes.AteReview:
          return this.review.reviewMethodTypeId == ReviewMethodTypes.Oral 
                  ? MeetingTypes.ATEOralReview 
                  : MeetingTypes.ATEWrittenReview;
        case ReviewTypes.TrafficReview:
          return this.review.reviewMethodTypeId == ReviewMethodTypes.Oral 
                  ? MeetingTypes.TSAOralReview
                  : MeetingTypes.TSAWrittenReview;
      }
    })();

    var event = new ScheduledEvent({
      eventDate: this.datePipe.transform(this.newReviewDateUtc, 'M/d/yyyy', 'UTC'),
      eventTime: this.review.reviewTime,
      duration: 60,
      meetingTypeId: meetingTypeId,
      title: this.getEventTitle(),
      attendees: this.getAttendee(),
      recordNumber: this.review.contraventionNumber,
      complexity: this.review.complexity,
      scheduledEventId: this.review.scheduledEventId != null ? +this.review.scheduledEventId : 0,
      specificUserId: this.review.isReReviewBySameAdjudicator ? this.review.userId : null
    });

    return this.schedulerService.reserveEvent(event);    
  }

  getAttendee() {
    return `${this.contraventionOverview.recipientLastName} ${this.contraventionOverview.recipientFirstName} ${this.contraventionOverview.recipientMiddleName??''}`;
  }

  getEventTitle() {
    const recipientLastName = this.contraventionOverview.recipientLastName;
    return `${this.review.contraventionNumber} ${recipientLastName}`;
  }  
  
  private getReviewSchedule() {    
    this.spinner.show();
    this.isAvailabilityRequestFailed = false;

    this.contraventionService.getReviewAvailability(
      this.contraventionNumber,
      this.review.reviewTypeId,
      this.review.reviewMethodTypeId,
      this.review.scheduledEvent?.complexity,
      this.review.scheduledEventId,
      this.review.isReReviewBySameAdjudicator ? this.review.userId : null,
      true)
      .subscribe((result: AvailabilityView) => {
        this.availabilityView = result;
        this.availabilityViewForWritten = this.availabilityView?.availabilityInformationList?.filter(x=>x.timeSlots.length>0);
        if (this.newReviewDateUtc) {
          this.selAvailableInfoForWritten = this.availabilityViewForWritten.find(x => this.datePipe.transform(new Date(x.date),'dd MMM, yyyy') == this.datePipe.transform(new Date(this.newReviewDateUtc),'dd MMM, yyyy', 'UTC'));
        }
        this.spinner.hide();
      }, (error: any) => {         
        this.spinner.hide();
        this.isAvailabilityRequestFailed = true;
      });
  }

}

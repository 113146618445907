import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-foip',
  templateUrl: './foip.component.html',
  styleUrls: ['./foip.component.scss']
})
export class FoipComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

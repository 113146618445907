import { Component, Input, OnInit } from '@angular/core';
import { Contravention } from '@apis/shared/models/contravention.model';
import { ContraventionDetailType } from '@apis/shared/models/types/contravention-detail-type.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-contravention-information',
  templateUrl: './contravention-information.component.html',
  styleUrls: ['./contravention-information.component.scss'],
})
export class ContraventionInformationComponent implements OnInit {
  @Input() contravention: Contravention;
  contraventionTypes: ContraventionType[];
  contraventionDetailTypes: ContraventionDetailType[];

  contraventionDetails: number[];

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.contraventionDetails = this.contravention.contraventionDetails
        ?.split(',')
        .filter(x => +x != 0)
        .map((x) => +x);
        
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    this.contraventionDetailTypes = this.localStorageService.getContraventionDetailTypes();
  }
}
